<template>
  <div class="row">
<!--    <div class="col-lg-12 d-flex justify-content-end mb-h" v-if="isNotWizard" >-->
<!--              <div class="pl-1">-->
<!--          <button class="btn btn-warning btn-md float-right" @click="openMapsWizard" v-if="!isDisable">-->
<!--            {{ $t('maps.addMapButton') }}-->
<!--          </button>-->
<!--        </div>-->
<!--    </div>-->
<!--    <div class="col-lg-12" v-if="isNotWizard">-->
<!--      <div class="filter-block p-0">-->
<!--        <div>-->
<!--          <div class="filters">-->
<!--            <div class="filters__search">-->
<!--              <div class="input-group input-group-sm filters__input">-->
<!--                <span class="input-group-addon">-->
<!--                  <i class="fa fa-spin fa-circle-o-notch" v-if="searchSpinnerForTable"></i>-->
<!--                  <i class="fa fa-search" v-else></i>-->
<!--                </span>-->
<!--                &lt;!&ndash;                <input&ndash;&gt;-->
<!--                &lt;!&ndash;                  id="search"&ndash;&gt;-->
<!--                &lt;!&ndash;                  name="search"&ndash;&gt;-->
<!--                &lt;!&ndash;                  :placeholder="$t('aps.search')"&ndash;&gt;-->
<!--                &lt;!&ndash;                  class="form-control filters__input"&ndash;&gt;-->
<!--                &lt;!&ndash;                  v-model="filters.query"&ndash;&gt;-->
<!--                &lt;!&ndash;                  @input="disableEditMode"&ndash;&gt;-->
<!--                &lt;!&ndash;                />&ndash;&gt;-->
<!--                &lt;!&ndash;                <span class="clear-input text-muted" v-if="filters.query != ''" @click="filters.query = ''">&ndash;&gt;-->
<!--                &lt;!&ndash;                  <i class="fa fa-remove"></i>&ndash;&gt;-->
<!--                &lt;!&ndash;                </span>&ndash;&gt;-->
<!--                <input-->
<!--                  id="search"-->
<!--                  name="search"-->
<!--                  :placeholder="$t('general.search')"-->
<!--                  @keyup.13="searchInputHandlerForTable(searchValue)"-->
<!--                  class="form-control"-->
<!--                  v-model="searchValue"-->
<!--                  @input="searchInputHandlerForTable(searchValue)"-->
<!--                />-->
<!--                <span class="clear-input text-muted">-->
<!--                  <i-->
<!--                    class="fa fa-remove"-->
<!--                    v-if="searchValue && !searchSpinnerForTable"-->
<!--                    @click="searchInputHandlerForTable('')"-->
<!--                  ></i>-->
<!--                </span>-->
<!--              </div>-->

<!--&lt;!&ndash;              <div class="input-group input-group-sm filters__input ml-1">&ndash;&gt;-->
<!--&lt;!&ndash;                <span class="input-group-addon filters__field-icon">&ndash;&gt;-->
<!--&lt;!&ndash;                  <i class="fa fa-map-marker"></i>&ndash;&gt;-->
<!--&lt;!&ndash;                </span>&ndash;&gt;-->
<!--&lt;!&ndash;                <select&ndash;&gt;-->
<!--&lt;!&ndash;                  @change="selectLocationInFilterLocationSelector"&ndash;&gt;-->
<!--&lt;!&ndash;                  class="form-control form-control-sm filters__field"&ndash;&gt;-->
<!--&lt;!&ndash;                  v-model="filters.location"&ndash;&gt;-->
<!--&lt;!&ndash;                >&ndash;&gt;-->
<!--&lt;!&ndash;                  <option value="All locations" :selected="true">{{ $t('maps.allLocations') }}</option>&ndash;&gt;-->
<!--&lt;!&ndash;                  <option v-for="location in locationsList" :value="location.id" :key="location.id">&ndash;&gt;-->
<!--&lt;!&ndash;                    {{ location.name }}&ndash;&gt;-->
<!--&lt;!&ndash;                  </option>&ndash;&gt;-->
<!--&lt;!&ndash;                </select>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->

<!--&lt;!&ndash;                              <div&ndash;&gt;-->
<!--&lt;!&ndash;                  class=" d-flex  align-items-center switcher-with-childs-for-use-in-filters-wrapper">&ndash;&gt;-->
<!--&lt;!&ndash;                  <div&ndash;&gt;-->
<!--&lt;!&ndash;                    class="  d-flex align-items-end justify-content-start"&ndash;&gt;-->
<!--&lt;!&ndash;                  >&ndash;&gt;-->
<!--&lt;!&ndash;                    <Switch-component&ndash;&gt;-->
<!--&lt;!&ndash;                      :disabled="this.filters.location === 'All locations'"&ndash;&gt;-->
<!--&lt;!&ndash;                      v-model="filters.with_childs"&ndash;&gt;-->
<!--&lt;!&ndash;                      class="switcher-with-childs-for-use-in-filters"&ndash;&gt;-->
<!--&lt;!&ndash;                      @change="selectLocationInFilterLocationSelector"&ndash;&gt;-->
<!--&lt;!&ndash;                    />&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->
<!--&lt;!&ndash;                  <div&ndash;&gt;-->
<!--&lt;!&ndash;                    class="input-group input-group-sm filters__location switcher-with-childs-for-use-in-filters-caption ">&ndash;&gt;-->
<!--&lt;!&ndash;                    <small class="with-child">{{ $t('general.withChild') }}</small>&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->

<!--                                        <div class="col-lg-3 col-md-12 mr-h input-group-for-location-select-in-AnalyticsMaps&#45;&#45;filters">-->


<!--                                                                   <span class="input-group-addon filters__field-icon">-->
<!--                  <i class="fa fa-map-marker"-->
<!--                     :class="{ 'text-primary': filters.location !== 'All locations' }"></i>-->
<!--                </span>-->
<!--                  <SelectComponentV2-->
<!--                    v-if="showLocationFilter"-->
<!--                    no-wrap-selected-option-->
<!--                    class="select-location-in-AnalyticsMaps&#45;&#45;filters"-->
<!--                    enable-max-option-width-->
<!--                    small-size-->
<!--                    show-delete-button-->
<!--                    v-model="locationInFiltersData"-->
<!--                    track-by="name"-->
<!--                    option-id-name="id"-->
<!--                    :no-options-found="$t('general.noResultForSearch')"-->
<!--                    :async-function="requestLocations.bind(this)"-->
<!--                    :placeholder="$t('portalStats.selectLocation')"-->
<!--                    @select="selectLocationInFilters"-->
<!--                  >-->
<!--                    <template #dropdown-start>-->
<!--                      <div class="ml-h mt-1">-->
<!--                        <Switch-component-->
<!--                          @input="selectLocationInFilterLocationSelector"-->
<!--                          :label="$t('general.withChild')"-->
<!--                          v-model="filters.with_childs"-->
<!--                          class=""-->
<!--                        />-->
<!--                      </div>-->
<!--                    </template>-->
<!--                  </SelectComponentV2>-->


<!--              </div>-->

<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--&lt;!&ndash;        <div class="pl-1">&ndash;&gt;-->
<!--&lt;!&ndash;          <button class="btn btn-warning btn-md float-right" @click="openMapsWizard" v-if="!isDisable">&ndash;&gt;-->
<!--&lt;!&ndash;            {{ $t('maps.addMapButton') }}&ndash;&gt;-->
<!--&lt;!&ndash;          </button>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="col-lg-12 d-flex justify-content-start mb-h" v-if="isNotWizard" >-->
<!--        <div v-if="true" class="mt-1">-->
<!--              <button class="btn-outline-secondary btn btn-sm new-modern-style-btn-in-filters" @click="resetAllFilters">-->
<!--                <i class="fa fa-close"></i>-->
<!--                {{ $t('general.resetAllFilters') }}-->
<!--              </button>-->
<!--  </div>-->
<!--    </div>-->


    <div class="col-lg-12 d-flex justify-content-end mb-h" v-if="isNotWizard">
      <div class="pl-1">
        <button class="btn btn-info btn-md float-right" @click="openMapsWizard"
                v-if="!isDisable">
          {{ $t('maps.addMapButton') }}
        </button>
      </div>
    </div>
    <div class="col-lg-12" v-if="isNotWizard">
      <div class="row">
        <div class="col-lg-3 mb-h pr-0">
          <div class="input-group input-group-sm filters__input">
                <span class="input-group-addon">
                  <i class="fa fa-spin fa-circle-o-notch" v-if="searchSpinnerForTable"></i>
                  <i class="fa fa-search" v-else></i>
                </span>
            <input
              id="search"
              name="search"
              :placeholder="$t('general.search')"
              @keyup.13="searchInputHandlerForTable(searchValue)"
              class="form-control"
              v-model="searchValue"
              @input="searchInputHandlerForTable(searchValue)"
            />
            <span class="clear-input text-muted">
                  <i
                    class="fa fa-remove"
                    v-if="searchValue && !searchSpinnerForTable"
                    @click="searchInputHandlerForTable('')"
                  ></i>
                </span>
          </div>
        </div>
        <div
          class="col-lg-3 mb-h pr-0  input-group-for-location-select-in-AnalyticsMaps--filters">


                                                                   <span
                                                                     class="input-group-addon filters__field-icon">
                  <i class="fa fa-map-marker"
                     :class="{ 'text-primary': filters.location !== 'All locations' }"></i>
                </span>
          <SelectComponentV3ForUseInLocationSelectors

            v-if="showLocationFilter"
            no-wrap-selected-option
            class="select-location-in-AnalyticsMaps-filters"
            enable-max-option-width
            small-size
            :no-clear-selected-option-when-click-on-it="isSelectedDefaultLocationForRequests"
            :show-delete-button="!isSelectedDefaultLocationForRequests"
            v-model="locationInFiltersData"
            :show-search-field-into-items-list="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
            track-by="name"
            option-id-name="id"
            :no-options-found="$t('general.noResultForSearch')"
            :async-function="requestLocations.bind(this)"
            :placeholder="$t('portalStats.selectLocation')"
            @select="selectLocationInFilters"
          >
            <template #dropdown-start>
              <div class="ml-h mt-1">
                <Switch-component
                  v-if="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                  @input="selectLocationInFilterLocationSelector"
                  :label="$t('general.withChild')"
                  v-model="filters.with_childs"
                  class=""
                />
              </div>
            </template>
          </SelectComponentV3ForUseInLocationSelectors>


        </div>
        <div class="col-lg-3 mb-h pr-0">

          <div class="input-group input-group-sm ">
                <span class="input-group-addon">
                  <i
                    class="fa fa-sort-amount-desc text-primary icon-sort"
                    v-if="filters.sortOrder === 'desc'"
                    @click="toggleSortOrder"
                  ></i>
                  <i
                    class="fa fa-sort-amount-asc text-primary icon-sort"
                    v-if="filters.sortOrder === 'asc'"
                    @click="toggleSortOrder"
                  ></i>
                </span>
            <select
              @change="selectSorting"
              class="form-control form-control-sm"
              v-model="filters.sortByFilter"
            >
              <option :selected="true" value="Name">{{ $t('maps.sortTypeName') }}</option>
              <option :selected="true" value="Created">{{ $t('maps.sortTypeCreated') }}</option>
              <option :selected="true" value="Updated">{{ $t('maps.sortTypeUpdated') }}</option>
            </select>
          </div>

        </div>

      </div>
    </div>

    <div class="col-lg-12 d-flex justify-content-start mb-h" v-if="isNotWizard">
      <div v-if="isFiltersActive" class="mt-1">
        <button class="btn-outline-secondary btn btn-sm new-modern-style-btn-in-filters"
                @click="resetAllFilters">
          <i class="fa fa-close"></i>
          {{ $t('general.resetAllFilters') }}
        </button>
      </div>
    </div>


    <div class="col-lg-12" v-if="isNotWizard">
      <div class="maps " :class="{ 'edit-visible': editVisible }">
        <div class="map-list animated fadeIn">
          <div class="row sticky-top" v-show="!(mapMode.planning && editVisible && !isMapOnFullScreen)">
            <div class="col-lg-12">
              <div class="card">
                <!--                <div class="loader loader-backdrop loader-maps" v-if="loadingMaps || loadingCpes"></div>-->
                <div v-if="loadingMaps || loadingCpes"><Loader-spinner /></div>
                <div class="card-header">
                  <i class="fa fa-align-justify"></i>
                  {{ $t('maps.mapsList') }}
                  <span class="text-muted ml-3" v-if="!loadingMaps && !editVisible">
                    {{ $t('aps.tableView') }} {{ filteredMaps(mapsList).length }} {{ $t('aps.tableItemsOf') }}
                    {{ this.totalResult }}
                  </span>
                </div>
                <div class="card-block">
                  <table class="table mb-h">
                    <thead>
                      <tr>
                        <th>{{ $t('general.name') }}</th>
                        <th>{{ $t('general.cpes') }}</th>
                        <th v-if="!editVisible && isShowingZonesFunctionality">{{ $t('maps.zonesForTable') }}</th>
                        <th v-if="!editVisible">{{ $t('maps.Created') }}</th>
                        <th v-if="!editVisible">{{ $t('maps.lastUpdated') }}</th>
                        <th v-if="!editVisible">{{ $t('general.location') }}</th>
                        <th v-if="!editVisible"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(map, index) in filteredMaps(mapsList)"
                        :data-index="index"
                        :data-data-id="map.id"
                        @click="toggleEdit($event, map)"
                        class="map-row"
                        :class="{
                          selected: editVisible === map.id,
                          'map-row-disabled': !map.cpe_list.length && isDisable
                        }"
                      >
                        <td class="responsive-row">{{ map.name }}</td>
                        <td class="responsive-row">
                          <span
                            v-tooltip.top-center="{
                              content: `${$t('maps.cpesTooltipForTable')}: ` + formatCpes(map.cpe_list)
                            }"
                            :class="{ 'badge-success': map.cpe_list.length, 'badge-default': !map.cpe_list.length }"
                            class="badge badge-light mr-q"
                          >
                            <img class="img img-responsive cpe-badge" src="/static/img/router-icon-white.svg" alt />
                            {{ map.cpe_list.length || '0' }}
                          </span>
                        </td>
                        <td v-if="!editVisible && isShowingZonesFunctionality" class="responsive-row">
                          <span
                            v-tooltip.top-center="{
                              content: `${$t('maps.zonesTooltipForTable')}: ` + formatZonesForTooltip(map.Zones)
                            }"
                            :class="{ 'badge-success': map.Zones.length, 'badge-default': !map.Zones.length }"
                            class="badge badge-light mr-q"
                          >
                            {{ map.Zones.length || '0' }}
                          </span>
                        </td>
                        <td class="responsive-row" v-if="!editVisible">
                          <small>{{ map.created_at | moment }}</small>
                        </td>
                        <td class="responsive-row" v-if="!editVisible">
                          <small>{{ map.last_updated | moment }}</small>
                        </td>
                        <td class="responsive-row" v-if="!editVisible">{{ locationName(map.base_location) }}</td>
                        <td class="action-row" v-if="!editVisible && !isDisable">
                          <i class="fa fa-remove fa-lg mr-q text-danger user-action" data-action="delete"></i>
                        </td>
                      </tr>
                      <tr v-if="!filteredMaps(mapsList).length">
                        <td colspan="6" class="no-data-row">
                          <span class="text-muted">{{ $t('general.noDataToDisplay') }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div
                    v-if="canLoadMore && !editVisible && !loadingMaps && mapsList.length"
                    class="card-block text-center p-0"
                  >
                    <button class="btn btn-link" @click="loadMore()">
                      {{ $t('aps.tableLoad') }}
                      <span v-if="this.limit < this.totalResult - this.mapsList.length">{{ this.limit }}</span>
                      <span v-else>{{ this.totalResult - this.mapsList.length }}</span>
                      {{ $t('maps.tableOutOf') }} {{ this.totalResult - this.mapsList.length }}
                    </button>
                  </div>
                  <div class="m-0 p-0" v-if="editVisible">
                    <a href="#" @click.prevent="disableEditMode">
                      <small>{{ $t('maps.closeCurrentMap') }}</small>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row" v-if="mapMode.planning && editVisible && !isMapOnFullScreen">
            <div class="col-lg-12">
              <div class="assign-cpes-block card">
                <div class="card-header" v-if="filteredCpesOnlyWithNames(cpesList).length">
                  <i class="fa fa-align-justify"></i>
                  {{ $t('maps.availableCPEs') }}
                  <info class="" :content="$t('maps.availableCPEHint')" />
                </div>
                <div class="card-header" v-if="!filteredCpesOnlyWithNames(cpesList).length">
                  <i class="fa fa-align-justify"></i>
                  {{ $t('maps.noAvailableCPEs') }}
                  <info class="" :content="$t('maps.availableCPEHint')" />
                </div>
                <div class="card-block">
                  <div class="input-group input-group-sm filters__input mb-h">
                    <span class="input-group-addon">
                      <i class="fa fa-search"></i>
                    </span>
                    <input
                      id="cpe_name"
                      name="mac_filter"
                      :placeholder="$t('maps.cpeNamePlaceHolder')"
                      class="form-control pr-2"
                      v-model="cpeFilters.query"
                    />
                    <span class="clear-input text-muted">
                      <i class="fa fa-remove" v-if="cpeFilters.query" @click="cpeFilters.query = ''"></i>
                    </span>
                  </div>
                  <div class="assign-cpes-items">
                    <table class="table">
                      <tbody>
                        <tr
                          v-for="cpe in filteredCpesOnlyWithNames(cpesList)"
                          @click="addCpeOnMap(cpe, true, false, mapMode.planning)"
                        >
                          <td class="assign-cpes-item">
                            <span
                              class="badge-cpe badge-status ml-h"
                              :class="{
                                'badge-status__connected': cpe.connected,
                                'badge-status__disconnected': !cpe.connected
                              }"
                            >
                              <span
                                v-tooltip.top-center="{ content: cpe.model.name }"
                                class="tooltip-text"
                                v-if="cpe.name"
                              >
                                {{ cpe.name }}
                              </span>
                              <span class="text-muted" v-else>{{ $t('misc.no_name') }}</span>
                              <small class="text-success add-text ml-h">
                                <i class="fa fa-plus"></i>
                              </small>
                            </span>
                          </td>
                        </tr>
                        <tr v-if="!clientsMarker.length && macFilter && !searchSpinner">
                          <td>
                            <span class="text-muted">{{ $t('misc.no_data') }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <button
                    class="btn btn-outline-success btn-sm mt-1 center-block btn-block"
                    @click="saveCpeDisposition('show')"
                  >
                    {{ $t('maps.saveAndHandleClients') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!--          <div class="row" v-if="mapMode.zones && editVisible && currentMapData.Zones.length !== 0">-->
          <!--            <div class="col-lg-12">-->
          <!--              <div class="assign-cpes-block card">-->
          <!--                <div class="card-header" >-->
          <!--                  <i class="fa fa-align-justify"></i>-->
          <!--                  Available Zones-->
          <!--                </div>-->
          <!--                <div class="card-block">-->
          <!--                  <div class="assign-cpes-items">-->
          <!--                    <table class="table">-->
          <!--                      <tbody>-->
          <!--                        <tr-->
          <!--                          v-for="zone  in currentMapData.Zones"-->
          <!--                        >-->
          <!--                          <td class="assign-cpes-item">-->

          <!--                              <span>-->
          <!--                                <input type="text"-->
          <!--                                v-model="zone.name">-->

          <!--                                </input>-->

          <!--                              </span>-->

          <!--                          </td>-->
          <!--                        </tr>-->
          <!--                      </tbody>-->
          <!--                    </table>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>

        <div class="map-edit" v-if="editVisible && currentMap">
          <div class="card sticky-top">
            <!--            <div class="loader loader-backdrop" v-if="loadingMaps || loadingCpes || loadingCurrentMapData"></div>-->
            <div class="card-header">
              <i class="fa fa-map-o"></i>
              <span v-if="currentMap">{{ currentMap.name }}</span>
              <button
                :disabled="disableButtonsWhenhenLoadingClientsAndSplitRequestsToAPI"
                type="button"
                class="btn btn-sm btn-outline-secondary float-right ml-2 new-modern-style-btn-close-panel"
                @click="disableEditMode"
              >
                <i class="fa fa-close"></i>
              </button>
            </div>
            <div class="card-block map-wrapper">
              <div class="map-mode mb-3">
                <div :class="{ 'actions-buttons__operator': isDisable }">
                  <div class="pull-left">
                    <button
                      :disabled="disableButtonsWhenhenLoadingClientsAndSplitRequestsToAPI"
                      type="button"
                      class="btn btn-outline-secondary btn-md"
                      @click="openEditModal"
                    >
                      <span>{{ $t('maps.editMapModalOpenButton') }}</span>
                    </button>
                  </div>
                </div>
                <div class="btn-toolbar pull-right" v-if="mapMode.show">
                  <!-- //FIXME delete commented -->
                  <!-- <div class="filter-date">
                    <small class="text-muted">{{ (dateRange.startDate / 1000) | moment }}</small>
                    <small class="text-muted">{{ (dateRange.endDate / 1000) | moment }}</small>
                  </div>-->
                  <!-- <drPicker
                    :startDate="dateRange.startDate"
                    :endDate="dateRange.endDate"
                    :onChange="handleDateChange"
                    :disabled="loadingCurrentMapData"
                    classList="dr-picker btn btn-sm btn-outline-primary font-sm"
                    showSelected
                  ></drPicker>-->
                  <date-picker
                    v-if="isDatepickerShowed"
                    v-model="dateRange"
                    :onChange="handleDateChange.bind(this)"
                    :disabled="loadingCurrentMapData"
                  />
                  <!--<button @click="refreshClients"-->
                  <!--class="btn btn-sm btn-outline-primary ml-q"-->
                  <!--:disabled="loadingCurrentMapData"-->
                  <!--v-tooltip.top-center="{content: 'refresh clients'}">-->
                  <!--<i class="fa fa-refresh"></i>-->
                  <!--</button>-->
                </div>
              </div>

              <!--              <div class="loader loader-backdrop loader-maps" v-if="loadingCurrentMapData"></div>-->
              <div class="map-container mt-1">
                <div
                  class="map-context-menu-block"
                  v-if="showContextMenu && !isMapOnFullScreen"
                  :style="{ top: contextMenu.top + 'px', left: contextMenu.left + 'px' }"
                >
                  <div class="context-menu-item" @click="deleteCpeFromMap(contextMenu.item)">
                    <span class="text-danger">
                      <i class="fa fa-remove mr-h"></i>
                      {{ $t('general.delete') }}
                    </span>
                  </div>
                </div>

                <div
                  @fullscreenchange="mapFullScreenChange"
                  ref="map"
                  id="map"
                  class="map map-class-for-image-rendering-hack"
                >
                  <!--                  <div class="loader loader-backdrop" v-if="loadingMaps || loadingCpes || loadingCurrentMapData"></div>-->
                  <!--                  many clients point click-->
                  <div
                    @click.stop=""
                    @dblclick.stop=""
                    @mouseover="mouseOverClickedClusterData"
                    @mouseout="mouseOutClickedClusterData"
                    class="clients-info-from-api custom-border-for-client-info d-flex flex-column clients-info-from-cluster-click"
                    v-if="isClickedManyClientsData"
                  >
                    <div class="d-flex justify-content-between clients-info-from-cluster-header">
                      <div class="input-group input-group-sm filters__input">
                        <span class="search-state input-group-addon">
                          <i class="fa fa-search"></i>
                        </span>
                        <input
                          @input="inputTextInSearchManyClientsDataFilter"
                          v-model="clickedManyClientsDataSearchField"
                          placeholder=""
                        />
                        <!--                        </input>-->
                      </div>
                      <div>
                        <button
                          type="button"
                          class="btn btn-outline-secondary btn-sm"
                          @click="closeClickManyClientsDataWindow"
                        >
                          <i class="fa fa-close"></i>
                        </button>
                      </div>
                    </div>
                    <div>
                      <div>
                        <!--                        <Loader-spinner centered v-if="isClickedManyClientsDataLoadingExecute"></Loader-spinner>-->
                        <div
                          v-if="clickedManyClientsDataFiltered.length > 0"
                          class="clients-info-from-cluster-click-list"
                        >
                          <div @click="clickInManyClientsData(item)" v-for="item of clickedManyClientsDataFiltered">
                            <span role="button">
                              <!--                            <span v-if="!isMaskingMACAddresses">{{marker.options.mac}}</span>-->
                              <!--                            <span v-else>{{maskMACAddress(marker.options.mac)}}</span>-->
                              <span>{{ returnMaskedOrNotMAC(item.mac) }}</span>
                            </span>
                          </div>
                        </div>
                        <div v-else class="clients-info-from-cluster-click-list">
                          <div>
                            {{ $t('general.noDataToDisplay') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div></div>
                    <div class="clients-info-from-cluster-click-items-counter">
                      <span class="text-muted" v-if="clickedManyClientsData.length > 0">
                        {{ clickedManyClientsDataFiltered.length }} {{ $t('general.of') }}
                        {{ clickedManyClientsData.length }}
                      </span>
                    </div>
                  </div>
                  <div
                    @click.stop=""
                    @dblclick.stop=""
                    @mouseover="mouseOverClickedClusterData"
                    @mouseout="mouseOutClickedClusterData"
                    class="clients-info-from-api custom-border-for-client-info d-flex flex-column clients-info-from-cluster-click"
                    v-if="isClickedClusterData"
                  >
                    <div class="d-flex justify-content-between clients-info-from-cluster-header">
                      <div class="input-group input-group-sm filters__input">
                        <span class="search-state input-group-addon">
                          <i class="fa fa-search"></i>
                        </span>
                        <input
                          @input="inputTextInSearchClusterDataFilter"
                          v-model="clickedClusterDataSearchField"
                          placeholder=""
                        />
                        <!--                        </input>-->
                      </div>
                      <div>
                        <button
                          type="button"
                          class="btn btn-outline-secondary btn-sm"
                          @click="closeClickClusterDataWindow"
                        >
                          <i class="fa fa-close"></i>
                        </button>
                      </div>
                    </div>
                    <div>
                      <div>
                        <Loader-spinner
                          centered
                          v-if="isClickedClusterDataFiltereInMapsFilteringExecute"
                        ></Loader-spinner>
                        <div v-if="clickedClusterDataFiltered.length > 0" class="clients-info-from-cluster-click-list">
                          <div @click="clickInClickClusterData(marker)" v-for="marker of clickedClusterDataFiltered">
                            <span role="button">
                              <!--                            <span v-if="!isMaskingMACAddresses">{{marker.options.mac}}</span>-->
                              <!--                            <span v-else>{{maskMACAddress(marker.options.mac)}}</span>-->
                              <span>{{ returnMaskedOrNotMAC(marker.options.mac) }}</span>
                            </span>
                          </div>
                        </div>
                        <div v-else class="clients-info-from-cluster-click-list">
                          <div>
                            {{ $t('general.noDataToDisplay') }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div></div>
                    <div class="clients-info-from-cluster-click-items-counter">
                      <span class="text-muted" v-if="clickedClusterData.length > 0">
                        {{ clickedClusterDataFiltered.length }} {{ $t('general.of') }} {{ clickedClusterData.length }}
                      </span>
                    </div>
                  </div>
                  <div
                    v-if="
                      loadingMaps ||
                      loadingCpes ||
                      loadingCurrentMapData ||
                      loadingMapCpesStatisticAndClients ||
                      isManyClientsPointDataLoadingInMaps ||
                      isImageOverlayLoading ||
                      isClientPathRequestExecuting ||
                      isLoadingUsersInfoForSelectedCpeOnMap
                    "
                  >
                    <!--                    <Loader-spinner centered></Loader-spinner>-->
                    <loaderSpinnerWithProgressBar
                      :percents="percentsOfClientsMarkerDownloadsRequestsCount"
                      centered
                    ></loaderSpinnerWithProgressBar>
                  </div>
                  <div
                    @click.stop=""
                    @dblclick.stop=""
                    @mouseover="mouseOverFullScreenDatePicker"
                    @mouseout="mouseOutFullScreenDatePicker"
                    v-if="isMapOnFullScreen && isDatepickerFullScreenShowed"
                    class="datepicker-on-fullscreen-map"
                  >
                    <date-picker
                      v-model="dateRange"
                      :onChange="handleDateChange.bind(this)"
                      :disabled="loadingCurrentMapData"
                      :appendToBody="false"
                    />
                  </div>
                  <div
                    class="map-context-menu-block context-on-fullscreen-map"
                    v-if="showContextMenu && isMapOnFullScreen"
                    :style="{ top: contextMenu.top + 'px', left: contextMenu.left + 'px' }"
                  >
                    <div class="context-menu-item" @click="deleteCpeFromMap(contextMenu.item)">
                      <span class="text-danger">
                        <i class="fa fa-remove mr-h"></i>
                        {{ $t('general.delete') }}
                      </span>
                    </div>
                  </div>
                  <MapsHeatmapLegend
                    class="position-heatmap-legend"
                  v-if="showHeatmapLegend"
                    :heatmapLegendData="heatmapLegendData"
                  >

                  </MapsHeatmapLegend>
                  <!--                  <div-->
                  <!--                    class="loader loader-backdrop loader-maps"-->
                  <!--                    v-if="loadingMaps || loadingCpes || loadingCurrentMapData"-->
                  <!--                  ></div>-->
                  <MapsVisitors
                    v-if="
                      !loadingMaps &&
                      !loadingCpes &&
                      !loadingCurrentMapData &&
                      showMode.timeline &&
                      beforeTimelineShowShowingMode.includes('clientsLastPathPoints')
                    "
                    :clients="segmentedClientsCounts"
                    :timestamps="segmentedPeriods.slice(1)"
                    :switch-segment="switchSegment.bind(this)"
                    :selected="selectedSegment"
                    :segmentedInterval="segmentedInterval"
                    class="position-clients-graph"
                  />
                  <MapsVisitors
                    v-if="
                      !loadingMaps &&
                      !loadingCpes &&
                      !loadingCurrentMapData &&
                      showMode.timeline &&
                      beforeTimelineShowShowingMode.includes('bubble_clients')
                    "
                    :clients="segmentedForBubblesCounts"
                    :timestamps="segmentedPeriods.slice(1)"
                    :switch-segment="switchSegmentForBubbles.bind(this)"
                    :selected="selectedSegment"
                    :segmentedInterval="segmentedInterval"
                    :timeline-title="$t('maps.timelineConnectedTitle')"
                    :timeline-tooltip-caption="$t('maps.timelineConnectedTooltipCaption')"
                    class="position-clients-graph"
                  />
                  <MapsVisitors
                    v-if="
                      !loadingMaps &&
                      !loadingCpes &&
                      !loadingCurrentMapData &&
                      showMode.timeline &&
                      beforeTimelineShowShowingMode.includes('bubble_lbs')
                    "
                    :clients="segmentedForBubblesCounts"
                    :timestamps="segmentedPeriods.slice(1)"
                    :switch-segment="switchSegmentForBubbles.bind(this)"
                    :selected="selectedSegment"
                    :segmentedInterval="segmentedInterval"
                    :timeline-title="$t('maps.timelinePasserbyTitle')"
                    :timeline-tooltip-caption="$t('maps.timelinePasserbyTooltipCaption')"
                    class="position-clients-graph"
                  />
                  <MapsVisitors
                    v-if="
                      !loadingMaps &&
                      !loadingCpes &&
                      !loadingCurrentMapData &&
                      showMode.timeline &&
                      beforeTimelineShowShowingMode.includes('bubble_traffic')
                    "
                    :clients="segmentedForBubblesCounts"
                    :timestamps="segmentedPeriods.slice(1)"
                    :switch-segment="switchSegmentForBubbles.bind(this)"
                    :selected="selectedSegment"
                    :segmentedInterval="segmentedInterval"
                    :timeline-title="$t('maps.timelineTrafficTitle')"
                    :timeline-tooltip-caption="$t('maps.timelineTrafficTooltipCaption')"
                    :counter-in-bytes="true"
                    class="position-clients-graph"
                  />
                  <div
                    @click.stop=""
                    @dblclick.stop=""
                    @mouseover="mouseOverClientsInfoFromApi"
                    @mouseout="mouseOutClientsInfoFromApi"
                    v-if="infoFromClientsApiForSelectedClientShowing && !pathForSelectedClientShowing"
                    class="clients-info-from-api custom-border-for-client-info d-flex align-items-center"
                  >
                    <div class="d-flex flex-column justify-content-center" style="padding: 8px;">
                      <div>
                        <span class="clients-info-from-api-header">{{ $t('general.description') }}:</span>
                        <span class="clients-info-from-api-value">
                          {{ selectedClientDataFromClientsApi.data.description || '' }}
                        </span>
                      </div>
                      <div>
                        <span class="clients-info-from-api-header">{{ $t('general.firstConnected') }}:</span>
                        <span class="clients-info-from-api-value">
                          {{ selectedClientDataFromClientsApi.first_connect | moment }}
                        </span>
                      </div>
                      <div>
                        <span
                          v-if="selectedClientDataFromClientsApi.State === 'CONNECTED'"
                          class="clients-info-from-api-header"
                        >
                          {{ $t('maps.lastConnected') }}:
                        </span>
                        <span
                          v-if="selectedClientDataFromClientsApi.State === 'DISCONNECTED'"
                          class="clients-info-from-api-header"
                        >
                          {{ $t('general.lastDisconnected') }}
                        </span>
                        <span class="clients-info-from-api-value">
                          {{ selectedClientDataFromClientsApi.timestamp | moment }}
                        </span>
                      </div>
                      <div v-if="selectedClientDataFromClientsApi.ip">
                        <span class="clients-info-from-api-header">IP:</span>
                        <span class="clients-info-from-api-value">{{ selectedClientDataFromClientsApi.ip }}</span>
                      </div>
                      <div>
                        <span class="clients-info-from-api-header">{{ $t('maps.cpe') }}:</span>
                        <span class="clients-info-from-api-value ml-q">
                          {{
                            cpeNamebyId(selectedClientDataFromClientsApi.cpe_id) ||
                            selectedClientDataFromClientsApi.cpe_id
                          }}
                        </span>
                      </div>
                      <div>
                        <span class="clients-info-from-api-header">WLAN:</span>
                        <span class="clients-info-from-api-value">
                          {{
                            wlanNamebyId(selectedClientDataFromClientsApi.wlan_id) ||
                            selectedClientDataFromClientsApi.wlan_id
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div v-if="pathForSelectedClientShowing" class="clients-path-timeline custom-border-for-client-info">
                    <div
                      v-if="pathForSelectedClientTimestampsArray.length > 1"
                      class="clients-path-timeline-inner"
                      @dblclick.stop=""
                      @click.stop=""
                    >
                      <div>
                        {{ moment(pathForSelectedClientSelectedTimestamp * 1000).format(' D MMM YYYY, H:mm:ss ') }}
                      </div>
                      <div class="slider" @mouseover="mouseOverSlider" @mouseout="mouseOutSlider">
                        <vue-slider
                          :data="pathForSelectedClientTimestampsArray"
                          :marks="pathForSelectedClientTimestampsArray"
                          :val="pathForSelectedClientSelectedTimestamp"
                          :dot-size="10"
                          :tooltip="'none'"
                          v-model="pathForSelectedClientSelectedTimestamp"
                        >
                          <template v-slot:label="{ value }">
                            <div></div>
                          </template>
                        </vue-slider>
                      </div>
                      <div class="slider-buttons">
                        <div
                          @dblclick.stop=""
                          @click.stop="pathForSelectedClientFirstPoint"
                          role="button"
                          v-bind:class="{ 'slider-buttons-disable': sliderButtonsDisableLeft }"
                        >
                          <i class="fa fa-fast-backward" aria-hidden="true"></i>
                        </div>
                        <div
                          @dblclick.stop=""
                          @click.stop="pathForSelectedClientPreviousPoint"
                          role="button"
                          v-bind:class="{ 'slider-buttons-disable': sliderButtonsDisableLeft }"
                        >
                          <i class="fa fa-arrow-left" aria-hidden="true"></i>
                        </div>
                        <div
                          @dblclick.stop=""
                          @click.stop="pathForSelectedClientNextPoint"
                          role="button"
                          v-bind:class="{ 'slider-buttons-disable': sliderButtonsDisableRight }"
                        >
                          <i class="fa fa-arrow-right" aria-hidden="true"></i>
                        </div>
                        <div
                          @dblclick.stop=""
                          @click.stop="pathForSelectedClientLastPoint"
                          role="button"
                          v-bind:class="{ 'slider-buttons-disable': sliderButtonsDisableRight }"
                        >
                          <i class="fa fa-fast-forward" aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>
                    <div v-else class="clients-path-timeline-inner">
                      <div class="text-center">
                        {{ $t('maps.onlyOnePointInPath') }}
                      </div>
                      <div class>
                        {{ moment(pathForSelectedClientSelectedTimestamp * 1000).format(' D MMM YYYY, H:mm:ss ') }}
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="mapMode.planning && editVisible && isMapOnFullScreen"
                    class="cpes-list-in-heatmap-wrapper"
                    @click.stop=""
                    @dblclick.stop=""
                    @mouseover="mouseOverModalCpeMoreInfo"
                    @mouseout="mouseOutModalCpeMoreInfo"
                  >
                    <div class="cpes-list-in-heatmap" v-if="mapMode.planning && editVisible && isMapOnFullScreen">
                      <div class="col-lg-12">
                        <div class="assign-cpes-block card">
                          <div class="card-header" v-if="filteredCpesOnlyWithNames(cpesList).length">
                            <i class="fa fa-align-justify"></i>
                            {{ $t('maps.availableCPEs') }}
                          </div>
                          <div class="card-header" v-if="!filteredCpesOnlyWithNames(cpesList).length">
                            <i class="fa fa-align-justify"></i>
                            {{ $t('maps.noAvailableCPEs') }}
                          </div>
                          <div class="card-block">
                            <p class="available-cpe-hint-fullscreen">{{ $t('maps.availableCPEHint') }}</p>
                            <div class="input-group input-group-sm filters__input mb-h">
                              <span class="input-group-addon">
                                <i class="fa fa-search"></i>
                              </span>
                              <input
                                id="cpe_name"
                                name="mac_filter"
                                :placeholder="$t('maps.cpeNamePlaceHolder')"
                                class="form-control pr-2"
                                v-model="cpeFilters.query"
                              />
                              <span class="clear-input text-muted">
                                <i class="fa fa-remove" v-if="cpeFilters.query" @click="cpeFilters.query = ''"></i>
                              </span>
                            </div>
                            <div class="assign-cpes-items">
                              <table class="table">
                                <tbody>
                                  <tr
                                    v-for="cpe in filteredCpesOnlyWithNames(cpesList)"
                                    @click="addCpeOnMap(cpe, true, false, mapMode.planning)"
                                  >
                                    <td class="assign-cpes-item">
                                      <span
                                        class="badge-cpe badge-status ml-h"
                                        :class="{
                                          'badge-status__connected': cpe.connected,
                                          'badge-status__disconnected': !cpe.connected
                                        }"
                                      >
                                        <span
                                          v-tooltip.top-center="{ content: cpe.model.name }"
                                          class="tooltip-text"
                                          v-if="cpe.name"
                                        >
                                          {{ cpe.name }}
                                        </span>
                                        <span class="text-muted" v-else>{{ $t('misc.no_name') }}</span>
                                        <small class="text-success add-text ml-h">
                                          <i class="fa fa-plus"></i>
                                        </small>
                                      </span>
                                    </td>
                                  </tr>
                                  <tr v-if="!clientsMarker.length && macFilter && !searchSpinner">
                                    <td>
                                      <span class="text-muted">{{ $t('misc.no_data') }}</span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <button
                              class="btn btn-outline-success btn-sm mt-1 center-block btn-block"
                              @click="saveCpeDisposition('show')"
                            >
                              {{ $t('maps.saveAndHandleClients') }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--                  <div-->
                  <!--                    v-if="isMapOnFullScreen && !mapMode.planning"-->

                  <!--                    class="maps-list-in-heatmap-wrapper"-->
                  <!--                    @click.stop=""-->
                  <!--                    @dblclick.stop=""-->
                  <!--                    @mouseover="mouseOverModalCpeMoreInfo"-->
                  <!--                    @mouseout="mouseOutModalCpeMoreInfo">-->
                  <!--                    <div class="row sticky-top cpes-list-in-heatmap">-->
                  <!--                      <div class="col-lg-12">-->
                  <!--                        <div class="card">-->
                  <!--                          &lt;!&ndash;                <div class="loader loader-backdrop loader-maps" v-if="loadingMaps || loadingCpes"></div>&ndash;&gt;-->
                  <!--                          <div v-if="loadingMaps || loadingCpes">-->
                  <!--                            <Loader-spinner/>-->
                  <!--                          </div>-->
                  <!--                          <div class="card-header">-->
                  <!--                            <i class="fa fa-align-justify"></i>-->
                  <!--                            {{$t('maps.mapsList')}}-->
                  <!--                            <span class="text-muted ml-3" v-if="!loadingMaps && !editVisible">-->
                  <!--                    {{$t('aps.tableView')}} {{ filteredMaps(mapsList).length }} {{$t('aps.tableItemsOf')}} {{ this.totalResult }}-->
                  <!--                  </span>-->
                  <!--                          </div>-->
                  <!--                          <div class="card-block maps-list-in-heatmap-inner">-->
                  <!--                            <table class="table mb-h">-->
                  <!--                              <thead>-->
                  <!--                              <tr>-->
                  <!--                                <th>{{ $t('general.name') }}</th>-->
                  <!--                                <th>{{ $t('general.cpes') }}</th>-->
                  <!--                              </tr>-->
                  <!--                              </thead>-->
                  <!--                              <tbody>-->
                  <!--                              <tr-->
                  <!--                                v-for="(map, index) in filteredMaps(mapsList)"-->
                  <!--                                :data-index="index"-->
                  <!--                                :data-data-id="map.id"-->
                  <!--                                @click="toggleEditFromFullScreen($event, map)"-->
                  <!--                                class="map-row"-->
                  <!--                                :class="{-->
                  <!--                          selected: editVisible === map.id,-->
                  <!--                          'map-row-disabled': !map.cpe_list.length && isDisable-->
                  <!--                        }"-->
                  <!--                              >-->
                  <!--                                <td class="responsive-row">{{ map.name }}</td>-->
                  <!--                                <td class="responsive-row">-->
                  <!--                          <span-->
                  <!--                            v-tooltip.top-center="{ content: `${$t('maps.cpesTooltipForTable')}: ` + formatCpes(map.cpe_list) }"-->
                  <!--                            :class="{ 'badge-success': map.cpe_list.length, 'badge-default': !map.cpe_list.length }"-->
                  <!--                            class="badge badge-light mr-q"-->
                  <!--                          >-->
                  <!--                            <img class="img img-responsive cpe-badge"-->
                  <!--                                 src="/static/img/router-icon-white.svg" alt/>-->
                  <!--                            {{ map.cpe_list.length || '0' }}-->
                  <!--                          </span>-->
                  <!--                                </td>-->
                  <!--                                <td v-if="!editVisible" class="responsive-row">-->
                  <!--                          <span-->
                  <!--                            v-tooltip.top-center="{ content: `${$t('maps.zonesTooltipForTable')}: ` + formatZonesForTooltip(map.Zones) }"-->
                  <!--                            :class="{ 'badge-success': map.Zones.length, 'badge-default': !map.Zones.length }"-->
                  <!--                            class="badge badge-light mr-q"-->
                  <!--                          >-->
                  <!--                            {{ map.Zones.length || '0' }}-->
                  <!--                          </span>-->
                  <!--                                </td>-->
                  <!--                                <td class="responsive-row" v-if="!editVisible">-->
                  <!--                                  <small>{{ map.created_at | moment }}</small>-->
                  <!--                                </td>-->
                  <!--                                <td class="responsive-row" v-if="!editVisible">-->
                  <!--                                  <small>{{ map.last_updated | moment }}</small>-->
                  <!--                                </td>-->
                  <!--                                <td class="responsive-row" v-if="!editVisible">{{-->
                  <!--                                  locationName(map.base_location) }}-->
                  <!--                                </td>-->
                  <!--                                <td class="action-row" v-if="!editVisible && !isDisable">-->
                  <!--                                  <i class="fa fa-remove fa-lg mr-q text-danger user-action"-->
                  <!--                                     data-action="delete"></i>-->
                  <!--                                </td>-->
                  <!--                              </tr>-->
                  <!--                              <tr v-if="!filteredMaps(mapsList).length">-->
                  <!--                                <td colspan="6" class="no-data-row">-->
                  <!--                                  <span-->
                  <!--                                    class="text-muted">{{ $t('general.noDataToDisplay') }}</span>-->
                  <!--                                </td>-->
                  <!--                              </tr>-->
                  <!--                              </tbody>-->
                  <!--                            </table>-->
                  <!--                            <div-->
                  <!--                              v-if="canLoadMore  && !editVisible && !loadingMaps && mapsList.length"-->
                  <!--                              class="card-block text-center p-0">-->
                  <!--                              <button class="btn btn-link" @click="loadMore()">-->
                  <!--                                {{$t('aps.tableLoad')}}-->
                  <!--                                <span v-if="this.limit < this.totalResult - this.mapsList.length">{{ this.limit }}</span>-->
                  <!--                                <span v-else>{{ this.totalResult - this.mapsList.length }}</span>-->
                  <!--                                {{$t('maps.tableOutOf')}} {{ this.totalResult - this.mapsList.length-->
                  <!--                                }}-->
                  <!--                              </button>-->
                  <!--                            </div>-->
                  <!--                          </div>-->
                  <!--                        </div>-->
                  <!--                      </div>-->
                  <!--                    </div>-->
                  <!--                  </div>-->

                  <div
                    v-if="showingCPEChangedModal === true"
                    class="modal-with-more-cpe-info-in-heatmap"
                    @click.stop=""
                    @dblclick.stop=""
                    @mouseover="mouseOverModalCpeMoreInfo"
                    @mouseout="mouseOutModalCpeMoreInfo"
                  >
                    <modal
                      title="Change CPEs"
                      v-model="showingCPEChangedModal"
                      class="modal-warning modal-more-cpe-info"
                      effect="fade/zoom"
                    >
                      <h4 slot="modal-title" class="modal-title">
                        {{ $t('maps.changedCPEsModalTitle') }}
                      </h4>
                      <div class>
                        <p>{{ $t('maps.changedCPEsModalText') }}?</p>
                      </div>
                      <div slot="modal-footer" class="modal-footer">
                        <button type="button" class="btn btn-secondary ml-auto" @click="CPEChangedModalHandleYes">
                          {{ $t('general.yes') }}, {{ $t('general.close').toLowerCase() }}
                        </button>
                        <button type="button" class="btn btn-secondary ml-1" @click="CPEChangedModalHandleNo">
                          {{ $t('general.no') }}
                        </button>
                      </div>
                    </modal>
                  </div>
                  <div
                    v-if="isMoreCpeInfoModalShowing === true"
                    class="modal-with-more-cpe-info-in-heatmap"
                    @click.stop=""
                    @dblclick.stop=""
                    @mouseover="mouseOverModalCpeMoreInfo"
                    @mouseout="mouseOutModalCpeMoreInfo"
                  >
                    <!-- cpe info-->
                    <modal
                      v-model="isMoreCpeInfoModalShowing"
                      class="modal-info modal-more-cpe-info"
                      large
                      effect="fade/zoom"
                      :backdrop="false"
                      closeBtn
                    >
                      <!-- Modal header -->
                      <h4 slot="modal-title" class="modal-title">
                        <!--                        <span>{{$t('maps.cpeShowMore')}}</span>-->
                      </h4>
                      <!-- Modal body -->
                      <div class="d-flex flex-column">
                        <div>
                          <div class="card-header pb-0">
                            <div class="row">
                              <div class="col-lg-12">
                                <div class="edit-header">
                                  <div class="edit-header__info mb-1 mt-1">
                                    <div class="edit-header__name">
                                      <span
                                        class="badge-cpe badge-status badge-status--cpe ml-h h4"
                                        :class="{
                                          'badge-status__connected': cpeEditCurrent.connected,
                                          'badge-status__disconnected': !cpeEditCurrent.connected
                                        }"
                                      >
                                        <span v-if="cpeEditCurrent.name">{{ cpeEditCurrent.name }}</span>
                                        <span class="text-muted" v-else>{{ $t('misc.no_name') }}</span>
                                      </span>

                                      <span class="ml-1 mb-0 cpe-edit-name">
                                        <span
                                          v-if="isBusy(cpeEditCurrent.id, cpeEditCurrent.config_status)"
                                          class="badge badge-info text-sm"
                                        >
                                          {{
                                            cpesIdsOperations.includes(cpeEditCurrent.id)
                                              ? $t('aps.updating')
                                              : $t(`aps.${cpeEditCurrent.config_status}`)
                                          }}
                                        </span>

                                        <span
                                          v-else-if="cpeEditCurrent.config_status === 'ok'"
                                          class="badge badge-success text-sm"
                                        >
                                          {{ $t('aps.ok') }}
                                        </span>
                                        <span
                                          v-else-if="cpeEditCurrent.config_status === 'offline'"
                                          class="badge badge-default text-sm"
                                        >
                                          {{ $t('aps.offline') }}
                                        </span>
                                        <span
                                          v-else-if="cpeEditCurrent.config_status === 'empty'"
                                          class="badge badge-default text-sm"
                                        >
                                          {{ $t('aps.empty') }}
                                        </span>

                                        <span v-else class="badge badge-default text-sm">
                                          {{ cpeEditCurrent.config_status }}
                                        </span>
                                      </span>
                                    </div>
                                    <div class="edit-header__tags">
                                      <span
                                        v-for="(tag, index) in updatedCpe.tags"
                                        v-if="updatedCpe.tags.length"
                                        :key="tag"
                                        :class="{ 'tag--disable': isDisable }"
                                        @click.prevent="removeTag"
                                        :data-index="index"
                                        class="tag tag--item"
                                      >
                                        <i class="fa fa-tag"></i>
                                        <i class="fa fa-remove"></i>
                                        {{ tag }}
                                      </span>
                                      <span class="tag tag--default" v-if="!updatedCpe.tags.length">
                                        <i class="fa fa-tag"></i>
                                        {{ $t('aps.noTags') }}
                                      </span>
                                    </div>
                                  </div>

                                  <!--                                            <div class="edit-header__buttons">-->
                                  <!--                                              <button-->
                                  <!--                                                type="button"-->
                                  <!--                                                v-tooltip.top-center="{ content: $t('aps.restartCpeAgent') }"-->
                                  <!--                                                v-if="-->
                                  <!--                                                  cpeEditCurrent.connected && (!isDisable || cpeEditCurrent.config_status === 'updating')-->
                                  <!--                                                "-->
                                  <!--                                                class="btn btn-outline-danger btn-sm"-->
                                  <!--                                                @click="openRebootCpeAgentModal"-->
                                  <!--                                              >-->
                                  <!--                                                <span>-->
                                  <!--                                                  <i class="fa fa-refresh"></i>-->
                                  <!--                                                </span>-->
                                  <!--                                              </button>-->
                                  <!--                                              <button-->
                                  <!--                                                type="button"-->
                                  <!--                                                v-tooltip.top-center="{ content: $t('aps.rebootCpeRedundant') }"-->
                                  <!--                                                v-if="-->
                                  <!--                                                  cpeEditCurrent.connected && (!isDisable || cpeEditCurrent.config_status === 'updating')-->
                                  <!--                                                "-->
                                  <!--                                                class="btn btn-outline-danger btn-sm"-->
                                  <!--                                                @click="openRebootCpeRedundantModal"-->
                                  <!--                                              >-->
                                  <!--                                                <span>-->
                                  <!--                                                  <i class="fa fa-history"></i>-->
                                  <!--                                                </span>-->
                                  <!--                                              </button>-->
                                  <!--                                              <button-->
                                  <!--                                                type="button"-->
                                  <!--                                                v-tooltip.top-center="{ content: $t('aps.IPsec') }"-->
                                  <!--                                                v-if="-->
                                  <!--                                                  cpeEditCurrent.connected && (!isDisable || cpeEditCurrent.config_status === 'updating')-->
                                  <!--                                                "-->
                                  <!--                                                class="btn btn-outline-danger btn-sm"-->
                                  <!--                                                @click="openIPsecModal"-->
                                  <!--                                              >-->
                                  <!--                                                <span>-->
                                  <!--                                                  <i class="fa fa-shield"></i>-->
                                  <!--                                                </span>-->
                                  <!--                                              </button>-->
                                  <!--                                              <button-->
                                  <!--                                                type="button"-->
                                  <!--                                                v-tooltip.top-center="{ content: $t('aps.sendConfigCmd') }"-->
                                  <!--                                                v-if="-->
                                  <!--                                                  cpeEditCurrent.connected && (!isDisable || cpeEditCurrent.config_status === 'updating')-->
                                  <!--                                                "-->
                                  <!--                                                class="btn btn-outline-danger btn-sm"-->
                                  <!--                                                @click="openConfigModal"-->
                                  <!--                                              >-->
                                  <!--                                                <span>-->
                                  <!--                                                  <i class="fa fa-code"></i>-->
                                  <!--                                                </span>-->
                                  <!--                                              </button>-->
                                  <!--                                              <button-->
                                  <!--                                                type="button"-->
                                  <!--                                                v-tooltip.top-center="{ content: $t('aps.rebootCpe') }"-->
                                  <!--                                                v-if="cpeEditCurrent.connected && !isDisable"-->
                                  <!--                                                class="btn btn-outline-info btn-sm"-->
                                  <!--                                                @click="openRebootCpeModal"-->
                                  <!--                                              >-->
                                  <!--                                                <span>-->
                                  <!--                                                  <i class="fa fa-refresh" @click="openRebootCpeModal"></i>-->
                                  <!--                                                </span>-->
                                  <!--                                              </button>-->
                                  <!--                                              <button-->
                                  <!--                                                type="button"-->
                                  <!--                                                v-tooltip.top-center="{ content: $t('aps.resetCpe') }"-->
                                  <!--                                                v-if="cpeEditCurrent.connected && !isDisable"-->
                                  <!--                                                class="btn btn-outline-info btn-sm"-->
                                  <!--                                                @click="openResetCpeModal"-->
                                  <!--                                              >-->
                                  <!--                                                <span>-->
                                  <!--                                                  <i class="fa fa-power-off" @click="openResetCpeModal"></i>-->
                                  <!--                                                </span>-->
                                  <!--                                              </button>-->
                                  <!--                                              <button-->
                                  <!--                                                type="button"-->
                                  <!--                                                v-tooltip.top-center="{ content: $t('aps.fwUpgrade') }"-->
                                  <!--                                                v-if="cpeEditCurrent.connected && !isDisable"-->
                                  <!--                                                class="btn btn-outline-info btn-sm"-->
                                  <!--                                                @click="openFWUpgradeDialog"-->
                                  <!--                                              >-->
                                  <!--                                                <i class="fa fa-download" @click="openFWUpgradeDialog"></i>-->
                                  <!--                                                &lt;!&ndash;<span v-if="updatedCpe.state.firmware.has_update" class="badge badge-danger badge-pill badge-upgrade">!</span>&ndash;&gt;-->
                                  <!--                                              </button>-->
                                  <!--                                              &lt;!&ndash;<button-->
                                  <!--                                                type="button"-->
                                  <!--                                                v-tooltip.top-center="{content: 'Get web and ssh access'}"-->
                                  <!--                                                v-if="cpeEditCurrent.connected && !isDisable"-->
                                  <!--                                                class="btn btn-outline-info btn-sm"-->
                                  <!--                                                @click="getWebAccess"-->
                                  <!--                                              >-->
                                  <!--                                                <span :class="{ invisible: WebAccess }"> <i-->
                                  <!--                                                  class="fa fa-terminal"-->
                                  <!--                                                  @click="getWebAccess"-->
                                  <!--                                                ></i></span>-->
                                  <!--                                                <span-->
                                  <!--                                                  v-if="WebAccess"-->
                                  <!--                                                  class="loader loader&#45;&#45;mini"-->
                                  <!--                                                ></span>-->
                                  <!--                                              </button>&ndash;&gt;-->
                                  <!--                                              <button-->
                                  <!--                                                type="button"-->
                                  <!--                                                v-tooltip.top-center="{ content: $t('aps.assignTemplate') }"-->
                                  <!--                                                v-if="-->
                                  <!--                                                  cpeEditCurrent.connected &&-->
                                  <!--                                                  cpeEditCurrent.config.wifi &&-->
                                  <!--                                                  cpeEditCurrent.config.wifi.length &&-->
                                  <!--                                                  cpeModelData.caps &&-->
                                  <!--                                                  cpeModelData.caps.wifi &&-->
                                  <!--                                                  Object.keys(cpeModelData.caps.wifi).length &&-->
                                  <!--                                                  Object.keys(cpeEditCurrent.config.wifi).length != 0 &&-->
                                  <!--                                                  !isDisable-->
                                  <!--                                                "-->
                                  <!--                                                class="btn btn-outline-success btn-sm"-->
                                  <!--                                                @click="setTemplateModal = true"-->
                                  <!--                                              >-->
                                  <!--                                                <i class="fa fa-paste" @click="setTemplateModal = true"></i>-->
                                  <!--                                              </button>-->
                                  <!--                                              <button-->
                                  <!--                                                type="button"-->
                                  <!--                                                v-tooltip.top-center="{ content: $t('aps.createTemplateFromCpe') }"-->
                                  <!--                                                v-if="-->
                                  <!--                                                  cpeEditCurrent.connected &&-->
                                  <!--                                                  cpeEditCurrent.config.wifi &&-->
                                  <!--                                                  cpeEditCurrent.config.wifi.length &&-->
                                  <!--                                                  cpeModelData.caps &&-->
                                  <!--                                                  cpeModelData.caps.wifi &&-->
                                  <!--                                                  Object.keys(cpeModelData.caps.wifi).length &&-->
                                  <!--                                                  Object.keys(cpeEditCurrent.config.wifi).length != 0 &&-->
                                  <!--                                                  !isDisable-->
                                  <!--                                                "-->
                                  <!--                                                class="btn btn-outline-success btn-sm"-->
                                  <!--                                                @click="showNewTemplate"-->
                                  <!--                                              >-->
                                  <!--                                                <i class="fa fa-copy" @click="showNewTemplate"></i>-->
                                  <!--                                              </button>-->
                                  <!--                                              <button-->
                                  <!--                                                type="button"-->
                                  <!--                                                v-tooltip.top-center="{ content: $t('aps.editTags') }"-->
                                  <!--                                                v-if="!isDisable"-->
                                  <!--                                                class="btn btn-outline-success btn-sm"-->
                                  <!--                                                @click="cpeTagsModal = true"-->
                                  <!--                                              >-->
                                  <!--                                                <i class="fa fa-tag" @click="cpeTagsModal = true"></i>-->
                                  <!--                                              </button>-->
                                  <!--                                              <button-->
                                  <!--                                                type="button"-->
                                  <!--                                                v-tooltip.top-center="{ content: $t('general.close') }"-->
                                  <!--                                                class="btn btn-outline-secondary btn-sm"-->
                                  <!--                                                @click="disableSingleEditMode"-->
                                  <!--                                              >-->
                                  <!--                                                <i class="fa fa-close"></i>-->
                                  <!--                                              </button>-->
                                  <!--                                            </div>-->
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="card-block pt-0">
                            <div class="row">
                              <div class="col-lg-12">
                                <div class="row mt-1">
                                  <div class="col-lg-7">
                                    <ul class="list-unstyled cpe-info">
                                      <li>
                                        <strong>{{ $t('aps.cpeInfo') }}</strong>
                                      </li>
                                      <li>
                                        {{ $t('aps.model') }}:
                                        <span class="text-muted">
                                          {{ cpeEditCurrent.model.name }} {{ isCisco ? '(Cisco)' : '' }}
                                        </span>
                                      </li>
                                      <li v-if="cpeEditCurrent.state.network && cpeEditCurrent.state.network.macaddr">
                                        {{ $t('aps.macaddr') }}:
                                        <span class="text-muted">{{ cpeEditCurrent.state.network.macaddr }}</span>
                                      </li>
                                      <li>
                                        {{ $t('aps.cpeId') }}:
                                        <span class="text-muted">{{ cpeEditCurrent.id }}</span>
                                      </li>
                                      <li>
                                        {{ $t('aps.FWversion') }}:
                                        <a
                                          href="#"
                                          v-if="
                                            cpeEditCurrent.connected &&
                                            !isDisable &&
                                            updatedCpe.state.firmware.version.version
                                          "
                                        >
                                          {{ updatedCpe.state.firmware.version.version }}
                                        </a>
                                        <span class="text-muted" v-else>
                                          {{ updatedCpe.state.firmware.version.version }}
                                        </span>
                                      </li>
                                    </ul>
                                  </div>

                                  <div class="col-lg-5">
                                    <ul class="list-unstyled cpe-info">
                                      <li>
                                        <strong>
                                          <span v-if="cpeEditCurrent.connected">{{ $t('aps.connectionInfo') }}</span>
                                          <span v-else>{{ $t('aps.connectionInfo') }}</span>
                                        </strong>
                                      </li>

                                      <li>
                                        {{ $t('aps.interfaceType') }}:
                                        <span
                                          v-if="
                                            cpeEditCurrent.state.wan &&
                                            cpeEditCurrent.state.wan.proto &&
                                            cpeEditCurrent.state.wan.proto === `qmi`
                                          "
                                          class="text-muted"
                                        >
                                          Cellular modem
                                        </span>

                                        <span v-else class="text-muted">Ethernet</span>
                                      </li>

                                      <li
                                        v-if="
                                          cpeEditCurrent.state &&
                                          cpeEditCurrent.state.l2tp_state &&
                                          cpeEditCurrent.state.l2tp_state.tunnel_type &&
                                          cpeEditCurrent.state.l2tp_state.tunnel_type !== `none`
                                        "
                                      >
                                        {{ $t('aps.type') }}:
                                        <span class="text-muted">
                                          {{ cpeEditCurrent.state.l2tp_state.tunnel_type }}
                                        </span>
                                      </li>

                                      <li
                                        v-if="
                                          cpeEditCurrent.state &&
                                          cpeEditCurrent.state.network &&
                                          cpeEditCurrent.state.network.ipaddrs &&
                                          cpeEditCurrent.state.network.ipaddrs.length
                                        "
                                      >
                                        {{ $t('aps.ipaddr') }}:
                                        <span
                                          v-for="(ip, index) in cpeEditCurrent.state.network.ipaddrs"
                                          :key="ip.ipaddr"
                                          class="text-muted"
                                        >
                                          <a
                                            :href="'http://' + ip.ipaddr"
                                            v-if="cpeEditCurrent.connected && !isDisable"
                                          >
                                            {{ ip.ipaddr }}
                                          </a>
                                          <span v-else>{{ ip.ipaddr }}</span>
                                          <span v-if="index !== cpeEditCurrent.state.network.ipaddrs.length - 1">
                                            ,
                                          </span>
                                        </span>
                                      </li>

                                      <li
                                        v-if="
                                          cpeEditCurrent.state.l2tp_state && cpeEditCurrent.state.l2tp_state.local_addr
                                        "
                                      >
                                        {{ $t('aps.localAddr') }}:
                                        <span class="text-muted">{{ cpeEditCurrent.state.l2tp_state.local_addr }}</span>
                                      </li>

                                      <li
                                        v-if="
                                          cpeEditCurrent.state.l2tp_state && cpeEditCurrent.state.l2tp_state.host_addr
                                        "
                                      >
                                        {{ $t('aps.hostAddr') }}:
                                        <span class="text-muted">{{ cpeEditCurrent.state.l2tp_state.host_addr }}</span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>

                                <div class="row">
                                  <div class="col-lg-12">
                                    <ul class="list-unstyled cpe-info cpe-info--stats">
                                      <li>
                                        <strong>RRM</strong>
                                        <info class="info-tooltip-in-aps" :content="$t('aps.rrmInfoTooltip')" />
                                      </li>
                                      <li>
                                        {{ $t('RRM.group') }}:
                                        <span
                                          class="text-muted"
                                          v-if="updatedCpe.rrm_group && updatedCpe.rrm_group.rrm_group"
                                        >
                                          {{ commonService.getRrmById(updatedCpe.rrm_group.rrm_group) }}
                                        </span>
                                        <span class="text-muted" v-else>{{ $t('RRM.noGroup') }}</span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>

                                <div class="row">
                                  <div class="col-lg-12">
                                    <ul class="list-unstyled cpe-info cpe-info--stats">
                                      <li>
                                        <strong>{{ $t('aps.statAndRadarCaptionInCpeInfoBlock') }}</strong>
                                      </li>

                                      <li>
                                        {{ $t('aps.Stats') }}:
                                        <span class="text-muted">
                                          {{
                                            updatedCpe.config.stats_config.enabled
                                              ? $t('general.On')
                                              : $t('general.Off')
                                          }}
                                        </span>
                                        <span
                                          class="text-muted"
                                          v-if="
                                            updatedCpe.config.stats_config.enabled &&
                                            updatedCpe.config.stats_config.reportperiod
                                          "
                                        >
                                          ( {{ $t('aps.reportPeriod') }}:
                                          {{ updatedCpe.config.stats_config.reportperiod }} )
                                        </span>
                                      </li>

                                      <li v-if="isFull">
                                        {{ $t('aps.lbs') }}:
                                        <span class="text-muted">
                                          {{
                                            updatedCpe.config.lbs_config.enabled ? $t('general.On') : $t('general.Off')
                                          }}
                                        </span>
                                        <span
                                          class="text-muted"
                                          v-if="
                                            updatedCpe.config.lbs_config.enabled &&
                                            updatedCpe.config.lbs_config.reportperiod
                                          "
                                        >
                                          ( {{ $t('aps.reportPeriod') }}:
                                          {{ updatedCpe.config.lbs_config.reportperiod }} )
                                        </span>
                                      </li>
                                      <!--<li>-->
                                      <!--{{$t('aps.LBSFilterMode')}}:-->
                                      <!--<span class="text-muted">{{ updatedCpe.config.lbs_config.filtermode}}</span>-->
                                      <!--</li>-->
                                      <li>
                                        {{ $t('aps.log') }}:
                                        <span class="text-muted">
                                          {{
                                            updatedCpe.config.log_config.enabled ? $t('general.On') : $t('general.Off')
                                          }}
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row mb-2">
                              <div class="col-lg-12">
                                <div class="form-group">
                                  <div class="h5 mt-1 mb-1">{{ $t('aps.general') }}</div>
                                  <label for="name">{{ $t('aps.name') }}</label>
                                  <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    v-validate="'max:30'"
                                    class="form-control"
                                    :disabled="true"
                                    :class="{ input: true, 'is-danger': errors.has('name') }"
                                    v-model="updatedCpe.name"
                                  />
                                  <span v-show="errors.has('name')" class="help is-danger">
                                    {{ errors.first('name') }}
                                  </span>
                                </div>

                                <div class="form-group">
                                  <label for="description">{{ $t('aps.description') }}</label>
                                  <input
                                    type="text"
                                    id="description"
                                    name="description"
                                    v-validate="'max:200'"
                                    class="form-control"
                                    :class="{ input: true, 'is-danger': errors.has('description') }"
                                    :disabled="true"
                                    v-model="updatedCpe.description"
                                  />
                                  <span v-show="errors.has('description')" class="help is-danger">
                                    {{ errors.first('description') }}
                                  </span>
                                </div>

                                <!--                                <div-->
                                <!--                                  class="form-group mt-1"-->
                                <!--                                  v-if="isBeeline && updatedCpe.config && updatedCpe.config.beeline_config"-->
                                <!--                                >-->
                                <!--                                  <label for="beeline-nas-ip">{{ $t('aps.nasIp') }}</label>-->
                                <!--                                  <input-->
                                <!--                                    type="text"-->
                                <!--                                    id="beeline-nas-ip"-->
                                <!--                                    placeholder="0.0.0.0"-->
                                <!--                                    name="beeline-nas-ip"-->
                                <!--                                    v-validate="'ipv4'"-->
                                <!--                                    class="form-control"-->
                                <!--                                    :class="{ input: true, 'is-danger': errors.has('beeline-nas-ip') }"-->
                                <!--                                    v-model="updatedCpe.config.beeline_config.nas_ip"-->
                                <!--                                  />-->
                                <!--                                  <span v-show="errors.has('beeline-nas-ip')" class="help is-danger">-->
                                <!--                                    {{ errors.first('beeline-nas-ip') }}-->
                                <!--                                  </span>-->
                                <!--                                </div>-->

                                <div class="form-group">
                                  <label for="location">{{ $t('aps.location') }}</label>
                                  <select
                                    v-model="updatedCpe.base_location"
                                    name="location"
                                    id="location"
                                    :class="{
                                      'select-disabled': true
                                    }"
                                    class="form-control"
                                    :disabled="true"
                                  >
                                    <option v-for="location in locationsList" :key="location.id" :value="location.id">
                                      {{ location.name }}
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-12">
                                <div v-if="isCpeHasIntefaces">
                                  <div class="h5 mb-1">{{ $t('aps.interfaces') }}</div>

                                  <!-- <div
                                    v-if="
                                      updatedCpe.config.hasOwnProperty('wifi_lock') &&
                                      updatedCpe.config.wifi &&
                                      !!updatedCpe.config.wifi.length
                                    "
                                    class="mb-h"
                                  >
                                    <Switch-component
                                      v-model="updatedCpe.config.wifi_lock"
                                      :disabled="isDisable"
                                      :label="$t('aps.wifiLock')"
                                      id="maps-wifi-lock"
                                      key="maps-wifi-lock"
                                    />
                                  </div> -->

                                  <div
                                    class="form-group interface-block mb-1"
                                    v-for="cpeInterface in updatedCpe.config.wifi"
                                    :data-id="cpeInterface.id"
                                    :class="{ 'multiselect-disabled-field': isDisable || updatedCpe.config.wifi_lock }"
                                  >
                                    <div class="interface-block-header mb-1" @click.prevent="showId">
                                      <div class="mb-0">
                                        <span class="h6">
                                          <img class="interface-block-icon" src="/static/img/radio.svg" />
                                          <strong>{{ cpeInterface.id }}</strong>
                                          <span class="badge badge-success ml-1 frequency-title">
                                            <span
                                              v-if="
                                                updatedCpe.state.wifi[cpeInterface.id].frequency === '2.4' ||
                                                updatedCpe.state.wifi[cpeInterface.id].frequency === '5'
                                              "
                                            >
                                              {{ updatedCpe.state.wifi[cpeInterface.id].frequency }}{{ $t('aps.ghz') }}
                                            </span>
                                          </span>
                                        </span>
                                        <div
                                          v-if="
                                            cpeModelData &&
                                            cpeModelData.caps.wifi &&
                                            updatedCpe.state.wifi &&
                                            updatedCpe.state.wifi[cpeInterface.id] &&
                                            cpeModelData.caps.wifi[cpeInterface.id] &&
                                            cpeModelData.caps.wifi[cpeInterface.id].frequency !== 'None'
                                          "
                                          class="float-right"
                                        >
                                          <button class="btn btn-link" v-if="!interfaceSettings[cpeInterface.id]">
                                            <i class="icon-arrow-left mr-1"></i>
                                            <i class="icon-settings"></i>
                                          </button>
                                          <button
                                            class="btn btn-link btn-link__disabled float-right"
                                            v-if="interfaceSettings[cpeInterface.id]"
                                          >
                                            <i class="icon-arrow-down mr-1"></i>
                                            <i class="icon-settings"></i>
                                          </button>
                                        </div>
                                        <div v-else>
                                          <small class="text-danger">No available capabilities</small>
                                        </div>
                                      </div>

                                      <div class>
                                        <small
                                          class
                                          v-if="updatedCpe.connected && updatedCpe.state.wifi[cpeInterface.id].enabled"
                                        >
                                          <b>{{ $t('aps.currentState') }}</b>
                                        </small>
                                        <small class="text-muted" v-else>{{ $t('aps.interfaceDisabled') }}</small>
                                        <br />
                                        <small
                                          class="text-xs"
                                          v-if="updatedCpe.connected && updatedCpe.state.wifi[cpeInterface.id].enabled"
                                        >
                                          {{ $t('aps.mode') }}:
                                          <span class="radio-data">{{ cpeInterface.bandmode }},</span>
                                          {{ $t('aps.width') }}:
                                          <span class="radio-data">
                                            {{ updatedCpe.state.wifi[cpeInterface.id].bandwidth }},
                                          </span>
                                          {{ $t('aps.channel') }}:
                                          <span class="radio-data">
                                            <span v-if="updatedCpe.state.wifi[cpeInterface.id].channel">
                                              {{ updatedCpe.state.wifi[cpeInterface.id].channel }}
                                            </span>
                                          </span>
                                          {{ $t('aps.txPower') }}:
                                          <span
                                            class="radio-data"
                                            v-if="
                                              !updatedCpe.state.wifi[cpeInterface.id].txpower ||
                                              updatedCpe.state.wifi[cpeInterface.id].txpower == '0'
                                            "
                                          >
                                            {{ $t('aps.auto') }}
                                          </span>
                                          <span class="radio-data" v-else>
                                            {{ updatedCpe.state.wifi[cpeInterface.id].txpower }} {{ $t('aps.dbm') }}
                                          </span>
                                          <span v-if="cpeInterface.country">{{ $t('aps.country') }}:</span>
                                          <span class="radio-data" v-if="cpeInterface.country">
                                            {{ cpeInterface.country }},
                                          </span>
                                          {{ $t('aps.rateControl') }}:
                                          <span class="radio-data">{{ cpeInterface.require_mode }},</span>
                                          {{ $t('aps.maxClients') }}:
                                          <span
                                            class="radio-data"
                                            v-if="
                                              updatedCpe.clients &&
                                              updatedCpe.clients[cpeInterface.id] &&
                                              updatedCpe.clients[cpeInterface.id].total
                                            "
                                          >
                                            {{ updatedCpe.clients[cpeInterface.id].total }}
                                          </span>
                                          <span class="radio-data" v-else>0</span>
                                          of
                                          <span class="radio-dataChannel" v-if="cpeInterface.maxclients">
                                            <span v-if="cpeInterface.maxclients == 0 || cpeInterface.maxclients === ''">
                                              {{ $t('aps.cpeEditCaptionUnlimited') }}
                                            </span>
                                            <span v-else>{{ cpeInterface.maxclients }}</span>
                                          </span>
                                          <span class="radio-data" v-else>{{ $t('aps.cpeEditCaptionUnlimited') }}</span>
                                        </small>
                                      </div>
                                    </div>
                                    <transition name="interface">
                                      <div class v-if="interfaceSettings[cpeInterface.id]">
                                        <!-- <label for="channel">{{interfaceCapabilities[cpeInterface.id].selected.name}}</label> -->
                                        <span class="h5">{{ $t('aps.cpeEditRadioSettings') }}</span>
                                        <div
                                          class="form-group mt-1"
                                          v-if="
                                            cpeModelData.caps.wifi &&
                                            cpeModelData.caps.wifi[cpeInterface.id].frequency === `Both`
                                          "
                                        >
                                          <label for="frequency">Frequency</label>
                                          <select
                                            id="frequency"
                                            class="form-control"
                                            :disabled="isDisable || updatedCpe.config.wifi_lock"
                                            :class="{ 'select-disabled': isDisable || updatedCpe.config.wifi_lock }"
                                            v-model="interfaceCapabilities[cpeInterface.id].selected.frequency"
                                          >
                                            <option>2.4</option>
                                            <option>5</option>
                                          </select>
                                        </div>

                                        <div class="form-group mt-1">
                                          <label for="bandmode">{{ $t('aps.bandMode') }}</label>
                                          <select
                                            id="bandmode"
                                            class="form-control"
                                            :disabled="isDisable || updatedCpe.config.wifi_lock"
                                            :class="{ 'select-disabled': isDisable || updatedCpe.config.wifi_lock }"
                                            v-model="interfaceCapabilities[cpeInterface.id].selected.bandmode"
                                          >
                                            <option
                                              v-for="(key, value) in interfaceCapabilities[cpeInterface.id].capabilities
                                                .hwmodelist"
                                              :selected="
                                                interfaceCapabilities[cpeInterface.id].selected.bandmode ===
                                                '11' + value
                                              "
                                              :value="'11' + value"
                                              v-if="
                                                isModeAllowed(
                                                  interfaceCapabilities[cpeInterface.id].selected.frequency,
                                                  value,
                                                  interfaceCapabilities[cpeInterface.id].capabilities.hwmodelist
                                                )
                                              "
                                            >
                                              {{ '11' + value }}
                                            </option>
                                          </select>
                                        </div>

                                        <div class="form-group" v-if="computedCapabilities[cpeInterface.id]">
                                          <label for="bandwidth">{{ $t('aps.bandwidth') }}</label>
                                          <select
                                            id="bandwidth"
                                            class="form-control"
                                            :disabled="isDisable || updatedCpe.config.wifi_lock"
                                            :class="{ 'select-disabled': isDisable || updatedCpe.config.wifi_lock }"
                                            v-model="interfaceCapabilities[cpeInterface.id].selected.bandwidth"
                                          >
                                            <option
                                              v-for="bandwidth in calculatedCapabilities[cpeInterface.id].bandwidth"
                                              :selected="
                                                interfaceCapabilities[cpeInterface.id].selected.bandwidth == bandwidth
                                              "
                                              :value="bandwidth"
                                            >
                                              <span
                                                v-if="
                                                  bandwidth === 'HT40' &&
                                                  interfaceCapabilities[cpeInterface.id].selected.frequency === '2.4'
                                                "
                                              >
                                                HT40+
                                              </span>
                                              <span v-else>{{ bandwidth }}</span>
                                            </option>
                                          </select>
                                        </div>

                                        <label for="channel">{{ $t('aps.channel') }}</label>
                                        <!--For 'Both'-->
                                        <!--<div class="form-group" v-if="computedCapabilities[cpeInterface.id] && interfaceCapabilities[cpeInterface.id].selected.frequency === 'Both'">-->
                                        <!--<strong>Auto</strong>-->
                                        <!--</div>-->
<!--                                        <div-->
<!--                                          class="form-group"-->
<!--                                          v-if="-->
<!--                                            computedCapabilities[cpeInterface.id] &&-->
<!--                                            interfaceCapabilities[cpeInterface.id].selected.frequency === '2.4'-->
<!--                                          "-->
<!--                                        >-->
<!--                                          <div class="channel-list">-->
<!--                                            <div-->
<!--                                              class="channel-list-item interface-channel-trapezoid"-->
<!--                                              v-for="channel in computedCapabilities[cpeInterface.id].channels"-->
<!--                                              :class="{-->
<!--                                                disabled: isDisable || updatedCpe.config.wifi_lock,-->
<!--                                                active: interfaceCapabilities[-->
<!--                                                  cpeInterface.id-->
<!--                                                ].selected.channels.includes(channel)-->
<!--                                              }"-->
<!--                                              v-if="channel != 'auto'"-->
<!--                                            >-->
<!--                                              <input-->
<!--                                                type="checkbox"-->
<!--                                                class="interface-channel-checkbox"-->
<!--                                                :disabled="isDisable || updatedCpe.config.wifi_lock"-->
<!--                                                :value="channel"-->
<!--                                                :id="channel"-->
<!--                                                v-model="interfaceCapabilities[cpeInterface.id].selected.channels"-->
<!--                                              />-->
<!--                                              <label-->
<!--                                                class-->
<!--                                                :class="{ 'label-disabled': isDisable || updatedCpe.config.wifi_lock }"-->
<!--                                                :for="channel"-->
<!--                                              >-->
<!--                                                {{ channel }}-->
<!--                                              </label>-->
<!--                                            </div>-->
<!--                                          </div>-->
<!--                                        </div>-->

<!--                                        <div-->
<!--                                          class="form-group"-->
<!--                                          v-if="-->
<!--                                            computedCapabilities[cpeInterface.id] &&-->
<!--                                            interfaceCapabilities[cpeInterface.id].selected.frequency === '5' &&-->
<!--                                            (interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'HT20' ||-->
<!--                                              interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'VHT20' ||-->
<!--                                              interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'auto')-->
<!--                                          "-->
<!--                                        >-->
<!--                                          <div class="channel-list">-->
<!--                                            <div-->
<!--                                              class="channel-list-item interface-channel-trapezoid"-->
<!--                                              v-for="channel in computedCapabilities[cpeInterface.id].channels"-->
<!--                                              :class="{-->
<!--                                                disabled: isDisable || updatedCpe.config.wifi_lock,-->
<!--                                                active: interfaceCapabilities[-->
<!--                                                  cpeInterface.id-->
<!--                                                ].selected.channels.includes(channel)-->
<!--                                              }"-->
<!--                                              v-if="channel != 'auto'"-->
<!--                                            >-->
<!--                                              <input-->
<!--                                                type="checkbox"-->
<!--                                                class="interface-channel-checkbox"-->
<!--                                                :disabled="isDisable || updatedCpe.config.wifi_lock"-->
<!--                                                :value="channel"-->
<!--                                                :id="channel"-->
<!--                                                v-model="interfaceCapabilities[cpeInterface.id].selected.channels"-->
<!--                                              />-->
<!--                                              <label-->
<!--                                                class-->
<!--                                                :class="{ 'label-disabled': isDisable || updatedCpe.config.wifi_lock }"-->
<!--                                                :for="channel"-->
<!--                                              >-->
<!--                                                {{ channel }}-->
<!--                                              </label>-->
<!--                                            </div>-->
<!--                                          </div>-->
<!--                                        </div>-->

<!--                                        <div-->
<!--                                          class="form-group"-->
<!--                                          v-if="-->
<!--                                            computedCapabilities[cpeInterface.id] &&-->
<!--                                            interfaceCapabilities[cpeInterface.id].selected.frequency === '5' &&-->
<!--                                            (interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'HT40' ||-->
<!--                                              interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'VHT40')-->
<!--                                          "-->
<!--                                        >-->
<!--                                          <div class="channel-list">-->
<!--                                            <div-->
<!--                                              class="channel-list-item interface-channel-trapezoid__ht40"-->
<!--                                              v-for="channel in computedCapabilities[cpeInterface.id].channels"-->
<!--                                              :class="{-->
<!--                                                disabled: isDisable || updatedCpe.config.wifi_lock,-->
<!--                                                active: interfaceCapabilities[-->
<!--                                                  cpeInterface.id-->
<!--                                                ].selected.channels.includes(channel)-->
<!--                                              }"-->
<!--                                              v-if="channel != 'auto'"-->
<!--                                            >-->
<!--                                              <input-->
<!--                                                type="checkbox"-->
<!--                                                class="interface-channel-checkbox"-->
<!--                                                :disabled="isDisable || updatedCpe.config.wifi_lock"-->
<!--                                                :value="channel"-->
<!--                                                :id="channel"-->
<!--                                                v-model="interfaceCapabilities[cpeInterface.id].selected.channels"-->
<!--                                              />-->
<!--                                              <label-->
<!--                                                class-->
<!--                                                :class="{ 'label-disabled': isDisable || updatedCpe.config.wifi_lock }"-->
<!--                                                :for="channel"-->
<!--                                              >-->
<!--                                                {{ channel }}-->
<!--                                                {{ channel + 4 }}-->
<!--                                              </label>-->
<!--                                            </div>-->
<!--                                          </div>-->
<!--                                        </div>-->

<!--                                        <div-->
<!--                                          class="form-group"-->
<!--                                          v-if="-->
<!--                                            computedCapabilities[cpeInterface.id] &&-->
<!--                                            interfaceCapabilities[cpeInterface.id].selected.frequency === '5' &&-->
<!--                                            interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'VHT80'-->
<!--                                          "-->
<!--                                        >-->
<!--                                          <div class="channel-list">-->
<!--                                            <div-->
<!--                                              class="channel-list-item interface-channel-trapezoid__ht80"-->
<!--                                              v-for="channel in computedCapabilities[cpeInterface.id].channels"-->
<!--                                              :class="{-->
<!--                                                disabled: isDisable || updatedCpe.config.wifi_lock,-->
<!--                                                active: interfaceCapabilities[-->
<!--                                                  cpeInterface.id-->
<!--                                                ].selected.channels.includes(channel)-->
<!--                                              }"-->
<!--                                              v-if="channel != 'auto'"-->
<!--                                            >-->
<!--                                              <input-->
<!--                                                type="checkbox"-->
<!--                                                class="interface-channel-checkbox"-->
<!--                                                :disabled="isDisable || updatedCpe.config.wifi_lock"-->
<!--                                                :value="channel"-->
<!--                                                :id="channel"-->
<!--                                                v-model="interfaceCapabilities[cpeInterface.id].selected.channels"-->
<!--                                              />-->
<!--                                              <label-->
<!--                                                class-->
<!--                                                :class="{ 'label-disabled': isDisable || updatedCpe.config.wifi_lock }"-->
<!--                                                :for="channel"-->
<!--                                              >-->
<!--                                                {{ channel }}-->
<!--                                                {{ channel + 4 }} {{ channel + 8 }} {{ channel + 12 }}-->
<!--                                              </label>-->
<!--                                            </div>-->
<!--                                          </div>-->
<!--                                        </div>-->

                                        <div
                                          class="form-group"
                                          v-if="
                                            computedCapabilities[cpeInterface.id] &&
                                            interfaceCapabilities[cpeInterface.id].selected.frequency === '2.4'
                                          "
                                        >
                                          <div class="channel-list">
                                            <div
                                              class="channel-list-item interface-channel-trapezoid"
                                              v-for="channel in computedCapabilities[cpeInterface.id].channels"
                                              :class="{
                                                disabled: isDisable || updatedCpe.config.wifi_lock,
                                                active: interfaceCapabilities[
                                                  cpeInterface.id
                                                ].selected.channels.includes(channel)
                                              }"
                                              v-if="channel != 'auto'"
                                            >
                                              <input
                                                type="checkbox"
                                                class="interface-channel-checkbox"
                                                :disabled="isDisable || updatedCpe.config.wifi_lock"
                                                :value="channel"
                                                :id="channel"
                                                v-model="interfaceCapabilities[cpeInterface.id].selected.channels"
                                              />
                                              <label
                                                class
                                                :class="{ 'label-disabled': isDisable || updatedCpe.config.wifi_lock }"
                                                :for="channel"
                                              >
                                                {{ channel }}
                                              </label>
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          class="form-group"
                                          v-if="
                                            computedCapabilities[cpeInterface.id] &&
                                            interfaceCapabilities[cpeInterface.id].selected.frequency === '5' &&
                                            (interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'HT20' ||
                                              interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'VHT20' ||
                                              interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'HE20' ||
                                              interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'auto')
                                          "
                                        >
                                          <div class="channel-list">
                                            <div
                                              class="channel-list-item interface-channel-trapezoid"
                                              v-for="channel in computedCapabilities[cpeInterface.id].channels"
                                              :class="{
                                                disabled: isDisable || updatedCpe.config.wifi_lock,
                                                active: interfaceCapabilities[
                                                  cpeInterface.id
                                                ].selected.channels.includes(channel)
                                              }"
                                              v-if="channel != 'auto'"
                                            >
                                              <input
                                                type="checkbox"
                                                class="interface-channel-checkbox"
                                                :disabled="isDisable || updatedCpe.config.wifi_lock"
                                                :value="channel"
                                                :id="channel"
                                                v-model="interfaceCapabilities[cpeInterface.id].selected.channels"
                                              />
                                              <label
                                                class
                                                :class="{ 'label-disabled': isDisable || updatedCpe.config.wifi_lock }"
                                                :for="channel"
                                              >
                                                {{ channel }}
                                              </label>
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          class="form-group"
                                          v-if="
                                            computedCapabilities[cpeInterface.id] &&
                                            interfaceCapabilities[cpeInterface.id].selected.frequency === '5' &&
                                            (interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'HT40' ||
                                            interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'HE40' ||
                                              interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'VHT40')
                                          "
                                        >
                                          <div class="channel-list">
                                            <div
                                              class="channel-list-item interface-channel-trapezoid__ht40"
                                              v-for="channel in computedCapabilities[cpeInterface.id].channels"
                                              :class="{
                                                disabled: isDisable || updatedCpe.config.wifi_lock,
                                                active: interfaceCapabilities[
                                                  cpeInterface.id
                                                ].selected.channels.includes(channel)
                                              }"
                                              v-if="channel != 'auto'"
                                            >
                                              <input
                                                type="checkbox"
                                                class="interface-channel-checkbox"
                                                :disabled="isDisable || updatedCpe.config.wifi_lock"
                                                :value="channel"
                                                :id="channel"
                                                v-model="interfaceCapabilities[cpeInterface.id].selected.channels"
                                              />
                                              <label
                                                class
                                                :class="{ 'label-disabled': isDisable || updatedCpe.config.wifi_lock }"
                                                :for="channel"
                                              >
                                                {{ channel }}
                                                {{ channel + 4 }}
                                              </label>
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          class="form-group"
                                          v-if="
                                            computedCapabilities[cpeInterface.id] &&
                                            interfaceCapabilities[cpeInterface.id].selected.frequency === '5' &&
                                            (interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'VHT80' ||
                                            interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'HE80')
                                          "
                                        >
                                          <div class="channel-list">
                                            <div
                                              class="channel-list-item interface-channel-trapezoid__ht80"
                                              v-for="channel in computedCapabilities[cpeInterface.id].channels"
                                              :class="{
                                                disabled: isDisable || updatedCpe.config.wifi_lock,
                                                active: interfaceCapabilities[
                                                  cpeInterface.id
                                                ].selected.channels.includes(channel)
                                              }"
                                              v-if="channel != 'auto'"
                                            >
                                              <input
                                                type="checkbox"
                                                class="interface-channel-checkbox"
                                                :disabled="isDisable || updatedCpe.config.wifi_lock"
                                                :value="channel"
                                                :id="channel"
                                                v-model="interfaceCapabilities[cpeInterface.id].selected.channels"
                                              />
                                              <label
                                                class
                                                :class="{ 'label-disabled': isDisable || updatedCpe.config.wifi_lock }"
                                                :for="channel"
                                              >
                                                {{ channel }}
                                                {{ channel + 4 }} {{ channel + 8 }} {{ channel + 12 }}
                                              </label>
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          class="form-group"
                                          v-if="
                                            computedCapabilities[cpeInterface.id] &&
                                            interfaceCapabilities[cpeInterface.id].selected.frequency === '5' &&
                                                                       (interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'VHT80+80' ||
                                  interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'VHT160' ||
                                  interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'HE80+80' ||
                                  interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'HE160')
                                          "
                                        >
                                          <div class="channel-list">
                                            <div
                                              class="channel-list-item interface-channel-trapezoid__ht80"
                                              v-for="channel in computedCapabilities[cpeInterface.id].channels"
                                              :class="{
                                                disabled: isDisable || updatedCpe.config.wifi_lock,
                                                active: interfaceCapabilities[
                                                  cpeInterface.id
                                                ].selected.channels.includes(channel)
                                              }"
                                              v-if="channel != 'auto'"
                                            >
                                              <input
                                                type="checkbox"
                                                class="interface-channel-checkbox"
                                                :disabled="isDisable || updatedCpe.config.wifi_lock"
                                                :value="channel"
                                                :id="channel"
                                                v-model="interfaceCapabilities[cpeInterface.id].selected.channels"
                                              />
                                              <label
                                                class
                                                :class="{ 'label-disabled': isDisable || updatedCpe.config.wifi_lock }"
                                                :for="channel"
                                              >
                                                {{ channel }}
                                                {{ channel + 4 }} {{ channel + 8 }} {{ channel + 12
                                                }} {{ channel + 16
                                                }} {{ channel + 20 }} {{ channel + 24 }} {{ channel
                                                + 28 }}
                                              </label>
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          class="form-group mb-2"
                                          v-if="
                                            interfaceCapabilities[cpeInterface.id].selected.power &&
                                            interfaceCapabilities[cpeInterface.id].selected.power.range
                                          "
                                        >
                                          <span class="m-0 mt-2">{{ $t('aps.txPowerAdjust') }}</span>
                                          <Switch-component
                                            v-model="interfaceCapabilities[cpeInterface.id].selected.power.auto"
                                            :disabled="isDisable || updatedCpe.config.wifi_lock"
                                            :label="$t('general.Auto')"
                                            class="float-right"
                                            id="maps-power-auto"
                                            key="maps-power-auto"
                                          />

                                          <vue-slider
                                            ref="slider"
                                            v-if="
                                              !isDisable &&
                                              !updatedCpe.config.wifi_lock &&
                                              !interfaceCapabilities[cpeInterface.id].selected.power.auto
                                            "
                                            :disabled="isCisco"
                                            v-bind="TXPower"
                                            :max="computedCapabilities[cpeInterface.id].txPower"
                                            :min="computedCapabilities[cpeInterface.id].minTxPower || 0"
                                            tooltipDir="bottom"
                                            tooltip="always"
                                            formatter="{value} dbm"
                                            v-model="interfaceCapabilities[cpeInterface.id].selected.power.range"
                                          ></vue-slider>
                                          <vue-slider
                                            ref="slider"
                                            v-else
                                            :disabled="true"
                                            v-bind="TXPower"
                                            :max="computedCapabilities[cpeInterface.id].txPower"
                                            :min="computedCapabilities[cpeInterface.id].minTxPower || 0"
                                            tooltipDir="bottom"
                                            tooltip="always"
                                            formatter="{value} dbm"
                                            v-model="interfaceCapabilities[cpeInterface.id].selected.power.range"
                                          ></vue-slider>
                                        </div>
                                        <div class="text-danger mb-2 mt-2" v-else>Incorrect TX Power data</div>

                                        <div class="form-group" v-if="!isCisco">
                                          <label for="country">{{ $t('aps.country') }}</label>
                                          <input
                                            id="country"
                                            class="form-control"
                                            :disabled="isDisable || updatedCpe.config.wifi_lock"
                                            :class="{ 'select-disabled': isDisable || updatedCpe.config.wifi_lock }"
                                            v-model="interfaceCapabilities[cpeInterface.id].selected.country"
                                          />
                                        </div>

                                        <hr class="hr-settings mb-2" />

                                        <div v-if="!isCisco">
                                          <span class="h5">{{ $t('aps.advancedSettings') }}</span>

                                          <div
                                            class="form-group mt-2 mb-2"
                                            v-if="rateControl[interfaceCapabilities[cpeInterface.id].selected.bandmode]"
                                          >
                                            <label for="rate_control">
                                              <b>{{ $t('aps.rateControl') }}</b>
                                            </label>
                                            <vue-slider
                                              :disabled="
                                                isDisable ||
                                                updatedCpe.config.wifi_lock ||
                                                (interfaceCapabilities[cpeInterface.id].selected.bandmode != '11n' &&
                                                  interfaceCapabilities[cpeInterface.id].selected.bandmode != '11ac')
                                              "
                                              v-bind="
                                                rateControl[interfaceCapabilities[cpeInterface.id].selected.bandmode]
                                              "
                                              tooltipDir="bottom"
                                              tooltip="hover"
                                              formatter="{value}"
                                              id="rate_control"
                                              v-model="interfaceCapabilities[cpeInterface.id].selected.require_mode"
                                            ></vue-slider>
                                          </div>

                                          <div class="form-group mb-0 mt-2">
                                            <Switch-component
                                              v-model="
                                                interfaceCapabilities[cpeInterface.id].selected.scanningconfig.enabled
                                              "
                                              :disabled="
                                                isDisable ||
                                                updatedCpe.config.wifi_lock ||
                                                (!updatedCpe.connected && updatedCpe.config_status == 'empty')
                                              "
                                              :label="$t('aps.backgroundScaning')"
                                              class=""
                                              id="maps-background-scanning"
                                              key="maps-background-scanning"
                                            />
                                          </div>
                                          <div class="form-group mt-1 mb-0">
                                            <Switch-component
                                              v-model="advancedSettings[cpeInterface.id].maxClientsEnabled"
                                              :disabled="
                                                isDisable ||
                                                updatedCpe.config.wifi_lock ||
                                                (!updatedCpe.connected && updatedCpe.config_status == 'empty')
                                              "
                                              :label="$t('aps.maxClients')"
                                              @change="
                                                resetMaxClients(
                                                  advancedSettings[cpeInterface.id].maxClientsEnabled,
                                                  cpeInterface.id
                                                )
                                              "
                                              class=""
                                              id="maps-max-clients"
                                              key="maps-max-clients"
                                            />
                                          </div>
                                          <transition name="interface">
                                            <div
                                              class="form-group mb-2"
                                              v-if="advancedSettings[cpeInterface.id].maxClientsEnabled"
                                            >
                                              <label for="maxclients">{{ $t('aps.limitperWlan') }}</label>
                                              <input
                                                id="maxclients"
                                                class="form-control"
                                                :disabled="
                                                  isDisable ||
                                                  updatedCpe.config.wifi_lock ||
                                                  !advancedSettings[cpeInterface.id].maxClientsEnabled
                                                "
                                                name="maxclients"
                                                v-validate="'numeric|between:0,2007'"
                                                :class="{
                                                  'select-disabled': isDisable || updatedCpe.config.wifi_lock,
                                                  input: true,
                                                  'is-danger': errors.has('description')
                                                }"
                                                v-model="interfaceCapabilities[cpeInterface.id].selected.maxclients"
                                              />
                                              <span v-show="errors.has('maxclients')" class="help is-danger">
                                                {{ errors.first('maxclients') }}
                                              </span>
                                            </div>
                                          </transition>

                                          <div class="form-group mt-1 mb-0">
                                            <label
                                              class="switch switch-text switch-bee switch-success mr-1 float-right"
                                              :class="{
                                                'switch-disabled': true
                                              }"
                                            >
                                              <input
                                                type="checkbox"
                                                name="legacyRates"
                                                class="switch-input"
                                                v-model="interfaceCapabilities[cpeInterface.id].selected.legacy_rates"
                                                true-value="0"
                                                false-value="1"
                                                :disabled="true"
                                              />
                                              <span class="switch-label"></span>
                                              <span class="switch-handle"></span>
                                            </label>
                                            <label for="legacyRates">
                                              <b>{{ $t('aps.legacyRates') }}</b>
                                            </label>
                                          </div>

                                          <div class="form-group mt-1 mb-0">
                                            <label
                                              class="switch switch-text switch-bee switch-success mr-1 float-right"
                                              :class="{
                                                'switch-disabled': true
                                              }"
                                            >
                                              <input
                                                type="checkbox"
                                                name="logLevel"
                                                class="switch-input"
                                                v-model="interfaceCapabilities[cpeInterface.id].selected.log_level"
                                                true-value="0"
                                                false-value="2"
                                                :disabled="true"
                                              />
                                              <span class="switch-label"></span>
                                              <span class="switch-handle"></span>
                                            </label>
                                            <label for="logLevel">
                                              <b>{{ $t('aps.logLevel') }}</b>
                                            </label>
                                          </div>
                                          <small class="text-muted">{{ $t('aps.logLevelMode') }}</small>
                                        </div>
                                      </div>
                                    </transition>
                                    <hr class="hr-settings" />

                                    <div class="wlans-title mt-2">
                                      <label class>
                                        <strong>{{ $t('aps.wlansCaption') }}</strong>
                                      </label>
                                      <!--                                                <a href="#" class="ml-q pull-right" @click.prevent="isWlanDescription = !isWlanDescription">-->
                                      <!--                                                  <span v-if="!isWlanDescription">{{ $t('aps.showDescription') }}</span>-->
                                      <!--                                                  <span v-else>{{ $t('aps.hideDescription') }}</span>-->
                                      <!--                                                </a>-->
                                    </div>
                                    <multiselect
                                      v-model="interfaceSelectedWlans[cpeInterface.id]"
                                      :multiple="true"
                                      :options="wlansList || []"
                                      :max="8 - interfaceHiddenWlans[cpeInterface.id].length"
                                      label="ssid"
                                      trackBy="ssid"
                                      :closeOnSelect="false"
                                      :customLabel="ssidWithDescription"
                                      :hideSelected="true"
                                      @input="updateCpeInterface"
                                      :disabled="true"
                                      :class="{ 'multiselect-disabled': isDisable || updatedCpe.config.wifi_lock }"
                                    ></multiselect>
                                    <div v-if="cpeInterface.wlans" class="mb-1">
                                      <small class="mr-1" v-if="cpeInterface.wlans && cpeInterface.wlans.length">
                                        {{ $t('aps.WLANsstatus') }}:
                                      </small>
                                      <small
                                        class
                                        v-for="wlanId in cpeInterface.wlans"
                                        v-if="
                                          updatedCpe.state.wifi[cpeInterface.id] &&
                                          updatedCpe.state.wifi[cpeInterface.id].wlanstates
                                        "
                                      >
                                        <span
                                          class="text-success mr-1 interface-wlan-state"
                                          v-if="
                                            updatedCpe.state.wifi[cpeInterface.id].wlanstates[wlanId] &&
                                            updatedCpe.state.wifi[cpeInterface.id].wlanstates[wlanId].state ===
                                              'ENABLED'
                                          "
                                        >
                                          <span v-if="wlanObj(wlanId)">
                                            <i class="fa fa-check"></i>
                                            {{ wlanObj(wlanId).ssid }}
                                          </span>
                                        </span>
                                        <span
                                          class="text-danger mr-1 interface-wlan-state"
                                          v-if="
                                            !updatedCpe.state.wifi[cpeInterface.id].wlanstates[wlanId] ||
                                            (updatedCpe.state.wifi[cpeInterface.id].wlanstates[wlanId] &&
                                              updatedCpe.state.wifi[cpeInterface.id].wlanstates[wlanId].state ===
                                                'DISABLED')
                                          "
                                        >
                                          <span v-if="wlanObj(wlanId)">
                                            <i class="fa fa-stop-circle-o"></i>
                                            {{ wlanObj(wlanId).ssid }}
                                          </span>
                                        </span>
                                        <span
                                          class="text-info mr-1 interface-wlan-state"
                                          v-if="updatedCpe.state.wifi[cpeInterface.id].wlanstates[wlanId] && (updatedCpe.state.wifi[cpeInterface.id].wlanstates[wlanId].state != 'ENABLED' && updatedCpe.state.wifi[cpeInterface.id].wlanstates[wlanId].state != 'DISABLED')"
                                        >
                                          <span v-if="wlanObj(wlanId)">
                                            <i class="fa fa-refresh"></i>
                                            {{ wlanObj(wlanId).ssid }}
                                          </span>
                                        </span>
                                      </small>
                                      <p
                                        class="p-0 mb-h mt-q small text-"
                                        v-if="interfaceHiddenWlans[cpeInterface.id].length"
                                      >
                                        Service hidden WLANs: {{ interfaceHiddenWlans[cpeInterface.id].length }}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-lg-12">
                                <div v-if="cpeModelData && cpeModelData.caps.wired">
                                  <!-- Wired component -->
                                  <wiredConfig
                                    v-if="cpeModelData.caps.wired && updatedCpe"
                                    :redirectsList="redirectsList"
                                    :cpeModelData="cpeModelData"
                                    :objWiredState="updatedCpe.state.wired"
                                    :objWiredConfig="updatedCpe.config.wired"
                                    :isRootUser="isRootUser"
                                    :isDisable="isDisable || updatedCpe.config.wifi_lock"
                                    :isOperator="isOperator"
                                    :hostsList="hostsList"
                                    :wlansList="wlansList"
                                  ></wiredConfig>
                                </div>
                              </div>
                            </div>

                            <div class="advanced-block" v-if="!isCisco">
                              <div class="h5 mt-1 mb-1">{{ $t('aps.Advanced') }}</div>

                              <!--showLogStatLbs-->
                              <statLogLbsConfig
                                v-if="updatedCpe"
                                :updatedCpeConfig="updatedCpe.config"
                                :currentCpeConfig="cpeEditCurrent.config"
                                :isEditShow="statLogLbsmodal"
                                :isRootUser="isRootUser"
                                :lbsFilterList="configFilterList"
                                :isDisable="isDisable || updatedCpe.config.wifi_lock"
                                :isOperator="isOperator"
                              ></statLogLbsConfig>

                              <accessControl
                                v-if="updatedCpe"
                                :updatedCpeConfig="updatedCpe.config"
                                :isConnected="updatedCpe.connected"
                                :firewallL3List="firewallL3List"
                                :currentCpeConfig="cpeEditCurrent.config"
                                :isEditShow="isFirewallShow"
                                :isRootUser="isRootUser"
                                :firewallsList="firewallsList"
                                :isDisable="isDisable || updatedCpe.config.wifi_lock"
                                :isOperator="isOperator"
                              ></accessControl>

                              <div class="interface-block mb-1" v-if="isCpeHasIntefaces">
                                <div class="setting-block-header" @click.prevent="showFWUpgrade">
                                  <div class="setting-block-status">
                                    <p class="h6 mb-h pr-h setting-block-title">{{ $t('aps.FWAutoUpgrade') }}</p>
                                  </div>
                                  <div class="setting-block-buttons">
                                    <button class="btn btn-link" v-if="!isFWUpgradeShow">
                                      <i class="icon-arrow-left"></i>
                                    </button>
                                    <button class="btn btn-link" v-if="isFWUpgradeShow">
                                      <i class="icon-arrow-down"></i>
                                    </button>
                                  </div>
                                </div>

                                <transition name="interface">
                                  <div class="mt-1" v-if="isFWUpgradeShow">
                                    <div class="form-group">
                                      <label for="mode">{{ $t('general.Mode') }}</label>
                                      <select
                                        v-model="updatedCpe.config.firmware.mode"
                                        name="location"
                                        id="mode"
                                        :class="{
                                          'select-disabled': true
                                        }"
                                        class="form-control"
                                        :disabled="true"
                                      >
                                        <option value="off">{{ $t('aps.firmwareModeOff') }}</option>
                                        <option value="on">{{ $t('aps.firmwareModeOn') }}</option>
                                        <option value="check">{{ $t('aps.firmwareModeCheck') }}</option>
                                      </select>
                                    </div>
                                    <div class="form-group">
                                      <label for="fw_timeout">
                                        {{ $t('general.Timeout') }} ({{ $t('general.insec') }})
                                      </label>
                                      <input
                                        type="text"
                                        id="fw_timeout"
                                        placeholder="Enter timeout"
                                        name="fw_timeout"
                                        data-vv-as="Timeout"
                                        class="form-control"
                                        v-validate="'required|between:600,86400'"
                                        :class="{ input: true, 'is-danger': errors.has('description') }"
                                        :disabled="true"
                                        v-model.number="updatedCpe.config.firmware.timeout"
                                      />
                                      <span v-show="errors.has('fw_timeout')" class="help is-danger">
                                        {{ errors.first('fw_timeout') }}
                                      </span>
                                    </div>
                                  </div>
                                </transition>
                              </div>
                            </div>
                          </div>
                          <!--                                    <div class="actions-buttons" :class="{ 'actions-buttons__operator': isDisable }">-->
                          <!--                                      <div>-->
                          <!--                                        <button-->
                          <!--                                          v-if="(!isDisable && !isUpdating )"-->
                          <!--                                          type="button"-->
                          <!--                                          class="btn btn-outline-success btn-md"-->
                          <!--                                          @click="updateCpe()"-->
                          <!--                                          :disabled="!enableSaveChanges || isUpdating || errors.any() || muteEdit"-->
                          <!--                                        >-->
                          <!--                                          <span :class="{ invisible: muteEdit || isUpdating }">{{ $t('aps.save') }}</span>-->
                          <!--                                          <span v-if="muteEdit || isUpdating" class="loader loader&#45;&#45;mini"></span>-->
                          <!--                                        </button>-->

                          <!--                                        <button-->
                          <!--                                          v-if="!(isDisable || isUpdating || updatedCpe.connected)"-->
                          <!--                                          type="button"-->
                          <!--                                          :disabled="isUpdating || muteEdit"-->
                          <!--                                          class="btn btn-outline-danger btn-md ml-1"-->
                          <!--                                          @click="openDeletingCpeDialog(updatedCpe.id)"-->
                          <!--                                        >-->
                          <!--                                          <span>{{ $t('general.delete') }}</span>-->
                          <!--                                          <span v-if="muteEdit" class="loader loader&#45;&#45;mini"></span>-->
                          <!--                                        </button>-->
                          <!--                                      </div>-->
                          <!--                                      <div>-->
                          <!--                                        <button type="button" class="btn btn-outline-secondary btn-md" @click="disableSingleEditMode">-->
                          <!--                                          <span v-if="isDisable">{{ $t('aps.close') }}</span>-->
                          <!--                                          <span v-else>{{ $t('aps.cancel') }}</span>-->
                          <!--                                        </button>-->
                          <!--                                      </div>-->
                          <!--                                    </div>-->
                        </div>
                      </div>
                      <!-- Modal footer -->
                      <div slot="modal-footer" class="modal-footer justify-content-between">
                        <div>
                          <button type="button" class="btn btn-outline-secondary" @click="closeMoreCpeInfoModal">
                            {{ $t('general.close') }}
                          </button>
                        </div>
                      </div>
                    </modal>
                  </div>
                  <div
                    v-if="isSelectedCpeUsersInfoModalShowing === true"
                    class="modal-with-more-cpe-info-in-heatmap"
                    @click.stop=""
                    @dblclick.stop=""
                    @mouseover="mouseOverModalCpeMoreInfo"
                    @mouseout="mouseOutModalCpeMoreInfo"
                  >
                    <modal
                      xlarge
                      title="Selected Cpe Users Info Modal"
                      v-model="isSelectedCpeUsersInfoModalShowing"
                      cancelText="Cancel"
                      okText="Delete"
                      @cancel="closeSelectedCpeUsersInfoModal"
                      class="modal-info modal-with-cpe-users-info"
                      effect="fade/zoom"
                    >
                      <h4 slot="modal-title" class="modal-title">
                        {{ userListForSelectedCpeCpeName }}: {{ $t('maps.cpeUsers') }}
                      </h4>
                      <div>
                        <div class="">
                          <loader-spinner v-if="isLoadingUsersInfoForSelectedCpeOnMap"></loader-spinner>
                          <div v-else>
                            <div class="d-flex justify-content-center" v-if="userListForSelectedCpe.length === 0">
                              <div>
                                {{ $t('maps.noCpeConnectedUsersToDisplay') }}
                              </div>
                            </div>
                            <div v-else>
                              <!--                              {{userListForSelectedCpe}}-->
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th
                                      v-for="column in tableSettingsForUsersList"
                                      :key="column.name"
                                      v-if="column.value"
                                      class="cpe-clients-modal-table-th"
                                    >
                                      {{ column.name }}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(client, index) in userListForSelectedCpe"
                                    :data-index="index"
                                    class="client-row cpe-clients-modal-table-client-row"
                                    :data-mac="client.mac"
                                  >
                                    <td class="client-name">
                                      <span
                                        class="badge-client badge-status"
                                        :class="{
                                          'badge-status__connected': client.State === 'CONNECTED',
                                          'badge-status__disconnected': client.State === 'DISCONNECTED'
                                        }"
                                      >
                                        <i
                                          class="fa mr-2"
                                          :class="{
                                            'fa-plug': client.Type === 'wired',
                                            'fa-video-camera': client.Type === 'camera',
                                            'fa-mobile': client.Type === 'other'
                                          }"
                                        ></i>
                                        <span>{{ returnMaskedOrNotMAC(getFormatMac(client.mac)) }}</span>
                                      </span>
                                    </td>
                                    <td class v-if="tableSettingsForUsersList.ip.value">
                                      <div class="description-row">
                                        <span
                                          class="badge mr-q badge-light"
                                          :class="{ 'badge-default': !client.ip, 'badge-dark': client.ip }"
                                        >
                                          <i class="fa fa-user mr-q" v-if="client.ip"></i>
                                          {{ client.ip || `${$t('clients.tableNoData')}` }}
                                        </span>
                                      </div>
                                    </td>
                                    <!--                                  <td class="responsive-row"-->
                                    <!--                                      v-if="tableSettingsForUsersList.description.value">-->
                                    <!--                                    <div class="description-row">-->
                                    <!--                              <span-->
                                    <!--                                class="text-info tooltip-text ml-q"-->
                                    <!--                                v-if="client.username"-->
                                    <!--                                v-tooltip.top-center="{-->
                                    <!--                                  content: `<ul class='list-unstyled text-left m-0'>-->
                                    <!--                                    <li class='mb-q'><b>${$t('clients.authInfo')}</b></li>-->
                                    <!--                                    <li><small><b>${$t('clients.authUsername')}</b>: ${client.username}</small></li>-->
                                    <!--                                    <li><small><b>${$t('clients.authType')}</b>: ${client.ua_type}</small></li>-->
                                    <!--                                    <li><small><b>${$t('clients.authDevice')}</b>: ${client.ua_device}</small></li>-->
                                    <!--                                    <li><small><b>${$t('clients.authOs')}</b>: ${client.os}</small></li>-->
                                    <!--                                    <li><small><b>${$t('clients.authOsVersion')}</b>: ${client.os_version}</small></li>-->
                                    <!--                                    <li><small><b>${$t('clients.authUseragent')}</b>: ${client.useragent}</small></li>-->
                                    <!--                                 </ul>`-->
                                    <!--                                }"-->
                                    <!--                              >-->
                                    <!--                                {{ client.username }}-->
                                    <!--                              </span>-->
                                    <!--                                      <span class="text-muted"-->
                                    <!--                                            v-else>{{$t('clients.tableNo')}}</span>-->
                                    <!--                                    </div>-->
                                    <!--                                  </td>-->
                                    <td class="responsive-row" v-if="tableSettingsForUsersList.description.value">
                                      <div class="description-row">{{ client.data.description }}</div>
                                    </td>

                                    <td v-if="tableSettingsForUsersList.cpe.value">
                                      <div class="client-state d-flex flex-wrap">
                                        <div class="client-state__main">
                                          <span
                                            class="badge badge-light mr-1 badge-light"
                                            :class="{
                                              'badge-primary': cpeNamebyId(client.cpe_id),
                                              'badge-default': !cpeNamebyId(client.cpe_id)
                                            }"
                                          >
                                            <img
                                              class="img img-responsive cpe-badge mr-q"
                                              src="/static/img/router-icon-white.svg"
                                              alt
                                            />
                                            <span v-if="cpeNamebyId(client.cpe_id)">
                                              {{ cpeNamebyId(client.cpe_id) }}
                                            </span>
                                            <span
                                              class="tooltip-text"
                                              v-else
                                              v-tooltip.top-center="{ content: 'CPE ID: ' + client.cpe_id }"
                                            >
                                              {{ $t('clients.tableNotFound') }}
                                            </span>
                                          </span>
                                        </div>

                                        <div class="client-state__advanced" v-if="cpeNamebyId(client.cpe_id)">
                                          <span
                                            class="badge badge-light badge-info badge-light"
                                            v-if="client.Type !== 'wired'"
                                          >
                                            {{ client.freq }} GHz
                                          </span>
                                          <span
                                            class="badge badge-light badge-info badge-light"
                                            v-if="client.Type !== 'wired'"
                                          >
                                            {{ client.channel }}
                                          </span>
                                          <span
                                            class="badge badge-light badge-info badge-light"
                                            v-if="client.Type !== 'wired'"
                                          >
                                            {{ client.rssi }} dBm
                                          </span>
                                          <span
                                            class="badge badge-light badge-info badge-light"
                                            v-if="client.Type === 'wired'"
                                          >
                                            {{ $t('clients.tableInterface') }}: {{ client.radio_id }}
                                          </span>
                                        </div>
                                      </div>
                                    </td>
                                    <td v-if="tableSettingsForUsersList.wlan.value">
                                      <span
                                        class="badge mr-q badge-light"
                                        :class="{
                                          'badge-primary': wlanNamebyId(client.wlan_id),
                                          'badge-default': !wlanNamebyId(client.wlan_id)
                                        }"
                                        v-if="client.Type !== 'wired'"
                                      >
                                        <i class="fa fa-wifi mr-q"></i>
                                        <span v-if="wlanNamebyId(client.wlan_id)">
                                          {{ wlanNamebyId(client.wlan_id) }}
                                        </span>
                                        <span
                                          class="tooltip-text"
                                          v-else
                                          v-tooltip.top-center="{ content: 'WLAN ID: ' + client.wlan_id }"
                                        >
                                          {{ $t('clients.tableNotFound') }}
                                        </span>
                                      </span>
                                      <span v-else class="text-muted">—</span>
                                    </td>
                                    <td v-if="tableSettingsForUsersList.healthScore.value">
                                      <div v-if="client.hasOwnProperty('health_score') && client.State === 'CONNECTED'">
                                        <span
                                          class="badge badge-light"
                                          :class="getBadgeClassByClientHealthScores(client.health_score)"
                                        >
                                          {{ $t(`clients.health${getClientHealthStatus(client.health_score)}`) }}
                                        </span>
                                        <span
                                          class="badge badge-light"
                                          :class="getBadgeClassByClientHealthScores(client.health_score)"
                                        >
                                          ({{ client.health_score }} {{ $t('clients.scores') }})
                                        </span>
                                      </div>
                                      <span v-else class="text-muted">—</span>
                                    </td>
                                  </tr>
                                  <tr
                                    v-if="
                                      !userListForSelectedCpe.length &&
                                      this.filters.statusFilter === 'CONNECTED' &&
                                      this.filters.frequencyFilter === '' &&
                                      this.filters.typeFilter === '' &&
                                      this.filters.healthStatusFilter == ''
                                    "
                                  >
                                    <td colspan="7" class="no-data-row">
                                      <span class="text-muted">
                                        {{ $t('clients.noConnectedClientsToDisplay') }}
                                        <br />
                                        <a href @click.prevent="showAllClients">{{ $t('clients.showAllClients') }}</a>
                                      </span>
                                    </td>
                                  </tr>
                                  <tr v-else-if="!userListForSelectedCpe.length">
                                    <td colspan="7" class="no-data-row">
                                      <span class="text-muted">{{ $t('general.noDataToDisplay') }}</span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div slot="modal-footer" class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="closeSelectedCpeUsersInfoModal">
                          {{ $t('general.close') }}
                        </button>
                      </div>
                    </modal>
                  </div>
                  <!--                  <div-->
                  <!--                    v-if="showZoneEditing && mapMode.zones"-->
                  <!--                    class="zone-editing"-->
                  <!--                    @click.stop=""-->
                  <!--                    @dblclick.stop=""-->
                  <!--                  >-->

                  <!--                    {{zonesLayersAsArray}}-->
                  <!--                  </div>-->
                  <!--                  <div-->
                  <!--                    v-if="showZoneAddNew && mapMode.zones"-->
                  <!--                    class="new-zone-name"-->
                  <!--                    @click.stop=""-->
                  <!--                    @dblclick.stop=""-->
                  <!--                  >-->
                  <!--                    <div class="d-flex justify-content-center align-items-center">-->

                  <!--                      <div class="d-flex flex-column new-zone-name-inner">-->
                  <!--                        <div>-->
                  <!--                          <span>{{$t('maps.zonesAddNewName')}}:</span>-->
                  <!--                        </div>-->
                  <!--                        <div>-->
                  <!--                          <input id="zones-add-new-name"  type="text" v-model="zonesAddNewName">-->
                  <!--                        </div>-->
                  <!--                      </div>-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                  <div
                    v-if="showZoneAddNew && mapMode.zones"
                    class="new-zone-block"
                    @click.stop=""
                    @dblclick.stop=""
                    @mouseover="mouseOverNewZoneBlock"
                    @mouseout="mouseOutNewZoneBlock"
                  >
                    <div class="d-flex justify-content-center align-items-center">
                      <div class="d-flex flex-column new-zone-name-inner">
                        <div>
                          <h6>{{ $t('maps.zoneCreating') }}</h6>
                        </div>
                        <div>
                          <label>
                            {{ $t('maps.zonesAddNewName') }}
                            <input
                              type="text"
                              id="zones-add-new-name"
                              v-model="zonesAddNewName"
                              :placeholder="$t('maps.enterZoneNamePlaceholder')"
                              name="newZoneName"
                              :data-vv-as="$t('maps.zoneName')"
                              v-validate="'required|min:1|max:22'"
                            />
                            <span style="font-size: 0.8em;" v-show="errors.has('newZoneName')" class="help is-danger">
                              {{ errors.first('newZoneName') }}
                            </span>
                          </label>
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="showZoneEditBlock && mapMode.zones"
                    class="edit-zone-block"
                    @click.stop=""
                    @dblclick.stop=""
                    @mouseover="mouseOverEditZoneBlock"
                    @mouseout="mouseOutEditZoneBlock"
                  >
                    <div class="d-flex justify-content-center align-items-center">
                      <div class="d-flex flex-column new-zone-name-inner">
                        <div>
                          <h6>{{ this.editedZone.name }}</h6>
                        </div>
                        <div>
                          <label>
                            {{ $t('maps.zoneName') }}
                            <input
                              :class="{ input: true, 'is-danger': errors.has('zoneName') }"
                              name="zoneName"
                              type="text"
                              :placeholder="$t('maps.enterZoneNamePlaceholder')"
                              v-model="editedZoneNewName"
                              :data-vv-as="$t('maps.zoneName')"
                              v-validate="'required|min:1|max:22'"
                            />
                            <span style="font-size: 0.9em;" v-show="errors.has('zoneName')" class="help is-danger">
                              {{ errors.first('zoneName') }}
                            </span>
                          </label>
                        </div>
                        <div class="edit-zone-block-buttons-wrapper">
                          <div class="d-flex justify-content-between">
                            <div>
                              <button
                                :disabled="errors.has('zoneName')"
                                class="btn btn-outline-success btn-sm mt-1 center-block btn-block"
                                @click="saveEditedZone"
                              >
                                {{ $t('general.save') }}
                              </button>
                            </div>
                            <div>
                              <button
                                class="btn btn-outline-secondary btn-sm mt-1 center-block btn-block"
                                @click="closeEditingZoneBlock"
                              >
                                {{ $t('general.close') }}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="showIntersectionErrorWhenZoneCreating && mapMode.zones"
                    class="creating-zone-block-intersection-error"
                    @click.stop=""
                    @dblclick.stop=""
                    @mouseover="mouseOverErrorZoneBlock"
                    @mouseout="mouseOutErrorZoneBlock"
                  >
                    <div class="d-flex justify-content-center align-items-center">
                      <div class="d-flex flex-column">
                        <div class="mt-1 text-center">
                          <h6>{{ $t('maps.error') }}</h6>
                          <button
                            type="button"
                            class="btn btn-sm btn-outline-secondary creating-zone-block-intersection-error-close-button"
                            @click="closeErrorZoneBlock"
                          >
                            <i class="fa fa-close"></i>
                          </button>
                        </div>
                        <div class="text-center">
                          <span>{{ $t('maps.zoneIntersectionError') }}</span>
                        </div>
                        <div class="d-flex justify-content-center">
                          <div class="w-50">
                            <button
                              class="btn btn-outline-secondary btn-sm mt-1 center-block btn-block"
                              @click="closeErrorZoneBlock"
                            >
                              {{ $t('general.close') }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="showNameErrorWhenZoneCreating && mapMode.zones"
                    class="creating-zone-block-zone-name-error"
                    @click.stop=""
                    @dblclick.stop=""
                    @mouseover="mouseOverErrorZoneBlock"
                    @mouseout="mouseOutErrorZoneBlock"
                  >
                    <div class="d-flex justify-content-center align-items-center">
                      <div class="d-flex flex-column">
                        <div class="mt-1 text-center">
                          <h6>{{ $t('maps.error') }}</h6>
                          <button
                            type="button"
                            class="btn btn-sm btn-outline-secondary creating-zone-block-intersection-error-close-button"
                            @click="closeNameErrorZoneBlock"
                          >
                            <i class="fa fa-close"></i>
                          </button>
                        </div>
                        <div class="text-center">
                          <span>{{ $t('maps.newZoneNameError') }}</span>
                        </div>
                        <div class="d-flex justify-content-center">
                          <div class="w-50">
                            <button
                              class="btn btn-outline-secondary btn-sm mt-1 center-block btn-block"
                              @click="closeNameErrorZoneBlock"
                            >
                              {{ $t('general.close') }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="set-scale-block card" v-if="mapMode.scale">
                <p class="mb-0" v-if="isTwoPoints">
                  {{ $t('maps.scaleModeDistance') }}
                  <span v-if="scaleData.pixelDistance && isTwoPoints">
                    {{ $t('maps.scaleModeOf') }} {{ scaleData.pixelDistance }} px
                  </span>
                </p>
                <p>
                  <small v-if="!isTwoPoints" class="text-danger">{{ $t('maps.scaleModeSetTwoPoints') }}</small>
                  <small v-else class="text-success">{{ $t('maps.scaleModeTypeDistance') }}</small>
                </p>
                <div class="form-group">
                  <div class="input-group-btn">
                    <select class="btn btn-default form-control" v-model="scaleData.scaleUnits">
                      <option value="m">m</option>
                      <!--<option value="m">ft</option>-->
                    </select>
                  </div>
                  <input
                    type="number"
                    :disabled="!isTwoPoints"
                    :placeholder="$t('maps.scaleModePlaceholder')"
                    :class="{ input: true, 'is-danger': errors.has('distance') }"
                    name="distance"
                    v-validate="'required|decimal:2|min_value:0.01|max_value:999'"
                    class="form-control"
                    v-model.number="scaleData.realDistance"
                    :data-vv-as="$t('maps.scaleModeDistance')"
                  />
                  <span v-show="errors.has('distance')" class="help is-danger location-danger">
                    {{ errors.first('distance') }}
                  </span>
                </div>
                <button
                  type="button"
                  class="btn btn-sm btn-block btn-outline-success float-right mt-1"
                  :disabled="!scaleData.realDistance || !isTwoPoints"
                  @click="updateMapScale"
                >
                  <span>{{ $t('maps.scaleModeSave') }}</span>
                </button>
                <small class="text-danger">{{ $t('maps.scaleModeDangerText') }}</small>
              </div>

              <div class="actions-buttons mt-2 p-0" :class="{ 'actions-buttons__operator': isDisable }">
                <div>
                  <button
                    :disabled="disableButtonsWhenhenLoadingClientsAndSplitRequestsToAPI"
                    v-if="!isDisable"
                    type="button"
                    class="btn btn-outline-danger btn-md mr-1"
                    @click="openDeleteMapModal"
                  >
                    <span>{{ $t('general.delete') }}</span>
                  </button>
                  <button
                    :disabled="disableButtonsWhenhenLoadingClientsAndSplitRequestsToAPI"
                    type="button"
                    class="btn btn-outline-secondary btn-md"
                    @click="disableEditMode"
                  >
                    <span v-if="isDisable">{{ $t('aps.close') }}</span>
                    <span v-else>{{ $t('aps.cancel') }}</span>
                  </button>
                </div>
                <div class="float-right" v-if="this.mapMode.show">
                  <Switch-Component  @input="cpeTooltipsHighlightSwitcherHandler"  v-model="cpeTooltipsHighlightEnabled"></Switch-Component>
                  <small style="margin-left: -10px">{{$t('maps.highlightCPEsInfoCaption')}}<Info :content="$t('maps.highlightCPEsInfoHint')" class="ml-q"></Info></small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-12">
      <router-view></router-view>
    </div>

    <modal
      title="Delete Map"
      v-model="deleteMapModal"
      cancelText="Cancel"
      okText="Delete"
      @cancel="closeDeleteMapModal"
      @ok="deleteMap"
      class="modal-danger"
      effect="fade/zoom"
    >
      <h4 slot="modal-title" class="modal-title">{{ $t('maps.deleteMapModalTitle') }}</h4>
      <div class>
        <p v-if="currentMapData">
          {{ $t('maps.deleteMapModalText') }}
          <!--          <b>{{ currentMapData.name }}</b>-->
          ?
        </p>
        <p v-if="!currentMapData">{{ $t('maps.deleteMapModalText') }}?</p>
      </div>
      <div slot="modal-footer" class="modal-footer">
        <button type="button" class="btn btn-secondary" @click="closeDeleteMapModal">{{ $t('general.cancel') }}</button>
        <button type="button" class="btn btn-outline-danger" @click="deleteMap">{{ $t('general.delete') }}</button>
      </div>
    </modal>

    <!--    <modal-->
    <!--      title="Change CPEs"-->
    <!--      v-model="showingCPEChangedModal"-->
    <!--      class="modal-warning"-->
    <!--      effect="fade/zoom"-->
    <!--    >-->
    <!--      <h4 slot="modal-title" class="modal-title">{{$t('maps.changedCPEsModalTitle')}}</h4>-->
    <!--      <div class>-->
    <!--        <p>{{$t('maps.changedCPEsModalText')}}?</p>-->
    <!--      </div>-->
    <!--    <div slot="modal-footer" class="modal-footer">-->
    <!--      <button type="button" class="btn btn-secondary ml-auto" @click="CPEChangedModalHandleYes">-->
    <!--        {{ $t('general.yes') }}, {{ $t('general.close').toLowerCase() }}-->
    <!--      </button>-->
    <!--      <button type="button" class="btn btn-secondary ml-1" @click="CPEChangedModalHandleNo">-->
    <!--        {{ $t('general.no') }}-->
    <!--      </button>-->
    <!--    </div>-->
    <!--    </modal>-->

    <modal
      title="Oops!"
      v-model="warningMapModal"
      cancelText="Cancel"
      okText="Delete"
      @cancel="closeWarningModal"
      @ok="deleteMap"
      class="modal-primary"
      effect="fade/zoom"
    >
      <h4 slot="modal-title" class="modal-title">Oops!</h4>
      <div class>Problem with getting clients</div>
      <div slot="modal-footer" class="modal-footer">
        <button
          type="button"
          class="btn btn-outline-primary"
          @click="tryToGetClients"
          :disabled="loadingCurrentMapData"
        >
          <span :class="{ invisible: loadingCurrentMapData }">Try to get again</span>
          <span v-if="loadingCurrentMapData" class="loader loader--mini"></span>
        </button>
        <button type="button" class="btn btn-secondary" @click="closeWarningModal">{{ $t('general.cancel') }}</button>
      </div>
    </modal>
    <modal
      title="EditMap"
      v-model="editMapModal"
      cancelText="Cancel"
      okText="Ok"
      @cancel="closeEditMapModal"
      class="modal-info"
      effect="fade/zoom"
    >
      <h4 slot="modal-title" class="modal-title">{{ $t('maps.editMapModalTitle') }}</h4>
      <div class v-if="editVisible && currentMap">
        <form action="">
          <div class="form-group">
            <div class="form-group">
              <label for="name">{{ $t('general.name') }}</label>
              <input
                type="text"
                name="new-map-name"
                id="new-map-name"
                :placeholder="$t('maps.enterMapNamePlaceholder')"
                class="form-control"
                v-validate="'required|max:32'"
                :class="{ input: true, 'is-danger': errors.has('name') }"
                v-model="updatedMap.name"
                :data-vv-as="$t('general.name')"
              />
              <span v-show="errors.has('new-map-name')" class="help is-danger">{{ errors.first('new-map-name') }}</span>
            </div>

            <label for="location">{{ $t('general.location') }}</label>
            <select class="form-control form-control-sm filters__field" v-model="updatedMap.base_location">
              <option v-for="location in locationsList" :value="location.id" :key="location.id">
                {{ location.name }}
              </option>
            </select>
          </div>
          <!--          // далее для настроек времени работы-->
          <div class="form-group mt-3">
            <h6>
              {{ $t('maps.workTimeCaption') }}
              <info :content="$t('maps.workTimeHint')" />
            </h6>
            <div class="form-group">
              <label for="start-work-time">
                {{ $t('maps.workTimeStart') }}
                <info :content="$t('maps.workTimeStartHint')" />
              </label>
              <input
                type="text"
                name="start-work-time"
                id="start-work-time"
                :placeholder="$t('maps.workTimeStartPlaceholder')"
                class="form-control"
                v-validate="'hoursMinutes'"
                data-vv-validate-on="change"
                :class="{ input: true, 'is-danger': errors.has('start-work-time') }"
                v-model="updatedMap.start"
                :data-vv-as="$t('maps.workTimeStart')"
                @input="handleModalStartWorkTimeFieldInput"
              />
              <span v-show="errors.has('start-work-time')" class="help is-danger">
                {{ errors.first('start-work-time') }}
              </span>
            </div>
            <div class="form-group">
              <label for="stop-work-time">
                {{ $t('maps.workTimeStop') }}
                <info :content="$t('maps.workTimeStopHint')" />
              </label>
              <input
                type="text"
                name="stop-work-time"
                id="stop-work-time"
                :placeholder="$t('maps.workTimeStopPlaceholder')"
                class="form-control"
                v-validate="'hoursMinutes'"
                data-vv-validate-on="change"
                :class="{ input: true, 'is-danger': errors.has('stop-work-time') }"
                v-model="updatedMap.stop"
                :data-vv-as="$t('maps.workTimeStop')"
                @input="handleModalStopWorkTimeFieldInput"
              />
              <span v-show="errors.has('stop-work-time')" class="help is-danger">
                {{ errors.first('stop-work-time') }}
              </span>
            </div>
            <!--            <div class="form-group w-25">-->
            <!--              <label for="stop-work-time">{{ $t('maps.workTimeTimezone') }}</label>-->
            <!--              <input-->
            <!--                type="text"-->
            <!--                name="timezone-work-time"-->
            <!--                id="timezone-work-time"-->
            <!--                :placeholder="''"-->
            <!--                class="form-control"-->
            <!--                v-validate="'required|max:100'"-->
            <!--                :class="{ input: true, 'is-danger': errors.has('timezone-work-time') }"-->
            <!--                v-model="updatedMap.time_zone"-->
            <!--                :data-vv-as="$t('maps.workTimeTimezone')"-->
            <!--              />-->
            <!--              <span v-show="errors.has('timezone-work-time')"-->
            <!--                    class="help is-danger">{{ errors.first('timezone-work-time') }}</span>-->
            <!--            </div>-->
            <div class="form-group">
              <label for="tz">{{ $t('maps.workTimeTimezone') }}</label>
              <multiselect
                id="tz"
                v-model="workTimeTimezoneSelectedInMultiselect"
                @select="
                  (selectedOpt) => {
                    // тут делаем чтобы для москвы выбиралось Europe/Moscow
                    if (selectedOpt.value === 'Moscow Standard Time') {
                      updatedMap.time_zone = selectedOpt.utc[1];
                    } else {
                      updatedMap.time_zone = selectedOpt.utc[0];
                    }
                  }
                "
                :options="timeZonesOptions"
                class="input"
                :class="{ 'is-danger': errors.has('tz'), 'multiselect-disabled': isDisable }"
                :disabled="isDisable"
                :data-vv-as="$t('maps.workTimeTimezone')"
                label="text"
                track-by="text"
                :empty="false"
                :placeholder="$t('maps.workTimeTimezoneMultiselectPlaceholder')"
                :selectLabel="$t('general.multiSelectPressToSelect')"
                :deselectLabel="$t('general.multiSelectDeselectLabel')"
                :selectedLabel="$t('general.multiSelectSelected')"
              >
                <template v-slot:noOptions>
                  {{ $t('general.notFound') }}
                </template>
                <template v-slot:noResult>
                  {{ $t('general.notFound') }}
                </template>
              </multiselect>

              <!-- <span v-show="errors.has('tz')" class="help is-danger">{{ errors.first('tz') }}</span> -->
              <!-- <small class="mt-h text-muted d-block" :id="'tz-caption'">{{ $t('portal.tzCaption') }}</small> -->
            </div>
          </div>
        </form>
      </div>
      <div slot="modal-footer" class="modal-footer">
        <button type="button" class="btn btn-secondary" @click="closeEditMapModal">{{ $t('general.cancel') }}</button>
        <button
          :disabled="errors.has('new-map-name') || errors.has('start-work-time') || errors.has('stop-work-time')"
          type="button"
          class="btn btn-primary"
          @click="saveFromEditMapModal"
        >
          {{ $t('general.save') }}
        </button>
      </div>
    </modal>

    <modal
      title="Info Filtering Visitors"
      v-model="showingInfoModalAboutWorkTimeFiltering"
      cancelText="Cancel"
      okText="Ok"
      @cancel="closeInfoModalAboutWorkTimeFiltering"
      class="modal-info"
      effect="fade/zoom"
    >
      <h4 slot="modal-title" class="modal-title">{{ $t('maps.filteringNightClientsModalCaption') }}</h4>
      <div>
        <span>{{ $t('maps.filteringNightClientsModalText') }}</span>
      </div>
      <div slot="modal-footer" class="modal-footer">
        <button type="button" class="btn btn-secondary" @click="closeInfoModalAboutWorkTimeFiltering">
          {{ $t('general.close') }}
        </button>
        <!--        <button :disabled="errors.has('new-map-name')" type="button" class="btn btn-primary" @click="saveFromEditMapModal">{{-->
        <!--          $t('general.save') }}-->
        <!--        </button>-->
      </div>
    </modal>
  </div>
</template>

<script>
import Vue from 'vue';
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';
import LDrawToolbar from 'vue2-leaflet-draw-toolbar';
import intersect from '@turf/intersect';
import {
  LMap,
  LCircle,
  LTooltip,
  LTileLayer,
  LMarker,
  LImageOverlay,
  LPopup,
  LPolyline,
  LControlScale,
  LControlLayers,
  LLayerGroup
} from 'vue2-leaflet';
import vueSlider from 'vue-slider-component';
import { Multiselect } from 'vue-multiselect';
import VueNotifications from 'vue-notifications';

import HeatmapOverlay from 'leaflet-heatmap';
import mapsService from '../../services/mapsService';
import cpeService from '../../services/cpeService';
import clientService from '../../services/clientService';
import helpers from '../../helpers';
import Filters from '../../filters';
import commonService from '../../services/commonService';
import Modal from '../../components/Modal.vue';
import DatePicker from '../../components/date-picker.vue';
import 'vue-slider-component/theme/default.css';

import MapsVisitors from '../../components/Maps/MapsVisitors.vue';
import templatesService from '../../services/templatesService';
import wlanService from '../../services/wlanService';
import capabilities from '../../services/capabilities';
import wiredConfig from '../../components/Aps/wiredConfig.vue';
import redirectsService from '../../services/redirectsService';
import statLogLbsConfig from '../../components/Aps/statLogLbsConfig.vue';
import accessControl from '../../components/Aps/accessControl.vue';
import SwitchComponent from '../../components/Universal/Switch-component.vue';
import Info from '../../components/Universal/info-icon.vue';
import defaultTableSettings from '../../table-settings';
import loaderSpinnerWithProgressBar from '../../components/loaderSpinnerWithProgressBar.vue';
// import timeZones from '../Portal/timeZones.json';
import timeZones from '../Portal/timeZones.js';
import locationService from '../../services/locationService';
import SelectComponentV2 from '../../components/Universal/select/select-componentV2.vue';
import SelectComponentV3ForUseInLocationSelectors from '../../components/Universal/select/select-componentV3ForUseInLocationSelectors.vue';
import MapsHeatmapLegend from '../../components/Maps/MapsHeatmapLegend'

export default {
  name: 'Maps',
  components: {
    Modal,
    LMap,
    LTileLayer,
    LMarker,
    LCircle,
    LImageOverlay,
    LPopup,
    LTooltip,
    LPolyline,
    LControlScale,
    LControlLayers,
    LLayerGroup,
    'l-marker-cluster': Vue2LeafletMarkerCluster,
    MapsVisitors,
    DatePicker,
    vueSlider,
    Multiselect,
    wiredConfig,
    statLogLbsConfig,
    accessControl,
    LDrawToolbar,
    Info,
    SwitchComponent,
    loaderSpinnerWithProgressBar,
    SelectComponentV2,
    MapsHeatmapLegend,
    SelectComponentV3ForUseInLocationSelectors
  },
  data() {
    return {
      cpeTooltipsHighlightEnabled: true,
      heatmapLegendData: {},
      showHeatmapLegend: false,
      showLocationFilter: true,
      locationInFiltersData: [],
      imageOverlayLoading: false,
      showingInfoModalAboutWorkTimeFiltering: false,
      // далее для настроек времени работы (workTimeTimezoneSelectedInMultiselect и addToRequestsWithOutNightClientsSettings)
      // тут храним объект таймзаоны, выбраный в селекторе таймзоны для настройки часов работы
      workTimeTimezoneSelectedInMultiselect: '',
      addToRequestsWithOutNightClientsSettings: false,
      // тут храним номер сегмента выбранного в разделе Динамика
      mapVisitorsSelectedSegmentNumber: undefined,
      // тут храним объект квадрата, который появляется при наведении на точку с клиентами
      polygonWhenMouseOverCLientsPoint: undefined,
      // тут настраиваем размеры сетки для запросов в /heatmap
      gridSize: { x: 10, y: 10 },
      configForHeatmapJS: {
        // https://www.patrick-wied.at/static/heatmapjs/plugin-leaflet-layer.html
        // radius should be small ONLY if scaleRadius is true (or small radius is intended)
        // if scaleRadius is false it will be the constant radius used in pixels
        radius: 10,
        maxOpacity: 0.8,
        // scales the radius based on map zoom
        scaleRadius: true,
        // if set to false the heatmap uses the global maximum for colorization
        // if activated: uses the data maximum within the current map boundaries
        //   (there will always be a red spot with useLocalExtremas true)
        useLocalExtrema: false,
        // which field name in your data represents the latitude - default "lat"
        latField: 'lat',
        // which field name in your data represents the longitude - default "lng"
        lngField: 'lng',
        // which field name in your data represents the data value - default "value"
        valueField: 'count'
      },
      clickedManyClientsDataMarker: undefined,
      clickedManyClientsDataSearchTimeout: false,
      clickedManyClientsDataSearchField: '',
      clickedManyClientsDataFiltered: false,
      clickedManyClientsData: false, // храним тут данные при клике на точки где много клиентов, в режиме посетитиелей
      clickedClusterDataSearchTimeout: false, // тут записывае объект тамаута при поиске, чтобы его прерывать
      clickedClusterDataSearchField: '', // а это используем для поиску по списку адерсов
      clickedClusterDataFiltered: false, // храним тут данные при клике на большие кластеры, в режиме посетитиелей фильтрованые
      clickedClusterData: false, // храним тут данные при клике на большие кластеры, в режиме посетитиелей
      pathLayerArrows: false, // используется для  слоя со стрелками для пути пользователя
      isClosePopupMutex: false, // не дает попапу с ифной о точке  исчезать по таймауту, если установлен в true
      // устанавливается в true когда юзер перводить мышь в сам попап
      userListForSelectedCpeCpeName: '',
      userListForSelectedCpe: [],
      isSelectedCpeUsersInfoModalShowing: false,
      pointIsDraging: false,
      searchSpinnerForTable: false,
      searchValue: '',
      totalResult: false,
      canLoadMore: true,
      limit: 30,
      offset: 0,
      updatedMap: {
        name: '',
        base_location: '',
        // далее для настроек времени работы
        start: '',
        stop: '',
        time_zone: ''
      },
      editMapModal: false,
      segmentedForBubbles: [],
      segmentedForBubblesCounts: [],
      showNameErrorWhenZoneCreating: false,
      cpeChangedModalTarget: false,
      cpeChangedModalSource: false,
      showingCPEChangedModal: false,
      isCPEsChanged: false,
      // эти свойства нужны для работы модального окна с подробой информации по точке //
      rateControl: {
        '11n': {
          data: ['off', 'ht'],
          piecewise: true,
          bgStyle: {
            backgroundColor: '#cccccc'
          },
          processStyle: {
            backgroundColor: '#3498db'
          },
          piecewiseStyle: {
            backgroundColor: '#ccc',
            visibility: 'visible',
            width: '12px',
            height: '12px'
          },
          piecewiseActiveStyle: {
            backgroundColor: '#3498db'
          },
          labelActiveStyle: {
            color: '#3498db'
          },
          piecewiseLabel: true
        },
        '11ac': {
          data: ['off', 'ht', 'vht'],
          piecewise: true,
          bgStyle: {
            backgroundColor: '#cccccc'
          },
          processStyle: {
            backgroundColor: '#3498db'
          },
          piecewiseStyle: {
            backgroundColor: '#ccc',
            visibility: 'visible',
            width: '12px',
            height: '12px'
          },
          piecewiseActiveStyle: {
            backgroundColor: '#3498db'
          },
          labelActiveStyle: {
            color: '#3498db'
          },
          piecewiseLabel: true
        }
      },
      isFWUpgradeShow: false,
      isFirewallShow: false,
      statLogLbsmodal: false,
      updatedCpe: [],
      configFilterList: {
        blacklist: [],
        whitelist: []
      },
      firewallL3List: {
        ip_list: [],
        mac_list: []
      },
      interfaceSettings: {
        radio0: false,
        radio1: false,
        radio2: false
      },
      interfaceSelectedWlans: {
        radio0: [],
        radio1: [],
        radio2: []
        // сюда будем запихивать ключами id интерфейсов и массивами выбранные wlans из них
      },
      advancedSettings: {
        radio0: {
          maxClientsEnabled: false,
          rateControlEnabled: false
        },
        radio1: {
          maxClientsEnabled: false,
          rateControlEnabled: false
        },
        radio2: {
          maxClientsEnabled: false,
          rateControlEnabled: false
        }
      },
      interfaceHiddenWlans: {
        radio0: [],
        radio1: [],
        radio2: []
      },
      interfaceCapabilities: {},
      cpeModelData: false,
      calculatedCapabilities: {},
      isWlanDescription: false,
      TXPower: {
        processStyle: {
          backgroundImage: '-webkit-linear-gradient(left, #3498db, #f05b72)'
        }
        // disabled: [true, false]
      },
      // конец блока //
      passerbyCounterInZoneByZoneIdentity: false, // тут счетчик сколько клиентов в каждой зоне
      startMapZoom: false, // для пересчета радиуса кругов-пузырей при изменении масштаба карты
      showIntersectionErrorWhenZoneCreating: false,
      drawZonesEditMode: false,
      drawZonesDeleteMode: false,
      editedZone: {},
      editedZoneNewName: '',
      showZoneEditBlock: false,
      editedZoneName: '',
      updatedZone: [],
      zonesLayers: false,
      zonesLayersAsArray: [],
      zonesLayersByKey: {},
      zonesDrawButtons: false,
      zonesAddNewName: '',
      showZoneEditing: false,
      showZoneAddNew: false,
      selectedClientDataFromClientsApiForTooltip: {},
      selectedClientDataFromClientsApi: {},
      moreCpeInfoForSelectedCpe: [],
      isMoreCpeInfoModalShowing: false,
      isDatepickerShowed: true,
      isDatepickerFullScreenShowed: true,
      isMapOnFullScreen: false,
      // показываем или нет блок со слайдером
      pathForSelectedClientShowing: false,
      // таймстамп последней (по времени) точки пути пользователя
      pathForSelectedClientLastPointTimestamp: null,
      // выбранный таймстамп точки пути пользователя (слайдером или кнопками)
      pathForSelectedClientSelectedTimestamp: null,
      // точки пути выбранного пользователя
      pathForSelectedClient: [],
      // точки пути выбранного пользователя, в объекте, где таймстамп - ключ
      pathForSelectedClientByTime: {},
      // массив с таймстампами точек пути клиента
      pathForSelectedClientTimestampsArray: [],
      clientsMarker: [],
      foundClient: false,
      macFilter: '',
      delayTimer: null,
      searchSpinner: false,
      showContextMenu: false,
      isMapUpdating: false,
      deleteCpeFromMapModal: false,
      deleteMapModal: false,
      warningMapModal: false,
      markerClusterGroup: false,
      cpeIdForDelete: '',
      currentClusterMarkers: [],
      controlOptions: {
        position: 'topleft'
      },
      cpeMarkerOptions: {
        interactive: true
      },
      mapControls: {
        modeControl: false,
        showModeControl: false,
        fitBoundsControl: false,
        searchControl: false,
        clientsControl: false,
        layersControl: false,
        cpesControl: false
      },
      tooltipCpeOptions: {
        permanent: true,
        direction: 'top',
        offset: [0, -10],
        interactive: false,
        // direction: 'bottom',
        // offset: [0, 5]
        className: 'cpe-tooltip-wrapper',
        opacity: 1.0
      },
      tooltipBigCpeOptions: {
        opacity: 0.7,
        closeButton: false,
        closeOnClick: false
      },
      scaleData: {
        measure: [],
        pixelDistance: '',
        realDistance: '',
        scaleUnits: 'm'
      },
      tooltipClientOptions: {
        permanent: false,
        direction: 'top',
        offset: [0, -10]
      },
      tooltipBubbleOptions: {
        permanent: true,
        direction: 'bottom',
        offset: [0, 10],
        className: 'bubble-tooltip',
        opacity: 1.0
      },
      map: false,
      tileLayer: false,
      mapData: {
        minZoom: -1,
        maxZoom: 12,
        crossOrigin: true,
        crs: L.CRS.Simple
      },
      mapMode: {
        planning: false,
        scale: false,
        show: false
      },
      beforeTimelineShowShowingMode: [],
      showMode: {
        clientsLastPathPoints: false,
        heatmap: false,
        timeline: false,
        bubble_clients: false,
        bubble_lbs: false,
        bubble_traffic: false
      },
      mapLayers: {
        heatLayer: false,
        clientsLayer: false,
        pathLayer: false,
        cpesLayer: false,
        scaleLayer: false,
        bublesLayer: false
      },
      dateRange: {
        startDate: moment().subtract(24, 'hours').valueOf(),
        endDate: moment().valueOf()
      },
      isContextMenuOpened: false,
      contextMenu: {
        item: null,
        top: 0,
        left: 0,
        info: false,
        video: false
      },
      imperial: false,
      cpesIdsOnMap: [],
      cpesStatisticOnMap: {},
      cpesStatisticClientsOnMap: {},
      cpesMarkers: [],
      cpesInfo: {},
      segmentedClients: [],
      segmentedClientsCounts: [],
      segmentedPeriods: [],
      segmentedInterval: 0,
      selectedSegment: -1,
      clientsOnMap: [],
      bubblesOnMaps: [],
      cpeConnectedIcon: L.icon({
        iconUrl: '/static/img/wifi.svg',
        iconSize: [15, 15]
      }),
      cpeConnectedIconBig: L.icon({
        iconUrl: '/static/img/wifi.svg',
        iconSize: [35, 35]
      }),
      cpeNewIcon: L.icon({
        iconUrl: '/static/img/wifi_new.svg',
        iconSize: [15, 15]
      }),
      cpeNewIconBig: L.icon({
        iconUrl: '/static/img/wifi_new.svg',
        iconSize: [35, 35]
      }),
      cpeDisconnectedIcon: L.icon({
        iconUrl: '/static/img/wifi_un.svg',
        iconSize: [15, 15]
      }),
      cpeDisconnectedIconBig: L.icon({
        iconUrl: '/static/img/wifi_un.svg',
        iconSize: [35, 35]
      }),
      clientDefaultIcon: L.icon({
        iconUrl: '/static/img/user.svg',
        iconSize: [15, 15]
      }),
      clientDefaultIconManyClients: L.icon({
        iconUrl: '/static/img/users-heatmap.svg',
        iconSize: [20, 20]
      }),
      clientDefaultIconBig: L.icon({
        iconUrl: '/static/img/user.svg',
        iconSize: [30, 30]
      }),
      clientPathDot: L.icon({
        iconUrl: '/static/img/location-pin.svg',
        iconSize: [25, 25]
      }),
      clientPathDotBlink: L.icon({
        iconUrl: '/static/img/location-pin.svg',
        iconSize: [25, 25],
        className: 'client-path-shake-icon'
      }),
      clientPathDotFirst: L.icon({
        iconUrl: '/static/img/location-pin-green.svg',
        iconSize: [35, 35]
      }),
      clientPathDotFirstBlink: L.icon({
        iconUrl: '/static/img/location-pin-green.svg',
        iconSize: [35, 35],
        className: 'client-path-shake-icon'
      }),
      clientPathDotLast: L.icon({
        iconUrl: '/static/img/location-pin-green.svg',
        iconSize: [35, 35]
      }),
      clientPathDotLastBlink: L.icon({
        iconUrl: '/static/img/location-pin-green.svg',
        iconSize: [35, 35],
        className: 'client-path-shake-icon'
      }),
      currentMapData: null,
      filters: {
        query: '',
        location: 'All locations',
        with_childs: true,
        sortByFilter: 'Name',
        sortOrder: 'asc',
      },
      cpeFilters: {
        query: ''
      },
      dragEvent: {
        id: null
      }
    };
  },
  watch: {
    // cpesIdsOnMap() {
    //   if (this.showCPEsStatisticForUser) {
    //     const cpesIds = JSON.parse(JSON.stringify(this.cpesIdsOnMap));
    //     // следим за списком точек  на карте, если меняется - подтягиваем данные для них
    //     mapsService.getCpesRating(this, cpesIds);
    //   }
    // },
    // следим за переключением локали, чтобы перерисовать карту со всеми надписями на нужном языке
    // иначе надписи на карте не меняются
    localeSwitchedTimeStamp() {
      if (this.map && this.editVisible && this.currentMap) {
        this.map.remove();
        this.resetAllMode();
        this.passerbyCounterInZoneByZoneIdentity = false; // очищаем счетчик клиентов в зоне
        // this.$store.commit('setMapsEditId', map.id);
        this.$nextTick(() => {
          this.initMap();
        });
      }
    },
    // следим за визардом, как только вышли из него обновляем список карт,
    // чтобы только что созданая карта отобразилась в таблице
    isNotWizard(newValue, oldValue) {
      if (newValue === true && oldValue === false) {
        this.refreshAndClearMaps();
      }
    },
    isMoreCpeInfoModalShowing(newValue, oldValue) {
      if (newValue === false) {
        // включаем возможность делать drag карты
        this.mouseOutModalCpeMoreInfo();
      }
    },
    isSelectedCpeUsersInfoModalShowing(newValue, oldValue) {
      if (newValue === false) {
        // включаем возможность делать drag карты
        this.mouseOutModalCpeMoreInfo();
      }
    },
    isMapOnFullScreen() {
      this.$refs.map.focus();
      // код далее нужен для синхронизации данных
      // между datepicker'ами в полноэкранном и обычном режиме
      this.isDatepickerShowed = false;
      this.isDatepickerFullScreenShowed = false;
      this.$nextTick(() => {
        this.isDatepickerShowed = true;
        this.isDatepickerFullScreenShowed = true;
      });
    },
    currentMapCpes(cpes) {
      if (!cpes) {
        return;
      }
      cpes.forEach((cpe) => {
        const fullCpe = commonService.cpeObjbyId(cpe.cpe_id);

        // cpe.model = fullCpe.model;
        // cpe.macaddr = fullCpe.state.network.macaddr;
        // cpe.ipaddr = fullCpe.state.network.ipaddr;
        // cpe.connected = fullCpe.connected;
        // cpe.name = fullCpe.name;
        // cpe.lbs = fullCpe.config.lbs_config.enabled;
        // cpe.config_status = fullCpe.config_status;
        cpe.model = fullCpe !== undefined && fullCpe.hasOwnProperty('model') ? fullCpe.model : '';
        cpe.macaddr =
          fullCpe !== undefined &&
          fullCpe.hasOwnProperty('state') &&
          fullCpe.state !== undefined &&
          fullCpe.state.hasOwnProperty('network') &&
          fullCpe.state.network !== undefined &&
          fullCpe.state.network.hasOwnProperty('macaddr') ?
            fullCpe.state.network.macaddr :
            '';
        cpe.ipaddr =
          fullCpe !== undefined &&
          fullCpe.hasOwnProperty('state') &&
          fullCpe.state !== undefined &&
          fullCpe.state.hasOwnProperty('network') &&
          fullCpe.state.network !== undefined &&
          fullCpe.state.network.hasOwnProperty('ipaddr') ?
            fullCpe.state.network.ipaddr :
            '';
        cpe.connected = fullCpe !== undefined && fullCpe.hasOwnProperty('connected') ? fullCpe.connected : '';
        cpe.name = fullCpe !== undefined && fullCpe.hasOwnProperty('name') ? fullCpe.name : '';
        cpe.lbs =
          fullCpe !== undefined &&
          fullCpe.hasOwnProperty('config') &&
          fullCpe.config !== undefined &&
          fullCpe.config.hasOwnProperty('lbs_config') &&
          fullCpe.config.lbs_config !== undefined &&
          fullCpe.config.lbs_config.hasOwnProperty('enabled') ?
            fullCpe.config.lbs_config.enabled :
            '';
        cpe.config_status =
          fullCpe !== undefined && fullCpe.hasOwnProperty('config_status') ? fullCpe.config_status : '';
      });
    },
    // currentClientsOnMap(list) {
    //   const currentClients = document.querySelector('.leaflet-current-clients');
    //   if (currentClients) {
    //     currentClients.innerHTML = `${this.$t('maps.Clients')}: ${list.length}`;
    //   }
    // },
    currentClientsOnMap(list) {
      const currentClients = document.querySelector('.leaflet-current-clients');
      // if (currentClients && this.showMode.timeline) {
      //   currentClients.innerHTML = `${this.$t('maps.Clients')}: ${list.length}`;
      // }
      // if (currentClients && !this.showMode.timeline) {
      //   let counter = 0;
      //   list.forEach((item) => {
      //     counter = counter + item.total;
      //   });
      //   currentClients.innerHTML = `${this.$t('maps.Clients')}: ${counter}`;
      // }

      if (currentClients) {
        let counter = 0;
        list.forEach((item) => {
          counter += item.total;
        });
        currentClients.innerHTML = `${this.$t('maps.Clients')}: ${counter}`;
      }
    },
    currentBubblesOnMap(list) {
      let counter = 0;
      for (const bubble of list) {
        const bubbleValue = bubble.result || 0;
        counter += bubbleValue;
      }
      if (
        this.showMode.bubble_traffic ||
        (this.showMode.timeline && this.beforeTimelineShowShowingMode.includes('bubble_traffic'))
      ) {
        // console.log('traffffic')
        counter = commonService.formatBytes(counter);
      }
      const passerbyCounter = document.querySelector('.leaflet-current-passerby-counter');
      if (passerbyCounter) {
        passerbyCounter.innerHTML = `${this.$t('maps.passerbyCounterTitle')}: ${counter}`;
      }
      const connectedyCounter = document.querySelector('.leaflet-current-connected-counter');
      if (connectedyCounter) {
        connectedyCounter.innerHTML = `${this.$t('maps.connectedCounterTitle')}: ${counter}`;
      }
      const trafficCounter = document.querySelector('.leaflet-current-traffic-counter');
      if (trafficCounter) {
        trafficCounter.innerHTML = `${this.$t('maps.trafficCounterTitle')}: ${counter}`;
      }
    },
    clientsMarkerList(list) {
      if (!list.length && !this.macFilter) {
        return;
      }
      this.initClientsControl();
    },
    currentMapMode: {
      handler(mapMode) {
        if (mapMode.show) {
          this.initSearchControl();
        }
      },
      deep: true
    },
    pathPointsWithTimestampLessThenSelectedTimestamp() {
      // удаляем старый слой с линеей и точками
      this.map.removeLayer(this.pathLayerArrows);
      this.map.removeLayer(this.pathLayer);
      // Считаем координаты для линии, соединяющей точки, на основе точек, в которых клиент был
      // до выбранного таймстампа
      const coords = [];
      const coordForArrows = [];
      for (const point of this.pathPointsWithTimestampLessThenSelectedTimestamp) {
        coords.push({ lat: point._latlng.lat, lng: point._latlng.lng });
        coordForArrows.push([point._latlng.lat, point._latlng.lng]);
      }
      const polyline = L.polyline(coords, { color: '#00ff35', weight: 1 });
      // слой со стрелками
      this.pathLayerArrows = L.featureGroup(this.getArrows(coordForArrows, 'red', 2, this.map)).addTo(this.map);
      this.map.addLayer(this.pathLayerArrows);
      // делаем новый слой с точками и линеей
      this.pathLayer = L.featureGroup(this.pathPointsWithTimestampLessThenSelectedTimestamp)
        .addLayer(polyline)
        .addTo(this.map);
      // отрисовываем его на карту.
      // this.map.fitBounds(this.pathLayer.getBounds()); // <- это отрисовывает карту меняя масштаб в зависимости от точек
      this.map.addLayer(this.pathLayer); // <- это отрисовывает карту не меняя масштаба
      // далее находим индекс последней точки чтобы передвинуть на нее карту
      const lastPointIndex = this.pathPointsWithTimestampLessThenSelectedTimestamp.length - 1;
      this.map.setView(this.pathPointsWithTimestampLessThenSelectedTimestamp[lastPointIndex]._latlng);
    }
  },
  computed: {
    isSelectedDefaultLocationForRequests() {
      if (this.$store.getters.getDefaultLocationForRequests(this)) {
        return true;
      } else {
        return false;
      }
    },
    isGetAllCompactCpesWithDataRemainingRequestExecuting(){
      return this.$store.state.getAllCompactCpesWithDataRemainingRequestExecuting;
    },
    userBaseLocation() {
      return this.$store.state.userData.base_location;
    },
    isClientPathRequestExecuting() {
      return this.$store.state.isGetClientPathRequestExecuting;
    },
    isFiltersActive() {
      return this.filters.query !== '' || this.filters.location !== 'All locations' || this.searchValue !== '' ||
        this.filters.sortByFilter !== 'Name' || this.filters.sortOrder !== 'asc';
    },
    isImageOverlayLoading() {
      // загружается ли сама картинка карты
      return this.imageOverlayLoading;
    },
    isAllFieldsForFilteringNightClientsFilled() {
      // далее настроек времени работы
      // проверяем заполненны ли у карты все поля, для фильтрации ночных клиентов
      try {
        if (
          this.currentMapData.start !== '' &&
          this.currentMapData.stop !== '' &&
          this.currentMapData.time_zone !== ''
        ) {
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    },
    timeZonesOptions() {
      // опиции для выбора timezones
      return timeZones;
    },
    isShowingZonesFunctionality() {
      // чтобы легко можно было отключить всю функциональность  сзонами
      return false;
    },
    disableButtonsWhenhenLoadingClientsAndSplitRequestsToAPI() {
      // блокируем кнопки интерфейса при последовательных запросах в
      // /api/maps/heatmap
      if (
        this.isShowingProgressBarWhenLoadingClientsAndSplitRequestsToAPI === true &&
        this.percentsOfClientsMarkerDownloadsRequestsCount !== undefined
      ) {
        return true;
      }
      return false;
    },
    isShowingProgressBarWhenLoadingClientsAndSplitRequestsToAPI() {
      // чтобы легко можно было отключить режим со множественными запросами для загрузки данных о
      // посетителях из /api/maps/heatmap
      return false;
    },
    percentsOfClientsMarkerDownloadsRequestsCount() {
      return this.$store.state.percentsOfClientsMarkerDownloadsRequestsCount;
    },
    userIsNotAdmin() {
      try {
        return this.$store.state.userData.role !== 'admin';
      } catch (e) {
        return false;
      }
    },
    // isMaskingMACAddresses() {
    //   if ((this.isBeeline() === true) || (this.userIsNotAdmin === true)) {
    //     // console.log('masking mac!')
    //     return true;
    //   }
    //   return false;
    // },
    isMaskingMACAddresses() {
      if (this.userHasMacMaskFlagEnabled === true) {
        // console.log('masking mac!')
        return true;
      }
      return false;
    },
    userHasMacMaskFlagEnabled() {
      try {
        if (
          Object.prototype.hasOwnProperty.call(this.$store.state, 'userData') &&
          Object.prototype.hasOwnProperty.call(this.$store.state.userData, 'mac_mask') &&
          this.$store.state.userData.mac_mask === true
        ) {
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    },
    isClickedClusterDataFiltereInMapsFilteringExecute() {
      // console.log(this.$store.state.clickedClusterDataFiltereInMapsFilteringExecute)
      return this.$store.state.clickedClusterDataFiltereInMapsFilteringExecute;
    },
    isClickedClusterData() {
      if (this.clickedClusterData !== false) {
        return true;
      }
      return false;
    },
    isClickedManyClientsData() {
      if (this.clickedManyClientsData !== false) {
        return true;
      }
      return false;
    },
    tableSettingsForUsersList() {
      return defaultTableSettings.clientsInMapsForSelectedCpe;
    },
    isManyClientsPointDataLoadingInMaps() {
      return this.$store.state.manyClientsPointDataLoadingInMaps;
    },
    isLoadingUsersInfoForSelectedCpeOnMap() {
      return this.$store.state.loadingUsersInfoForSelectedCpeOnMap;
    },
    showCPEsStatisticForUser() {
      // показываем статистику по CPE для админов
      try {
        return this.$store.state.userData.role === 'admin';
      } catch (e) {
        return false;
      }
    },
    localeSwitchedTimeStamp() {
      return this.$store.state.localeSwitchedTimeStamp;
    },
    infoFromClientsApiForSelectedClientShowing() {
      if (Object.keys(this.selectedClientDataFromClientsApi).length > 0) {
        return true;
      }
      return false;
    },
    // эти компьютед нужны для работы модального окна с подробой информации по точке //
    isCisco() {
      let isCisco = false;
      if (this.cpeModelData && this.cpeModelData.name.toLowerCase().includes('cisco')) {
        isCisco = true;
      }
      if (this.updatedCpe && this.updatedCpe.model.short.toLowerCase().includes('cisco')) {
        isCisco = true;
      }
      return isCisco;
    },
    firewallsList() {
      return this.$store.state.firewallsList;
    },
    cpesIdsOperations() {
      return helpers.combineOperationIdsArrayFromObjects(this.$store.state.cpeOperations);
    },
    cpeEditCurrent() {
      const sameCpe = false;
      const currentCpe = this.moreCpeInfoForSelectedCpe;

      // // Если новая точка, то копируем
      // if (
      //   (this.updatedCpe && this.updatedCpe.id === this.$store.state.editCpeId) ||
      //   (this.updatedCpe && this.updatedCpe.id === this.selectedCpesId[0])
      // ) {
      //   sameCpe = true;
      // }
      // if (
      //   (!sameCpe ||
      //     this.afterUpdate ||
      //     (this.updatedCpe &&
      //       helpers.combineModelIdsArrayFromObjectsArray(this.cpeOperations).includes(this.updatedCpe.id))) &&
      //   currentCpe
      // ) {
      this.updatedCpe = JSON.parse(JSON.stringify(currentCpe));
      this.updatedCpe.config.wifi_lock = true;

      // Находим capabilities у текущей модели
      if (!this.updatedCpe.config.wired) {
        this.updatedCpe.config.wired = [];
      }
      // заполняем временные white/blacklist
      this.configFilterList.blacklist = this.updatedCpe.config.lbs_config.blacklist;
      this.configFilterList.whitelist = this.updatedCpe.config.lbs_config.whitelist;

      if (this.updatedCpe.config.firewall.l3_filter) {
        if (
          !this.updatedCpe.config.firewall.l3_filter.mac_list ||
          !Array.isArray(this.updatedCpe.config.firewall.l3_filter.mac_list)
        ) {
          this.updatedCpe.config.firewall.l3_filter.mac_list = [];
        }
        this.firewallL3List.mac_list = this.updatedCpe.config.firewall.l3_filter.mac_list.sort();

        if (!Array.isArray(this.updatedCpe.config.firewall.l3_filter.ip_list)) {
          this.updatedCpe.config.firewall.l3_filter.ip_list = [];
        }
        this.firewallL3List.ip_list = this.updatedCpe.config.firewall.l3_filter.ip_list.sort();
      }

      if (!this.updatedCpe.config.log_config.log_port) {
        this.updatedCpe.config.log_config.log_port = '';
      }
      if (!this.updatedCpe.config.stats_config.reportperiod) {
        this.updatedCpe.config.stats_config.reportperiod = '';
      }
      if (!this.updatedCpe.config.lbs_config.reportperiod) {
        this.updatedCpe.config.lbs_config.reportperiod = '';
      }
      if (!this.updatedCpe.config.lbs_config.clienttimeout) {
        this.updatedCpe.config.lbs_config.clienttimeout = '';
      }

      const modelId = this.updatedCpe.model.id;
      const { cpeModelsList } = this.$store.state;

      if (cpeModelsList.length) {
        for (const model of cpeModelsList) {
          if (model.id === modelId) {
            this.cpeModelData = model;
            if (
              this.cpeModelData.name.toLowerCase().includes('cisco') ||
              this.updatedCpe.model.short.toLowerCase().includes('cisco')
            ) {
              this.cpeModelData.cisco = true;
            }

            for (const wiredInterface in this.cpeModelData.caps.wired) {
              if (this.updatedCpe && !helpers.transformArrayToObject(this.updatedCpe.config.wired)[wiredInterface]) {
                this.updatedCpe.config.wired.push({
                  id: wiredInterface,
                  vlans: [],
                  primary_vlan: 0,
                  fake_wlan: ''
                });
              }
              const wiredPorts = this.cpeModelData.caps.wired[wiredInterface].ports;
              const filteredPorts = wiredPorts.sort((curr, prev) => {
                if (curr.index > prev.index) {
                  return 1;
                }
                if (curr.index < prev.index) {
                  return -1;
                }
                return 0;
              });
              this.cpeModelData.caps.wired[wiredInterface].ports = filteredPorts;
            }
            break;
          } else {
            this.cpeModelData = false;
          }
        }
      }

      if (
        this.updatedCpe &&
        this.updatedCpe.config &&
        this.updatedCpe.config.wifi &&
        this.updatedCpe.state &&
        this.updatedCpe.state.wifi &&
        this.cpeModelData
      ) {
        this.updatedCpe.config.wifi.forEach((configInterface) => {
          if (!this.cpeModelData.caps.wifi[configInterface.id].txpwrlist.length) {
            return;
          }

          const currentWlans = [];
          const hiddenWlans = [];

          const stateInterface = this.updatedCpe.state.wifi[configInterface.id];
          const sortedTxpwrlist = this.cpeModelData.caps.wifi[configInterface.id].txpwrlist.sort(helpers.compareDbm);
          const maxTxPower = sortedTxpwrlist[sortedTxpwrlist.length - 1].dbm;
          const minTxPower = sortedTxpwrlist[0].dbm;

          if (!configInterface.power.range || configInterface.power.range.length !== 2) {
            if (this.cpeModelData && this.cpeModelData.caps.wifi[configInterface.id].txpwrlist) {
              configInterface.power.range = [minTxPower, maxTxPower];
            } else {
              configInterface.power.range = [0, 10];
            }
          }
          if (this.cpeModelData.cisco) {
            configInterface.power.range[0] = minTxPower;
          }

          if (this.wlansList) {
            configInterface.wlans.forEach((id) => {
              if (this.wlansItems[id]) {
                this.wlansItems[id].id = id;
                currentWlans.push(this.wlansItems[id]);
              } else {
                hiddenWlans.push(id);
              }
            });
          }

          if (!sameCpe || this.afterUpdate) {
            this.interfaceSelectedWlans[configInterface.id] = JSON.parse(JSON.stringify(currentWlans));
            if (!this.isRootUser) {
              this.interfaceHiddenWlans[configInterface.id] = JSON.parse(JSON.stringify(hiddenWlans));
            }
          }

          configInterface.wlans = helpers.combineIdsArrayFromObjectsArray(currentWlans);

          if (configInterface.maxclients) {
            this.advancedSettings[configInterface.id].maxClientsEnabled = true;
            // console.log(configInterface);
          } else {
            this.advancedSettings[configInterface.id].maxClientsEnabled = false;
          }

          if (stateInterface.bandmode === '11bgn') {
            stateInterface.bandmode = '11n';
          }

          if (!configInterface.bandmode) {
            configInterface.bandmode = stateInterface.bandmode;
          }

          if (!configInterface.bandwidth) {
            configInterface.bandwidth = stateInterface.bandwidth;
          }

          if (!configInterface.bandmode && !stateInterface.bandmode) {
            // проверяем на наличие доступного capabilities
            if (this.cpeModelData.caps.wifi && this.cpeModelData.caps.wifi[configInterface.id]) {
              if (this.cpeModelData.caps.wifi[configInterface.id].hwmodelist.ac) {
                configInterface.bandmode = '11ac';
              } else if (this.cpeModelData.caps.wifi[configInterface.id].hwmodelist.n) {
                configInterface.bandmode = '11n';
              } else if (this.cpeModelData.caps.wifi[configInterface.id].hwmodelist.a) {
                configInterface.bandmode = '11a';
              } else if (this.cpeModelData.caps.wifi[configInterface.id].hwmodelist.b) {
                configInterface.bandmode = '11b';
              } else if (this.cpeModelData.caps.wifi[configInterface.id].hwmodelist.g) {
                configInterface.bandmode = '11g';
              }
            }
          }

          if (!configInterface.frequency) {
            if (
              this.cpeModelData &&
              this.cpeModelData.caps.wifi &&
              this.cpeModelData.caps.wifi[configInterface.id] &&
              this.cpeModelData.caps.wifi[configInterface.id].frequency !== 'Both'
            ) {
              Vue.set(configInterface, 'frequency', this.cpeModelData.caps.wifi[configInterface.id].frequency);
            } else if (stateInterface.frequency !== 'Both') {
              Vue.set(configInterface, 'frequency', stateInterface.frequency);
            } else {
              Vue.set(configInterface, 'frequency', '5');
            }
          }

          if (!configInterface.bandmode && !stateInterface.bandmode) {
            // проверяем на наличие доступного capabilities
            if (this.cpeModelData.caps.wifi && this.cpeModelData.caps.wifi[configInterface.id]) {
              if (this.cpeModelData.caps.wifi[configInterface.id].hwmodelist.ac) {
                configInterface.bandmode = '11ac';
              } else if (this.cpeModelData.caps.wifi[configInterface.id].hwmodelist.n) {
                configInterface.bandmode = '11n';
              } else if (this.cpeModelData.caps.wifi[configInterface.id].hwmodelist.a) {
                configInterface.bandmode = '11a';
              } else if (this.cpeModelData.caps.wifi[configInterface.id].hwmodelist.b) {
                configInterface.bandmode = '11b';
              } else if (this.cpeModelData.caps.wifi[configInterface.id].hwmodelist.g) {
                configInterface.bandmode = '11g';
              }
            }
          }

          if (!configInterface.country) {
            configInterface.country = 'RU';
          }

          if (this.cpeModelData && this.cpeModelData.caps.wifi && this.cpeModelData.caps.wifi[configInterface.id]) {
            this.interfaceCapabilities[configInterface.id] = {
              selected: configInterface,
              capabilities: this.cpeModelData.caps.wifi[configInterface.id]
            };
            this.reverseCalculateCapabilities(configInterface, stateInterface);
            this.calculateCapabilities(
              configInterface,
              stateInterface,
              this.cpeModelData.caps.wifi[configInterface.id],
              configInterface.id
            );
          } else {
            this.interfaceCapabilities[configInterface.id] = {
              selected: configInterface
            };
          }
        });
      }

      this.afterUpdate = false;
      return currentCpe;
    },
    wlansList() {
      return this.$store.state.wlansList;
    },
    wlansItems() {
      return this.$store.state.wlansItems;
    },
    redirectsList() {
      return this.$store.state.redirectsList;
    },
    hostsList: {
      get() {
        return this.$store.state.hostsList;
      },
      set() {}
    },
    isRootUser() {
      return this.$store.state.baseLocation === this.$store.state.userData.base_location;
    },
    isOperator() {
      return this.$store.state.userData.role === 'operator';
    },
    computedCapabilities() {
      return this.calculatedCapabilities;
    },

    // конец блока //
    iscpeInfoForSelectedCpeOnMapLoading() {
      return this.$store.state.loadingCpeInfoForSelectedCpeOnMap;
    },
    sliderButtonsDisableLeft() {
      if (
        this.pathForSelectedClientTimestampsArray.length &&
        this.pathForSelectedClientSelectedTimestamp === this.pathForSelectedClientTimestampsArray[0]
      ) {
        return true;
      }
      return false;
    },
    sliderButtonsDisableRight() {
      if (
        this.pathForSelectedClientTimestampsArray.length &&
        this.pathForSelectedClientSelectedTimestamp ===
          this.pathForSelectedClientTimestampsArray[this.pathForSelectedClientTimestampsArray.length - 1]
      ) {
        return true;
      }
      return false;
    },
    pathPointsWithTimestampLessThenSelectedTimestamp() {
      // возвращаем массив точек с таймстампом меньше выбранного
      const result = [];
      if (
        this.pathForSelectedClientSelectedTimestamp !== null &&
        this.pathForSelectedClientByTime &&
        Object.keys(this.pathForSelectedClientByTime).length !== 0
      ) {
        for (const timestamp in this.pathForSelectedClientByTime) {
          if (timestamp <= this.pathForSelectedClientSelectedTimestamp) {
            result.push(this.pathForSelectedClientByTime[timestamp]);
          }
        }
        const resultLen = result.length - 1;

        const startPointTS = Math.min(...Object.keys(this.pathForSelectedClientByTime));
        const endPointTS = Math.max(...Object.keys(this.pathForSelectedClientByTime));
        // console.log('recalc')

        // console.log(result)
        for (const index in result) {
          // console.log(index, resultLen);
          result[index].setIcon(this.clientPathDot);
          if (index == resultLen) {
            // console.log('equal');
            result[index].setIcon(this.clientPathDotBlink);
          }
          if (result[index].options.unixTimestamp == startPointTS) {
            // console.log('first');
            result[index].setIcon(this.clientPathDotFirst);
          }
          if (result[index].options.unixTimestamp == endPointTS) {
            // console.log('last');
            result[index].setIcon(this.clientPathDotLast);
          }
          if (result[index].options.unixTimestamp == startPointTS && index == 0 && resultLen == 0) {
            // console.log('firstblink');
            result[index].setIcon(this.clientPathDotFirstBlink);
          }
          if (result[index].options.unixTimestamp == endPointTS && index == resultLen) {
            // console.log('last blink');
            result[index].setIcon(this.clientPathDotLastBlink);
          }
        }

        // const resultLen = result.length - 1;
        // result[resultLen].setIcon(this.clientPathDotBlink)
        return result;
      }
      return null;
    },
    window() {
      return window;
    },
    commonService() {
      return commonService;
    },
    editVisible() {
      return this.$store.state.editMapId;
    },
    isDisable() {
      return this.$store.state.userData.role === 'operator';
    },
    locationsList() {
      // return this.$store.state.locationsList;
      // return helpers.sortLocationsList(this.$store.state.locationsList);
      return this.$store.getters.locationsListSorted;
    },
    mapsList() {
      return this.$store.state.mapsList;
    },
    isNotWizard() {
      return this.$route.name === 'maps';
    },
    loadingMaps() {
      return this.$store.state.loadingMaps;
    },
    loadingCurrentMapData() {
      return this.$store.state.loadingMapClients;
    },
    // loadingCpes() {
    //   return this.$store.state.loadingCpes
    // },
    loadingCpes() {
      return this.isGetAllCompactCpesWithDataRemainingRequestExecuting
    },
    loadingMapCpesStatisticAndClients() {
      return this.$store.state.loadingMapCpesStatisticAndClients;
    },
    currentClientsOnMap() {
      return this.clientsOnMap;
    },
    currentBubblesOnMap() {
      return this.bubblesOnMaps;
    },
    isTwoPoints() {
      return this.scaleData.measure.length === 2;
    },
    measureToLine() {
      const measureLine = this.scaleData.measure.map((point) => [point._latlng.lat, point._latlng.lng]);
      return measureLine;
    },
    currentMap() {
      for (const map of this.mapsList) {
        if (map.id === this.$store.state.editMapId) {
          this.currentMapData = map;
        }
      }
      if (!this.currentMapData) {
        return;
      }

      return this.currentMapData;
    },
    currentMapCpes() {
      if (this.currentMapData) {
        return this.currentMapData.cpe_list;
      }
    },
    clientsMarkerList() {
      return this.clientsMarker;
    },
    currentMapMode() {
      return this.mapMode;
    },
    currentBounds() {
      if (this.currentMapData.upp) {
        return [
          [0, 0],
          [this.currentMapData.height * this.currentMapData.upp, this.currentMapData.width * this.currentMapData.upp]
        ];
      }
      return [
        [0, 0],
        [this.currentMapData.height, this.currentMapData.width]
      ];
    },
    currentCenter() {
      return [
        (this.currentMapData.height * this.currentMapData.upp) / 2,
        (this.currentMapData.width * this.currentMapData.upp) / 2
      ];
    },
    cpesList: {
      get() {
        if (!this.$store.state.cpesList) {
          return;
        }
        const cpes = this.$store.state.cpesList.filter((cpe) => {
          if (this.cpesIdsOnMap.includes(cpe.id)) {
            return false;
          }
          return true;
        });
        cpes.forEach((cpe) => {
          cpe.cpe_id = cpe.id;
        });
        return JSON.parse(JSON.stringify(cpes));
      },
      set() {}
    },
    isRestoreOfMapZoomAndCenterEnabled() {
      // чтобы можно было быстро отключить режим восстановления масштаба и центрирования карты
      return true;
    },
    isSaveMapZoomAndCenteConditionsAreSatisfied() {
      // усливия когда нужно сохранять масштаб и зум карты.
      // Сохраняем только в режиме просмотра.
      if (this.mapMode.show) {
        return true;
      }
      return false;
    },
    isFitMapConditionsAreSatisfied() {
      // не сбрасываем масштаб карты к значеням по умолчанию для режима просмотра
      if (this.mapMode.show) {
        return false;
      }
      return true;
    }
  },
  methods: {
    addCpeTooltipsHighlight() {
      this.saveCpeTooltipsHighlightEnablingStateToLocalStorage(true);
      const nodes = document.querySelectorAll('.cpe-tooltip-wrapper, .bubble-tooltip');
      if (nodes) {
        nodes.forEach((node) => {
          node.classList.add('tooltip-highlighted');
        })
      }
    },
    removeCpeTooltipsHighlight() {
      this.saveCpeTooltipsHighlightEnablingStateToLocalStorage(false);
      const nodes = document.querySelectorAll('.cpe-tooltip-wrapper, .bubble-tooltip');
      if (nodes) {
        nodes.forEach((node) => {
          node.classList.remove('tooltip-highlighted');
        })
      }
    },
    saveCpeTooltipsHighlightEnablingStateToLocalStorage(value) {
      let currentCpeTooltipsHighlightEnablingStateInLocalstorge = JSON.parse(localStorage.getItem('cpeTooltipsHighlightEnablingState'))
      if (!currentCpeTooltipsHighlightEnablingStateInLocalstorge){
        currentCpeTooltipsHighlightEnablingStateInLocalstorge = {}
      }
      currentCpeTooltipsHighlightEnablingStateInLocalstorge[this.currentMap.id] = value

      localStorage.setItem('cpeTooltipsHighlightEnablingState', JSON.stringify(currentCpeTooltipsHighlightEnablingStateInLocalstorge))
    },
    readCpeTooltipsHighlightEnablingStateFromLocalStorageAndInit() {
      const cpeTooltipsHighlightEnablingState = JSON.parse(localStorage.getItem('cpeTooltipsHighlightEnablingState'))
      // console.log(cpeTooltipsHighlightEnablingState)

      if (cpeTooltipsHighlightEnablingState && typeof cpeTooltipsHighlightEnablingState === 'object' &&
        Object.prototype.hasOwnProperty.call(cpeTooltipsHighlightEnablingState, this.currentMap.id) &&
        (cpeTooltipsHighlightEnablingState[this.currentMap.id] === 'true' || cpeTooltipsHighlightEnablingState[this.currentMap.id] === true)) {
        this.cpeTooltipsHighlightEnabled = true;
      } else {
        this.cpeTooltipsHighlightEnabled = false;
      }
      this.$nextTick(()=>{
        this.highlightCpeTooltipsIfHighlightEnabled()
      })
    },
    cpeTooltipsHighlightEnable() {
      this.cpeTooltipsHighlightEnabled = true;
    },
    cpeTooltopsHighlightDisable() {
      this.cpeTooltipsHighlightEnabled = false;
    },
    highlightCpeTooltipsIfHighlightEnabled() {
      if (this.cpeTooltipsHighlightEnabled === true && this.mapMode.show) {
        this.addCpeTooltipsHighlight();
      }
    },
    cpeTooltipsHighlightSwitcherHandler() {
      this.removeCpeTooltipsHighlight()
      this.highlightCpeTooltipsIfHighlightEnabled();
    },
    makeStylesForHeatmapLegend(selfmadeHeatmapData) {

      const maxValue = selfmadeHeatmapData.maxValue
      const LEGEND_FOR_HEATMAP_KEYS = ['0', '1_5', '6_10', '11_15', '16_20', '21_25', '26_30', '31_35', '36_40', '41_45', '46_50', '51_55', '56_60', '61_65', '66_70', '71_75', '76_80', '81_85', '86_90', '91_95', '96_99', '100']

      // создаем массив куда будем заносить наибольшее и наименьшее значения
      const minMaxRealData = {};
      for (const key of LEGEND_FOR_HEATMAP_KEYS) {
        minMaxRealData[key] = {
          min_value: 0,
          max_value: 0
        }
      }


      for (const dataItem of selfmadeHeatmapData.dataForHeatmap) {
        const itemValue = dataItem[2]
        const percentOfMaxValue = (itemValue / maxValue) * 100
        if (percentOfMaxValue === 0) {
          if (minMaxRealData['0'].max_value < itemValue) {
            minMaxRealData['0'].max_value = itemValue
          }
          if (minMaxRealData['0'].min_value === 0) {
            minMaxRealData['0'].min_value = itemValue
          }
          if (minMaxRealData['0'].min_value > itemValue) {
            minMaxRealData['0'].min_value = itemValue
          }
        } else if (percentOfMaxValue > 0 && percentOfMaxValue <= 5) {
          if (minMaxRealData['1_5'].max_value < itemValue) {
            minMaxRealData['1_5'].max_value = itemValue
          }
          if (minMaxRealData['1_5'].min_value === 0) {
            minMaxRealData['1_5'].min_value = itemValue
          }
          if (minMaxRealData['1_5'].min_value > itemValue) {
            minMaxRealData['1_5'].min_value = itemValue
          }
        } else if (percentOfMaxValue > 5 && percentOfMaxValue <= 10) {
          if (minMaxRealData['6_10'].max_value < itemValue) {
            minMaxRealData['6_10'].max_value = itemValue
          }
          if (minMaxRealData['6_10'].min_value === 0) {
            minMaxRealData['6_10'].min_value = itemValue
          }
          if (minMaxRealData['6_10'].min_value > itemValue) {
            minMaxRealData['6_10'].min_value = itemValue
          }
        } else if (percentOfMaxValue > 10 && percentOfMaxValue <= 15) {
          if (minMaxRealData['11_15'].max_value < itemValue) {
            minMaxRealData['11_15'].max_value = itemValue
          }
          if (minMaxRealData['11_15'].min_value === 0) {
            minMaxRealData['11_15'].min_value = itemValue
          }
          if (minMaxRealData['11_15'].min_value > itemValue) {
            minMaxRealData['11_15'].min_value = itemValue
          }
        } else if (percentOfMaxValue > 15 && percentOfMaxValue <= 20) {
          if (minMaxRealData['16_20'].max_value < itemValue) {
            minMaxRealData['16_20'].max_value = itemValue
          }
          if (minMaxRealData['16_20'].min_value === 0) {
            minMaxRealData['16_20'].min_value = itemValue
          }
          if (minMaxRealData['16_20'].min_value > itemValue) {
            minMaxRealData['16_20'].min_value = itemValue
          }
        } else if (percentOfMaxValue > 20 && percentOfMaxValue <= 25) {
          if (minMaxRealData['21_25'].max_value < itemValue) {
            minMaxRealData['21_25'].max_value = itemValue
          }
          if (minMaxRealData['21_25'].min_value === 0) {
            minMaxRealData['21_25'].min_value = itemValue
          }
          if (minMaxRealData['21_25'].min_value > itemValue) {
            minMaxRealData['21_25'].min_value = itemValue
          }
        } else if (percentOfMaxValue > 25 && percentOfMaxValue <= 30) {
          if (minMaxRealData['26_30'].max_value < itemValue) {
            minMaxRealData['26_30'].max_value = itemValue
          }
          if (minMaxRealData['26_30'].min_value === 0) {
            minMaxRealData['26_30'].min_value = itemValue
          }
          if (minMaxRealData['26_30'].min_value > itemValue) {
            minMaxRealData['26_30'].min_value = itemValue
          }
        } else if (percentOfMaxValue > 30 && percentOfMaxValue <= 35) {
          if (minMaxRealData['31_35'].max_value < itemValue) {
            minMaxRealData['31_35'].max_value = itemValue
          }
          if (minMaxRealData['31_35'].min_value === 0) {
            minMaxRealData['31_35'].min_value = itemValue
          }
          if (minMaxRealData['31_35'].min_value > itemValue) {
            minMaxRealData['31_35'].min_value = itemValue
          }
        } else if (percentOfMaxValue > 35 && percentOfMaxValue <= 40) {
          if (minMaxRealData['36_40'].max_value < itemValue) {
            minMaxRealData['36_40'].max_value = itemValue
          }
          if (minMaxRealData['36_40'].min_value === 0) {
            minMaxRealData['36_40'].min_value = itemValue
          }
          if (minMaxRealData['36_40'].min_value > itemValue) {
            minMaxRealData['36_40'].min_value = itemValue
          }
        } else if (percentOfMaxValue > 40 && percentOfMaxValue <= 45) {
          if (minMaxRealData['41_45'].max_value < itemValue) {
            minMaxRealData['41_45'].max_value = itemValue
          }
          if (minMaxRealData['41_45'].min_value === 0) {
            minMaxRealData['41_45'].min_value = itemValue
          }
          if (minMaxRealData['41_45'].min_value > itemValue) {
            minMaxRealData['41_45'].min_value = itemValue
          }
        } else if (percentOfMaxValue > 45 && percentOfMaxValue <= 50) {
          if (minMaxRealData['46_50'].max_value < itemValue) {
            minMaxRealData['46_50'].max_value = itemValue
          }
          if (minMaxRealData['46_50'].min_value === 0) {
            minMaxRealData['46_50'].min_value = itemValue
          }
          if (minMaxRealData['46_50'].min_value > itemValue) {
            minMaxRealData['46_50'].min_value = itemValue
          }
        } else if (percentOfMaxValue > 50 && percentOfMaxValue <= 55) {
          if (minMaxRealData['51_55'].max_value < itemValue) {
            minMaxRealData['51_55'].max_value = itemValue
          }
          if (minMaxRealData['51_55'].min_value === 0) {
            minMaxRealData['51_55'].min_value = itemValue
          }
          if (minMaxRealData['51_55'].min_value > itemValue) {
            minMaxRealData['51_55'].min_value = itemValue
          }
        } else if (percentOfMaxValue > 55 && percentOfMaxValue <= 60) {
          if (minMaxRealData['56_60'].max_value < itemValue) {
            minMaxRealData['56_60'].max_value = itemValue
          }
          if (minMaxRealData['56_60'].min_value === 0) {
            minMaxRealData['56_60'].min_value = itemValue
          }
          if (minMaxRealData['56_60'].min_value > itemValue) {
            minMaxRealData['56_60'].min_value = itemValue
          }
        } else if (percentOfMaxValue > 60 && percentOfMaxValue <= 65) {
          if (minMaxRealData['61_65'].max_value < itemValue) {
            minMaxRealData['61_65'].max_value = itemValue
          }
          if (minMaxRealData['61_65'].min_value === 0) {
            minMaxRealData['61_65'].min_value = itemValue
          }
          if (minMaxRealData['61_65'].min_value > itemValue) {
            minMaxRealData['61_65'].min_value = itemValue
          }
        } else if (percentOfMaxValue > 65 && percentOfMaxValue <= 70) {
          if (minMaxRealData['66_70'].max_value < itemValue) {
            minMaxRealData['66_70'].max_value = itemValue
          }
          if (minMaxRealData['66_70'].min_value === 0) {
            minMaxRealData['66_70'].min_value = itemValue
          }
          if (minMaxRealData['66_70'].min_value > itemValue) {
            minMaxRealData['66_70'].min_value = itemValue
          }
        } else if (percentOfMaxValue > 70 && percentOfMaxValue <= 75) {
          if (minMaxRealData['71_75'].max_value < itemValue) {
            minMaxRealData['71_75'].max_value = itemValue
          }
          if (minMaxRealData['71_75'].min_value === 0) {
            minMaxRealData['71_75'].min_value = itemValue
          }
          if (minMaxRealData['71_75'].min_value > itemValue) {
            minMaxRealData['71_75'].min_value = itemValue
          }
        } else if (percentOfMaxValue > 75 && percentOfMaxValue <= 80) {
          if (minMaxRealData['76_80'].max_value < itemValue) {
            minMaxRealData['76_80'].max_value = itemValue
          }
          if (minMaxRealData['76_80'].min_value === 0) {
            minMaxRealData['76_80'].min_value = itemValue
          }
          if (minMaxRealData['76_80'].min_value > itemValue) {
            minMaxRealData['76_80'].min_value = itemValue
          }
        } else if (percentOfMaxValue > 80 && percentOfMaxValue <= 85) {
          if (minMaxRealData['81_85'].max_value < itemValue) {
            minMaxRealData['81_85'].max_value = itemValue
          }
          if (minMaxRealData['81_85'].min_value === 0) {
            minMaxRealData['81_85'].min_value = itemValue
          }
          if (minMaxRealData['81_85'].min_value > itemValue) {
            minMaxRealData['81_85'].min_value = itemValue
          }
        } else if (percentOfMaxValue > 85 && percentOfMaxValue <= 90) {
          if (minMaxRealData['86_90'].max_value < itemValue) {
            minMaxRealData['86_90'].max_value = itemValue
          }
          if (minMaxRealData['86_90'].min_value === 0) {
            minMaxRealData['86_90'].min_value = itemValue
          }
          if (minMaxRealData['86_90'].min_value > itemValue) {
            minMaxRealData['86_90'].min_value = itemValue
          }
        } else if (percentOfMaxValue > 90 && percentOfMaxValue <= 95) {
          if (minMaxRealData['91_95'].max_value < itemValue) {
            minMaxRealData['91_95'].max_value = itemValue
          }
          if (minMaxRealData['91_95'].min_value === 0) {
            minMaxRealData['91_95'].min_value = itemValue
          }
          if (minMaxRealData['91_95'].min_value > itemValue) {
            minMaxRealData['91_95'].min_value = itemValue
          }
        } else if (percentOfMaxValue > 95 && percentOfMaxValue < 100) {
          if (minMaxRealData['96_99'].max_value < itemValue) {
            minMaxRealData['96_99'].max_value = itemValue
          }
          if (minMaxRealData['96_99'].min_value === 0) {
            minMaxRealData['96_99'].min_value = itemValue
          }
          if (minMaxRealData['96_99'].min_value > itemValue) {
            minMaxRealData['96_99'].min_value = itemValue
          }
        } else if (percentOfMaxValue === 100) {
          if (minMaxRealData['100'].max_value < itemValue) {
            minMaxRealData['100'].max_value = itemValue
          }
          if (minMaxRealData['100'].min_value === 0) {
            minMaxRealData['100'].min_value = itemValue
          }
          if (minMaxRealData['100'].min_value > itemValue) {
            minMaxRealData['100'].min_value = itemValue
          }
        }

      }

      // находим расчетное количество соотвествующее цвету
      const colors = {
        '0': {
          min_percent: 0,
          max_percent: 0,
          min_value: 0,
          max_value: 0,
          color: this.getColorForSelfMadeHeatmap(((((maxValue / 100) * 0) + ((maxValue / 100) * 0)) / 2), maxValue)
        },
        '1_5': {
          min_percent: 1,
          max_percent: 5,
          min_value: ((maxValue / 100) * 1),
          max_value: ((maxValue / 100) * 5),
          color: this.getColorForSelfMadeHeatmap(((((maxValue / 100) * 1) + ((maxValue / 100) * 5)) / 2), maxValue)
        },
        '6_10': {
          min_percent: 6,
          max_percent: 10,
          min_value: ((maxValue / 100) * 6),
          max_value: ((maxValue / 100) * 10),
          color: this.getColorForSelfMadeHeatmap(((((maxValue / 100) * 6) + ((maxValue / 100) * 10)) / 2), maxValue)
        },
        '11_15': {
          min_percent: 11,
          max_percent: 15,
          min_value: ((maxValue / 100) * 11),
          max_value: ((maxValue / 100) * 15),
          color: this.getColorForSelfMadeHeatmap(((((maxValue / 100) * 11) + ((maxValue / 100) * 15)) / 2), maxValue)
        },
        '16_20': {
          min_percent: 16,
          max_percent: 20,
          min_value: ((maxValue / 100) * 16),
          max_value: ((maxValue / 100) * 20),
          color: this.getColorForSelfMadeHeatmap(((((maxValue / 100) * 16) + ((maxValue / 100) * 20)) / 2), maxValue)
        },
        '21_25': {
          min_percent: 21,
          max_percent: 25,
          min_value: ((maxValue / 100) * 21),
          max_value: ((maxValue / 100) * 25),
          color: this.getColorForSelfMadeHeatmap(((((maxValue / 100) * 21) + ((maxValue / 100) * 25)) / 2), maxValue)
        },
        '26_30': {
          min_percent: 26,
          max_percent: 30,
          min_value: ((maxValue / 100) * 26),
          max_value: ((maxValue / 100) * 30),
          color: this.getColorForSelfMadeHeatmap(((((maxValue / 100) * 26) + ((maxValue / 100) * 30)) / 2), maxValue)
        },
        '31_35': {
          min_percent: 31,
          max_percent: 35,
          min_value: ((maxValue / 100) * 31),
          max_value: ((maxValue / 100) * 25),
          color: this.getColorForSelfMadeHeatmap(((((maxValue / 100) * 31) + ((maxValue / 100) * 35)) / 2), maxValue)
        },
        '36_40': {
          min_percent: 36,
          max_percent: 40,
          min_value: ((maxValue / 100) * 36),
          max_value: ((maxValue / 100) * 40),
          color: this.getColorForSelfMadeHeatmap(((((maxValue / 100) * 36) + ((maxValue / 100) * 40)) / 2), maxValue)
        },
        '41_45': {
          min_percent: 41,
          max_percent: 45,
          min_value: ((maxValue / 100) * 41),
          max_value: ((maxValue / 100) * 45),
          color: this.getColorForSelfMadeHeatmap(((((maxValue / 100) * 41) + ((maxValue / 100) * 45)) / 2), maxValue)
        },
        '46_50': {
          min_percent: 46,
          max_percent: 50,
          min_value: ((maxValue / 100) * 46),
          max_value: ((maxValue / 100) * 50),
          color: this.getColorForSelfMadeHeatmap(((((maxValue / 100) * 46) + ((maxValue / 100) * 50)) / 2), maxValue)
        },
        '51_55': {
          min_percent: 51,
          max_percent: 55,
          min_value: ((maxValue / 100) * 51),
          max_value: ((maxValue / 100) * 55),
          color: this.getColorForSelfMadeHeatmap(((((maxValue / 100) * 51) + ((maxValue / 100) * 55)) / 2), maxValue)
        },
        '56_60': {
          min_percent: 56,
          max_percent: 60,
          min_value: ((maxValue / 100) * 56),
          max_value: ((maxValue / 100) * 60),
          color: this.getColorForSelfMadeHeatmap(((((maxValue / 100) * 56) + ((maxValue / 100) * 60)) / 2), maxValue)
        },
        '61_65': {
          min_percent: 61,
          max_percent: 65,
          min_value: ((maxValue / 100) * 61),
          max_value: ((maxValue / 100) * 65),
          color: this.getColorForSelfMadeHeatmap(((((maxValue / 100) * 61) + ((maxValue / 100) * 65)) / 2), maxValue)
        },
        '66_70': {
          min_percent: 66,
          max_percent: 70,
          min_value: ((maxValue / 100) * 66),
          max_value: ((maxValue / 100) * 70),
          color: this.getColorForSelfMadeHeatmap(((((maxValue / 100) * 66) + ((maxValue / 100) * 70)) / 2), maxValue)
        },
        '71_75': {
          min_percent: 71,
          max_percent: 75,
          min_value: ((maxValue / 100) * 71),
          max_value: ((maxValue / 100) * 75),
          color: this.getColorForSelfMadeHeatmap(((((maxValue / 100) * 71) + ((maxValue / 100) * 75)) / 2), maxValue)
        },
        '76_80': {
          min_percent: 76,
          max_percent: 80,
          min_value: ((maxValue / 100) * 76),
          max_value: ((maxValue / 100) * 80),
          color: this.getColorForSelfMadeHeatmap(((((maxValue / 100) * 76) + ((maxValue / 100) * 80)) / 2), maxValue)
        },
        '81_85': {
          min_percent: 81,
          max_percent: 85,
          min_value: ((maxValue / 100) * 81),
          max_value: ((maxValue / 100) * 85),
          color: this.getColorForSelfMadeHeatmap(((((maxValue / 100) * 81) + ((maxValue / 100) * 85)) / 2), maxValue)
        },
        '86_90': {
          min_percent: 86,
          max_percent: 90,
          min_value: ((maxValue / 100) * 86),
          max_value: ((maxValue / 100) * 90),
          color: this.getColorForSelfMadeHeatmap(((((maxValue / 100) * 86) + ((maxValue / 100) * 90)) / 2), maxValue)
        },
        '91_95': {
          min_percent: 91,
          max_percent: 95,
          min_value: ((maxValue / 100) * 91),
          max_value: ((maxValue / 100) * 95),
          color: this.getColorForSelfMadeHeatmap(((((maxValue / 100) * 91) + ((maxValue / 100) * 95)) / 2), maxValue)
        },
        '96_99': {
          min_percent: 96,
          max_percent: 99,
          min_value: ((maxValue / 100) * 96),
          max_value: ((maxValue / 100) * 99),
          color: this.getColorForSelfMadeHeatmap(((((maxValue / 100) * 96) + ((maxValue / 100) * 99)) / 2), maxValue)
        },
        '100': {
          min_percent: 100,
          max_percent: 100,
          min_value: ((maxValue / 100) * 100),
          max_value: ((maxValue / 100) * 100),
          color: this.getColorForSelfMadeHeatmap(((((maxValue / 100) * 100) + ((maxValue / 100) * 100)) / 2), maxValue)
        },
      }
      // меняем расчетные минимальные и максимальные значения для каждого цвета на реальные
      for (const key in minMaxRealData){
        colors[key].min_value = minMaxRealData[key].min_value
        colors[key].max_value = minMaxRealData[key].max_value
      }

      const result = { maxValue, colors }

      // console.log('result', result)
      return result


    },
    selectSorting() {
      this.disableEditMode();
      this.refreshAndClearMaps();
    },
    toggleSortOrder() {
      if (this.filters.sortOrder === 'asc') {
        this.filters.sortOrder = 'desc';
      } else {
        this.filters.sortOrder = 'asc';
      }
      this.disableEditMode();
      this.refreshAndClearMaps();
    },
    handleModalStartWorkTimeFieldInput() {
      // очищаем ошибку поля start-work-time, если она есть, каждый раз когда начанаем вводить start-work-time
      if (Object.prototype.hasOwnProperty.call(this, 'errors')) {
        if (this.errors.has('start-work-time')) {
          this.errors.remove('start-work-time');
        }
      }
    },
    handleModalStopWorkTimeFieldInput() {
      // очищаем ошибку поля stop-work-time, если она есть, каждый раз когда начанаем вводить stop-work-time
      if (Object.prototype.hasOwnProperty.call(this, 'errors')) {
        if (this.errors.has('stop-work-time')) {
          this.errors.remove('stop-work-time');
        }
      }
    },
    showInfoModalAboutWorkTimeFiltering() {
      // console.log('show modal')
      // показываем модалку с описанием работы режима времени работы и режима фильтрации
      if (this.showingInfoModalAboutWorkTimeFiltering !== true) {
        this.showingInfoModalAboutWorkTimeFiltering = true;
      }
    },
    closeInfoModalAboutWorkTimeFiltering() {
      this.showingInfoModalAboutWorkTimeFiltering = false;
    },
    // далее 2 функции для настроек времени работы
    extractTimeZoneObjectForDisplayInMultiselect(time_zone) {
      const timeZoneInBackendFormat = time_zone;
      // console.log(timeZoneInBackendFormat)
      if (timeZoneInBackendFormat === '') {
        return '';
      }
      const timeZonesList = this.timeZonesOptions;
      // console.log(timeZonesList)
      let timezoneObjectForDisplayInMultiselect;
      timezoneObjectForDisplayInMultiselect = timeZonesList.find((element) => {
        if (element.utc.includes(timeZoneInBackendFormat)) {
          return true;
        }
      });
      // console.log(timezoneObjectForDisplayInMultiselect)
      if (typeof timezoneObjectForDisplayInMultiselect === 'object') {
        return timezoneObjectForDisplayInMultiselect;
      }

      return '';
    },
    selectLocationInFilters(location) {
      // console.log(location)
      if (!location) {
        this.filters.location = 'All locations'
      } else {
        this.filters.location = location.id;
        // this.addLocationInPreviouslySelectedLocationsInFilter(location)
      }
      this.selectLocationInFilterLocationSelector()


    },
    // resetAllFilters() {
    //   this.locationInFiltersData = [];
    //   this.toggleShowLocationFilter();
    //   this.filters.location = 'All locations';
    //   this.filters.query = '';
    //   this.filters.sortByFilter = 'Name';
    //   this.filters.sortOrder = 'asc';
    //   this.searchValue = '';
    //   this.refreshMaps();
    // },
    resetAllFilters() {
      // this.locationInFiltersData = [];
      // this.toggleShowLocationFilter();
      // this.filters.location = 'All locations';
      if (!this.isSelectedDefaultLocationForRequests) {
        this.filters.location = 'All locations';
        this.filters.with_childs = false;
        this.locationInFiltersData = [];
      } else {
        this.filters.location = this.$store.getters.getDefaultLocationForRequests(this)
        this.filters.with_childs = this.$store.getters.getDefaultWithChildsForRequests(this)
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject;
      }
      this.toggleShowLocationFilter();
      this.filters.query = '';
      this.filters.sortByFilter = 'Name';
      this.filters.sortOrder = 'asc';
      this.searchValue = '';
      this.refreshMaps();
    },
    async requestLocations(searchQuery) {
      // return locationService.requestLocations(searchQuery);
      // return locationService.requestLocationsWithSort(searchQuery);
      return locationService.requestLocationsWithSortAndFilteringByChildsForLocationSelectInPages(this, searchQuery)
    },
    toggleShowLocationFilter() {
      this.showLocationFilter = false;
      this.$nextTick(() => {
        this.showLocationFilter = true;
      })
    },
    toggleAddToRequestsWithOutNightClientsSettings() {
      this.addToRequestsWithOutNightClientsSettings = !this.addToRequestsWithOutNightClientsSettings;
    },
    setGridSizeForRequestsToHeatmapAPI() {
      // устанавливаем значение размеров ячейки сетки для запросов в ручку /heatmap
      if (!this.currentMapData.upp) {
        return;
      }
      if (!this.currentMapData.height || !this.currentMapData.width) {
        return;
      }
      // const gridSize = Math.floor((this.currentMapData.width * this.currentMapData.upp / 15));
      // const gridSize = ((this.currentMapData.width * this.currentMapData.upp) / 15);
      const gridSize = Math.floor((this.currentMapData.width * this.currentMapData.upp) / 15);
      if (gridSize !== 0) {
        this.gridSize.x = gridSize;
        this.gridSize.y = gridSize;
      }

      // console.log(this.gridSize);
    },
    setGridSizeForRequests() {
      // // устанавливаем значение
      // // if (!this.currentMapData.upp){
      // //   return
      // // }
      // if (!this.currentMapData.height || !this.currentMapData.width) {
      //   return;
      // }
      // const gridSize = this.currentMapData.width / 20;
      // this.gridSize.x = gridSize;
      // this.gridSize.y = gridSize;
      // // console.log(this.gridSize);
    },
    // createRectanglesForSelfmadeHeatmap(heatmapData) {
    //   // формируем прямоугольники, которыми закроем всю карту
    //   // console.log(heatmapData);
    //   const rectangles = [];
    //   // console.log(this.currentMapData);
    //   for (const item of heatmapData.dataForHeatmap) {
    //     // для построения прямоугольника нужны south-west and north-east углы
    //     // то есть нижний левый и верхний правый
    //     // L.latLngBounds(<LatLng> southWest, <LatLng> northEast)
    //     const lat = item[0];
    //     const lng = item[1];
    //     const total = item[2];
    //     const rectangleCoords = item[3];
    //     const { maxValue } = heatmapData;
    //     // тут был самостоятельный подсчет координат прямоугольников для отображения хитмапы на
    //     // основе данных о размерах сетки (из запроса), и координат центра ячеек (из ответа)
    //     // const halfLatGridSize = this.gridSize.y / 2;
    //     // const halfLngGridSize = this.gridSize.x / 2;
    //     // // const leftTopCorner = [lat - halfLatGridSize, lng - halfLngGridSize];
    //     // // const rightBottomCorner = [lat + halfLatGridSize, lng + halfLngGridSize];
    //     // // const cornersForRectangle = [leftTopCorner, rightBottomCorner];
    //     // const northEastCorner = [lat + halfLatGridSize, lng + halfLngGridSize];
    //     // const southWestCorner = [lat - halfLatGridSize, lng - halfLngGridSize];
    //     // const cornersForRectangle = [southWestCorner, northEastCorner];
    //     //
    //     // const stylesForRectangle = this.getStyleForSelfmadeHeatmapRectangle(total, maxValue);
    //     // const rectangle = L.rectangle(cornersForRectangle, stylesForRectangle);
    //     // rectangles.push(rectangle);
    //
    //     // а тут отрисовываем на основе данных о координатах прямоугольников с бэка
    //     if (rectangleCoords !== undefined) {
    //       const northEastCorner = [rectangleCoords.topRightCorner.y, rectangleCoords.topRightCorner.x];
    //       const southWestCorner = [rectangleCoords.bottomLeftCorner.y, rectangleCoords.bottomLeftCorner.x];
    //       const cornersForRectangle = [southWestCorner, northEastCorner];
    //       // const optionsAnsStylesForRectangle = this.getStyleForSelfmadeHeatmapRectangle(total, maxValue);
    //       const optionsAnsStylesForRectangle = this.getStyleForSelfmadeHeatmapRectangleWithNoOpacityWhenTotalIsZero(
    //         total,
    //         maxValue
    //       );
    //       optionsAnsStylesForRectangle.pane = 'shadowPane';
    //       const rectangle = L.rectangle(cornersForRectangle, optionsAnsStylesForRectangle);
    //       rectangles.push(rectangle);
    //     }
    //
    //     // if (leftTopCorner[0] <= (this.currentMapData.height * this.currentMapData.upp) &&
    //     //   leftTopCorner[1] <= (this.currentMapData.width * this.currentMapData.upp)) {
    //     //   const cornersForRectangle = [leftTopCorner, rightBottomCorner];
    //     //   const stylesForRectangle = this.getStyleForSelfmadeHeatmapRectangle(total, maxValue);
    //     //   const rectangle = L.rectangle(cornersForRectangle, stylesForRectangle);
    //     //   rectangles.push(rectangle);
    //     // }
    //   }
    //   // console.log(rectangles)
    //   return rectangles;
    // },

    createRectanglesForSelfmadeHeatmap(heatmapData) {
      // формируем прямоугольники, которыми закроем всю карту
      // console.log(heatmapData);
      const rectangles = [];
      // console.log(this.currentMapData);
      for (const item of heatmapData.dataForHeatmap) {
        // для построения прямоугольника нужны south-west and north-east углы
        // то есть нижний левый и верхний правый
        // L.latLngBounds(<LatLng> southWest, <LatLng> northEast)
        const lat = item[0];
        const lng = item[1];
        const total = item[2];
        const rectangleCoords = item[3];
        const { maxValue } = heatmapData;
        // тут был самостоятельный подсчет координат прямоугольников для отображения хитмапы на
        // основе данных о размерах сетки (из запроса), и координат центра ячеек (из ответа)
        // const halfLatGridSize = this.gridSize.y / 2;
        // const halfLngGridSize = this.gridSize.x / 2;
        // // const leftTopCorner = [lat - halfLatGridSize, lng - halfLngGridSize];
        // // const rightBottomCorner = [lat + halfLatGridSize, lng + halfLngGridSize];
        // // const cornersForRectangle = [leftTopCorner, rightBottomCorner];
        // const northEastCorner = [lat + halfLatGridSize, lng + halfLngGridSize];
        // const southWestCorner = [lat - halfLatGridSize, lng - halfLngGridSize];
        // const cornersForRectangle = [southWestCorner, northEastCorner];
        //
        // const stylesForRectangle = this.getStyleForSelfmadeHeatmapRectangle(total, maxValue);
        // const rectangle = L.rectangle(cornersForRectangle, stylesForRectangle);
        // rectangles.push(rectangle);

        // а тут отрисовываем на основе данных о координатах прямоугольников с бэка
        if (rectangleCoords !== undefined) {
          const northEastCorner = [rectangleCoords.topRightCorner.y, rectangleCoords.topRightCorner.x];
          const southWestCorner = [rectangleCoords.bottomLeftCorner.y, rectangleCoords.bottomLeftCorner.x];
          const cornersForRectangle = [southWestCorner, northEastCorner];
          // const optionsAnsStylesForRectangle = this.getStyleForSelfmadeHeatmapRectangle(total, maxValue);
          const optionsAnsStylesForRectangle = this.getStyleForSelfmadeHeatmapRectangleWithNoOpacityWhenTotalIsZero(
            total,
            maxValue
          );
          optionsAnsStylesForRectangle.pane = 'shadowPane';
          const rectangle = L.rectangle(cornersForRectangle, optionsAnsStylesForRectangle);
          rectangle.on('mouseover', () => {
            if (maxValue !== 0) {
              const nodesItemsInHeatmapLegend = document.querySelectorAll(`div[data-color="${optionsAnsStylesForRectangle.fillColor}"]`)
              if (nodesItemsInHeatmapLegend) {
                nodesItemsInHeatmapLegend.forEach((node) => {
                  node.classList.add('heatmap-legend-item-highlited')
                });
              }
              if (total !== 0) {
                const nodesColorRectanglesOnHeatmap = document.querySelectorAll(`path[fill="${optionsAnsStylesForRectangle.fillColor}"]`)
                if (nodesColorRectanglesOnHeatmap) {
                  nodesColorRectanglesOnHeatmap.forEach((node) => {
                    node.classList.add('pulse-heatmap-legend-item')
                  })
                }
              }
            }
          });
          rectangle.on('mouseout', () => {
            const nodesItemsInHeatmapLegend = document.querySelectorAll(`div[data-color="${optionsAnsStylesForRectangle.fillColor}"]`)
            if (nodesItemsInHeatmapLegend) {
              nodesItemsInHeatmapLegend.forEach((node) => {
                node.classList.remove('heatmap-legend-item-highlited')
              })
            }
            const nodesColorRectanglesOnHeatmap = document.querySelectorAll(`path[fill="${optionsAnsStylesForRectangle.fillColor}"]`)
            if (nodesColorRectanglesOnHeatmap) {
              nodesColorRectanglesOnHeatmap.forEach((node) => {
                node.classList.remove('pulse-heatmap-legend-item')
              })
            }
          });
          rectangles.push(rectangle);
        }

        // if (leftTopCorner[0] <= (this.currentMapData.height * this.currentMapData.upp) &&
        //   leftTopCorner[1] <= (this.currentMapData.width * this.currentMapData.upp)) {
        //   const cornersForRectangle = [leftTopCorner, rightBottomCorner];
        //   const stylesForRectangle = this.getStyleForSelfmadeHeatmapRectangle(total, maxValue);
        //   const rectangle = L.rectangle(cornersForRectangle, stylesForRectangle);
        //   rectangles.push(rectangle);
        // }
      }
      // console.log(rectangles)
      return rectangles;
    },

    // getStyleForSelfmadeHeatmapRectangle(totalCounter, maxValue) {
    //   let stylesForRectangle = { fillColor: 'rgba(55,133,249,0.77)', opacity: 0.1, fillOpacity: 0.1 };
    //   const max = maxValue;
    //   const inRectangleTotal = totalCounter;
    //   if (inRectangleTotal === 0) {
    //     return stylesForRectangle;
    //   }
    //   const inRectangleTotalPercentsFromMaxValue = (inRectangleTotal / max) * 100;
    //   const percent = inRectangleTotalPercentsFromMaxValue;
    //   // console.log(percent)
    //   if (percent > 0 && percent <= 5) {
    //     stylesForRectangle = { fillColor: '#3785f9', opacity: 0.1, fillOpacity: 0.7 };
    //   } else if (percent > 5 && percent <= 10) {
    //     stylesForRectangle = { fillColor: '#47affa', opacity: 0.1, fillOpacity: 0.7 };
    //   } else if (percent > 10 && percent <= 15) {
    //     stylesForRectangle = { fillColor: '#59dbfb', opacity: 0.1, fillOpacity: 0.7 };
    //   } else if (percent > 15 && percent <= 20) {
    //     stylesForRectangle = { fillColor: '#65fbf2', opacity: 0.1, fillOpacity: 0.7 };
    //   } else if (percent > 20 && percent <= 25) {
    //     stylesForRectangle = { fillColor: '#65fac8', opacity: 0.1, fillOpacity: 0.7 };
    //   } else if (percent > 25 && percent <= 30) {
    //     stylesForRectangle = { fillColor: '#65fa9d', opacity: 0.1, fillOpacity: 0.7 };
    //   } else if (percent > 30 && percent <= 35) {
    //     stylesForRectangle = { fillColor: '#64fa74', opacity: 0.1, fillOpacity: 0.7 };
    //   } else if (percent > 35 && percent <= 40) {
    //     stylesForRectangle = { fillColor: '#64f94d', opacity: 0.1, fillOpacity: 0.7 };
    //   } else if (percent > 40 && percent <= 45) {
    //     stylesForRectangle = { fillColor: '#64f92c', opacity: 0.1, fillOpacity: 0.7 };
    //   } else if (percent > 45 && percent <= 50) {
    //     stylesForRectangle = { fillColor: '#67f925', opacity: 0.1, fillOpacity: 0.7 };
    //   } else if (percent > 50 && percent <= 55) {
    //     stylesForRectangle = { fillColor: '#78fa26', opacity: 0.1, fillOpacity: 0.7 };
    //   } else if (percent > 55 && percent <= 60) {
    //     stylesForRectangle = { fillColor: '#92fa28', opacity: 0.1, fillOpacity: 0.7 };
    //   } else if (percent > 60 && percent <= 65) {
    //     stylesForRectangle = { fillColor: '#b0fb2a', opacity: 0.1, fillOpacity: 0.7 };
    //   } else if (percent > 65 && percent <= 70) {
    //     stylesForRectangle = { fillColor: '#d2fc2c', opacity: 0.1, fillOpacity: 0.7 };
    //   } else if (percent > 70 && percent <= 75) {
    //     stylesForRectangle = { fillColor: '#f5fd2f', opacity: 0.1, fillOpacity: 0.7 };
    //   } else if (percent > 75 && percent <= 80) {
    //     stylesForRectangle = { fillColor: '#f8de2c', opacity: 0.1, fillOpacity: 0.7 };
    //   } else if (percent > 80 && percent <= 85) {
    //     stylesForRectangle = { fillColor: '#f1b227', opacity: 0.1, fillOpacity: 0.7 };
    //   } else if (percent > 85 && percent <= 90) {
    //     stylesForRectangle = { fillColor: '#ec8923', opacity: 0.1, fillOpacity: 0.7 };
    //   } else if (percent > 90 && percent <= 95) {
    //     stylesForRectangle = { fillColor: '#e7601f', opacity: 0.1, fillOpacity: 0.7 };
    //   } else if (percent > 95 && percent < 100) {
    //     stylesForRectangle = { fillColor: '#e43a1c', opacity: 0.1, fillOpacity: 0.7 };
    //   } else if (percent === 100) {
    //     stylesForRectangle = { fillColor: '#e3231b', opacity: 0.1, fillOpacity: 0.7 };
    //   }
    //   return stylesForRectangle;
    // },
    // getStyleForSelfmadeHeatmapRectangle(totalCounter, maxValue) {
    //
    //   const max = maxValue;
    //   const inRectangleTotal = totalCounter;
    //   let stylesForRectangle = { fillColor: 'rgba(55,133,249,0.77)', opacity: 0.1, weight: 1, fillOpacity: 0.1 };
    //   if (inRectangleTotal === 0) {
    //     return stylesForRectangle;
    //   }
    //   const inRectangleTotalPercentsFromMaxValue = (inRectangleTotal / max) * 100;
    //   const percent = inRectangleTotalPercentsFromMaxValue;
    //   const color = this.getColorForSelfMadeHeatmap(inRectangleTotal, max)
    //   // console.log(percent)
    //   if (percent > 0 && percent <= 5) {
    //     stylesForRectangle = { fillColor: color, opacity: 0.1, weight: 1, fillOpacity: 0.7 };
    //   } else if (percent > 5 && percent <= 10) {
    //     stylesForRectangle = { fillColor: color, opacity: 0.1, weight: 1, fillOpacity: 0.7 };
    //   } else if (percent > 10 && percent <= 15) {
    //     stylesForRectangle = { fillColor: color, opacity: 0.1, weight: 1, fillOpacity: 0.7 };
    //   } else if (percent > 15 && percent <= 20) {
    //     stylesForRectangle = { fillColor: color, opacity: 0.1, weight: 1, fillOpacity: 0.7 };
    //   } else if (percent > 20 && percent <= 25) {
    //     stylesForRectangle = { fillColor: color, opacity: 0.1, weight: 1, fillOpacity: 0.7 };
    //   } else if (percent > 25 && percent <= 30) {
    //     stylesForRectangle = { fillColor: color, opacity: 0.1, weight: 1, fillOpacity: 0.7 };
    //   } else if (percent > 30 && percent <= 35) {
    //     stylesForRectangle = { fillColor: color, opacity: 0.1, weight: 1, fillOpacity: 0.7 };
    //   } else if (percent > 35 && percent <= 40) {
    //     stylesForRectangle = { fillColor: color, opacity: 0.1, weight: 1, fillOpacity: 0.7 };
    //   } else if (percent > 40 && percent <= 45) {
    //     stylesForRectangle = { fillColor: color, opacity: 0.1, weight: 1, fillOpacity: 0.7 };
    //   } else if (percent > 45 && percent <= 50) {
    //     stylesForRectangle = { fillColor: color, opacity: 0.1, weight: 1, fillOpacity: 0.7 };
    //   } else if (percent > 50 && percent <= 55) {
    //     stylesForRectangle = { fillColor: color, opacity: 0.1, weight: 1, fillOpacity: 0.7 };
    //   } else if (percent > 55 && percent <= 60) {
    //     stylesForRectangle = { fillColor: color, opacity: 0.1, weight: 1, fillOpacity: 0.7 };
    //   } else if (percent > 60 && percent <= 65) {
    //     stylesForRectangle = { fillColor: color, opacity: 0.1, weight: 1, fillOpacity: 0.7 };
    //   } else if (percent > 65 && percent <= 70) {
    //     stylesForRectangle = { fillColor: color, opacity: 0.1, weight: 1, fillOpacity: 0.7 };
    //   } else if (percent > 70 && percent <= 75) {
    //     stylesForRectangle = { fillColor: color, opacity: 0.1, weight: 1, fillOpacity: 0.7 };
    //   } else if (percent > 75 && percent <= 80) {
    //     stylesForRectangle = { fillColor: color, opacity: 0.1, weight: 1, fillOpacity: 0.7 };
    //   } else if (percent > 80 && percent <= 85) {
    //     stylesForRectangle = { fillColor: color, opacity: 0.1, weight: 1, fillOpacity: 0.7 };
    //   } else if (percent > 85 && percent <= 90) {
    //     stylesForRectangle = { fillColor: color, opacity: 0.1, weight: 1, fillOpacity: 0.7 };
    //   } else if (percent > 90 && percent <= 95) {
    //     stylesForRectangle = { fillColor: color, opacity: 0.1, weight: 1, fillOpacity: 0.7 };
    //   } else if (percent > 95 && percent < 100) {
    //     stylesForRectangle = { fillColor: color, opacity: 0.1, weight: 1, fillOpacity: 0.7 };
    //   } else if (percent === 100) {
    //     stylesForRectangle = { fillColor: color, opacity: 0.1, weight: 1, fillOpacity: 0.7 };
    //   }
    //   return stylesForRectangle;
    // },

    // getStyleForSelfmadeHeatmapRectangle(totalCounter, maxValue) {
    //   const max = maxValue;
    //   const inRectangleTotal = totalCounter;
    //   let stylesForRectangle = { fillColor: 'rgba(55,133,249,0.77)', opacity: 0.1, weight: 1, fillOpacity: 0.1 };
    //   if (inRectangleTotal === 0) {
    //     return stylesForRectangle;
    //   }
    //   const inRectangleTotalPercentsFromMaxValue = (inRectangleTotal / max) * 100;
    //   const percent = inRectangleTotalPercentsFromMaxValue;
    //   const color = this.getColorForSelfMadeHeatmap(inRectangleTotal, max);
    //   stylesForRectangle = { fillColor: color, opacity: 0.1, weight: 1, fillOpacity: 0.7 };
    //   return stylesForRectangle;
    // },

    getStyleForSelfmadeHeatmapRectangle(totalCounter, maxValue) {
      const max = maxValue;
      const inRectangleTotal = totalCounter;
      let stylesForRectangle = {
        fillColor: 'rgba(55,133,249,0.77)', opacity: 0.1, weight: 1, fillOpacity: 0.1
      };
      if (inRectangleTotal === 0) {
        return stylesForRectangle;
      }
      // const inRectangleTotalPercentsFromMaxValue = (inRectangleTotal / max) * 100;
      // const percent = inRectangleTotalPercentsFromMaxValue;
      const color = this.getColorForSelfMadeHeatmap(inRectangleTotal, max);
      stylesForRectangle = {
        fillColor: color, opacity: 0.1, weight: 1, fillOpacity: 0.7
      };
      return stylesForRectangle;
    },

    // getStyleForSelfmadeHeatmapRectangleWithNoOpacityWhenTotalIsZero(totalCounter, maxValue) {
    //   const max = maxValue;
    //   const inRectangleTotal = totalCounter;
    //   let stylesForRectangle = { fillColor: 'rgba(55,133,249,0.77)', opacity: 0.1, weight: 1, fillOpacity: 0.0 };
    //   if (inRectangleTotal === 0) {
    //     return stylesForRectangle;
    //   }
    //   // const inRectangleTotalPercentsFromMaxValue = (inRectangleTotal / max) * 100;
    //   // const percent = inRectangleTotalPercentsFromMaxValue;
    //   const color = this.getColorForSelfMadeHeatmap(inRectangleTotal, max);
    //   stylesForRectangle = { fillColor: color, opacity: 0.1, weight: 1, fillOpacity: 0.7 };
    //   return stylesForRectangle;
    // },

    getStyleForSelfmadeHeatmapRectangleWithNoOpacityWhenTotalIsZero(totalCounter, maxValue) {
      try {
        const max = maxValue;
        const inRectangleTotal = totalCounter;
        let stylesForRectangle = {
          fillColor: 'rgba(55,133,249,0.77)',
          opacity: 0.1,
          weight: 1,
          fillOpacity: 0.0
        };
        if (inRectangleTotal === 0) {
          return stylesForRectangle;
        }
        // const inRectangleTotalPercentsFromMaxValue = (inRectangleTotal / max) * 100;
        // const percent = inRectangleTotalPercentsFromMaxValue;
        const color = this.getColorForSelfMadeHeatmap(inRectangleTotal, max);
        stylesForRectangle = {
          fillColor: color, opacity: 0.1, weight: 1, fillOpacity: 0.7
        };
        return stylesForRectangle;
      } catch (e) {
        return {
          fillColor: 'rgba(55,133,249,0.77)', opacity: 0.1, weight: 1, fillOpacity: 0.0
        };
      }
    },

    getColorForSelfMadeHeatmap(totalCounter, maxValue) {
      let color = 'rgba(55,133,249,0.77)';
      const max = maxValue;
      const total = totalCounter;
      if (total === 0) {
        return color;
      }
      const totalPercentsFromMaxValue = (total / max) * 100;
      const percent = totalPercentsFromMaxValue;
      // console.log(percent)
      if (percent > 0 && percent <= 5) {
        color = '#3785f9';
      } else if (percent > 5 && percent <= 10) {
        color = '#47affa';
      } else if (percent > 10 && percent <= 15) {
        color = '#59dbfb';
      } else if (percent > 15 && percent <= 20) {
        color = '#65fbf2';
      } else if (percent > 20 && percent <= 25) {
        color = '#65fac8';
      } else if (percent > 25 && percent <= 30) {
        color = '#65fa9d';
      } else if (percent > 30 && percent <= 35) {
        color = '#64fa74';
      } else if (percent > 35 && percent <= 40) {
        color = '#64f94d';
      } else if (percent > 40 && percent <= 45) {
        color = '#64f92c';
      } else if (percent > 45 && percent <= 50) {
        color = '#67f925';
      } else if (percent > 50 && percent <= 55) {
        color = '#78fa26';
      } else if (percent > 55 && percent <= 60) {
        color = '#92fa28';
      } else if (percent > 60 && percent <= 65) {
        color = '#b0fb2a';
      } else if (percent > 65 && percent <= 70) {
        color = '#d2fc2c';
      } else if (percent > 70 && percent <= 75) {
        color = '#f5fd2f';
      } else if (percent > 75 && percent <= 80) {
        color = '#f8de2c';
      } else if (percent > 80 && percent <= 85) {
        color = '#f1b227';
      } else if (percent > 85 && percent <= 90) {
        color = '#ec8923';
      } else if (percent > 90 && percent <= 95) {
        color = '#e7601f';
      } else if (percent > 95 && percent < 100) {
        color = '#e43a1c';
      } else if (percent === 100) {
        color = '#e3231b';
      }
      return color;
    },
    makeDataForHeatmapSelfmade(clientsOnMap) {
      // для самодельной heatmap с секторами
      try {
        const dataForHeatmap = [];
        let maxHeatmapValue = 0;
        clientsOnMap.forEach((client) => {
          if (client.total > maxHeatmapValue) {
            maxHeatmapValue = client.total;
          }
        });
        clientsOnMap.forEach((client) => {
          // let heatmapValue = (client.total / maxHeatmapValue) * client.total;
          // let heatmapValue = (client.total / maxHeatmapValue);
          const heatmapValue = client.total;
          // console.log(client.rectangle)
          // проверям чтобы были все нужные координаты для построения прямоугольнка
          let rectangle;
          if (
            Object.prototype.hasOwnProperty.call(client, 'rectangle') &&
            Object.prototype.hasOwnProperty.call(client.rectangle, 'min') &&
            Object.prototype.hasOwnProperty.call(client.rectangle, 'max') &&
            Object.prototype.hasOwnProperty.call(client.rectangle.min, 'x') &&
            Object.prototype.hasOwnProperty.call(client.rectangle.min, 'y') &&
            Object.prototype.hasOwnProperty.call(client.rectangle.max, 'x') &&
            Object.prototype.hasOwnProperty.call(client.rectangle.max, 'y')
          ) {
            rectangle = {
              bottomLeftCorner: {
                x: client.rectangle.min.x,
                y: client.rectangle.min.y
              },
              topRightCorner: {
                x: client.rectangle.max.x,
                y: client.rectangle.max.y
              }
            };
          }
          dataForHeatmap.push([client.lat, client.lng, heatmapValue, rectangle]);
          // if (heatmapValue > 0) {
          //   dataForHeatmap.push([client.lat, client.lng, heatmapValue]);
          // }
        });
        return { maxValue: maxHeatmapValue, dataForHeatmap };
      } catch (e) {
        console.log(e);
        return { maxValue: 0, dataForHeatmap: [] };
      }
    },
    makeDataForHeatmapLeafletHeatMAPJS(clientsOnMap) {
      // for Heatmap js
      try {
        const dataForHeatmap = [];
        let maxHeatmapValue = 0;
        clientsOnMap.forEach((client) => {
          if (client.total > maxHeatmapValue) {
            maxHeatmapValue = client.total;
          }
        });
        clientsOnMap.forEach((client) => {
          // let heatmapValue = (client.total / maxHeatmapValue) * client.total;
          // let heatmapValue = (client.total / maxHeatmapValue);
          const heatmapValue = client.total;
          // dataForHeatmap.push( { lat: client.lat, lng: client.lng, count: heatmapValue } );
          if (heatmapValue > 0) {
            dataForHeatmap.push({ lat: client.lat, lng: client.lng, count: heatmapValue });
          }
        });
        return { max: maxHeatmapValue, data: dataForHeatmap };
      } catch (e) {
        return { max: 0, data: [] };
      }
    },
    makeDataForHeatmapLeafletHeat(clientsOnMap) {
      // for Leaflet.heat
      try {
        const dataForHeatmap = [];
        let maxHeatmapValue = 0;
        clientsOnMap.forEach((client) => {
          if (client.total > maxHeatmapValue) {
            maxHeatmapValue = client.total;
          }
        });
        clientsOnMap.forEach((client) => {
          // let heatmapValue = (client.total / maxHeatmapValue) * client.total;
          // let heatmapValue = (client.total / maxHeatmapValue);
          const heatmapValue = client.total;
          // dataForHeatmap.push([client.lat, client.lng, heatmapValue]);
          if (heatmapValue > 0) {
            dataForHeatmap.push([client.lat, client.lng, heatmapValue]);
          }
        });
        return { maxValue: maxHeatmapValue, dataForHeatmap };
      } catch (e) {
        return { maxValue: 0, dataForHeatmap: [] };
      }
    },
    returnMaskedOrNotMAC(mac) {
      if (this.isMaskingMACAddresses === true) {
        return this.maskMACAddress(mac);
      }
      return mac;
    },
    maskMACAddress(mac) {
      try {
        // console.log(mac)
        let maskedMac = mac.slice(0, 8);
        maskedMac += ':**:**:**';
        return maskedMac;
      } catch (e) {
        return mac;
      }
    },
    inputTextInSearchClusterDataFilter() {
      // console.log('input')
      clearTimeout(this.clickedClusterDataSearchTimeout);
      this.clickedClusterDataSearchTimeout = setTimeout(() => {
        this.filterClickedClusterData();
      }, 1000);
    },
    inputTextInSearchManyClientsDataFilter() {
      // console.log('input')
      clearTimeout(this.clickedManyClientsDataSearchTimeout);
      this.clickedManyClientsDataSearchTimeout = setTimeout(() => {
        // console.log('filtering');
        this.filterClickedManyClientsData();
      }, 1000);
    },
    filterClickedClusterData() {
      // console.log('search')
      this.$store.commit('clickedClusterDataFiltereInMapsBegin');
      this.$nextTick(() => {
        this.clickedClusterDataFiltered = this.clickedClusterData.filter((item) =>
          // console.log('here!')
          true);
        if (this.clickedClusterDataSearchField === '') {
          this.$store.commit('clickedClusterDataFiltereInMapsEnd');
          return;
        }
        const inputedMac = helpers.formatMACCutColon(this.clickedClusterDataSearchField);
        this.clickedClusterDataFiltered = this.clickedClusterData.filter((item) => {
          // console.log(item.options.mac, inputedMac);
          if (helpers.formatMACCutColon(item.options.mac).includes(inputedMac)) {
            // console.log(helpers.getFormatMac(item.options.mac))
            return true;
          }
        });
        this.$store.commit('clickedClusterDataFiltereInMapsEnd');
      });
    },
    filterClickedManyClientsData() {
      this.clickedManyClientsDataFiltered = this.clickedManyClientsData.filter((item) =>
        // console.log('here!')
        true);
      if (this.clickedManyClientsDataSearchField === '') {
        return;
      }
      const inputedMac = helpers.formatMACCutColon(this.clickedManyClientsDataSearchField);
      this.clickedManyClientsDataFiltered = this.clickedManyClientsData.filter((item) => {
        // console.log(item.options.mac, inputedMac);
        if (helpers.formatMACCutColon(item.mac).includes(inputedMac)) {
          // console.log(helpers.getFormatMac(item.options.mac))
          return true;
        }
      });
      // console.log(this.clickedManyClientsDataFiltered)
    },
    clickInClickClusterData(marker) {
      // console.log(marker)
      this.mouseOutClickedClusterData();
      this.selectClient(marker);
      this.clearclickedClusterData();
      this.$store.commit('clickInClusterDataForSeeUserInfoEnable');
    },
    clickInManyClientsData(item) {
      if (this.clickedManyClientsDataMarker !== undefined) {
        this.selectClientWhenManyClientsData(item, this.clickedManyClientsDataMarker);
      }
      this.closeClickManyClientsDataWindow();
      // console.log(item);
    },
    closeClickClusterDataWindow() {
      this.mouseOutClickedClusterData();
      this.clearclickedClusterData();
    },
    closeClickManyClientsDataWindow() {
      this.mouseOutClickedClusterData();
      this.clearclickedManyClientsData();
    },
    clearclickedManyClientsData() {
      this.clickedManyClientsData = false;
      this.clickedManyClientsDataSearchField = '';
      this.clickedManyClientsDataFiltered = false;
    },
    clearclickedClusterData() {
      this.clickedClusterData = false;
      this.clickedClusterDataSearchField = '';
      this.clickedClusterDataFiltered = false;
    },
    // эти функции нцжны для отрисовки стрелок на пути пользователя
    getArrows(arrLatlngs, color, arrowCount, mapObj) {
      if (typeof arrLatlngs === undefined || arrLatlngs == null || !arrLatlngs.length || arrLatlngs.length < 2) return [];

      if (typeof arrowCount === 'undefined' || arrowCount == null) arrowCount = 1;

      if (typeof color === 'undefined' || color == null) color = '';
      else color = `color:${color}`;

      const result = [];
      for (let i = 1; i < arrLatlngs.length; i++) {
        const icon = L.divIcon({
          className: 'arrow-icon-for-clients-path',
          bgPos: [5, 5],
          html:
            `<div style="${
              color
            };transform: rotate(${
              this.getAngle(arrLatlngs[i - 1], arrLatlngs[i], -1).toString()
            }deg)">▶</div>`
        });
        for (let c = 1; c <= arrowCount; c++) {
          result.push(
            L.marker(this.myMidPoint(arrLatlngs[i], arrLatlngs[i - 1], c / (arrowCount + 1), mapObj), { icon })
          );
        }
      }
      return result;
    },

    getAngle(latLng1, latlng2, coef) {
      // console.log(latLng1)
      // console.log(latlng2)
      const dy = latlng2[0] - latLng1[0];
      // console.log(latLng1);
      const dx = Math.cos((Math.PI / 180) * latLng1[0]) * (latlng2[1] - latLng1[1]);
      // console.log(dx)
      const ang = (Math.atan2(dy, dx) / Math.PI) * 180 * coef;
      // console.log((ang).toFixed(2))
      return ang.toFixed(2);
    },

    myMidPoint(latlng1, latlng2, per, mapObj) {
      if (!mapObj) throw new Error('map is not defined');

      let halfDist;
      let segDist;
      let dist;
      let p1;
      let p2;
      let ratio;
      const points = [];

      p1 = mapObj.project(new L.latLng(latlng1));
      p2 = mapObj.project(new L.latLng(latlng2));

      halfDist = this.distanceTo(p1, p2) * per;

      if (halfDist === 0) return mapObj.unproject(p1);

      dist = this.distanceTo(p1, p2);

      if (dist > halfDist) {
        ratio = (dist - halfDist) / dist;
        const res = mapObj.unproject(new this.pointForPath(p2.x - ratio * (p2.x - p1.x), p2.y - ratio * (p2.y - p1.y)));
        return [res.lat, res.lng];
      }
    },

    distanceTo(p1, p2) {
      const x = p2.x - p1.x;
      const y = p2.y - p1.y;

      return Math.sqrt(x * x + y * y);
    },

    toPoint(x, y, round) {
      if (x instanceof this.pointForPath) {
        return x;
      }
      if (isArray(x)) {
        return new this.pointForPath(x[0], x[1]);
      }
      if (x === undefined || x === null) {
        return x;
      }
      if (typeof x === 'object' && 'x' in x && 'y' in x) {
        return new this.pointForPath(x.x, x.y);
      }
      return new this.pointForPath(x, y, round);
    },

    pointForPath(x, y, round) {
      this.x = round ? Math.round(x) : x;
      this.y = round ? Math.round(y) : y;
    },
    // эти функции нцжны для отрисовки стрелок на пути пользователя

    setCursorForModes(mode) {
      if (mode === 'scale') {
        document.getElementById('map').style.cursor = 'pointer';
      } else {
        document.getElementById('map').style.cursor = '';
      }
    },
    selectLocationInFilterLocationSelector() {
      this.offset = 0;
      this.disableEditMode();
      this.searchSpinnerForTable = true;
      clearTimeout(this.delayTimer);
      this.delayTimer = setTimeout(() => {
        // mapsService.getMapsV2(this);
        // mapsService.getMapsV2(this, true);
        mapsService.getMapsV3(this, true);
      }, 100);
    },
    // часть отключения показ посетителей по умолчанию когда показываем статистику
    hideDynamicPartitionInMenu() {
      document.getElementById('dynamics-caption').style = 'display: none';
      document.getElementById('dynamics-button').style = 'display: none';
    },
    showDynamicPartitionInMenu() {
      document.getElementById('dynamics-caption').style = 'display: block';
      document.getElementById('dynamics-button').style = 'display: block';
    },
    hideRefreshButtonInMenu() {
      document.getElementById('refresh-button-in-menu').style = 'display: none';
    },
    showRefreshButtonInMenu() {
      document.getElementById('refresh-button-in-menu').style = 'display: block';
    },
    // hideHeatmapButton() {
    //   document.getElementById('heatmap-button-in-menu').style = `display: none`;
    // },
    // showHeatmapButton() {
    //   document.getElementById('heatmap-button-in-menu').style = `display: block`;
    // },
    // конец части отключения показ посетителей по умолчанию когда показываем статистику точки
    getClientHealthStatus(healthScore) {
      //  Red—Poor client devices. Health score range is 1 to 3.
      //  Orange—Fair client devices. Health score range is 4 to 7.
      //  Green—Good client devices. Health score range is 8 to 10
      //  Gray—Inactive client devices. Health score is 0.
      if (healthScore === 0) {
        return 'Inactive';
      } if (healthScore >= 1 && healthScore <= 3) {
        return 'Poor';
      } if (healthScore >= 4 && healthScore <= 7) {
        return 'Fair';
      } if (healthScore >= 8 && healthScore <= 10) {
        return 'Good';
      }
      return '';
    },
    getBadgeClassByClientHealthScores(healthScore) {
      const healthStatus = this.getClientHealthStatus(healthScore);
      if (healthStatus === 'Inactive') {
        return 'health-status-badge-inactive';
      }
      if (healthStatus === 'Poor') {
        return 'health-status-badge-poor';
      }
      if (healthStatus === 'Fair') {
        return 'health-status-badge-fair';
      }
      if (healthStatus === 'Good') {
        return 'health-status-badge-good';
      }
      return '';
    },
    getFormatMac(mac) {
      return helpers.getFormatMac(mac);
    },
    showCpeUsersInfo(cpeId, cpeName) {
      // console.log(cpeId);
      this.userListForSelectedCpeCpeName = cpeName;
      this.getSelectedCpeUsersInfo(cpeId);
    },
    getSelectedCpeUsersInfo(cpeId) {
      this.userListForSelectedCpe = [];
      this.isSelectedCpeUsersInfoModalShowing = true;
      this.$store.commit('beginLoadingUsersInfoForSelectedCpeOnMap');
      Vue.axios
        .post(`${window.RESTAPI || ''}/api/clients`, {
          action: 'R',
                  location: this.$store.getters.getDefaultLocationForRequests(this), with_childs: this.$store.getters.getDefaultWithChildsForRequests(this),
          sort_by: { field: 'state', order: 1 },
          q: {
            state: 'CONNECTED',
            cpe_id: { $in: [cpeId] }
          }
        })
        .then(
          (response) => {
            if (response.data.status === 'success') {
              const itemsList = response.data.data.itemslist;
              this.userListForSelectedCpe = itemsList;
              // обновляем количество клиентов для тулитпа точки
              if (this.userListForSelectedCpe.length > 0) {
                // если какие-то данные о подключенных пользователях есть тогда обновляем
                Vue.set(this.cpesStatisticClientsOnMap, cpeId, this.userListForSelectedCpe);
              } else {
                // а если нет ни одного то удалем ключ полностью
                Vue.delete(this.cpesStatisticClientsOnMap, cpeId);
              }
              // и перерисовывамем точки, чтобы значение в тулитпе поменялось
              this.refreshMapCpesNOgetCpesRatingAndClients();
              this.$store.commit('endLoadingUsersInfoForSelectedCpeOnMap');
            } else if (response.data.status === 'error') {
              this.$store.commit('endLoadingUsersInfoForSelectedCpeOnMap');
              VueNotifications.error({ message: response.data.description });
            }
          },
          (err) => {
            this.$store.commit('endLoadingUsersInfoForSelectedCpeOnMap');
            VueNotifications.error({ message: err });
          }
        );
    },
    memoryLoad(total, free) {
      return ((total - free) * 100) / total;
    },
    searchInputHandlerForTable(value) {
      this.offset = 0;
      this.disableEditMode();
      this.searchValue = value;
      this.searchSpinnerForTable = true;
      clearTimeout(this.delayTimer);
      this.delayTimer = setTimeout(() => {
        // mapsService.getMapsV2(this);
        // mapsService.getMapsV2(this, true);
        mapsService.getMapsV3(this, true);
      }, 1000);
    },
    loadMore() {
      this.offset++;
      // mapsService.getMapsV2Paginated(this);
      // mapsService.getMapsV2Paginated(this, true);
      mapsService.getMapsV3Paginated(this, true);
    },
    showCounter(counterName) {
      // для настроек времени работы
      const switcherForNightClients = document.getElementById('switcher-for-night-clients-filtering');
      if (switcherForNightClients) {
        // для настроек времени работы
        switcherForNightClients.style = 'display: none';
      }
      const currentClients = document.querySelector('.leaflet-current-clients');
      if (currentClients) {
        currentClients.style = 'display: none';
      }
      const passerbyItemCounter = document.querySelector('.leaflet-current-passerby-counter');
      if (passerbyItemCounter) {
        passerbyItemCounter.style = 'display: none';
      }
      const connectedItemCounter = document.querySelector('.leaflet-current-connected-counter');
      if (connectedItemCounter) {
        connectedItemCounter.style = 'display: none';
      }
      const trafficItemCounter = document.querySelector('.leaflet-current-traffic-counter');
      if (trafficItemCounter) {
        trafficItemCounter.style = 'display: none';
      }

      if (counterName === 'clients') {
        if (currentClients) {
          currentClients.style = 'display: list-item';
        }
        if (switcherForNightClients) {
          // для настроек времени работы
          switcherForNightClients.style = 'display: list-item';
        }
      }

      if (counterName === 'passerby') {
        if (passerbyItemCounter) {
          passerbyItemCounter.style = 'display: list-item';
        }
      }

      if (counterName === 'connected') {
        if (connectedItemCounter) {
          connectedItemCounter.style = 'display: list-item';
        }
      }

      if (counterName === 'traffic') {
        if (trafficItemCounter) {
          trafficItemCounter.style = 'display: list-item';
        }
      }
      // часть отключения показ посетителей по умолчанию когда показываем статистику точки
      if (counterName === 'none') {
        if (switcherForNightClients) {
          // для настроек времени работы
          switcherForNightClients.style = 'display: none';
        }
        if (currentClients) {
          currentClients.style = 'display: none';
        }
        if (passerbyItemCounter) {
          passerbyItemCounter.style = 'display: none';
        }
        if (connectedItemCounter) {
          connectedItemCounter.style = 'display: none';
        }
        if (trafficItemCounter) {
          trafficItemCounter.style = 'display: none';
        }
        // конец части отключения показ посетителей по умолчанию когда показываем статистику точки
      }
    },
    testgetBubbleTimeline() {
      mapsService.getBubblesTimeline(this, this.currentMapData.id, 'bubble_traffic');
    },
    getShowedMode() {
      // возвращает ключ включенного режима
      const enabledModes = [];
      const modes = Object.keys(this.showMode);
      for (const mode of modes) {
        // console.log(mode)
        if (this.showMode[mode] === true && mode !== 'timeline') {
          enabledModes.push(mode);
        }
      }
      return enabledModes;
    },
    setShowedMode(enablingModes) {
      // console.log(enablingModes)
      // устанавливает ключ включеного режима
      // console.log(mode)
      // вырубаем все
      const modes = Object.keys(this.showMode);
      for (const modeName of modes) {
        this.showMode[modeName] = false;
      }
      this.resetAllLayers();
      // включаем нужные
      for (const enablingModeName of enablingModes) {
        this.showMode[enablingModeName] = true;
      }
      // console.log(this.showMode)
      // рефрешим
      this.refreshV2();
    },
    bubbleRadiusCoefficientCalculatedUsingMapZoomValue(mapZoom) {
      // рассчитываем коэффициент для радиуса пузырей в зависимости от размера зума карты
      // нужно чтобы уменьшать пузыри при приближении
      try {
        return 1 / ((mapZoom + 0.001) * 0.8);
      } catch (e) {
        return 1;
      }
    },
    clearShowMode() {
      this.showMode.clientsLastPathPoints = false;
      this.showMode.heatmap = false;
      this.showMode.timeline = false;
      this.showMode.bubble_clients = false;
      this.showMode.bubble_lbs = false;
      this.showMode.bubble_traffic = false;
    },
    showZonesLayerWhenClickZonesEditButtons() {
      // если есть какие-то зоны, но их нет на карте, (зачем-то отключили галочку
      // показа зон в режиме редактирования зон) то добавляем слой с зонами обртано
      // чтобы видеть что редактировать
      if (this.zonesLayers.getLayers().length > 0 && !this.map.hasLayer(this.zonesLayer)) {
        this.map.addLayer(this.zonesLayers);
      }
    },
    CPEChangedModalDisabledEditModeWhenYes() {
      if (this.cpeChangedModalSource === 'toggleMode') {
        this.toggleMode(this.cpeChangedModalTarget);
      }
      if (this.cpeChangedModalSource === 'closeButtons') {
        this.disableEditMode();
      }
      if (this.cpeChangedModalSource === false) {
        this.disableEditMode();
      }
    },
    CPEChangedModalHandleYes() {
      this.showingCPEChangedModal = false;
      this.isCPEsChanged = false;
      this.CPEChangedModalDisabledEditModeWhenYes();
    },
    CPEChangedModalHandleNo() {
      this.CPEChangedModalHide();
    },
    CPEChangedModalShow(source, target) {
      if (!source) {
        source = false;
      }
      if (!target) {
        target = false;
      }
      this.cpeChangedModalSource = source;
      this.cpeChangedModalTarget = target;
      this.showingCPEChangedModal = true;
    },
    CPEChangedModalHide() {
      this.cpeChangedModalSource = false;
      this.showingCPEChangedModal = false;
    },
    mapZoomLevelChange(event) {
      // console.log(event.target._zoom);
      // console.log();
      // // перерисовываем пузыри (меняем радиус каждого) с трафиком / клиентами / подключенными клиентами
      // // в зависимости от масштаба карты
      // let isTargetZommLessThenStartMapZoom = false;
      // let targetZoom = event.target._zoom;
      // if (targetZoom <= this.startMapZoom) {
      //   // если устанавливаемый масштаб меньше начального не пересчитываем радиус
      //   isTargetZommLessThenStartMapZoom = true;
      // }
      // // чтобы таргет зум начанался сначала
      // targetZoom -= this.startMapZoom;
      // // чтобы не словить деление на ноль
      // targetZoom += 1;
      //
      // // console.log(targetZoom)

      if (this.mapLayers.bublesLayer) {
        // this.map.removeLayer(this.mapLayers.bublesLayer);
        // this.mapLayers.bublesLayer = L.featureGroup().addTo(this.map);
        //
        // this.bubblesOnMaps.forEach((bubble) => {
        //   let bubbleTooltipTemplate;
        //   if (this.showMode.bubble_lbs) {
        //     bubbleTooltipTemplate = ` <ul class="text-center list-unstyled m-q p-0">
        //                     <li class="bubble-result"> ${bubble.result}</li>
        //                     <li class="bubble-descr">passerby clients</li>
        //                  </ul>`;
        //   } else if (this.showMode.bubble_clients) {
        //     bubbleTooltipTemplate = `<ul class="text-center list-unstyled m-q p-0">
        //                     <li class="bubble-result"> ${bubble.result}</li>
        //                     <li class="bubble-descr"> connected clients</li>
        //                  </ul>`;
        //   } else if (this.showMode.bubble_traffic) {
        //     bubbleTooltipTemplate = `<ul class="text-center list-unstyled m-q p-0">
        //                                             <li class="bubble-result bubble-result--traffic">
        //                                              ${commonService.formatBytes(bubble.result)}
        //                                             </li>
        //                                             <li class="bubble-result bubble-result--traffic-rxtx">RX:
        //                                              ${commonService.formatBytes(bubble.result_rx)}
        //                                             </li>
        //                                             <li class="bubble-result bubble-result--traffic-rxtx">TX:
        //                                              ${commonService.formatBytes(bubble.result_tx)}
        //                                             </li>
        //                                         </ul>`;
        //   }
        //
        //   bubble.fillColor = this.showMode.bubble_lbs ?
        //     '#f8cb00' :
        //     this.showMode.bubble_clients ?
        //       '#88ec89' :
        //       this.showMode.bubble_traffic ?
        //         '#7cb5ec' :
        //         '#cecece';
        //   bubble.color = this.showMode.bubble_lbs ?
        //     '#f8cb00' :
        //     this.showMode.bubble_clients ?
        //       '#88ec89' :
        //       this.showMode.bubble_traffic ?
        //         '#7cb5ec' :
        //         '#cecece';
        //   const bubleMarker = L.circle(bubble.center, bubble)
        //     .bindTooltip(bubbleTooltipTemplate, this.tooltipBubbleOptions)
        //     .openTooltip();
        //   this.mapLayers.bublesLayer.addLayer(bubleMarker);
        // });

        // for (const bubbleIndex in this.mapLayers.bublesLayer._layers) {
        //   const layer = this.mapLayers.bublesLayer._layers[bubbleIndex];
        //   const layerRadius = layer._radius;
        //   // console.log(layerRadius / targetZoom)
        //   const origianlRadius = layer.options.original_radius;
        //   // console.log('original ' + origianlRadius);
        //   // console.log('zoom ' + targetZoom)
        //   if (isTargetZommLessThenStartMapZoom) {
        //     // если устанавливаемый масштаб меньше начального ставим оригинальный радиус
        //     layer.setRadius(origianlRadius);
        //   } else {
        //     // тут подсчет нового радиуса подобранный эмпирическим путем, вроде смотрится неплохо
        //     // const smallRadius = origianlRadius / (targetZoom * Math.log(targetZoom))
        //     const smallRadius = origianlRadius / (Math.log(targetZoom) * 2 * 1.2);
        //     // console.log('setted ' + (smallRadius))
        //     layer.setRadius(smallRadius);
        //   }
        // }
        for (const bubbleIndex in this.mapLayers.bublesLayer._layers) {
          const layer = this.mapLayers.bublesLayer._layers[bubbleIndex];
          // const layerRadius = layer._radius;
          // console.log(layerRadius / targetZoom)
          const origianlRadius = layer.options.original_radius;
          const newRadius =
            origianlRadius * this.bubbleRadiusCoefficientCalculatedUsingMapZoomValue(event.target._zoom);
          // console.log(`new: ${newRadius}`)
          // console.log(`origin: ${origianlRadius}`);

          // нужно чтобы радиус пузыря не блы больше чем изначально расситаный
          // (иначе если сильно отдалить карту, то пузыри становятся огромными)
          if (newRadius > origianlRadius) {
            // layer.setRadius(origianlRadius);
            layer.setRadius(0);
          } else {
            // layer.setRadius(newRadius);
            layer.setRadius(0);
          }
        }
      }
    },
    cancelZonesEditMode() {
      // програмно нажимает  на кнопку отмены режима редактирования.
      // нужно для отмены редактирования зон, если полигоны зоны пересекаются.
      if (document.querySelector('.leaflet-draw div:nth-child(2) .leaflet-draw-actions li:nth-child(2) a')) {
        document.querySelector('.leaflet-draw div:nth-child(2) .leaflet-draw-actions li:nth-child(2) a').click();
      }
    },
    roundZoneCornersCoordinates(zoneLayerCorners) {
      // округляем координаты углов зоны, до 3 после запятой, turf js бывает ругается на много
      // чисел после запятой при проверке на пересечение
      // console.log(zoneLayer)
      // const zoneLayerCorners = JSON.parse(JSON.stringify(zoneLayer['_latlngs'][0]));
      for (const coordinatePair of zoneLayerCorners) {
        coordinatePair.lat = parseFloat(coordinatePair.lat.toFixed(3));
        coordinatePair.lng = parseFloat(coordinatePair.lng.toFixed(3));
      }
      // // console.log(zoneLayerCorners)
      // zoneLayer['_latlngs'][0] = zoneLayerCorners;
      // return zoneLayer;
    },
    intersectionPolygonLayersCheck(polygonLayerOne, polygonLayerTwo) {
      // приводим полигоны к виду, понятному turf js
      const polygonOne = polygonLayerOne.toGeoJSON();
      const polygonTwo = polygonLayerTwo.toGeoJSON();
      // проверяем пересечение с помощью turf js
      const intersectCheckResult = intersect(polygonOne, polygonTwo);
      // console.log(intersectCheckResult);
      if (intersectCheckResult === null) {
        return false;
      }
      // если результат пересенчения - полигон или мультиполигон то считаем что зоны пересекаются
      if (intersectCheckResult.geometry && intersectCheckResult.geometry.type === 'Polygon') {
        return true;
      }
      if (intersectCheckResult.geometry && intersectCheckResult.geometry.type === ' MultiPolygon') {
        return true;
      }
      // а если результат перессечения другой (документация говорит что может быть
      // Point или MultiPoint или LineString или MultiLineString)
      // то считаем что нет пересечения
      return false;
    },
    saveEditedZone() {
      this.editedZone.name = this.editedZoneNewName;
      this.createZonesAsArray();
      mapsService.zonesUpdate(this, true);
      this.clearEditingZoneAndCloseBlock();
    },
    clearEditingZoneAndCloseBlock() {
      this.editedZone = {};
      this.editedZoneNewName = '';
      this.showZoneEditBlock = false;
    },
    closeEditingZoneBlock() {
      this.editedZone = {};
      this.editedZoneNewName = '';
      this.showZoneEditBlock = false;
      this.map.dragging.enable();
    },
    editZone(zoneObject) {
      function compare(a1, a2) {
        return a1.length === a2.length && a1.every((v, i) => v === a2[i]);
      }

      if (!zoneObject) {
        return;
      }
      this.showZoneEditBlock = true;
      let editedZone = {};
      for (const zone in this.zonesLayers._layers) {
        // тут  такой ад с поиском зоны, потому что нет id. Поэтому считаем зону той-же когда совпадают углы и имя
        // if (this.zonesLayers['_layers'][zone].name === zoneObject.name && compare(this.zonesLayers['_layers'][zone]['_latlngs'], zoneObject['_latlngs'])) {
        //   editedZone = this.zonesLayers['_layers'][zone];
        //   break;
        // }

        // if (this.zonesLayers._layers[zone].identity === zoneObject.identity) {
        //   editedZone = this.zonesLayers._layers[zone];
        //   break;
        // }

        if (zoneObject.identity !== '') {
          if (this.zonesLayers._layers[zone].identity === zoneObject.identity) {
            editedZone = this.zonesLayers._layers[zone];
            break;
          }
        } else if (this.zonesLayers._layers[zone].name === zoneObject.name) {
          editedZone = this.zonesLayers._layers[zone];
          break;
        }
      }
      this.editedZoneNewName = editedZone.name;
      this.editedZone = editedZone;

      // console.log(zoneId);
    },
    createZonesAsArray() {
      // создаем из слоев зон массив с зонами  в виде, который принимает API

      // const result = [];
      // if (this.zonesLayers !== false) {
      //   for (const zoneNumber in this.zonesLayers['_layers']) {
      //     const zone = {};
      //     console.log(this.zonesLayers['_layers'][zoneNumber]);
      //     zone.id = this.zonesLayers['_layers'][zoneNumber].id;
      //     zone.name = this.zonesLayers['_layers'][zoneNumber].name;
      //     zone.corners = [];
      //     for (const corner of this.zonesLayers['_layers'][zoneNumber]['_latlngs'][0]) {
      //       zone.corners.push([corner.lat, corner.lng, 0]);
      //     }
      //     result.push(zone);
      //   }
      // }
      // this.zonesLayersAsArray = []
      // this.zonesLayersAsArray = result;
      const result = [];
      if (this.zonesLayers !== false) {
        for (const zoneNumber in this.zonesLayers._layers) {
          const zone = {};
          // console.log(this.zonesLayers['_layers'][zoneNumber]);
          zone.id = this.zonesLayers._layers[zoneNumber].id;
          // поле identity это вместо id
          zone.identity = this.zonesLayers._layers[zoneNumber].identity;
          zone.name = this.zonesLayers._layers[zoneNumber].name;
          zone.corners = [];
          for (const corner of this.zonesLayers._layers[zoneNumber]._latlngs[0]) {
            zone.corners.push({ x: corner.lat, y: corner.lng });
          }
          result.push(zone);
        }
      }
      this.zonesLayersAsArray = [];
      this.zonesLayersAsArray = result;
    },

    wlanNamebyId(id) {
      const wlanNamebyId = commonService.wlanNamebyId(id);
      return wlanNamebyId;
    },
    cpeNamebyId(id) {
      const cpeNamebyId = commonService.cpeNamebyId(id);
      return cpeNamebyId;
    },
    // эти методы нужны для работы модального окна с подробой информации по точке //
    showFWUpgrade() {
      this.isFWUpgradeShow = !this.isFWUpgradeShow;
    },
    isBusy(id, status) {
      return (
        this.cpesIdsOperations.includes(id) ||
        status === 'updating' ||
        status === 'upgrading' ||
        status === 'rebooting' ||
        status === 'resetting'
      );
    },
    isFull() {
      return !!window.FULL;
    },
    isBeeline() {
      return window.CUSTOM_BUILD === 'BEELINE-WIMARK';
    },
    isCpeHasIntefaces() {
      if (!this.updatedCpe.config.wifi || !this.updatedCpe.state.wifi) {
        return false;
      }
      return true;
    },
    // reverseCalculateCapabilities(radioInterface, state) {
    //   const config = radioInterface;
    //
    //   if (config.bandmode === '11g' && config.frequency === '2.4') {
    //     if (config.bandwidth !== 'auto') {
    //       config.bandmode = '11n';
    //     }
    //   }
    //   if (config.bandmode === '11a' && config.frequency === '5') {
    //     if (config.bandwidth === 'auto') {
    //       config.bandmode = '11a';
    //     } else if (config.bandwidth.indexOf('HT') === 0) {
    //       config.bandmode = '11n';
    //     } else if (config.bandwidth.indexOf('VHT') === 0) {
    //       config.bandmode = '11ac';
    //     }
    //   }
    // },

    reverseCalculateCapabilities(radioInterface, state) {
      const config = radioInterface;

      if (config.bandmode === '11g' && config.frequency === '2.4') {
        if (config.bandwidth !== 'auto') {
          if (config.bandwidth.indexOf('HE') === 0) {
            config.bandmode = '11ax';
          } else {
            config.bandmode = '11n';
          }

        }
      }
      if (config.bandmode === '11a' && config.frequency === '5') {
        if (config.bandwidth === 'auto') {
          config.bandmode = '11a';
        } else if (config.bandwidth.indexOf('HT') === 0) {
          config.bandmode = '11n';
        } else if (config.bandwidth.indexOf('VHT') === 0) {
          config.bandmode = '11ac';
        } else if (config.bandwidth.indexOf('HE') === 0) {
          config.bandmode = '11ax';
        }
      }
    },

    // calculateCapabilities(configInterface, stateInterface, interfaceCapabilities, interfaceName) {
    //   if (!interfaceCapabilities) {
    //     return;
    //   }
    //
    //   const config = configInterface;
    //
    //   let selectedChannel = null;
    //   if (stateInterface) {
    //     selectedChannel = stateInterface.channel;
    //   } else {
    //     selectedChannel = configInterface.channels[0];
    //   }
    //   if (!interfaceName) return;
    //
    //   this.calculatedCapabilities[interfaceName] = capabilities.getCurrentCapabilities(config, interfaceCapabilities);
    //
    //   if (this.calculatedCapabilities[interfaceName].bandwidth.indexOf(config.bandwidth) === -1) {
    //     config.bandwidth = this.calculatedCapabilities[interfaceName].bandwidth[0];
    //   }
    //
    //   // validate channels for HT40 & 2.4
    //   if (
    //     this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HT40' &&
    //     this.interfaceCapabilities[interfaceName].selected.frequency === '2.4'
    //   ) {
    //     this.interfaceCapabilities[interfaceName].selected.channels = capabilities.getCurrentBandwidthChannels(
    //       this.interfaceCapabilities[interfaceName],
    //       this.calculatedCapabilities[interfaceName],
    //       'HT40'
    //     );
    //   }
    //   // end validate
    //
    //   // validate channels for HT40 & 5
    //   if (
    //     (this.interfaceCapabilities[interfaceName].selected.bandwidth === 'VHT40' ||
    //       this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HT40') &&
    //     this.interfaceCapabilities[interfaceName].selected.frequency === '5'
    //   ) {
    //     this.interfaceCapabilities[interfaceName].selected.channels = capabilities.getCurrentBandwidthChannels(
    //       this.interfaceCapabilities[interfaceName],
    //       this.calculatedCapabilities[interfaceName],
    //       'VHT40/HT40'
    //     );
    //   }
    //   // end
    //   // validate channels for HT80 & 5
    //   if (
    //     (this.interfaceCapabilities[interfaceName].selected.bandwidth === 'VHT80' ||
    //       this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HT80') &&
    //     this.interfaceCapabilities[interfaceName].selected.frequency === '5'
    //   ) {
    //     this.interfaceCapabilities[interfaceName].selected.channels = capabilities.getCurrentBandwidthChannels(
    //       this.interfaceCapabilities[interfaceName],
    //       this.calculatedCapabilities[interfaceName],
    //       'VHT80/HT80'
    //     );
    //   }
    //
    //   // end
    // },

    calculateCapabilities(configInterface, stateInterface, interfaceCapabilities, interfaceName) {
      if (!interfaceCapabilities) {
        return;
      }

      const config = configInterface;

      let selectedChannel = null;
      if (stateInterface) {
        selectedChannel = stateInterface.channel;
      } else {
        selectedChannel = configInterface.channels[0];
      }
      if (!interfaceName) return;

      this.calculatedCapabilities[interfaceName] = capabilities.getCurrentCapabilities(config, interfaceCapabilities);

      if (this.calculatedCapabilities[interfaceName].bandwidth.indexOf(config.bandwidth) === -1) {
        config.bandwidth = this.calculatedCapabilities[interfaceName].bandwidth[0];
      }

      // validate channels for HT40 & 2.4
      if (
        (this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HT40' ||
        this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HE40') &&
        this.interfaceCapabilities[interfaceName].selected.frequency === '2.4'
      ) {
        this.interfaceCapabilities[interfaceName].selected.channels = capabilities.getCurrentBandwidthChannels(
          this.interfaceCapabilities[interfaceName],
          this.calculatedCapabilities[interfaceName],
          'HT40/HE40'
        );
      }
      // end validate

      // validate channels for HT40 & 5
      if (
        (this.interfaceCapabilities[interfaceName].selected.bandwidth === 'VHT40' ||
          this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HT40' ||
          this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HE40') &&
        this.interfaceCapabilities[interfaceName].selected.frequency === '5'
      ) {
        this.interfaceCapabilities[interfaceName].selected.channels = capabilities.getCurrentBandwidthChannels(
          this.interfaceCapabilities[interfaceName],
          this.calculatedCapabilities[interfaceName],
          'VHT40/HT40/HE40'
        );
      }
      // end
      // validate channels for HT80 & 5
      if (
        (this.interfaceCapabilities[interfaceName].selected.bandwidth === 'VHT80' ||
          this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HT80' ||
          this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HE80') &&
        this.interfaceCapabilities[interfaceName].selected.frequency === '5'
      ) {
        this.interfaceCapabilities[interfaceName].selected.channels = capabilities.getCurrentBandwidthChannels(
          this.interfaceCapabilities[interfaceName],
          this.calculatedCapabilities[interfaceName],
          'VHT80/HT80/HE80'
        );
      }

      if (
        (this.interfaceCapabilities[interfaceName].selected.bandwidth === 'VHT80+80' ||
          this.interfaceCapabilities[interfaceName].selected.bandwidth === 'VHT160' ||
          this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HE80+80' ||
          this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HE160') &&
        this.interfaceCapabilities[interfaceName].selected.frequency === '5'
      ) {
        this.interfaceCapabilities[interfaceName].selected.channels = capabilities.getCurrentBandwidthChannels(
          this.interfaceCapabilities[interfaceName],
          this.calculatedCapabilities[interfaceName],
          'VHT80+80/VHT160/HE80+80/HE160'
        );
      }

      // end
    },

    ssidWithDescription({ ssid, description }) {
      if (this.isWlanDescription && description) {
        return `${ssid}: ${commonService.cutName(description)}`;
      }
      return `${ssid}`;
    },
    wlanObj(id) {
      const wlanObj = commonService.wlanObjbyId(id);
      return wlanObj;
    },
    updateCpeInterface() {},
    showId(e) {
      const selectedNode = e.target.closest('[data-id]');
      const { id } = selectedNode.dataset;
      if (!this.cpeModelData || !this.cpeModelData.caps.wifi || !this.cpeModelData.caps.wifi[id]) {
        return;
      }
      this.interfaceSettings[selectedNode.dataset.id] = !this.interfaceSettings[selectedNode.dataset.id];
    },
    // isModeAllowed(frequency, mode, hwmodelist) {
    //   if (frequency === '2.4') {
    //     const modes24 = {
    //       b: hwmodelist.b,
    //       g: hwmodelist.g,
    //       n: hwmodelist.n
    //       // 'ac': hwmodelist.ac
    //     };
    //     return modes24[mode];
    //   }
    //   const modes5 = {
    //     a: hwmodelist.a,
    //     n: hwmodelist.n,
    //     ac: hwmodelist.ac
    //   };
    //   return modes5[mode];
    // },
    isModeAllowed(frequency, mode, hwmodelist) {
      // console.log('frequency', frequency)
      // console.log('mode', mode)
      // console.log('hwmodelist', hwmodelist)
      if (frequency === '2.4') {
        const modes24 = {
          b: hwmodelist.b,
          g: hwmodelist.g,
          n: hwmodelist.n,
          // 'ac': hwmodelist.ac
          ax: hwmodelist.ax
        };
        return modes24[mode];
      }
      const modes5 = {
        a: hwmodelist.a,
        n: hwmodelist.n,
        ac: hwmodelist.ac,
        ax: hwmodelist.ax
      };
      return modes5[mode];
    },

    // конец //
    getClientInfoFromApi(mac) {
      // console.log(mac);
      Vue.axios
        .post(`${window.RESTAPI || ''}/api/clients`, {
          action: 'R',
                  location: this.$store.getters.getDefaultLocationForRequests(this), with_childs: this.$store.getters.getDefaultWithChildsForRequests(this),
          search: mac
        })
        .then(
          (response) => {
            if (response.data.status === 'success') {
              // console.log(response.data.data.itemslist);
              if (response.data.data.itemslist[0] !== undefined) {
                this.selectedClientDataFromClientsApi = response.data.data.itemslist[0];
              } else {
                this.selectedClientDataFromClientsApi = {};
              }
            } else if (response.data.status === 'error') {
              if (helpers.isTimeoutError(response.data.description) === true) {
                console.error(response.data.description);
              } else {
                VueNotifications.error({ message: response.data.description });
              }
              // VueNotifications.error({ message: response.data.description });
            }
          },
          (err) => {
            VueNotifications.error({ message: err });
          }
        );
    },
    getClientInfoFromApiForTooltip(mac, htmlId, ctx) {
      // console.log(mac);
      Vue.axios
        .post(`${window.RESTAPI || ''}/api/clients`, {
          action: 'R',
                  location: this.$store.getters.getDefaultLocationForRequests(this), with_childs: this.$store.getters.getDefaultWithChildsForRequests(this),
          search: mac
        })
        .then(
          (response) => {
            if (response.data.status === 'success') {
              // console.log(response.data.data.itemslist);
              if (response.data.data.itemslist[0] !== undefined) {
                // ctx.selectedClientDataFromClientsApiForTooltip = response.data.data.itemslist[0];
                // const clienttooltip = document.getElementById(`${htmlId}`);
                // if (Object.keys(ctx.selectedClientDataFromClientsApiForTooltip).length > 0) {
                //   clienttooltip.innerHTML += '<ul>'
                //   clienttooltip.innerHTML += `<li><strong>${ctx.$t('general.description')}:</strong> ${ctx.selectedClientDataFromClientsApiForTooltip.data.description || ''}</li></li>`;
                //   clienttooltip.innerHTML += `<li><strong>${ctx.$t('general.firstConnected')}:</strong> ${moment(ctx.selectedClientDataFromClientsApiForTooltip.first_connect * 1000)
                //     .format(' D MMM YYYY, H:mm ')}</li></li>`;
                //   if (ctx.selectedClientDataFromClientsApiForTooltip.State === 'CONNECTED') {
                //     clienttooltip.innerHTML += `<li><strong>${ctx.$t('maps.lastConnected')}:</strong> ${moment(ctx.selectedClientDataFromClientsApiForTooltip.timestamp * 1000)
                //       .format(' D MMM YYYY, H:mm ')}</li></li>`;
                //   }
                //   if (ctx.selectedClientDataFromClientsApiForTooltip.State === 'DISCONNECTED') {
                //     clienttooltip.innerHTML += `<li><strong>${ctx.$t('general.lastDisconnected')}:</strong> ${moment(ctx.selectedClientDataFromClientsApiForTooltip.timestamp * 1000)
                //       .format(' D MMM YYYY, H:mm ')}</li></li>`;
                //   }
                //   clienttooltip.innerHTML += '<ul>';
                // }
                ctx.selectedClientDataFromClientsApi = response.data.data.itemslist[0];
              } else {
                // ctx.selectedClientDataFromClientsApiForTooltip = {};
                ctx.selectedClientDataFromClientsApi = {};
              }
            } else if (response.data.status === 'error') {
              VueNotifications.error({ message: response.data.description });
            }
          },
          (err) => {
            VueNotifications.error({ message: err });
          }
        );
    },

    closeMoreCpeInfoModal() {
      this.isMoreCpeInfoModalShowing = false;
      this.moreCpeInfoForSelectedCpe = [];
      this.updatedCpe = [];
    },
    getSelectedCpeInfo(cpeId) {
      this.moreCpeInfoForSelectedCpe = [];
      this.updatedCpe = [];
      this.isFWUpgradeShow = false;
      this.isFirewallShow = false;
      this.statLogLbsmodal = false;
      this.interfaceSettings = {
        radio0: false,
        radio1: false,
        radio2: false
      };
      this.interfaceSelectedWlans = {
        radio0: [],
        radio1: [],
        radio2: []
      };
      this.interfaceHiddenWlans = {
        radio0: [],
        radio1: [],
        radio2: []
      };
      this.interfaceCapabilities = {};
      this.$store.commit('toggleLoadingCpeInfoForSelectedCpeOnMap');
      Vue.axios
        .post(`${window.RESTAPI || ''}/api/cpes`, {
          action: 'R',
          ids: [cpeId]
        })
        .then(
          (response) => {
            if (response.data.status === 'success') {
              const itemsList = response.data.data.itemslist;
              // itemsList.forEach((cpe)=>{
              //     if(cpe.config.wifi){
              //         cpe.config.wifi = helpers.transformObjectToArray(cpe.config.wifi);
              //     };
              //     if(cpe.config.wired){
              //         cpe.config.wired = helpers.transformObjectToArray(cpe.config.wired);
              //     };
              // })
              itemsList.forEach((cpe) => {
                if (cpe.config.wifi) {
                  cpe.config.wifi = helpers.transformObjectToArray(cpe.config.wifi);
                }
                if (cpe.config.wired) {
                  cpe.config.wired = helpers.transformObjectToArray(cpe.config.wired);
                }
              });
              // console.log(itemsList)
              this.moreCpeInfoForSelectedCpe = itemsList[0];
              this.updatedCpe = JSON.parse(JSON.stringify(this.moreCpeInfoForSelectedCpe));
              this.isMoreCpeInfoModalShowing = true;
              this.$store.commit('toggleLoadingCpeInfoForSelectedCpeOnMap');
            } else if (response.data.status === 'error') {
              VueNotifications.error({ message: response.data.description });
              this.$store.commit('toggleLoadingCpeInfoForSelectedCpeOnMap');
            }
          },
          (err) => {
            VueNotifications.error({ message: err });
            this.$store.commit('toggleLoadingCpeInfoForSelectedCpeOnMap');
          }
        );
    },
    showMoreCpeInfo(id) {
      // console.log(id);
      this.getSelectedCpeInfo(id);
    },
    mapFullScreenChange() {
      if (this.map !== false) {
        this.isMapOnFullScreen = !this.isMapOnFullScreen;
      }
    },
    mouseOverClickedClusterData() {
      this.map.dragging.disable();
    },
    mouseOutClickedClusterData() {
      this.map.dragging.enable();
    },
    mouseOverClientsInfoFromApi() {
      this.map.dragging.disable();
    },
    mouseOutClientsInfoFromApi() {
      this.map.dragging.enable();
    },
    mouseOverEditZoneBlock() {
      this.map.dragging.disable();
    },
    mouseOutEditZoneBlock() {
      this.map.dragging.enable();
    },
    mouseOverErrorZoneBlock() {
      this.map.dragging.disable();
    },
    mouseOutErrorZoneBlock() {
      this.map.dragging.enable();
    },
    closeErrorZoneBlock() {
      this.showIntersectionErrorWhenZoneCreating = false;
      this.map.dragging.enable();
    },
    closeNameErrorZoneBlock() {
      this.showNameErrorWhenZoneCreating = false;
      this.map.dragging.enable();
    },
    mouseOverNewZoneBlock() {
      this.map.dragging.disable();
    },
    mouseOutNewZoneBlock() {
      this.map.dragging.enable();
    },
    mouseOverModalCpeMoreInfo() {
      this.map.dragging.disable();
    },
    mouseOutModalCpeMoreInfo() {
      this.map.dragging.enable();
    },
    mouseOverFullScreenDatePicker() {
      this.map.dragging.disable();
    },
    mouseOutFullScreenDatePicker() {
      this.map.dragging.enable();
    },
    mouseOverSlider() {
      this.map.dragging.disable();
    },
    mouseOutSlider() {
      this.map.dragging.enable();
    },
    pathForSelectedClientFirstPoint() {
      // console.log('FirstPoint');
      this.pathForSelectedClientSelectedTimestamp = this.pathForSelectedClientTimestampsArray[0];
    },
    pathForSelectedClientPreviousPoint() {
      if (this.pathForSelectedClientTimestampsArray.length && this.pathForSelectedClientTimestampsArray.length) {
        const index = this.pathForSelectedClientTimestampsArray.indexOf(this.pathForSelectedClientSelectedTimestamp);
        if (index === 0) {
        } else {
          this.pathForSelectedClientSelectedTimestamp = this.pathForSelectedClientTimestampsArray[index - 1];
        }
      }
      // console.log('PreviousPoint');
    },
    pathForSelectedClientNextPoint() {
      if (this.pathForSelectedClientTimestampsArray.length && this.pathForSelectedClientTimestampsArray.length) {
        const index = this.pathForSelectedClientTimestampsArray.indexOf(this.pathForSelectedClientSelectedTimestamp);
        if (index === this.pathForSelectedClientTimestampsArray.length - 1) {
        } else {
          this.pathForSelectedClientSelectedTimestamp = this.pathForSelectedClientTimestampsArray[index + 1];
        }
      }
    },
    pathForSelectedClientLastPoint() {
      // console.log('LastPoint');
      this.pathForSelectedClientSelectedTimestamp = this.pathForSelectedClientLastPointTimestamp;
    },
    refreshAndClearMaps() {
      this.searchSpinner = false;
      this.searchValue = '';
      this.totalResult = false;
      this.canLoadMore = true;
      this.limit = 30;
      this.offset = 0;
      this.$store.commit('setMapsList', []);
      // mapsService.getMaps(this);
      // mapsService.getMapsV2(this);
      // mapsService.getMapsV2(this, true);
      mapsService.getMapsV3(this, true);
    },
    refreshMaps() {
      // mapsService.getMaps(this);
      // mapsService.getMapsV2(this);
      // mapsService.getMapsV2(this, true);
      mapsService.getMapsV3(this, true);
    },
    // filteredMaps(mapArray) {
    //   let filteredMapsArray = mapArray;
    //   if (this.filters.query && this.filters.location !== 'All locations') {
    //     filteredMapsArray = mapArray.filter(
    //       (map) => map.name.includes(this.filters.query) && map.base_location === this.filters.location
    //     );
    //   } else if (this.filters.query && this.filters.location === 'All locations') {
    //     filteredMapsArray = mapArray.filter((map) => map.name.includes(this.filters.query));
    //   } else if (!this.filters.query && this.filters.location !== 'All locations') {
    //     filteredMapsArray = mapArray.filter((map) => map.base_location === this.filters.location);
    //   }
    //
    //   return filteredMapsArray;
    // },
    // filteredMaps(mapArray) {
    //   let filteredMapsArray = mapArray;
    // if (this.filters.query && this.filters.location === 'All locations') {
    //     filteredMapsArray = mapArray.filter((map) => map.name.includes(this.filters.query));
    //   } else if (!this.filters.query && this.filters.location !== 'All locations') {
    //     filteredMapsArray = mapArray.filter((map) => map.base_location === this.filters.location);
    //   }
    //
    //   return filteredMapsArray;
    // },
    filteredMaps(mapArray) {
      let filteredMapsArray = mapArray;
      if (this.filters.query) {
        filteredMapsArray = mapArray.filter((map) => map.name.includes(this.filters.query));
      }
      return filteredMapsArray;
    },
    refreshClients() {
      if (this.loadingCurrentMapData) {
        return;
      }
      this.searchInputHandler('');
      this.dateRange.endDate = moment().valueOf();
      this.resetAllLayers();
      this.getClientsOnMap();
      this.resetButtons();
    },
    // переделана из функции refreshClients()
    refreshV2(refreshDateRange = false) {
      // console.log('refresh');
      if (this.loadingCurrentMapData) {
        return;
      }
      this.searchInputHandler('');
      // обновляем дату окончания интревала, если необходимо
      if (refreshDateRange) {
        this.dateRange.endDate = moment().valueOf();
        // перерисовываем datepicker'ы
        this.isDatepickerShowed = false;
        this.isDatepickerFullScreenShowed = false;
        this.$nextTick(() => {
          this.isDatepickerShowed = true;
          this.isDatepickerFullScreenShowed = true;
        });
      }
      // если нажата кнопка посетители
      if (this.showMode.clientsLastPathPoints === true) {
        // console.log('refresh!')
        // this.searchInputHandler('');
        // this.dateRange.endDate = moment().valueOf();
        // this.resetAllLayers();
        if (this.showMode.heatmap === true) {
          this.updateHeatmap();
        }
        this.getClientsOnMap();
        // this.resetButtons();
        if (this.showMode.heatmap === true) {
          this.resetButtonsExcludeHeatMap();
          this.resetAllLayersExcludeHeatMap();
          this.updateHeatmap();
        } else {
          this.resetAllLayers();
          this.resetButtons();
        }
      }
      if (this.showMode.bubble_lbs) {
        // this.dateRange.endDate = moment().valueOf();
        this.resetBubble();
        this.showMode.bubble_lbs = true;
        mapsService.getBubbleLbs(this, this.$store.state.editMapId);
      }
      if (this.showMode.bubble_clients) {
        // this.dateRange.endDate = moment().valueOf();
        this.resetBubble();
        this.showMode.bubble_clients = true;
        mapsService.getBubbleClients(this, this.$store.state.editMapId);
      }
      if (this.showMode.bubble_traffic) {
        // this.dateRange.endDate = moment().valueOf();
        this.resetBubble();
        this.showMode.bubble_traffic = true;
        mapsService.getBubbleTraffic(this, this.$store.state.editMapId);
      }
      if (this.showMode.timeline && this.beforeTimelineShowShowingMode.includes('bubble_clients')) {
        this.resetBubble();
        // this.showMode.bubble_clients = true;
        // mapsService.getBubbleClients(this, this.$store.state.editMapId)
        mapsService.getBubblesTimeline(
          this,
          this.currentMapData.id,
          'bubble_clients',
          this.switchSegmentForBubbles.bind(this)
        );
      }
      if (this.showMode.timeline && this.beforeTimelineShowShowingMode.includes('bubble_lbs')) {
        this.resetBubble();
        // this.showMode.bubble_lbs = true;
        // mapsService.getBubbleLbs(this, this.$store.state.editMapId);
        mapsService.getBubblesTimeline(
          this,
          this.currentMapData.id,
          'bubble_lbs',
          this.switchSegmentForBubbles.bind(this)
        );
      }
      if (this.showMode.timeline && this.beforeTimelineShowShowingMode.includes('bubble_traffic')) {
        this.resetBubble();
        // this.showMode.bubble_traffic = true;
        // mapsService.getBubbleTraffic(this, this.$store.state.editMapId);
        mapsService.getBubblesTimeline(
          this,
          this.currentMapData.id,
          'bubble_traffic',
          this.switchSegmentForBubbles.bind(this)
        );
      }
      // часть отключения показ посетителей по умолчанию когда показываем статистику
      // если включен режим показа статистики тогда обновляем только статистику и подключенных в данный момент юзеров
      if (
        this.showCPEsStatisticForUser &&
        !this.showMode.clientsLastPathPoints &&
        !this.showMode.bubble_lbs &&
        !this.showMode.bubble_clients &&
        !this.showMode.bubble_traffic &&
        !(this.showMode.timeline && this.beforeTimelineShowShowingMode.includes('bubble_clients')) &&
        !(this.showMode.timeline && this.beforeTimelineShowShowingMode.includes('bubble_lbs')) &&
        !(this.showMode.timeline && this.beforeTimelineShowShowingMode.includes('bubble_traffic'))
      ) {
        this.refreshMapCpes();
      }
      // конец части отключения показ посетителей по умолчанию когда показываем статистику точки
    },
    openMapsWizard() {
      this.resetAllLayers();
      this.resetAllMode();
      this.disableEditMode();
      this.passerbyCounterInZoneByZoneIdentity = false; // очищаем счетчик клиентов в зоне
      this.$router.push({ name: 'maps-wizard' });
    },
    resetButtons() {
      const heatmapLink = document.querySelector('.heatmap-btn');
      const trafficLink = document.querySelector('.traffic-btn');
      const connectedLink = document.querySelector('.connected-btn');
      const passerbyLink = document.querySelector('.passerby-btn');

      trafficLink.classList.remove('active');
      connectedLink.classList.remove('active');
      passerbyLink.classList.remove('active');
      heatmapLink.classList.remove('active');
    },
    resetButtonsExcludeHeatMap() {
      const trafficLink = document.querySelector('.traffic-btn');
      const connectedLink = document.querySelector('.connected-btn');
      const passerbyLink = document.querySelector('.passerby-btn');

      trafficLink.classList.remove('active');
      connectedLink.classList.remove('active');
      passerbyLink.classList.remove('active');
    },
    resetAllLayers() {
      if (!this.map) {
        return;
      }
      this.resetFoundClient();
      this.resetContext();
      this.resetBubble();
      this.resetHeatmap();
      this.removeScaleMarkers();
      this.resetScaleLayer();

      // this.fitMap();
      if (this.isFitMapConditionsAreSatisfied) {
        this.fitMap();
      }
    },
    resetAllLayersExcludeHeatMap() {
      if (!this.map) {
        return;
      }
      this.resetFoundClient();
      this.resetContext();
      this.resetBubble();
      this.removeScaleMarkers();
      this.resetScaleLayer();

      // this.fitMap();
      if (this.isFitMapConditionsAreSatisfied) {
        this.fitMap();
      }
    },

    resetControls() {
      for (const control in this.mapControls) {
        if (
          this.mapControls[control] &&
          control !== 'modeControl' &&
          control !== 'layersControl' &&
          control !== 'fitBoundsControl'
        ) {
          this.mapControls[control].remove();
        }
      }
    },

    resetHeatmap() {
      this.showMode.heatmap = false;
      if (this.mapLayers.heatLayer) {
        this.mapLayers.heatLayer.remove();
        this.showHeatmapLegend = false;
        this.mapLayers.heatLayer = false;
      }
    },
    resetScaleLayer() {
      if (this.map.hasLayer(this.mapLayers.scaleLayer)) {
        this.map.removeLayer(this.mapLayers.scaleLayer);
        this.mapLayers.scaleLayer = false;
      }
    },
    resetBubble() {
      this.bubblesOnMaps = [];
      this.showMode.bubble_lbs = false;
      this.showMode.bubble_clients = false;
      this.showMode.bubble_traffic = false;
      if (this.map.hasLayer(this.mapLayers.bublesLayer)) {
        this.map.removeLayer(this.mapLayers.bublesLayer);
      }
    },
    toggleEditFromFullScreen(event, map) {
      // if (this.map) {
      //   this.map.remove();
      //   this.disableEditMode();
      //   this.resetAllMode();
      //   // this.passerbyCounterInZoneByZoneIdentity = false; // очищаем счетчик клиентов в зоне
      //   this.$store.commit('setMapsEditId', map.id);
      //   this.map = false;
      //   this.initMap(map.id);
      //   this.map._isFullscreen = true; // хак без которого обратно не сворачивается
      //   this.map.isFullscreen();
      // }
    },
    toggleEdit(event, map) {
      // if (this.map) {
      //   return;
      // }
      if (this.disableButtonsWhenhenLoadingClientsAndSplitRequestsToAPI) {
        return;
      }
      if (this.map) {
        // console.log('changingmap')
        this.disableEditMode();
        this.$nextTick(() => {
          this.toggleEdit(event, map);
        });
        return;
      }
      // console.log('edit map')
      if (!map.cpe_list.length && this.isDisable) {
        return;
      }
      if (event.target.dataset.action === 'delete') {
        this.mapIdForDelete = map.id;
        this.openDeleteMapModal();
        return;
      }

      this.resetAllMode();
      if (this.isShowingProgressBarWhenLoadingClientsAndSplitRequestsToAPI) {
        this.$store.commit('setPercentsOfClientsMarkerDownloadsRequestsCount', undefined); // очищаем проценты в спинере на всякий случай
      }
      this.passerbyCounterInZoneByZoneIdentity = false; // очищаем счетчик клиентов в зоне
      this.$store.commit('setMapsEditId', map.id);

      Vue.nextTick(() => {
        this.initMap(map.id);
      });
    },
    initMap() {
      this.showHeatmapLegend = false;
      this.clearclickedManyClientsData();
      this.mapVisitorsSelectedSegmentNumber = undefined;
      this.polygonWhenMouseOverCLientsPoint = undefined;
      this.addToRequestsWithOutNightClientsSettings = false;

      this.clearclickedClusterData();
      // if (this.showCPEsStatisticForUser) {
      //   // console.log(this.currentMapData.cpe_list);
      //   const cpeList = [];
      //   for (const cpe of this.currentMapData.cpe_list) {
      //     cpeList.push(cpe.cpe_id);
      //   }
      //   // console.log(cpeList)
      //   mapsService.getCpesRatingAndClientsAndRefreshMapCpes(this, cpeList);
      //   // this.getMapObject();
      //   // this.selectMapMode();
      //   // this.initLeaflet();
      // }
      this.getMapObject();
      this.readCpeTooltipsHighlightEnablingStateFromLocalStorageAndInit();
      this.addToRequestsWithOutNightClientsSettings = this.isAllFieldsForFilteringNightClientsFilled;
      this.selectMapMode();
      // this.setGridSizeForRequests();
      this.setGridSizeForRequestsToHeatmapAPI();
      this.initLeaflet();

      // this.$refs.map.focus();
      // if (this.mapMode.show) {
      //   this.map.on('popupopen', () => {
      //     const cpePopUpShowMoreLinik = document.getElementsByClassName('showMoreCpeInfo')[0];
      //     // console.log(cpePopUpShowMoreLinik)
      //     const cpePopUpShowMoreLiniAsObject = cpePopUpShowMoreLinik.getAttribute('data-cpe-id');
      //     // console.log(cpePopUpShowMoreLiniAsObject);
      //     cpePopUpShowMoreLinik.addEventListener('click', () => {
      //       this.showMoreCpeInfo(cpePopUpShowMoreLiniAsObject);
      //     });
      //   });
      // }
    },
    getMapObject() {
      for (const map of this.mapsList) {
        if (map.id === this.$store.state.editMapId) {
          this.currentMapData = map;
          break;
        }
      }
    },
    selectMapMode() {
      if (!this.currentMapData.upp) {
        this.mapMode.scale = true;
      } else if (!this.currentMapData.cpe_list.length) {
        this.mapMode.planning = true;
      } else {
        this.mapMode.show = true;
      }
    },
    initLeaflet() {
      this.imageOverlayLoading = true;
      this.map = L.map('map', {
        crs: L.CRS.Simple,
        maxZoom: 11,
        zoomControl: false,
        scrollWheelZoom: false,
        drawControl: false
      });

      const imageOverlay = L.imageOverlay(
        `${window.RESTAPI || ''}${this.currentMapData.image_url}`,
        this.currentBounds
      ).addTo(this.map);
      imageOverlay.setZIndex(-1);

      imageOverlay.once('load', () => {
        this.$nextTick(() => {
          this.imageOverlayLoading = false;
        });
      });

      if (this.currentBounds) {
        this.map.fitBounds(this.currentBounds);
      }
      L.control.scale({ imperial: false }).addTo(this.map);

      this.map.on('click', (event) => {
        this.clickOnMap(event);
      });
      this.initModeControl();

      this.mapControls.layersControl = L.control
        .layers({}, {}, { collapsed: false, hideSingleBase: true })
        .addTo(this.map);
      const layersControlDiv = this.mapControls.layersControl.getContainer();
      layersControlDiv.classList.add('layers-div');
      layersControlDiv.classList.add('leaflet-panel-custom-width');
      const layersControlTitle = document.createElement('p');
      layersControlTitle.className = 'p-0 mb-h font-weight-bold';
      layersControlTitle.innerHTML = `${this.$t('maps.layersControl')}`;
      layersControlDiv.insertBefore(layersControlTitle, layersControlDiv.firstChild);

      this.changeModeSelect();
      // тут перехватываем событие зума карты, чтобы менять радиус пузырей, вместе с зумом
      // и сохранять значение зума в local storage
      this.map.on('zoom', (event) => {
        this.mapZoomLevelChange(event);
        // console.log('map zoomed');
        if (this.isSaveMapZoomAndCenteConditionsAreSatisfied) {
          this.saveMapZoomAndCenterValuesToLocalStorage(
            this.map.getZoom(),
            this.currentMapData.id,
            this.map.getCenter().lat,
            this.map.getCenter().lng
          );
        }
      });
      // а тут перехватываем событие когда карта двигается, чтобы сохранять масштаб и координаты центра
      this.map.on('moveend', (event) => {
        // console.log('map moved');
        if (this.isSaveMapZoomAndCenteConditionsAreSatisfied) {
          this.saveMapZoomAndCenterValuesToLocalStorage(
            this.map.getZoom(),
            this.currentMapData.id,
            this.map.getCenter().lat,
            this.map.getCenter().lng
          );
        }
      });

      // add fullscreen
      this.map.addControl(new L.Control.Fullscreen({ position: 'bottomright' }));

      // add zoom
      L.control
        .zoom({
          position: 'bottomright'
        })
        .addTo(this.map);

      this.initFitBoundsControl();
      this.startMapZoom = this.map.getZoom();
      // add CPEs
      // this.addCpesOnMap();
      this.refreshMapCpes();
      // add clients
      if (this.mapMode.show) {
        // устанавливаем режим показа последних точек клиентов
        // this.showMode.clientsLastPathPoints = true;
        // this.initShowModeControl();
        // this.getClientsOnMap();

        this.initShowModeControl();
        // часть отключения показ посетителей по умолчанию когда показываем статистику
        if (!this.showCPEsStatisticForUser) {
          this.getClientsOnMap();
          this.showMode.clientsLastPathPoints = true;
          // показываем чекбокс бля включение режима фильтрации ночных посетителей
          const switcherForNightClients = document.getElementById('switcher-for-night-clients-filtering');
          if (switcherForNightClients) {
            // для настроек времени работы
            switcherForNightClients.style = 'display: list-item';
          }
          // console.log('blublbulbu')
        }
        // конец части отключения показ посетителей по умолчанию когда показываем статистику точки
      }
      // add Zones
      this.zonesLayers = false;
      this.zonesLayersAsArray = [];
      // this.addZonesOnMap();
      if (this.isShowingZonesFunctionality) {
        this.addZonesOnMap();
      }

      // восстанавливаем сохраненный масштаб и центровку карты
      if (this.isRestoreOfMapZoomAndCenterEnabled) {
        // console.log('restore')
        this.restoreMapZoomAndCenter(); // восстанавливаем зум и (если можем) центр карты
      }
    },
    initFitBoundsControl() {
      this.mapControls.fitBoundsControl = L.control({ position: 'bottomright' });
      this.mapControls.fitBoundsControl.onAdd = () => {
        const fitBoundsButton = L.DomUtil.create('div', 'leaflet-control-fit-bounds leaflet-bar');
        const fitBoundsButtonLink = document.createElement('a');
        fitBoundsButton.appendChild(fitBoundsButtonLink);

        fitBoundsButtonLink.setAttribute('href', '#');
        fitBoundsButtonLink.innerHTML = '<i class="fa fa-compress"></i>';
        fitBoundsButton.addEventListener('click', (event) => {
          event.preventDefault();
          this.fitMap();
        });
        return fitBoundsButton;
      };

      this.mapControls.fitBoundsControl.addTo(this.map);
    },
    initModeControl() {
      this.mapControls.modeControl = L.control({ position: 'topleft' });

      this.mapControls.modeControl.onAdd = () => {
        const modeControlСontainer = L.DomUtil.create('div', 'leaflet-mode-control');
        const modeControlSelect = document.createElement('select');
        modeControlСontainer.appendChild(modeControlSelect);

        modeControlSelect.className = 'leaflet-mode-control-select form-control form-control-sm';
        if (!this.isDisable) {
          if (this.isShowingZonesFunctionality) {
            modeControlSelect.innerHTML = `
                                                   <option value="show"><i class="fa fa-globe"></i> ${this.$t(
    'maps.showMode'
  )}</option>
                                                   <option value="planning"><i class="fa fa-map-pin"></i> ${this.$t(
    'maps.planingMode'
  )}</option>
                                                   <option value="scale"><i class="fa fa-arrows-h"></i> ${this.$t(
    'maps.editScaleMode'
  )}</option>                                      <option value="zones"><i class="fa fa-arrows-h"></i> ${this.$t(
  'maps.zoneEditMode'
)}</option>
                                                   `;
          } else {
            modeControlSelect.innerHTML = `
                                                   <option value="show"><i class="fa fa-globe"></i> ${this.$t(
    'maps.showMode'
  )}</option>
                                                   <option value="planning"><i class="fa fa-map-pin"></i> ${this.$t(
    'maps.planingMode'
  )}</option>
                                                   <option value="scale"><i class="fa fa-arrows-h"></i> ${this.$t(
    'maps.editScaleMode'
  )}</option>
                                                   `;
          }
          //           modeControlSelect.innerHTML = `
          //                                                    <option value="show"><i class="fa fa-globe"></i> ${this.$t(
          //     'maps.showMode'
          //   )}</option>
          //                                                    <option value="planning"><i class="fa fa-map-pin"></i> ${this.$t(
          //     'maps.planingMode'
          //   )}</option>
          //                                                    <option value="scale"><i class="fa fa-arrows-h"></i> ${this.$t(
          //     'maps.editScaleMode'
          //   )}</option>                                      <option value="zones"><i class="fa fa-arrows-h"></i> ${this.$t(
          //   'maps.zoneEditMode'
          // )}</option>
          //                                                    `;
        } else {
          modeControlSelect.innerHTML = `
                                                   <option value="show"><i class="fa fa-globe"></i> ${this.$t(
    'maps.showMode'
  )}</option>
                                                   `;
        }

        modeControlSelect.addEventListener('change', (event) => {
          this.toggleMode(event.target.value);
        });

        modeControlSelect.addEventListener('click', (event) => {
          event.stopPropagation();
        });

        return modeControlСontainer;
      };
      this.mapControls.modeControl.addTo(this.map);
    },
    initZonesEditMode() {
      this.drawZonesEditMode = false;
      this.drawZonesDeleteMode = false;
      this.clearEditingZoneAndCloseBlock();
      L.drawLocal.draw.toolbar.buttons.polygon = 'Draw a zone';
      L.drawLocal.edit.toolbar.buttons.edit = 'Edit zones';
      L.drawLocal.edit.toolbar.buttons.remove = 'Delete zones';
      L.drawLocal.draw.handlers.polyline.error = 'Zone edges cannot cross';
      if (this.$i18n.locale === 'ru') {
        L.drawLocal.draw.toolbar.buttons.polygon = 'Нарисовать зону';
        L.drawLocal.draw.toolbar.actions.text = 'Отменить';
        L.drawLocal.draw.toolbar.actions.title = 'Отменить рисование';
        L.drawLocal.draw.toolbar.finish.text = 'Закончить';
        L.drawLocal.draw.toolbar.finish.title = 'Закончить рисование';
        L.drawLocal.draw.toolbar.undo.text = 'Удалить последнюю точку';
        L.drawLocal.draw.toolbar.undo.title = 'Удалить последнюю точку';
        L.drawLocal.draw.handlers.polygon.tooltip.cont = 'Кликните чтобы продолжить рисование';
        L.drawLocal.draw.handlers.polygon.tooltip.end = 'Кликните в первую точку чтобы закончить рисование';
        L.drawLocal.draw.handlers.polygon.tooltip.start = 'Кликните чтобы начать рисование';
        L.drawLocal.edit.toolbar.buttons.edit = 'Редактировать зоны';
        L.drawLocal.edit.toolbar.buttons.editDisabled = ' Нет зон для редактирования';
        L.drawLocal.edit.toolbar.buttons.remove = 'Удалить зоны';
        L.drawLocal.edit.toolbar.buttons.removeDisabled = 'Нет зон для удаления';
        L.drawLocal.edit.handlers.edit.tooltip.subtext = 'Кликните Отменить чтобы отменить изменения';
        L.drawLocal.edit.handlers.edit.tooltip.text = 'Передвигайте точки чтобы редактировать зону';
        L.drawLocal.edit.handlers.remove.tooltip.text = 'Кликните на зону чтобы её удалить';
        L.drawLocal.edit.toolbar.actions.cancel.text = 'Отменить';
        L.drawLocal.edit.toolbar.actions.cancel.title = 'Отменить редактирование';
        L.drawLocal.edit.toolbar.actions.clearAll.text = 'Удалить все';
        L.drawLocal.edit.toolbar.actions.clearAll.title = 'Удалить все зоны';
        L.drawLocal.edit.toolbar.actions.save.text = 'Сохранить';
        L.drawLocal.edit.toolbar.actions.save.title = 'Сохранить изменения';
        L.drawLocal.draw.handlers.polyline.error = 'Стороны зоны не могут пересекаться';
      }

      if (this.zonesLayers === false) {
        this.zonesLayers = new L.FeatureGroup();
      }
      this.map.addLayer(this.zonesLayers);
      //               let polygon1 = L.polygon([
      // 		[12.086, 8.2423],
      // 		[12.2744, 21.5580],
      // 		[8.7119, 20.9328],
      //                 [9.8369, 9.11756]
      // ]);
      //   this.zonesLayers.addLayer(polygon1)

      // тут удаляем кнопку удаления ВСЕХ зон
      L.EditToolbar.Delete.include({
        removeAllLayers: false
      });
      this.zonesDrawButtons = new L.Control.Draw({
        position: 'topleft',
        draw: {
          polyline: false,
          polygon: {
            allowIntersection: false,
            showArea: false,
            drawError: {
              color: '#b00b00',
              timeout: 1500
            },
            shapeOptions: {
              color: '#bada55'
            }
          },
          marker: false,
          circlemarker: false,
          circle: false,
          rectangle: false
        },
        edit: {
          featureGroup: this.zonesLayers,
          remove: true
        }
      });
      this.map.addControl(this.zonesDrawButtons);

      // очищаем все обработчики эвентов после прошлой инициализации (если есть)
      // иначе если пару раз шелкнуть селектором режима, то при добавлении зоны запросы
      // отправлялись дважды
      if (this.map._events.hasOwnProperty('draw:created')) {
        delete this.map._events['draw:created'];
      }
      if (this.map._events.hasOwnProperty('draw:edited')) {
        delete this.map._events['draw:edited'];
      }
      if (this.map._events.hasOwnProperty('draw:deleted')) {
        delete this.map._events['draw:deleted'];
      }
      if (this.map._events.hasOwnProperty('draw:drawstop')) {
        delete this.map._events['draw:drawstop'];
      }
      if (this.map._events.hasOwnProperty('draw:drawstop')) {
        delete this.map._events['draw:drawstop'];
      }
      if (this.map._events.hasOwnProperty('draw:drawstart')) {
        delete this.map._events['draw:drawstart'];
      }
      if (this.map._events.hasOwnProperty('draw:editstart')) {
        delete this.map._events['draw:editstart'];
      }
      if (this.map._events.hasOwnProperty('draw:editstop')) {
        delete this.map._events['draw:editstop'];
      }
      if (this.map._events.hasOwnProperty('draw:deletestart')) {
        delete this.map._events['draw:deletestart'];
      }
      if (this.map._events.hasOwnProperty('draw:deletestop')) {
        delete this.map._events['draw:deletestop'];
      }

      this.map.on('draw:created', (e) => {
        // console.log(e);
        const { layer } = e;
        // округляем до 3 знаков после точки координаты
        this.roundZoneCornersCoordinates(layer._latlngs[0]);
        layer.name = this.zonesAddNewName;
        layer.identity = '';
        if (this.errors.has('newZoneName') || this.zonesAddNewName.length == 0) {
          this.showNameErrorWhenZoneCreating = true;
          // console.log('validation error')
          return;
        }
        // проверяем есть ли вобще какие-то существующие слои с зонами
        if (this.zonesLayers._layers && Object.keys(this.zonesLayers._layers).length) {
          // проверяем пересекается ли этот созданный слой зоны со всеми уже существующими слоями зон
          for (const existingZoneLayerKey in this.zonesLayers._layers) {
            const existingZoneLayer = this.zonesLayers._layers[existingZoneLayerKey];
            if (this.intersectionPolygonLayersCheck(layer, existingZoneLayer)) {
              // тут обработка в случае если создаваемая зона пересекатеся с существующими
              // console.log('пересечение')
              this.showIntersectionErrorWhenZoneCreating = true;
              return;
            }
          }
        }
        //
        // layer.on('click', e => {
        //   e.target.name = e.target.name + '1';
        //   e.target.closeTooltip().openTooltip();
        //   this.createZonesAsArray();
        // })
        layer.on('click', (e) => {
          if (this.mapMode.zones) {
            if (this.drawZonesEditMode === false && this.drawZonesDeleteMode === false) {
              this.editZone(e.target);
            }
          }
        });
        // делаем тултип, выводящий имя зоны в ее середине
        layer.bindTooltip(
          (layerObj) => '<div class=".zones-on-heatmap-tooltip">' +
            `<div>${this.$t('maps.zoneNameCaptionInZoneTooltip')}: ${layerObj.name}</div>` +
            '</div>',
          {
            permanent: false,
            direction: 'center'
          }
        );
        this.zonesLayers.addLayer(layer);
        // убираем отображение окошка для ввода имени зоны и обнуляем имя
        this.showZoneAddNew = false;
        this.zonesAddNewName = '';
        this.createZonesAsArray();
        mapsService.zonesUpdate(this, true);
      });
      // при сохранении отредактированного
      this.map.on('draw:edited', (e) => {
        let haveZonesIntersections = false;
        const { layers } = e;
        // let countOfEditedLayers = 0;
        // округляем до 3 знаков после точки координаты
        layers.eachLayer((layer) => {
          this.roundZoneCornersCoordinates(layer._latlngs[0]);
          // console.log(layer['_latlngs'][0]);
          // countOfEditedLayers++;
        });
        // далее начинаем проверку на пересечение полигонов зон
        layers.eachLayer((layer) => {
          for (const existingZoneLayerKey in this.zonesLayers._layers) {
            // если объект измененного слоя и объект слоя на карте - один объект то не сравниваем
            if (this.zonesLayers._layers[existingZoneLayerKey] !== layer) {
              const existingZoneLayer = this.zonesLayers._layers[existingZoneLayerKey];
              if (this.intersectionPolygonLayersCheck(layer, existingZoneLayer)) {
                // если зоны пересекаются
                haveZonesIntersections = true;
                this.cancelZonesEditMode();
                this.showIntersectionErrorWhenZoneCreating = true;
                return;
              }
            }
          }
        });

        // console.log("Edited " + countOfEditedLayers + " layers");
        if (!haveZonesIntersections) {
          this.createZonesAsArray();
          mapsService.zonesUpdate(this, true);
        }
      });
      // при удалении
      this.map.on('draw:deleted', (e) => {
        this.createZonesAsArray();
        mapsService.zonesUpdate(this, true);
      });
      // окончание рисования новой зоны
      this.map.on('draw:drawstop', (e) => {
        this.zonesAddNewName = '';
        this.showZoneAddNew = false;
      });
      // нажатие на кнопку нарисовать зону
      this.map.on('draw:drawstart', (e) => {
        this.showZonesLayerWhenClickZonesEditButtons();
        this.clearEditingZoneAndCloseBlock();
        this.zonesAddNewName = '';
        this.showZoneAddNew = true;
        this.$nextTick(() => {
          document.getElementById('zones-add-new-name').focus();
        });
      });
      // Перевод в режим редактирования зон (кнопка Edit)
      this.map.on('draw:editstart', (e) => {
        this.showZonesLayerWhenClickZonesEditButtons();
        this.clearEditingZoneAndCloseBlock();
        this.drawZonesEditMode = true;
      });
      // Выход из режима редактирования зон
      this.map.on('draw:editstop', (e) => {
        this.clearEditingZoneAndCloseBlock();
        this.drawZonesEditMode = false;
      });
      // Перевод в режим удаления зон (кнопка Delete)
      this.map.on('draw:deletestart', (e) => {
        this.showZonesLayerWhenClickZonesEditButtons();
        this.clearEditingZoneAndCloseBlock();
        this.drawZonesDeleteMode = true;
      });
      // Выход из режима удаления зон
      this.map.on('draw:deletestop', (e) => {
        this.clearEditingZoneAndCloseBlock();
        this.drawZonesDeleteMode = false;
      });

      // document.getElementsByClassName('leaflet-draw-draw-polygon')[0].onclick = () => {
      //   this.zonesAddNewName = '';
      //   this.showZoneAddNew = true;
      // };
    },
    initShowModeControl() {
      this.mapControls.showModeControl = L.control({ position: 'topright' });

      this.mapControls.showModeControl.onAdd = () => {
        /* TODO direct DOM manipulation is a bad idea */
        const showModeControlContainer = L.DomUtil.create(
          'div',
          'leaflet-show-mode-control leaflet-panel-custom-width'
        );

        showModeControlContainer.addEventListener('dbclick', (event) => {
          event.stopPropagation();
          event.preventDefault();
        });
        // const showInfo = document.createElement('p');
        // showInfo.className = 'm-0 p-0 font-weight-bold';
        // showInfo.innerHTML = 'Map info';
        //
        // const showInfoList = document.createElement('ul');
        // showInfoList.className = 'list-unstyled mb-h p-0';
        //
        // showModeControlContainer.appendChild(showInfo);
        // showModeControlContainer.appendChild(showInfoList);
        //
        // const nameItem = document.createElement('li');
        // nameItem.className = 'pl-q pb-0';
        // nameItem.innerHTML = `<strong>Name:</strong> ${this.currentMap.name}`;
        // showInfoList.appendChild(nameItem);
        // const locationItem = document.createElement('li');
        // locationItem.className = 'pl-q';
        // locationItem.innerHTML = `<strong>Location</strong>: ${this.locationName(this.currentMap.base_location)}`;
        // showInfoList.appendChild(locationItem);
        //
        // const hr = document.createElement('hr');
        // hr.className = 'mb-h p-0';
        //
        // showModeControlContainer.appendChild(hr);

        const refreshControlList = document.createElement('ul');
        refreshControlList.className = 'list-unstyled m-0 p-0';
        showModeControlContainer.appendChild(refreshControlList);

        const refreshItem = document.createElement('li');
        refreshControlList.appendChild(refreshItem);
        const refreshLink = document.createElement('button');
        // heatmapLink.setAttribute('href', '#');
        refreshLink.className = 'btn btn-block btn-sm btn-outline-primary';
        refreshLink.innerHTML = `<i class="fa fa-refresh"></i> <small>${this.$t('maps.refresh')}</small>`;
        refreshLink.id = 'refresh-button-in-menu';
        refreshLink.addEventListener('click', (event) => {
          // this.refreshClients();
          this.refreshV2(true);
          event.preventDefault();
        });
        refreshItem.appendChild(refreshLink);

        // const heatMapControlList = document.createElement('ul');
        // heatMapControlList.className = 'list-unstyled m-0 p-0';
        // showModeControlContainer.appendChild(heatMapControlList);
        //
        // const heatmapItem = document.createElement('li');
        // heatMapControlList.appendChild(heatmapItem);
        // const heatmapLink = document.createElement('button');
        // // heatmapLink.setAttribute('href', '#');
        // heatmapLink.className = 'btn btn-block btn-sm btn-outline-danger heatmap-btn';
        // heatmapLink.innerHTML = `<i class="fa fa-fire"></i> <small>${this.$t('maps.heatmap')}</small>`;
        // heatmapLink.addEventListener('click', (event) => {
        //   event.preventDefault();
        //   heatmapLink.classList.toggle('active');
        //   this.toggleHeatmap();
        // });
        // heatmapItem.appendChild(heatmapLink);

        // const countersTitle = document.createElement('p');
        // countersTitle.className = 'm-0 p-0 font-weight-bold';
        // countersTitle.innerHTML = `${this.$t('maps.countersTitle')}`;
        // showModeControlContainer.appendChild(countersTitle);

        const countersControlList = document.createElement('ul');
        countersControlList.className = 'list-unstyled m-0 p-0';
        showModeControlContainer.appendChild(countersControlList);

        const clientsItem = document.createElement('li');
        clientsItem.className = 'pl-q leaflet-current-clients';
        clientsItem.innerHTML = `${this.$t('maps.Clients')}`;
        countersControlList.appendChild(clientsItem);

        // часть отключения показ посетителей по умолчанию когда показываем статистику
        if (this.showCPEsStatisticForUser) {
          clientsItem.style = 'display: none';
        }
        // конец части отключения показ посетителей по умолчанию когда показываем статистику точки

        const passerbyItemCounter = document.createElement('li');
        passerbyItemCounter.className = 'pl-q leaflet-current-passerby-counter';
        passerbyItemCounter.innerHTML = `${this.$t('maps.passerbyCounterTitle')}`;
        passerbyItemCounter.style = 'display: none';
        countersControlList.appendChild(passerbyItemCounter);

        const connectedItemCounter = document.createElement('li');
        connectedItemCounter.className = 'pl-q leaflet-current-connected-counter';
        connectedItemCounter.innerHTML = `${this.$t('maps.connectedCounterTitle')}`;
        connectedItemCounter.style = 'display: none';
        countersControlList.appendChild(connectedItemCounter);

        const trafficItemCounter = document.createElement('li');
        trafficItemCounter.className = 'pl-q leaflet-current-traffic-counter';
        trafficItemCounter.innerHTML = `${this.$t('maps.trafficCounterTitle')}`;
        trafficItemCounter.style = 'display: none';
        countersControlList.appendChild(trafficItemCounter);

        // добавляем выключать фильтрации ночных пользователей
        const nightClientsSwitcherItem = document.createElement('li');
        nightClientsSwitcherItem.id = 'switcher-for-night-clients-filtering';
        nightClientsSwitcherItem.style = 'display: none';
        if (this.isAllFieldsForFilteringNightClientsFilled) {
          countersControlList.appendChild(nightClientsSwitcherItem);
        }

        const nightClientsSwitcherWrapper = document.createElement('div');
        nightClientsSwitcherWrapper.classList.add('wrapper-for-night-clients-switcher');
        const nightClientsSwitcher = document.createElement('input');
        nightClientsSwitcher.id = 'night-clients-switcher-checkbox';
        nightClientsSwitcher.class = 'leaflet-control-layers-selector';
        nightClientsSwitcher.type = 'checkbox';
        nightClientsSwitcher.checked = this.addToRequestsWithOutNightClientsSettings;
        nightClientsSwitcher.addEventListener('click', (event) => {
          // event.preventDefault();
          this.toggleAddToRequestsWithOutNightClientsSettings();
          this.resetClientsLayer();
          this.resetBubble();
          this.refreshV2();
          // this.addClientsOnMap();
          this.addClientsOnMapFromAPIV2();
          this.showCounter('clients');
        });
        const nightClientsSwitcherText = document.createElement('span');
        nightClientsSwitcherText.innerHTML = ` ${this.$t('maps.filteringNightClientsCaption')}`;
        nightClientsSwitcherText.classList.add('nightClientsSwitcherText');
        nightClientsSwitcherText.role = 'button';
        // nightClientsSwitcherText.id = 'nightClientsSwitcherText';
        nightClientsSwitcherText.addEventListener('click', (event) => {
          event.preventDefault();
          event.stopPropagation();
          this.showInfoModalAboutWorkTimeFiltering();
        });

        nightClientsSwitcherItem.appendChild(nightClientsSwitcherWrapper);
        nightClientsSwitcherWrapper.appendChild(nightClientsSwitcher);
        nightClientsSwitcherWrapper.appendChild(nightClientsSwitcherText);

        const heatMapControlList = document.createElement('ul');
        heatMapControlList.className = 'list-unstyled m-0 p-0';
        showModeControlContainer.appendChild(heatMapControlList);

        const heatmapItem = document.createElement('li');
        heatMapControlList.appendChild(heatmapItem);
        const heatmapLink = document.createElement('button');
        // heatmapLink.setAttribute('href', '#');
        heatmapLink.id = 'heatmap-button-in-menu';
        heatmapLink.className = 'btn btn-block btn-sm btn-outline-danger heatmap-btn';
        heatmapLink.innerHTML = `<i class="fa fa-fire"></i> <small>${this.$t('maps.heatmap')}</small>`;
        heatmapLink.addEventListener('click', (event) => {
          event.preventDefault();
          if (this.showMode.clientsLastPathPoints === true) {
            // heatmapLink.classList.toggle('active');
            this.toggleHeatmap(heatmapLink);
          }
        });
        heatmapItem.appendChild(heatmapLink);

        // const showActions = document.createElement('p');
        // showActions.className = 'm-0 p-0 font-weight-bold';
        // showActions.innerHTML = `${this.$t('maps.mapActions')}`;
        //
        // showModeControlContainer.appendChild(showActions);

        const showActions = document.createElement('p');
        showActions.className = 'm-0 p-0 font-weight-bold';
        showActions.innerHTML = `${this.$t('maps.statisticsTitle')}`;

        showModeControlContainer.appendChild(showActions);

        const showModeControlList = document.createElement('ul');
        showModeControlList.className = 'list-unstyled m-0 p-0';
        showModeControlContainer.appendChild(showModeControlList);

        showModeControlContainer.addEventListener('dbclick', (event) => {
          event.stopPropagation();
        });

        // const refreshItem = document.createElement('li');
        // showModeControlList.appendChild(refreshItem);
        // const refreshLink = document.createElement('button');
        // // heatmapLink.setAttribute('href', '#');
        // refreshLink.className = 'btn btn-block btn-sm btn-outline-primary';
        // refreshLink.innerHTML = `<i class="fa fa-refresh"></i> <small>${this.$t('maps.refresh')}</small>`;
        // refreshLink.addEventListener('click', (event) => {
        //   this.refreshClients();
        //   event.preventDefault();
        // });
        // refreshItem.appendChild(refreshLink);

        // const clientsItem = document.createElement('li');
        // clientsItem.className = 'pl-q leaflet-current-clients';
        // clientsItem.innerHTML = `${this.$t('maps.Clients')}`;
        // showModeControlList.appendChild(clientsItem);

        // const heatmapItem = document.createElement('li');
        // showModeControlList.appendChild(heatmapItem);
        // const heatmapLink = document.createElement('button');
        // // heatmapLink.setAttribute('href', '#');
        // heatmapLink.className = 'btn btn-block btn-sm btn-outline-danger heatmap-btn';
        // heatmapLink.innerHTML = `<i class="fa fa-fire"></i> <small>${this.$t('maps.heatmap')}</small>`;
        // heatmapLink.addEventListener('click', (event) => {
        //   event.preventDefault();
        //   heatmapLink.classList.toggle('active');
        //   this.toggleHeatmap();
        // });
        // heatmapItem.appendChild(heatmapLink);

        // const timelineItem = document.createElement('li');
        // showModeControlList.appendChild(timelineItem);
        // const timelineLink = document.createElement('button');
        // timelineLink.className = 'btn btn-block btn-sm btn-outline-success heatmap-btn';
        // timelineLink.innerHTML = `<i class="fa fa-clock-o"></i> <small>${this.$t('maps.timeline')}</small>`;
        // timelineLink.addEventListener('click', (event) => {
        //   event.preventDefault();
        //   timelineLink.classList.toggle('active');
        //   this.toggleTimeline();
        // });
        // timelineItem.appendChild(timelineLink);

        // const clientsByCpesItem = document.createElement('li');
        // clientsByCpesItem.className = 'pl-q mt-q';
        // clientsByCpesItem.innerHTML = `${this.$t('maps.byCPEs')}`;
        // clientsByCpesItem.id = 'hide-on-timeline';
        // showModeControlList.appendChild(clientsByCpesItem);

        const clientPathLastPointItem = document.createElement('li');
        // clientPathLastPointItem.id = 'hide-on-timeline';
        showModeControlList.appendChild(clientPathLastPointItem);
        const clientPathLastPointLink = document.createElement('button');
        clientPathLastPointLink.classList.add('client-path-last-point-link');
        clientPathLastPointLink.classList.add('client-path-last-point-link');
        // clientPathLastPointLink.className = 'btn btn-sm btn-block btn-outline-warning client-path-last-point-btn active';

        // часть отключения показ посетителей по умолчанию когда показываем статистику
        if (this.showCPEsStatisticForUser) {
          clientPathLastPointLink.className = 'btn btn-sm btn-block btn-outline-warning client-path-last-point-btn ';
          heatmapLink.classList.remove('active');
          heatmapLink.classList.add('disabled');
          heatmapLink.style = 'display: none';
        } else {
          clientPathLastPointLink.className =
            'btn btn-sm btn-block btn-outline-warning client-path-last-point-btn active';
        }
        // конец части отключения показ посетителей по умолчанию когда показываем статистику точки

        // passerbyLink.setAttribute('href', '#');
        clientPathLastPointLink.innerHTML = `<small>${this.$t('maps.clientCoordsButton')}</small>`;
        clientPathLastPointLink.title = `${this.$t('maps.clientCoordsHint')}`;
        clientPathLastPointItem.appendChild(clientPathLastPointLink);

        const passerbyItem = document.createElement('li');
        passerbyItem.id = 'hide-on-timeline';
        showModeControlList.appendChild(passerbyItem);
        const passerbyLink = document.createElement('button');
        passerbyLink.classList.add('passerby-link');
        passerbyLink.classList.add('passerby-link');
        passerbyLink.className = 'btn btn-sm btn-block btn-outline-warning passerby-btn';
        // passerbyLink.setAttribute('href', '#');
        passerbyLink.innerHTML = `<small>${this.$t('maps.passerby')}</small>`;
        passerbyLink.title = `${this.$t('maps.passerbyHint')}`;
        passerbyItem.appendChild(passerbyLink);

        const connectedItem = document.createElement('li');
        connectedItem.id = 'hide-on-timeline';
        showModeControlList.appendChild(connectedItem);
        const connectedLink = document.createElement('button');
        // connectedLink.setAttribute('href', '#');
        connectedLink.classList.add('connected-link');
        connectedLink.className = 'btn btn-sm btn-block btn-outline-success connected-btn';
        connectedLink.innerHTML = `<small>${this.$t('maps.connected')}</small>`;
        connectedLink.title = `${this.$t('maps.connectedHint')}`;
        connectedItem.appendChild(connectedLink);

        const trafficItem = document.createElement('li');
        trafficItem.id = 'hide-on-timeline';
        showModeControlList.appendChild(trafficItem);
        const trafficLink = document.createElement('button');
        // trafficLink.setAttribute('href', '#');
        trafficLink.classList.add('traffic-link');
        trafficLink.className = 'btn btn-sm btn-block btn-outline-info traffic-btn';
        trafficLink.innerHTML = `<small>${this.$t('maps.traffic')}</small>`;
        trafficLink.title = `${this.$t('maps.trafficHint')}`;
        trafficItem.appendChild(trafficLink);

        clientPathLastPointLink.addEventListener('click', (event) => {
          // console.log(clientPathLastPointLink.classList)
          event.preventDefault();
          // часть отключения показ посетителей по умолчанию когда показываем статистику
          if (this.showCPEsStatisticForUser && clientPathLastPointLink.classList.contains('active')) {
            clientPathLastPointLink.classList.remove('active');
            this.resetClientsLayer();
            this.resetBubble();
            this.showCounter('none');
            heatmapLink.classList.remove('active');
            heatmapLink.classList.add('disabled');
            heatmapLink.style = 'display: none';
            this.showMode.clientsLastPathPoints = false;
            this.hideDynamicPartitionInMenu();
            // this.hideRefreshButtonInMenu();
            this.resetHeatmap();
            return;
          }
          // this.showRefreshButtonInMenu();
          this.showDynamicPartitionInMenu();
          // конец части отключения показ посетителей по умолчанию когда показываем статистику точки
          trafficLink.classList.remove('active');
          connectedLink.classList.remove('active');
          passerbyLink.classList.remove('active');
          clientPathLastPointLink.classList.remove('active');
          clientPathLastPointLink.classList.add('active');
          heatmapLink.classList.remove('disabled');
          heatmapLink.style = 'display: block';
          this.showMode.clientsLastPathPoints = true;
          this.showMode.timeline = false;
          timelineLink.classList.remove('active');
          this.resetClientsLayer();
          this.resetBubble();
          this.refreshV2();
          // this.addClientsOnMap();
          this.addClientsOnMapFromAPIV2();
          this.showCounter('clients');
        });

        passerbyLink.addEventListener('click', (event) => {
          event.preventDefault();
          // часть отключения показ посетителей по умолчанию когда показываем статистику
          if (this.showCPEsStatisticForUser && passerbyLink.classList.contains('active')) {
            passerbyLink.classList.remove('active');
            this.resetClientsLayer();
            this.resetBubble();
            this.showCounter('none');
            this.hideDynamicPartitionInMenu();
            // this.hideRefreshButtonInMenu();
            return;
          }
          this.showDynamicPartitionInMenu();
          // this.showRefreshButtonInMenu();
          // конец части отключения показ посетителей по умолчанию когда показываем статистику точки
          this.resetBubble();
          trafficLink.classList.remove('active');
          connectedLink.classList.remove('active');
          passerbyLink.classList.remove('active');
          passerbyLink.classList.add('active');
          clientPathLastPointLink.classList.remove('active');
          this.showMode.timeline = false;
          timelineLink.classList.remove('active');
          heatmapLink.classList.remove('active');
          heatmapLink.classList.add('disabled');
          heatmapLink.style = 'display: none';
          this.resetHeatmap();
          this.toggleBubble('bubble_lbs');
          this.showCounter('passerby');
        });

        connectedLink.addEventListener('click', (event) => {
          event.preventDefault();
          // часть отключения показ посетителей по умолчанию когда показываем статистику
          if (this.showCPEsStatisticForUser && connectedLink.classList.contains('active')) {
            connectedLink.classList.remove('active');
            this.resetClientsLayer();
            this.resetBubble();
            this.showCounter('none');
            this.hideDynamicPartitionInMenu();
            // this.hideRefreshButtonInMenu();
            return;
          }
          this.showDynamicPartitionInMenu();
          // this.showRefreshButtonInMenu();
          // конец части отключения показ посетителей по умолчанию когда показываем статистику точки
          this.resetBubble();
          trafficLink.classList.remove('active');
          connectedLink.classList.remove('active');
          connectedLink.classList.add('active');
          passerbyLink.classList.remove('active');
          clientPathLastPointLink.classList.remove('active');
          this.showMode.timeline = false;
          timelineLink.classList.remove('active');
          heatmapLink.classList.remove('active');
          heatmapLink.classList.add('disabled');
          heatmapLink.style = 'display: none';
          this.resetHeatmap();
          this.toggleBubble('bubble_clients');
          this.showCounter('connected');
        });

        trafficLink.addEventListener('click', (event) => {
          event.preventDefault();
          // часть отключения показ посетителей по умолчанию когда показываем статистику
          if (this.showCPEsStatisticForUser && trafficLink.classList.contains('active')) {
            trafficLink.classList.remove('active');
            this.resetClientsLayer();
            this.resetBubble();
            this.showCounter('none');
            this.hideDynamicPartitionInMenu();
            // this.hideRefreshButtonInMenu();
            return;
          }
          this.showDynamicPartitionInMenu();
          // this.showRefreshButtonInMenu();
          // конец части отключения показ посетителей по умолчанию когда показываем статистику точки
          this.resetBubble();
          trafficLink.classList.remove('active');
          trafficLink.classList.add('active');
          connectedLink.classList.remove('active');
          passerbyLink.classList.remove('active');
          clientPathLastPointLink.classList.remove('active');
          this.showMode.timeline = false;
          timelineLink.classList.remove('active');
          heatmapLink.classList.remove('active');
          heatmapLink.classList.add('disabled');
          heatmapLink.style = 'display: none';
          this.resetHeatmap();
          this.toggleBubble('bubble_traffic');
          this.showCounter('traffic');
        });

        const showDynamics = document.createElement('p');
        showDynamics.className = 'm-0 p-0 font-weight-bold';
        showDynamics.innerHTML = `${this.$t('maps.dynamicsTitle')}`;
        showModeControlContainer.appendChild(showDynamics);
        showDynamics.id = 'dynamics-caption';

        const showDynamicslList = document.createElement('ul');
        showDynamicslList.className = 'list-unstyled m-0 p-0';
        showModeControlContainer.appendChild(showDynamicslList);

        const timelineItem = document.createElement('li');
        showDynamicslList.appendChild(timelineItem);
        const timelineLink = document.createElement('button');
        timelineLink.id = 'dynamics-button';
        timelineLink.className = 'btn btn-block btn-sm btn-outline-success heatmap-btn';
        timelineLink.innerHTML = `<i class="fa fa-clock-o"></i> <small>${this.$t('maps.timeline')}</small>`;
        timelineLink.addEventListener('click', (event) => {
          event.preventDefault();
          timelineLink.classList.toggle('active');
          this.toggleTimelineV2();
        });
        timelineItem.appendChild(timelineLink);

        Vue.nextTick(() => {
          // часть отключения показ посетителей по умолчанию когда показываем статистику
          if (this.showCPEsStatisticForUser) {
            this.hideDynamicPartitionInMenu();
            // this.hideRefreshButtonInMenu();
          }
          // конец части отключения показ посетителей по умолчанию когда показываем статистику точки

          // const dateItem = document.createElement('li');
          // dateItem.className = 'pl-q';
          // dateItem.innerHTML = 'Date:'
          //
          // const drPickerItem = document.createElement('li');
          // showModeControlList.appendChild(drPickerItem);
          // const drPicker = document.querySelector('.dr-picker');
          // drPicker.classList.add('btn-block');
          // const drPickerRange = document.createElement('div');
          // drPickerRange.className = 'filter-date';
          // // drPickerRange.innerHTML = `<small class="text-muted"> ${ moment(this.dateRange.startDate).format('D MMM YY, H:mm') }</small>
          // //                            <small class="text-muted">${ moment(this.dateRange.endDate).format('D MMM YY, H:mm') }</small>`;
          // drPickerItem.appendChild(drPickerRange);
          // drPickerItem.appendChild(drPicker);
          //
          // showModeControlList.insertBefore(drPickerItem, showModeControlList.firstChild);
          // showModeControlList.insertBefore(dateItem, showModeControlList.firstChild);
        });

        return showModeControlContainer;
      };

      this.mapControls.showModeControl.addTo(this.map);
    },
    initSearchControl() {
      if (this.mapControls.searchControl) {
        this.mapControls.searchControl.remove();
      }
      this.mapControls.searchControl = L.control({ position: 'topleft' });
      this.mapControls.searchControl.onAdd = () => {
        const searchControlDiv = L.DomUtil.create('div', 'leaflet-search-control');

        const searchControlInputGroup = document.createElement('div');
        searchControlInputGroup.className = 'input-group input-group-sm filters__input';
        searchControlInputGroup.innerHTML = `<span class="search-state input-group-addon">
                                                             <i class="fa fa-search"></i>
                                                         </span>`;
        const searchControlInput = document.createElement('input');
        searchControlInput.setAttribute('placeholder', '2cf03c8ba8f1');
        searchControlDiv.setAttribute('title', `${this.$t('maps.serchByClientMAC')}`);
        searchControlInput.setAttribute('title', `${this.$t('maps.serchByClientMAC')}`);
        searchControlInputGroup.setAttribute('title', `${this.$t('maps.serchByClientMAC')}`);
        searchControlInput.className = 'form-control';

        const searchControlClear = document.createElement('span');
        searchControlClear.className = 'clear-input text-muted';
        searchControlClear.innerHTML = '<i data-v-6aef7ba8="" class="fa fa-remove"></i>';

        searchControlInputGroup.appendChild(searchControlInput);
        searchControlDiv.appendChild(searchControlInputGroup);

        searchControlInput.addEventListener('input', (event) => {
          if (event.target.value) {
            searchControlInputGroup.appendChild(searchControlClear);
          } else {
            searchControlInputGroup.removeChild(searchControlClear);
          }
          // event.preventDefault();
          this.resetFoundClient();
          this.searchInputHandler(event.target.value);
        });

        searchControlInput.addEventListener('keypress', (event) => {
          // event.preventDefault();
          const key = event.which || event.keyCode;
          if (key === 13) {
            this.searchInputHandler(event.target.value);
          }
        });

        const searchState = searchControlDiv.querySelector('.search-state');

        searchControlClear.addEventListener('click', (event) => {
          searchControlInput.value = '';
          this.searchInputHandler('');
          searchControlInputGroup.removeChild(searchControlClear);
          event.stopPropagation();
        });

        searchControlInput.addEventListener('dblclick', (event) => {
          event.stopPropagation();
        });

        searchControlInput.addEventListener('focus', (event) => {
          event.stopPropagation();
          searchState.innerHTML = '<i class="fa fa-user"></i>';
        });

        searchControlInput.addEventListener('blur', (event) => {
          event.stopPropagation();
          searchState.innerHTML = '<i class="fa fa-search"></i>';
        });

        // template for selected client
        const selectedClient = document.createElement('div');
        selectedClient.classList.add('leaflet-selected-client');
        selectedClient.classList.add('custom-border-for-client-info');
        searchControlDiv.appendChild(selectedClient);

        return searchControlDiv;
      };

      this.mapControls.searchControl.addTo(this.map);
    },
    initClientsControl() {
      if (this.mapControls.clientsControl) {
        this.mapControls.clientsControl.remove();
      }
      // if (!this.clientsMarker.length) {
      //     return;
      // }

      this.mapControls.clientsControl = L.control({ position: 'topleft' });

      this.mapControls.clientsControl.onAdd = () => {
        const clientsControlDiv = L.DomUtil.create('div', 'leaflet-clients-control');

        clientsControlDiv.innerHTML = `
              <span class="text-muted mb-h pl-h">
                ${
  !this.clientsMarker.length ?
    this.$t('maps.notFound') :
    `${this.$t('maps.found')}: ${this.clientsMarker.length}`
}
              </span>`;
        const foundClients = document.createElement('div');
        clientsControlDiv.appendChild(foundClients);

        this.clientsMarker.forEach((client) => {
          const foundClient = document.createElement('p');
          foundClient.className = 'm-0 pt-q pb-q font-md';
          foundClient.innerHTML = this.returnMaskedOrNotMAC(client.options.mac);

          foundClient.addEventListener('click', () => {
            this.selectClient(client);
          });

          foundClients.appendChild(foundClient);
        });

        return clientsControlDiv;
      };

      this.mapControls.clientsControl.addTo(this.map);
    },
    toggleSearchIcon() {
      const searchControlContainer = this.mapControls.searchControl.getContainer();
      const searchState = searchControlContainer.querySelector('.search-state');

      if (this.searchSpinner) {
        searchState.innerHTML = '<i class="fa fa-spin fa-circle-o-notch text-info"></i>';
      } else {
        searchState.innerHTML = '<i class="fa fa-search"></i>';
      }
    },
    setClientControl(marker) {
      const searchControlContainer = this.mapControls.searchControl.getContainer();
      const searchInput = searchControlContainer.querySelector('input');
      const searchState = searchControlContainer.querySelector('.search-state');

      const selectedClient = document.querySelector('.leaflet-selected-client');
      selectedClient.innerHTML = `<div class="leaflet-selected-client-info">
                                                <p class="p-0 m-0 font-lg">${this.returnMaskedOrNotMAC(
    marker.options.mac
  )}</p>
                                                <ul class="list-unstyled mb-0">
                                                    <li>
                                                    <span class="font-weight-bold">${this.$t(
    'maps.vendor'
  )}:</span> <span class=""> ${marker.options.vendor} </span>
                                                    </li>
                                                      <li>
                                                    <span class="font-weight-bold">${this.$t(
    'maps.lastSeen'
  )}</span> <span class=""> ${moment(
  marker.options.timestamp * 1000
).format(' D MMM YYYY, H:mm ')} </span>
                                                    </li>
                                                </ul>
                                             </div>
                                           `;

      const selectedClientActions = document.createElement('div');
      selectedClientActions.classList.add('leaflet-selected-client-actions');

      selectedClient.appendChild(selectedClientActions);

      const getPathButton = document.createElement('a');
      getPathButton.setAttribute('href', '#');
      getPathButton.innerHTML = `<i class="fa fa-location-arrow"></i> ${this.$t('maps.showPath')}`;
      getPathButton.addEventListener('click', (event) => {
        event.preventDefault();
        this.getPath(marker);
      });

      const closeSelectedButton = document.createElement('a');
      closeSelectedButton.setAttribute('href', '#');
      closeSelectedButton.innerHTML = `<i class="fa fa-close"></i> ${this.$t('maps.close')}`;
      closeSelectedButton.addEventListener('click', (event) => {
        event.preventDefault();
        this.resetFoundClient();
      });

      selectedClientActions.appendChild(getPathButton);
      selectedClientActions.appendChild(closeSelectedButton);
      searchControlContainer.appendChild(selectedClient);
    },
    setClientControlWhenManyClients(client, marker) {
      const searchControlContainer = this.mapControls.searchControl.getContainer();
      const searchInput = searchControlContainer.querySelector('input');
      const searchState = searchControlContainer.querySelector('.search-state');

      const selectedClient = document.querySelector('.leaflet-selected-client');
      selectedClient.innerHTML = `<div class="leaflet-selected-client-info">
                                                <p class="p-0 m-0 font-lg">${this.returnMaskedOrNotMAC(client.mac)}</p>
                                                <ul class="list-unstyled mb-0">
                                                    <li>
                                                    <span class="font-weight-bold">${this.$t(
    'maps.vendor'
  )}:</span> <span class=""> ${client.vendor} </span>
                                                    </li>
                                                      <li>
                                                    <span class="font-weight-bold">${this.$t(
    'maps.lastSeen'
  )}</span> <span class=""> ${moment(client.timestamp * 1000).format(
  ' D MMM YYYY, H:mm '
)} </span>
                                                    </li>
                                                </ul>
                                             </div>
                                           `;

      const selectedClientActions = document.createElement('div');
      selectedClientActions.classList.add('leaflet-selected-client-actions');

      selectedClient.appendChild(selectedClientActions);

      const getPathButton = document.createElement('a');
      getPathButton.setAttribute('href', '#');
      getPathButton.innerHTML = `<i class="fa fa-location-arrow"></i> ${this.$t('maps.showPath')}`;
      getPathButton.addEventListener('click', (event) => {
        event.preventDefault();
        this.getPath(marker, client);
      });

      const closeSelectedButton = document.createElement('a');
      closeSelectedButton.setAttribute('href', '#');
      closeSelectedButton.innerHTML = `<i class="fa fa-close"></i> ${this.$t('maps.close')}`;
      closeSelectedButton.addEventListener('click', (event) => {
        event.preventDefault();
        this.resetFoundClient();
        this.closeClickManyClientsDataWindow();
      });

      selectedClientActions.appendChild(getPathButton);
      selectedClientActions.appendChild(closeSelectedButton);
      searchControlContainer.appendChild(selectedClient);
    },
    initCpesLayer() {
      if (!this.mapLayers.cpesLayer || !this.map.hasLayer(this.mapLayers.cpesLayer)) {
        this.mapLayers.cpesLayer = L.layerGroup().addTo(this.map);
        this.mapControls.layersControl.addOverlay(this.mapLayers.cpesLayer, `${this.$t('maps.showCPEs')}`);
      }
    },
    initCPESControlLayer() {
      if (!this.mapLayers.cpesLayer || !this.map.hasLayer(this.mapLayers.cpesLayer)) {
        this.mapControls.cpesControl.addOverlay(this.mapLayers.cpesLayer, `${this.$t('maps.showCPEs')}`);
      }
    },
    initScaleLayer() {
      if (!this.mapLayers.scaleLayer || !this.map.hasLayer(this.mapLayers.scaleLayer)) {
        this.mapLayers.scaleLayer = L.layerGroup().addTo(this.map);
      }
    },
    getClientsOnMap() {
      this.resetClientsLayer();
      this.clientsOnMap = [];
      this.heatmapLegendData = [];
      if (this.showMode.timeline) {
        // mapsService.getMarkerClientsTimeline(this, this.currentMapData.id, this.addClientsOnMap);
        mapsService.getMarkerClientsTimelineV2(this, this.currentMapData.id, this.addClientsOnMapFromAPIV2);
      } else {
        // mapsService.getMarkerClients(this, this.currentMapData.id, this.addClientsOnMap);
        if (!this.isShowingProgressBarWhenLoadingClientsAndSplitRequestsToAPI) {
          // mapsService.getMarkerClients(this, this.currentMapData.id, this.addClientsOnMap);
          mapsService.getMarkerClientsAPIV2(this, this.currentMapData.id, this.addClientsOnMapFromAPIV2);
        } else {
          mapsService.getMarkerClientsSplitRequestsV2(this, this.currentMapData.id, this.addClientsOnMapFromAPIV2);
        }
      }
    },
    switchSegmentForBubbles(index) {
      // console.log(index)
      this.bubblesOnMaps = this.segmentedForBubbles[index];
      // console.log(this.bubblesOnMaps);
      this.selectedSegment = index;
      this.drawBubbleForTimeline();
      // if (this.showMode.heatmap) {
      //   this.updateHeatmap();
      // }
    },
    switchSegment(index) {
      // console.log(index)
      this.closeClickManyClientsDataWindow();
      this.mapVisitorsSelectedSegmentNumber = index;
      this.clientsOnMap = this.segmentedClients[index];
      this.selectedSegment = index;
      // this.addClientsOnMap();
      this.addClientsOnMapFromAPIV2();
      if (this.showMode.heatmap) {
        this.updateHeatmap();
      }
    },
    updateHeatmap() {
      // console.log('update heatmap')
      if (this.mapLayers.heatLayer) {
        this.mapLayers.heatLayer.remove();
        this.showHeatmapLegend = false;
      }
      // // with Leaflet.heat
      // const heatmapData = this.makeDataForHeatmapLeafletHeat(this.clientsOnMap)
      // this.mapLayers.heatLayer = L.heatLayer(heatmapData.dataForHeatmap, {
      //   blur: 30,
      //   radius: 30
      //   // minOpacity: 0.1,
      //   // max: 0.1,
      //   // radius: 30,
      //   // blur: 30,
      //   // maxZoom: 13,
      //   // gradient: {
      //   //   0: 'Navy',
      //   //   0.25: 'Blue',
      //   //   0.5: 'Green',
      //   //   0.65: 'Yellow',
      //   //   0.9: 'Orange',
      //   //   1: 'Red'
      //   // }
      // });

      // // with Heatmap js
      // const heatmapData = this.makeDataForHeatmapLeafletHeatMAPJS(this.clientsOnMap)
      // this.mapLayers.heatLayer = new HeatmapOverlay (this.configForHeatmapJS)
      // this.mapLayers.heatLayer.setData(heatmapData)

      // with selfmade heatmap
      const selfmadeHeatmapData = this.makeDataForHeatmapSelfmade(this.clientsOnMap);
      const selfmadeHeatmapRectangles = this.createRectanglesForSelfmadeHeatmap(selfmadeHeatmapData);

      this.heatmapLegendData = this.makeStylesForHeatmapLegend(selfmadeHeatmapData);
      if (this.heatmapLegendData.maxValue !== 0){
        // console.log('here')
        this.showHeatmapLegend = true;
      }

      this.mapLayers.heatLayer = L.layerGroup(selfmadeHeatmapRectangles);

      this.mapLayers.heatLayer.addTo(this.map);
    },
    addClientsOnMap() {
      this.resetClientsLayer();
      if (!this.clientsOnMap.length) {
        // обнуляем счетчики прохожих для зон
        for (const zoneIdentity in this.passerbyCounterInZoneByZoneIdentity) {
          if (this.passerbyCounterInZoneByZoneIdentity.hasOwnProperty(zoneIdentity)) {
            this.passerbyCounterInZoneByZoneIdentity[zoneIdentity] = 0;
          }
        }
        return;
      }

      // обнуляем счетчики прохожих для зон
      for (const zoneIdentity in this.passerbyCounterInZoneByZoneIdentity) {
        if (this.passerbyCounterInZoneByZoneIdentity.hasOwnProperty(zoneIdentity)) {
          this.passerbyCounterInZoneByZoneIdentity[zoneIdentity] = 0;
        }
      }

      // this.mapLayers.clientsLayer = L.markerClusterGroup({
      //   chunkedLoading: true,
      //   chunkInterval: 900,
      //   chunkDelay: 100,
      //   spiderfyOnMaxZoom: false,
      //   showCoverageOnHover: true,
      //   zoomToBoundsOnClick: true
      // });
      this.mapLayers.clientsLayer = L.markerClusterGroup({
        chunkedLoading: true,
        // chunkInterval: 900,
        // chunkDelay: 100,
        spiderfyOnMaxZoom: false,
        showCoverageOnHover: true,
        zoomToBoundsOnClick: false,
        spiderfyDistanceMultiplier: 1,
        animateAddingMarkers: false,
        animate: false,
        removeOutsideVisibleBounds: true
      });
      this.mapLayers.clientsLayer.on('click', (marker) => {
        // console.log(marker)
        // console.log(marker)
        // console.log(this.$store.state.clickSpiderfyDataForSeeUserInfo )
        // marker.unspiderfy();
        if (this.$store.state.clickSpiderfyDataForSeeUserInfo === true) {
          // marker.layer.setIcon(this.clientDefaultIcon);
          // console.log('true')
          this.$store.commit('clickSpiderfyDataForSeeUserInfoDisable');
          this.$store.commit('clickUserPointAfterClickSpiderfyDataForSeeUserInfoEnable');
          // console.log(marker)
          // console.log(marker.layer.__parent)
          // marker.layer.__parent.unspiderfy();
        }
        // console.log(marker)
        this.selectClient(marker.layer);
      });
      // this.mapLayers.clientsLayer.on('spiderfied', (cluster, markers) => {
      //   console.log(cluster)
      //   console.log(markers);
      // });
      this.mapLayers.clientsLayer.on('clusterclick', (a) => {
        this.resetFoundClient();
        this.map.setView(a.layer._latlng);
        this.$store.commit('clickInClusterDataForSeeUserInfoDisable');
        this.$store.commit('clickSpiderfyDataForSeeUserInfoDisable');
        this.$store.commit('clickUserPointAfterClickSpiderfyDataForSeeUserInfoDisable');

        // a.layer.zoomToBounds();
        this.clearclickedClusterData();
        // console.log(a.layer);
        // a.layer is actually a cluster
        // console.log('cluster ' + a.layer.getAllChildMarkers().length);
        if (a.layer.getAllChildMarkers().length > 0) {
          // a.layer.unspiderfy();
          this.clickedClusterData = a.layer.getAllChildMarkers();
          this.clickedClusterDataFiltered = a.layer.getAllChildMarkers();
          // console.log(a.layer.getAllChildMarkers())
          // const arr = []
          // for (const marker of a.layer.getAllChildMarkers()){
          //   arr.push({options: marker.options, layer: marker.__parent.layer})
          // }
          // console.log(arr)
          // console.log(a.layer.getAllChildMarkers())
          // let popup = L.popup()
          //   .setLatLng(a.layer.getLatLng())
          //   .setContent('feefsefs')
          //   .openOn(this.map);
          // popup.openPopup();
          // );
        } else {
          // /  сюда не должны попадать, осталось от старого кода
          this.$store.commit('clickSpiderfyDataForSeeUserInfoEnable');
          this.clearclickedClusterData();
          // console.log(a)
          a.layer.spiderfy();
        }
      });

      // function generateRandomNoiseForCoordinates() {
      //   const min = -0.5;
      //   const max = 0.5;
      //   const randomNumber = Math.random() * (max - min) + min;
      //   if (Math.random() > 0.5) {
      //     // console.log(randomNumber.toFixed(4))
      //     return randomNumber.toFixed(4);
      //   } else {
      //     // console.log(0 - randomNumber.toFixed(4))
      //     return 0 - randomNumber.toFixed(4);
      //   }
      // }

      this.clientsOnMap.forEach((client) => {
        if (Math.abs(parseInt(client.lat)).length > 6 || Math.abs(parseInt(client.lng)).length > 6) {
          return;
        }
        // client.lat = client.lat + parseFloat(generateRandomNoiseForCoordinates())
        // client.lng = client.lng + parseFloat(generateRandomNoiseForCoordinates())

        const clientMarker = L.marker(client, {
          mac: client.mac,
          mac_addr: client.mac_addr,
          vendor: client.vendor,
          timestamp: client.timestamp,
          icon: this.clientDefaultIcon
        });
        clientMarker.bindTooltip(
          `<ul class="list-unstyled m-0 p-0" id="client-marker${client.mac}${client.timestamp}">
                            <li><strong>MAC:</strong>  ${this.returnMaskedOrNotMAC(client.mac)}</li>
                            <li><strong>Vendor:</strong>  ${client.vendor}</li>
                        </ul>`,
          this.tooltipClientOptions
        );
        // clientMarker.on('mouseover', (event) => {
        //   // console.log('over')
        //   const htmlId = `client-marker${client.mac}${client.timestamp}`;
        //   this.getClientInfoFromApiForTooltip(client.mac, htmlId, this);
        // });
        // clientMarker.on('mouseout', (event) => {
        //   // this.selectedClientDataFromClientsApiForTooltip = {};
        //   this.selectedClientDataFromClientsApi = {};
        // });
        // console.log(client)
        // пополняем счетчик прохожих-клиентов для зон;
        if (client.zone && client.zone !== '') {
          this.passerbyCounterInZoneByZoneIdentity[client.zone] += 1;
        }
        this.mapLayers.clientsLayer.addLayer(clientMarker);
      });

      this.map.addLayer(this.mapLayers.clientsLayer);
      this.mapControls.layersControl.addOverlay(this.mapLayers.clientsLayer, `${this.$t('maps.showClients')}`);
      this.showMode.clientsLastPathPoints = true;
    },
    // addClientsOnMapFromAPIV2() {
    //   this.resetClientsLayer();
    //   if (!this.clientsOnMap.length) {
    //     // обнуляем счетчики прохожих для зон
    //     for (const zoneIdentity in this.passerbyCounterInZoneByZoneIdentity) {
    //       if (this.passerbyCounterInZoneByZoneIdentity.hasOwnProperty(zoneIdentity)) {
    //         this.passerbyCounterInZoneByZoneIdentity[zoneIdentity] = 0;
    //       }
    //     }
    //     return;
    //   }
    //
    //   // обнуляем счетчики прохожих для зон
    //   for (const zoneIdentity in this.passerbyCounterInZoneByZoneIdentity) {
    //     if (this.passerbyCounterInZoneByZoneIdentity.hasOwnProperty(zoneIdentity)) {
    //       this.passerbyCounterInZoneByZoneIdentity[zoneIdentity] = 0;
    //     }
    //   }
    //
    //   // this.mapLayers.clientsLayer = L.markerClusterGroup({
    //   //   chunkedLoading: true,
    //   //   chunkInterval: 900,
    //   //   chunkDelay: 100,
    //   //   spiderfyOnMaxZoom: false,
    //   //   showCoverageOnHover: true,
    //   //   zoomToBoundsOnClick: true
    //   // });
    //   this.mapLayers.clientsLayer = L.markerClusterGroup({
    //     chunkedLoading: true,
    //     // chunkInterval: 900,
    //     // chunkDelay: 100,
    //     spiderfyOnMaxZoom: true,
    //     showCoverageOnHover: true,
    //     zoomToBoundsOnClick: true,
    //     spiderfyDistanceMultiplier: 1,
    //     animateAddingMarkers: true,
    //     animate: true,
    //     removeOutsideVisibleBounds: false,
    //     iconCreateFunction: function(cluster) {
    //       const markers = cluster.getAllChildMarkers();
    //       // console.log(markers);
    //       let n = markers.length ? markers.length : 0;
    //       // console.log(markers)
    //       let counter = 0;
    //       for (const marker of markers) {
    //         counter = counter + marker.options.total
    //         // console.log(counter)
    //       }
    //       // console.log(counter)
    //       let total = counter
    //       // console.log(total);
    //       return L.divIcon({
    //         html: total,
    //         className: 'custom-cluster-for-heatmaps',
    //         iconSize: L.point(40, 40)
    //       });
    //     },
    //     maxClusterRadius: (mapZoom) => {
    //       // console.log(mapZoom)
    //       return 25;
    //     }
    //   });
    //
    //   // показываем тултип с количсеством посетителей к кластере при наведении мыши
    //   this.mapLayers.clientsLayer.on('clustermouseover', (ev) => {
    //     // console.log(ev.layer._icon.innerText)
    //     ev.propagatedFrom.bindTooltip(
    //       `<ul class="list-unstyled m-0 p-0">
    //                           <li><strong>${this.$t('maps.Clients')}:</strong>  ${ev.layer._icon.innerText}</li>
    //                       </ul>`,
    //       this.tooltipClientOptions
    //     ).openTooltip();
    //   }).on('clustermouseout', (ev) => {
    //     ev.propagatedFrom.unbindTooltip();
    //   });
    //   // this.mapLayers.clientsLayer.on('click', (marker) => {
    //   //   console.log('clickMArker')
    //   //   console.log(marker)
    //   //   // console.log(marker)
    //   //   // console.log(this.$store.state.clickSpiderfyDataForSeeUserInfo )
    //   //   // marker.unspiderfy();
    //   //   if (this.$store.state.clickSpiderfyDataForSeeUserInfo === true) {
    //   //     // marker.layer.setIcon(this.clientDefaultIcon);
    //   //     // console.log('true')
    //   //     this.$store.commit('clickSpiderfyDataForSeeUserInfoDisable');
    //   //     this.$store.commit('clickUserPointAfterClickSpiderfyDataForSeeUserInfoEnable');
    //   //     // console.log(marker)
    //   //     // console.log(marker.layer.__parent)
    //   //     // marker.layer.__parent.unspiderfy();
    //   //   }
    //   //   // console.log(marker)
    //   //   this.selectClient(marker.layer);
    //   // });
    //   this.mapLayers.clientsLayer.on('click', (marker) => {
    //     // console.log('clickMArker')
    //     // console.log(marker)
    //     // console.log(marker)
    //     // console.log(this.$store.state.clickSpiderfyDataForSeeUserInfo )
    //     // marker.unspiderfy();
    //     if (this.$store.state.clickSpiderfyDataForSeeUserInfo === true) {
    //       // marker.layer.setIcon(this.clientDefaultIcon);
    //       // console.log('true')
    //       this.$store.commit('clickSpiderfyDataForSeeUserInfoDisable');
    //       this.$store.commit('clickUserPointAfterClickSpiderfyDataForSeeUserInfoEnable');
    //       // console.log(marker)
    //       // console.log(marker.layer.__parent)
    //       // marker.layer.__parent.unspiderfy();
    //     }
    //     if (this.$store.state.clickManyClientsPointToSeeUserInfo === true) {
    //       this.$store.commit('clickManyClientsPointToSeeUserInfoDisable');
    //     }
    //     // console.log(marker)
    //     if (marker.layer.options.total === 1){
    //       this.selectClient(marker.layer);
    //     } else if (marker.layer.options.total > 1) {
    //       const markerLatlng = marker.latlng;
    //       const markerLat = markerLatlng.lat;
    //       const markerLng = markerLatlng.lng;
    //       // console.log (markerLat)
    //       // console.log (markerLng)
    //       this.$store.commit('clickManyClientsPointToSeeUserInfoEnable');
    //       mapsService.getMarkerClientsInfoForLatLngPoint(this, this.currentMapData.id, markerLat, markerLng, marker.layer)
    //     }
    //   });
    //   // this.mapLayers.clientsLayer.on('spiderfied', (cluster, markers) => {
    //   //   console.log(cluster)
    //   //   console.log(markers);
    //   // });
    //   // this.mapLayers.clientsLayer.on('clusterclick', (a) => {
    //   //   console.log('clusterclick')
    //   //   this.resetFoundClient();
    //   //   this.map.setView(a.layer._latlng);
    //   //   this.$store.commit('clickInClusterDataForSeeUserInfoDisable')
    //   //   this.$store.commit('clickSpiderfyDataForSeeUserInfoDisable')
    //   //   this.$store.commit('clickUserPointAfterClickSpiderfyDataForSeeUserInfoDisable')
    //   //
    //   //
    //   //   // a.layer.zoomToBounds();
    //   //   this.clearclickedClusterData();
    //   //   // console.log(a.layer);
    //   //   // a.layer is actually a cluster
    //   //   // console.log('cluster ' + a.layer.getAllChildMarkers().length);
    //   //   if (a.layer.getAllChildMarkers().length > 0) {
    //   //     // a.layer.unspiderfy();
    //   //     this.clickedClusterData = a.layer.getAllChildMarkers();
    //   //     this.clickedClusterDataFiltered = a.layer.getAllChildMarkers();
    //   //     // console.log(a.layer.getAllChildMarkers())
    //   //     // const arr = []
    //   //     // for (const marker of a.layer.getAllChildMarkers()){
    //   //     //   arr.push({options: marker.options, layer: marker.__parent.layer})
    //   //     // }
    //   //     // console.log(arr)
    //   //     // console.log(a.layer.getAllChildMarkers())
    //   //     // let popup = L.popup()
    //   //     //   .setLatLng(a.layer.getLatLng())
    //   //     //   .setContent('feefsefs')
    //   //     //   .openOn(this.map);
    //   //     // popup.openPopup();
    //   //     // );
    //   //   } else {
    //   //     ///  сюда не должны попадать, осталось от старого кода
    //   //     this.$store.commit('clickSpiderfyDataForSeeUserInfoEnable')
    //   //     this.clearclickedClusterData();
    //   //     // console.log(a)
    //   //     a.layer.spiderfy();
    //   //
    //   //   }
    //   // });
    //
    //
    //   // function generateRandomNoiseForCoordinates() {
    //   //   const min = -0.5;
    //   //   const max = 0.5;
    //   //   const randomNumber = Math.random() * (max - min) + min;
    //   //   if (Math.random() > 0.5) {
    //   //     // console.log(randomNumber.toFixed(4))
    //   //     return randomNumber.toFixed(4);
    //   //   } else {
    //   //     // console.log(0 - randomNumber.toFixed(4))
    //   //     return 0 - randomNumber.toFixed(4);
    //   //   }
    //   // }
    //
    //   this.clientsOnMap.forEach((client) => {
    //     if (Math.abs(parseInt(client.lat)).length > 6 || Math.abs(parseInt(client.lng)).length > 6) {
    //       return;
    //     }
    //     // client.lat = client.lat + parseFloat(generateRandomNoiseForCoordinates())
    //     // client.lng = client.lng + parseFloat(generateRandomNoiseForCoordinates())
    //     // console.log(client)
    //     let clientMarker;
    //
    //     if (client.total === 1) {
    //       clientMarker = L.marker(client, {
    //         total: client.total,
    //         mac: client.mac,
    //         mac_addr: client.mac_addr,
    //         vendor: client.vendor,
    //         timestamp: client.timestamp,
    //         icon: this.clientDefaultIcon
    //       });
    //       clientMarker.bindTooltip(
    //         `<ul class="list-unstyled m-0 p-0" id="client-marker${client.mac}${client.timestamp}">
    //                         <li><strong>MAC:</strong>  ${this.returnMaskedOrNotMAC(client.mac)}</li>
    //                         <li><strong>Vendor:</strong>  ${client.vendor}</li>
    //                     </ul>`,
    //         this.tooltipClientOptions
    //       );
    //
    //     } else if (client.total > 1) {
    //       clientMarker = L.marker(client, {
    //         total: client.total,
    //         // mac: client.mac,
    //         // mac_addr: client.mac_addr,
    //         // vendor: client.vendor,
    //         // timestamp: client.timestamp,
    //         icon: this.clientDefaultIconManyClients
    //       });
    //       clientMarker.bindTooltip(
    //         `<ul class="list-unstyled m-0 p-0">
    //                           <li><strong>${this.$t('maps.Clients')}:</strong>  ${client.total}</li>
    //                       </ul>`,
    //         this.tooltipClientOptions
    //       )
    //
    //     } else if (client.total === 0){
    //        // console.log('total is 0')
    //     }
    //     if (client.total >= 1) {
    //       // console.log('x', client.x, 'y', client.y, 'total', client.total)
    //     }
    //     // const clientMarker = L.marker(client, {
    //     //   mac: client.mac,
    //     //   mac_addr: client.mac_addr,
    //     //   vendor: client.vendor,
    //     //   timestamp: client.timestamp,
    //     //   icon: this.clientDefaultIcon
    //     // });
    //     // clientMarker.bindTooltip(
    //     //   `<ul class="list-unstyled m-0 p-0" id="client-marker${client.mac}${client.timestamp}">
    //     //                     <li><strong>MAC:</strong>  ${this.returnMaskedOrNotMAC(client.mac)}</li>
    //     //                     <li><strong>Vendor:</strong>  ${client.vendor}</li>
    //     //                 </ul>`,
    //     //   this.tooltipClientOptions
    //     // );
    //     // clientMarker.on('mouseover', (event) => {
    //     //   // console.log('over')
    //     //   const htmlId = `client-marker${client.mac}${client.timestamp}`;
    //     //   this.getClientInfoFromApiForTooltip(client.mac, htmlId, this);
    //     // });
    //     // clientMarker.on('mouseout', (event) => {
    //     //   // this.selectedClientDataFromClientsApiForTooltip = {};
    //     //   this.selectedClientDataFromClientsApi = {};
    //     // });
    //     // console.log(client)
    //     // пополняем счетчик прохожих-клиентов для зон;
    //     if (client.zone && client.zone !== '') {
    //       this.passerbyCounterInZoneByZoneIdentity[client.zone] += 1;
    //     }
    //     // console.log(this.currentMapData)
    //     if (clientMarker) {
    //       // console.log(clientMarker)
    //       this.mapLayers.clientsLayer.addLayer(clientMarker);
    //     }
    //
    //   });
    //
    //   this.map.addLayer(this.mapLayers.clientsLayer);
    //   this.mapControls.layersControl.addOverlay(this.mapLayers.clientsLayer, `${this.$t('maps.showClients')}`);
    //   this.showMode.clientsLastPathPoints = true;
    // },

    addClientsOnMapFromAPIV2() {
      this.resetClientsLayer();
      if (!this.clientsOnMap.length) {
        // обнуляем счетчики прохожих для зон
        for (const zoneIdentity in this.passerbyCounterInZoneByZoneIdentity) {
          if (this.passerbyCounterInZoneByZoneIdentity.hasOwnProperty(zoneIdentity)) {
            this.passerbyCounterInZoneByZoneIdentity[zoneIdentity] = 0;
          }
        }
        return;
      }

      // обнуляем счетчики прохожих для зон
      for (const zoneIdentity in this.passerbyCounterInZoneByZoneIdentity) {
        if (this.passerbyCounterInZoneByZoneIdentity.hasOwnProperty(zoneIdentity)) {
          this.passerbyCounterInZoneByZoneIdentity[zoneIdentity] = 0;
        }
      }

      this.mapLayers.clientsLayer = L.markerClusterGroup({
        chunkedLoading: true,
        // chunkInterval: 900,
        // chunkDelay: 100,
        spiderfyOnMaxZoom: true,
        showCoverageOnHover: true,
        zoomToBoundsOnClick: true,
        spiderfyDistanceMultiplier: 1,
        animateAddingMarkers: true,
        animate: true,
        removeOutsideVisibleBounds: false,
        iconCreateFunction(cluster) {
          const markers = cluster.getAllChildMarkers();
          // console.log(markers);
          const n = markers.length ? markers.length : 0;
          // console.log(markers)
          let counter = 0;
          for (const marker of markers) {
            counter += marker.options.total;
            // console.log(counter)
          }
          // console.log(counter)
          const total = counter;
          // console.log(total);
          return L.divIcon({
            html: total,
            className: 'custom-cluster-for-heatmaps',
            iconSize: L.point(40, 40)
          });
        },
        maxClusterRadius: (mapZoom) =>
          // console.log(mapZoom)
          25

      });

      // показываем тултип с количсеством посетителей к кластере при наведении мыши
      this.mapLayers.clientsLayer
        .on('clustermouseover', (ev) => {
          // console.log(ev.layer._icon.innerText)
          ev.propagatedFrom
            .bindTooltip(
              `<ul class="list-unstyled m-0 p-0">
                              <li><strong>${this.$t('maps.Clients')}:</strong>  ${ev.layer._icon.innerText}</li>
                          </ul>`,
              this.tooltipClientOptions
            )
            .openTooltip();
        })
        .on('clustermouseout', (ev) => {
          ev.propagatedFrom.unbindTooltip();
        });

      this.mapLayers.clientsLayer.on('click', (marker) => {
        if (this.$store.state.clickSpiderfyDataForSeeUserInfo === true) {
          // marker.layer.setIcon(this.clientDefaultIcon);
          // console.log('true')
          this.$store.commit('clickSpiderfyDataForSeeUserInfoDisable');
          this.$store.commit('clickUserPointAfterClickSpiderfyDataForSeeUserInfoEnable');
          // console.log(marker)
          // console.log(marker.layer.__parent)
          // marker.layer.__parent.unspiderfy();
        }
        if (this.$store.state.clickManyClientsPointToSeeUserInfo === true) {
          this.$store.commit('clickManyClientsPointToSeeUserInfoDisable');
        }
        // console.log(marker)
        if (marker.layer.options.total >= 1) {
          // const markerLatlng = marker.latlng;
          // const markerLat = markerLatlng.lat;
          // const markerLng = markerLatlng.lng;
          // console.log(marker.layer.options)
          const markerLat = marker.layer.options.y;
          const markerLng = marker.layer.options.x;

          // console.log (markerLat)
          // console.log (markerLng)
          this.$store.commit('clickManyClientsPointToSeeUserInfoEnable');
          mapsService.getMarkerClientsInfoForLatLngPoint(
            this,
            this.currentMapData.id,
            markerLat,
            markerLng,
            marker.layer
          );
        }
      });
      // добавляем прямоугольник-рамку при наведении
      this.mapLayers.clientsLayer
        .on('mouseover', (ev) => {
          if (this.polygonWhenMouseOverCLientsPoint !== undefined) {
            this.polygonWhenMouseOverCLientsPoint.removeFrom(this.map);
          }
          // подсчитывали сами на оснве известного размера сетки из запроса и приходящих в ответе координат центра прямоугольника
          // // console.log(ev);
          // console.log(ev.layer.options)
          // const lat = ev.layer.options.y;
          // const lng = ev.layer.options.x;
          // // строим массив углов для прямоугольника при наведении на точку
          // const corners = [[lat + (this.gridSize.y / 2), lng - (this.gridSize.x / 2)], [lat + (this.gridSize.y / 2), lng + (this.gridSize.x / 2) ], [lat - (this.gridSize.y / 2), lng + (this.gridSize.x / 2) ], [lat - (this.gridSize.y / 2), lng - (this.gridSize.x / 2) ]]
          //
          // this.polygonWhenMouseOverCLientsPoint = L.polygon(corners, {opacity: 0.5, stroke: false}).addTo(this.map);
          // на основе координат прихолдящих с бэка
          const rectangleCoords = ev.layer.options.rectangle;
          if (rectangleCoords !== undefined) {
            const northEastCorner = [rectangleCoords.topRightCorner.y, rectangleCoords.topRightCorner.x];
            const southWestCorner = [rectangleCoords.bottomLeftCorner.y, rectangleCoords.bottomLeftCorner.x];
            const cornersForRectangle = [southWestCorner, northEastCorner];
            this.polygonWhenMouseOverCLientsPoint = L.rectangle(cornersForRectangle, {
              opacity: 0.5,
              stroke: false
            }).addTo(this.map);
          }
        })
        .on('mouseout', (ev) => {
          if (this.polygonWhenMouseOverCLientsPoint !== undefined) {
            this.polygonWhenMouseOverCLientsPoint.removeFrom(this.map);
          }
        });

      this.clientsOnMap.forEach((client) => {
        if (Math.abs(parseInt(client.lat)).length > 6 || Math.abs(parseInt(client.lng)).length > 6) {
          return;
        }
        // client.lat = client.lat + parseFloat(generateRandomNoiseForCoordinates())
        // client.lng = client.lng + parseFloat(generateRandomNoiseForCoordinates())
        // console.log(client)
        let clientMarker;

        if (client.total >= 1) {
          let rectangle;
          if (
            Object.prototype.hasOwnProperty.call(client, 'rectangle') &&
            Object.prototype.hasOwnProperty.call(client.rectangle, 'min') &&
            Object.prototype.hasOwnProperty.call(client.rectangle, 'max') &&
            Object.prototype.hasOwnProperty.call(client.rectangle.min, 'x') &&
            Object.prototype.hasOwnProperty.call(client.rectangle.min, 'y') &&
            Object.prototype.hasOwnProperty.call(client.rectangle.max, 'x') &&
            Object.prototype.hasOwnProperty.call(client.rectangle.max, 'y')
          ) {
            rectangle = {
              bottomLeftCorner: {
                x: client.rectangle.min.x,
                y: client.rectangle.min.y
              },
              topRightCorner: {
                x: client.rectangle.max.x,
                y: client.rectangle.max.y
              }
            };
          }
          clientMarker = L.marker(client, {
            total: client.total,
            x: client.x,
            y: client.y,
            rectangle,

            // icon: this.clientDefaultIconManyClients
            icon: L.divIcon({
              html: client.total,
              className: 'custom-cluster-for-heatmaps',
              iconSize: L.point(30, 30)
            }),
            riseOnHover: true
          });
          clientMarker.bindTooltip(
            `<ul class="list-unstyled m-0 p-0">
                              <li><strong>${this.$t('maps.Clients')}:</strong>  ${client.total}</li>
                          </ul>`,
            this.tooltipClientOptions
          );
        }

        if (client.total >= 1) {
          // console.log('x', client.x, 'y', client.y, 'total', client.total)
        }

        // пополняем счетчик прохожих-клиентов для зон;
        if (client.zone && client.zone !== '') {
          this.passerbyCounterInZoneByZoneIdentity[client.zone] += 1;
        }
        // console.log(this.currentMapData)
        if (clientMarker) {
          // console.log(clientMarker)
          this.mapLayers.clientsLayer.addLayer(clientMarker);
        }
      });

      this.map.addLayer(this.mapLayers.clientsLayer);
      this.mapControls.layersControl.addOverlay(this.mapLayers.clientsLayer, `${this.$t('maps.showClients')}`);
      this.showMode.clientsLastPathPoints = true;
    },

    fitMap() {
      if (!this.map || !this.currentBounds) {
        return;
      }
      this.map.fitBounds(this.currentBounds);
    },
    addScaleMarker(e) {
      this.initScaleLayer();
      if (this.scaleData.measure.length === 2) {
        this.removeScaleMarkers();
      }

      const newMarker = new L.marker(e.latlng).addTo(this.map);
      this.mapLayers.scaleLayer.addLayer(newMarker);
      this.scaleData.measure.push(newMarker);

      if (this.scaleData.measure.length === 2) {
        L.polyline(this.measureToLine, { color: 'red', dashArray: '4' }).addTo(this.mapLayers.scaleLayer);
        this.getMeasure();
      }
    },
    getMeasure() {
      let { upp } = this.currentMapData;
      if (!upp) {
        upp = 1;
      }
      if (this.scaleData.measure.length !== 2) {
        return;
      }
      const a = this.scaleData.measure[0]._latlng;
      const b = this.scaleData.measure[1]._latlng;

      const distance = Math.sqrt(Math.pow(b.lat / upp - a.lat / upp, 2) + Math.pow(b.lng / upp - a.lng / upp, 2));
      this.scaleData.pixelDistance = Math.round(distance);
    },
    removeScaleMarkers() {
      if (!this.map) {
        return;
      }
      if (this.mapLayers.scaleLayer) {
        this.mapLayers.scaleLayer.clearLayers();
      }
      this.scaleData.measure = [];
    },
    resetScaleData() {
      this.scaleData = {
        measure: [],
        pixelDistance: '',
        realDistance: '',
        scaleUnits: 'm'
      };
    },
    updateMapScale() {
      this.$validator
        .validateAll({
          distance: this.scaleData.realDistance
        })
        .then((result) => {
          if (result) {
            this.deleteSavedMapZoomAndCenterValuesForMapFromLocalStorage(this.currentMapData.id);
            mapsService.updateMapScale(this);
          }
        });
    },
    clickOnMap(e) {
      if (this.clickedClusterData !== false) {
        this.clearclickedClusterData();
      }
      this.resetContext();
      if (this.mapMode.scale) {
        this.addScaleMarker(e);
      }
    },
    deleteMap() {
      mapsService.deleteMap(this);
      this.disableEditMode();
      this.closeDeleteMapModal();
    },
    openDeleteMapModal() {
      this.deleteMapModal = true;
    },
    closeDeleteMapModal() {
      this.deleteMapModal = false;
    },
    closeSelectedCpeUsersInfoModal() {
      this.isSelectedCpeUsersInfoModalShowing = false;
      this.userListForSelectedCpe = [];
      this.userListForSelectedCpeCpeName = '';
    },
    closeEditMapModal() {
      this.editMapModal = false;
      this.updatedMap.name = '';
      this.updatedMap.base_location = '';
      // далее для настроек времени работы
      this.updatedMap.start = '';
      this.updatedMap.stop = '';
      this.updatedMap.time_zone = '';
      this.workTimeTimezoneSelectedInMultiselect = '';
      this.$validator.reset();
    },
    saveFromEditMapModal() {
      // mapsService.updateMapFromModal(this);

      this.$validator.validateAll().then(
        (result) => {
          if (result) {
            mapsService.updateMapFromModal(this);
          } else {
            // this.validatorErrors = true;
          }
        },
        () => {
          console.warn('Validation failed');
        }
      );
      // console.log('saved');
    },
    openEditModal() {
      this.updatedMap.name = this.currentMapData.name;
      this.updatedMap.base_location = this.currentMapData.base_location;
      // далее для настроек времени работы
      this.updatedMap.start = this.currentMapData.start;
      this.updatedMap.stop = this.currentMapData.stop;
      this.updatedMap.time_zone = this.currentMapData.time_zone;
      this.workTimeTimezoneSelectedInMultiselect = this.extractTimeZoneObjectForDisplayInMultiselect(
        this.updatedMap.time_zone
      );

      this.editMapModal = true;
    },
    disableEditMode() {
      if (this.isCPEsChanged === true) {
        // console.log('есть измененя');
        // this.showingCPEChangedModal = true;
        this.CPEChangedModalShow('closeButtons');
        return;
      }
      this.map = false;

      this.cpesIdsOnMap = [];
      this.cpesMarkers = [];
      this.clientsOnMap = [];
      this.bubblesOnMaps = [];
      this.mapLayers.heatLayer = false;
      this.currentMapData = false;
      (this.dateRange = {
        startDate: moment().subtract(24, 'hours').valueOf(),
        endDate: moment().valueOf()
      }),
      this.$store.commit('setMapsEditId', null);
    },
    locationName(id) {
      const showLocationFromId = commonService.showLocationFromId(id);
      return showLocationFromId;
    },
    filteredCpes(items) {
      const filteredCpes = Filters.cpeSearchQuery(items, this.cpeFilters.query);

      const sortedFilteredCpes = filteredCpes.sort((curr, prev) => {
        if (curr.connected > prev.connected) {
          return -1;
        }
        if (curr.connected < prev.connected) {
          return 1;
        }
        return 0;
      });
      return sortedFilteredCpes;
    },
    filteredCpesOnlyWithNames(items) {
      const cpesWithName = [];
      const cpes = this.filteredCpes(items);
      for (const cpe of cpes) {
        if (cpe.name && cpe.name !== '' && cpe.base_location === this.currentMapData.base_location) {
          cpesWithName.push(cpe);
        }
      }
      return cpesWithName;
    },
    formatCpes(objects) {
      const cpesArray = helpers.combineCpeIdsArrayFromObjectsArray(objects);
      const cpesName = [];
      cpesArray.forEach((cpe) => {
        cpesName.push(commonService.cpeNamebyId(cpe));
      });
      let string = cpesName.join(', ');
      string = `<i>${string}</i>`;
      return string;
    },
    formatZonesForTooltip(zones) {
      const zonesName = [];
      for (const zone of zones) {
        zonesName.push(zone.name);
      }
      let string = zonesName.join(', ');
      string = `<i>${string}</i>`;
      return string;
    },
    addCpesOnMap() {
      if (!this.currentMapData.cpe_list.length) {
        this.mapLayers.cpesLayer = false;
        return;
      }
      this.mapLayers.cpesLayer = L.layerGroup().addTo(this.map);
      this.mapControls.layersControl.addOverlay(this.mapLayers.cpesLayer, `${this.$t('maps.showCPEs')}`);
      this.addCurrentCpesOnMap();
    },
    addZonesOnMap() {
      if (!this.currentMapData.Zones || !this.currentMapData.Zones.length) {
        return;
      }
      // this.zonesLayers = L.FeatureGroup().addTo(this.map);
      // this.addCurrentCpesOnMap();
      // убираем слой с карты и из меню управления слоями
      if (this.zonesLayers) {
        this.map.removeLayer(this.zonesLayers);
        this.mapControls.layersControl.removeLayer(this.zonesLayers);
      }
      this.zonesLayers = false;
      this.zonesLayers = new L.FeatureGroup();
      // добавляем чекбокс в список layer сщтекщд
      this.mapControls.layersControl.addOverlay(this.zonesLayers, `${this.$t('maps.showZones')}`);
      this.map.addLayer(this.zonesLayers);
      this.addCurrentZonesOnMap();
    },
    addCurrentCpesOnMap() {
      this.cpesIdsOnMap = [];
      this.cpesMarkers = [];

      if (this.currentMapData.cpe_list.length) {
        this.currentMapData.cpe_list.forEach((cpe) => {
          this.addCpeOnMap(cpe, false, [cpe.coord_top, cpe.coord_left], this.mapMode.planning);
        });
      }
      if (this.mapMode.show) {
        this.mapControls.cpesControl = L.control
          .layers({}, {}, { collapsed: true, hideSingleBase: true })
          .addTo(this.map);
        const cpesControlDiv = this.mapControls.cpesControl.getContainer();
        // console.log(cpesControlDiv)
        cpesControlDiv.classList.add('cpesControl-div');
        const cpesControlTitle = document.createElement('p');
        cpesControlTitle.className = 'p-0 mb-h font-weight-bold';
        cpesControlTitle.innerHTML = `${this.$t('maps.cpeControlTitle')}`;
        cpesControlDiv.insertBefore(cpesControlTitle, cpesControlDiv.firstChild);
        // добавляем точки в управление точкмии
        for (const cpe of this.cpesMarkers) {
          this.mapControls.cpesControl.addOverlay(cpe, cpe.options.name);
        }
      }
      this.highlightCpeTooltipsIfHighlightEnabled();
    },
    addCurrentZonesOnMap() {
      if (this.passerbyCounterInZoneByZoneIdentity === false) {
        this.passerbyCounterInZoneByZoneIdentity = {};
      }

      if (this.currentMapData.Zones.length) {
        this.currentMapData.Zones.forEach((zone) => {
          const convertedZone = this.convretZoneToLayer(zone);
          // console.log(convertedZone)
          // привязываем тултип с названем зоны и количеством клиентов в центр зоны
          convertedZone.bindTooltip(
            (layerObj) => '<div class=".zones-on-heatmap-tooltip">' +
              `<div>${this.$t('maps.zoneNameCaptionInZoneTooltip')}: ${layerObj.name}</div>` +
              `<div>${this.$t('maps.zoneClientsCounterCaptionInZoneTooltip')}: ${
                this.passerbyCounterInZoneByZoneIdentity[layerObj.identity]
              }</div>` +
              '</div>',
            {
              permanent: false,
              direction: 'center'
            }
          );
          // устанавливаем цвет для зон
          convertedZone.setStyle({
            color: '#333333',
            opacity: 0.2,
            fillColor: '#333333',
            fillOpacity: 0.1
          });
          convertedZone.on('click', (e) => {
            if (this.mapMode.zones) {
              if (this.drawZonesEditMode === false && this.drawZonesDeleteMode === false) {
                // console.log(e.target.id);
                this.editZone(e.target);
              }
            }
          });
          if (!this.passerbyCounterInZoneByZoneIdentity.hasOwnProperty(convertedZone.identity)) {
            this.passerbyCounterInZoneByZoneIdentity[convertedZone.identity] = 0;
          }

          this.zonesLayers.addLayer(convertedZone);
        });
        this.createZonesAsArray();
      }
    },
    convretZoneToLayer(zoneFromApi) {
      const corners = [];
      for (const zoneFromApiCorner of zoneFromApi.corners) {
        corners.push([zoneFromApiCorner.x, zoneFromApiCorner.y]);
      }
      // console.log(zoneFromApi)
      // console.log(corners)
      const polygon = L.polygon(corners);
      polygon.name = zoneFromApi.name;
      polygon.id = zoneFromApi.id;
      // поле identity это вместо id
      polygon.identity = zoneFromApi.identity;
      return polygon;
    },
    addCpeOnMap(cpe, isNewCpe, coordinate, isPlanning) {
      if (isNewCpe === true) {
        // Если отключили показ точек чекбоксом, а потом добавлем новую точку на карту то показываем слой с точками
        this.showCPESLayers();
        try {
          // пробуем подтянуть данные  по состоянию радара, ip и MAC для  вновь добавляемой точки (из режима планировнания)
          const fullCpe = commonService.cpeObjbyId(cpe.id);
          cpe.model = fullCpe !== undefined && fullCpe.hasOwnProperty('model') ? fullCpe.model : '';
          cpe.macaddr =
            fullCpe !== undefined &&
            fullCpe.hasOwnProperty('state') &&
            fullCpe.state !== undefined &&
            fullCpe.state.hasOwnProperty('network') &&
            fullCpe.state.network !== undefined &&
            fullCpe.state.network.hasOwnProperty('macaddr') ?
              fullCpe.state.network.macaddr :
              '';
          cpe.ipaddr =
            fullCpe !== undefined &&
            fullCpe.hasOwnProperty('state') &&
            fullCpe.state !== undefined &&
            fullCpe.state.hasOwnProperty('network') &&
            fullCpe.state.network !== undefined &&
            fullCpe.state.network.hasOwnProperty('ipaddr') ?
              fullCpe.state.network.ipaddr :
              '';
          cpe.connected = fullCpe !== undefined && fullCpe.hasOwnProperty('connected') ? fullCpe.connected : '';
          cpe.name = fullCpe !== undefined && fullCpe.hasOwnProperty('name') ? fullCpe.name : '';
          cpe.lbs =
            fullCpe !== undefined &&
            fullCpe.hasOwnProperty('config') &&
            fullCpe.config !== undefined &&
            fullCpe.config.hasOwnProperty('lbs_config') &&
            fullCpe.config.lbs_config !== undefined &&
            fullCpe.config.lbs_config.hasOwnProperty('enabled') ?
              fullCpe.config.lbs_config.enabled :
              '';
          cpe.config_status =
            fullCpe !== undefined && fullCpe.hasOwnProperty('config_status') ? fullCpe.config_status : '';
        } catch (e) {
          console.log(e);
        }
      }

      let showCpeStatisticForThisCpe = false;
      // let showClientsForThisCpe = false;
      let clients = 0;
      let cpuLoad = '';
      let memory = '';

      const { cpesStatisticOnMap } = this;
      const { cpesStatisticClientsOnMap } = this;
      // смотрим есть ли воббще статистика для этой точки
      // чтобы если статистики  нету не выводить ничего лишнего в окошко  с подсписью
      if (cpesStatisticOnMap.hasOwnProperty(cpe.cpe_id)) {
        showCpeStatisticForThisCpe = true;
        const currentCpeStatistic = cpesStatisticOnMap[cpe.cpe_id];
        // console.log(currentCpeStatistic);
        // далее вытаскиваем различные  параметры статистики  для отображения  в подписи к точке
        // if (currentCpeStatistic.hasOwnProperty('clients')) {
        //   clients = currentCpeStatistic.clients;
        // }
        if (currentCpeStatistic.hasOwnProperty('cpu_load')) {
          cpuLoad = currentCpeStatistic.cpu_load * 100;
          cpuLoad = cpuLoad.toFixed(2);
          cpuLoad = `${cpuLoad}%`;
        }
        if (currentCpeStatistic.hasOwnProperty('memory_free') && currentCpeStatistic.hasOwnProperty('memory_total')) {
          memory = this.memoryLoad(currentCpeStatistic.memory_total, currentCpeStatistic.memory_free);
          memory = memory.toFixed(2);
          memory = `${memory}%`;
        }
      }
      if (cpesStatisticClientsOnMap.hasOwnProperty(cpe.cpe_id)) {
        // showClientsForThisCpe = true;
        // console.log(cpesStatisticClientsOnMap);
        clients = cpesStatisticClientsOnMap[cpe.cpe_id].length;
      }

      if (!coordinate) {
        coordinate = this.currentCenter;
        coordinate[0] *= Math.random() * (1.2 - 0.8) + 0.8;
        coordinate[1] *= Math.random() * (1.2 - 0.8) + 0.8;
      }
      const newCpeMarker = new L.marker([coordinate[0], coordinate[1]], {
        clients,
        cpuLoad,
        memory,
        cpe_id: cpe.cpe_id,
        model: cpe.model.name,
        macaddr: cpe.macaddr,
        ipaddr: cpe.ipaddr,
        connected: cpe.connected,
        lbs: cpe.lbs,
        config_status: cpe.config_status,
        name: cpe.name,
        draggable: isPlanning,
        contextmenu: isPlanning,
        icon: isNewCpe ?
          this.cpeNewIcon :
          commonService.cpeObjbyId(cpe.cpe_id) && commonService.cpeObjbyId(cpe.cpe_id).connected ?
            this.cpeConnectedIcon :
            this.cpeDisconnectedIcon
      });
      const cpeConnection = newCpeMarker.options.connected ? 'connected' : 'disconnected';

      let smallCpeTooltip = ` <ul class="list-unstyled m-q p-0 cpe-tooltip">
                                              <li class="pl-1 text-center">
                                                  <span class="badge-cpe badge-status ml-h badge-status__${cpeConnection}">
                                                  </span>
                                                  <span v-if="cpe.options.name">
                                                      ${newCpeMarker.options.name}
                                                  </span>
                                              </li>
                                          </ul>`;
      if (this.showCPEsStatisticForUser && this.mapMode.show && newCpeMarker.options.connected) {
        smallCpeTooltip +=
          `<ul class="list-unstyled m-q p-0 cpe-tooltip">
                                                  <li class="pl-1">
                                                    <small class="font-weight-bold pl-h">${this.$t(
    'maps.cpeStatisticUsers'
  )}:</small>
                                                    <small>${newCpeMarker.options.clients}</small>
                                                   </li>
                                                  </ul>
                                                 `;
      }
      if (this.showCPEsStatisticForUser && showCpeStatisticForThisCpe && this.mapMode.show) {
        smallCpeTooltip +=
          `<ul class="list-unstyled m-q p-0 cpe-tooltip cpe-statistic-in-tooltip">
                                                   <li class="pl-1">
                                                    <small class="font-weight-bold pl-h">${this.$t(
    'maps.cpeStatisticCPULoad'
  )}:</small>
                                                    <small>${newCpeMarker.options.cpuLoad}</small>
                                                   </li>
                                                   <li class="pl-1">
                                                    <small class="font-weight-bold pl-h">${this.$t(
    'maps.cpeStatisticMemory'
  )}:</small>
                                                    <small>${newCpeMarker.options.memory}</small>
                                                   </li>
                                                  </ul>
                                                 `;
      }
      let bigCpePopup = `
                                    <ul class="list-unstyled m-q p-0 cpe-tooltip cpe-popup">
                                        <li class="pl-1" v-if="cpe.options.cpe_id">
                                            <span class="badge-cpe badge-status ml-h badge-status__${cpeConnection}">  </span>
                                            <span>
                                                ${newCpeMarker.options.name}
                                            </span>
                                        </li>
                                        <li>
                                            <small class="font-weight-bold pl-h">${this.$t('maps.cpePopUpID')}:</small>
                                            <small class="">${newCpeMarker.options.cpe_id}</small>
                                        </li>
                                        <li>
                                            <small class="font-weight-bold pl-h">${this.$t(
    'maps.cpePopUpModel'
  )}:</small>
                                            <small class="">${newCpeMarker.options.model}</small>
                                        </li>
                                        <li>
                                            <small class="font-weight-bold pl-h">${this.$t('maps.cpePopUpIP')}:</small>
                                            <small class="">${newCpeMarker.options.ipaddr}</small>
                                        </li>
                                        <li>
                                            <small class="font-weight-bold pl-h">${this.$t('maps.cpePopUpMAC')}:</small>
                                            <small class="">${newCpeMarker.options.macaddr}</small>
                                        </li>
                                        <li>
                                            <small class="font-weight-bold pl-h">${this.$t(
    'maps.cpePopUpWifiRadar'
  )}:</small>
                                            ${
  newCpeMarker.options.lbs ?
    `<small class="text-success">${this.$t('general.on')}</small>` :
    `<small class="text-danger">${this.$t('general.off')}</small>`
}
                                        </li>
                                        <li><br></li>
                                        <li role="button" id="showMoreCpeInfo${newCpeMarker.options.cpe_id}">
                                            <small class="font-weight-bold pl-h"><a style="color:#00aced" class = "showMoreCpeInfo" data-cpe-id='${
  newCpeMarker.options.cpe_id
}'>${this.$t('maps.cpeShowMore')}...</a></small>
                                        </li>
                                    </ul>`;
      if (this.showCPEsStatisticForUser && newCpeMarker.options.connected && this.mapMode.show) {
        bigCpePopup +=
          `                                     <ul class="list-unstyled m-q p-0 cpe-toolti"><li role="button" id="showCpeUsersInfo${
            newCpeMarker.options.cpe_id
          }">
                                            <small class="font-weight-bold pl-h"><a style="color:#00aced" class = "showCpeUsersInfo" data-cpe-id='${
  newCpeMarker.options.cpe_id
}'>${this.$t('maps.cpeInfoAboutUsers')}...</a></small>
                                        </li></ul>`;
      }
      // если нет иноформации о CPE (например CPE в другой, недоступной локации)
      if (commonService.cpeNamebyId(cpe.cpe_id) === '') {
        smallCpeTooltip = this.$t('maps.noCpeData');
        bigCpePopup = `<p id="showMoreCpeInfo${newCpeMarker.options.cpe_id}">${this.$t(
          'maps.noCpeDataFull'
        )}</p><p><small class="font-weight-bold pl-h">${this.$t('maps.cpePopUpID')}:</small>
                                            <small class="">${newCpeMarker.options.cpe_id}</small></p>
                                        `;
      }
      newCpeMarker.bindTooltip(smallCpeTooltip, this.tooltipCpeOptions).openTooltip();
      newCpeMarker.bindPopup(bigCpePopup, this.tooltipBigCpeOptions);

      const clickshowMoreCpeInfoFunction = () => {
        const cpeId = newCpeMarker.options.cpe_id;
        this.showMoreCpeInfo(cpeId);
      };

      const clickshowCpeUsersInfo = () => {
        const cpeId = newCpeMarker.options.cpe_id;
        const cpeName = newCpeMarker.options.name;
        this.showCpeUsersInfo(cpeId, cpeName);
      };

      // newCpeMarker.on('popupopen', () => {
      //   // console.log('open');
      //   const cpePopUpShowMoreLink = document.getElementById(`showMoreCpeInfo${newCpeMarker.options.cpe_id}`);
      //   // console.log(cpePopUpShowMoreLink)
      //   cpePopUpShowMoreLink.removeEventListener('click', clickshowMoreCpeInfoFunction);
      //   cpePopUpShowMoreLink.addEventListener('click', clickshowMoreCpeInfoFunction);
      // });

      if (this.mapMode.show && !this.mapMode.planning) {
        newCpeMarker.on('click', () => {
          // console.log('open');
          const cpePopUpShowMoreLink = document.getElementById(`showMoreCpeInfo${newCpeMarker.options.cpe_id}`);
          // console.log(cpePopUpShowMoreLink)
          cpePopUpShowMoreLink.removeEventListener('click', clickshowMoreCpeInfoFunction);
          cpePopUpShowMoreLink.addEventListener('click', clickshowMoreCpeInfoFunction);

          if (this.showCPEsStatisticForUser && newCpeMarker.options.connected && this.mapMode.show) {
            const cpePopUpShowCpeUsersInfo = document.getElementById(`showCpeUsersInfo${newCpeMarker.options.cpe_id}`);
            // console.log(cpePopUpShowMoreLink)
            cpePopUpShowCpeUsersInfo.removeEventListener('click', clickshowCpeUsersInfo);
            cpePopUpShowCpeUsersInfo.addEventListener('click', clickshowCpeUsersInfo);
          }
        });
      }

      newCpeMarker.on('mouseover', (event) => {
        // // if (!this.mapMode.show && !this.mapMode.planning) {
        // //   return;
        // // }
        // event.target.openPopup();
        // // event.target.closeTooltip();
        // const cpePopUpShowMoreLink = document.getElementById(`showMoreCpeInfo${newCpeMarker.options.cpe_id}`);
        // // console.log(cpePopUpShowMoreLink)
        // cpePopUpShowMoreLink.removeEventListener('click', clickshowMoreCpeInfoFunction);
        // cpePopUpShowMoreLink.addEventListener('click', clickshowMoreCpeInfoFunction);
        // // let icon = newCpeMarker.options.icon;
        // // icon.options.iconSize = [25, 25];
        // // newCpeMarker.setIcon(icon);
        if (isPlanning) {
          if (newCpeMarker.options.connected) {
            event.target.setIcon(this.cpeConnectedIconBig);
          } else {
            event.target.setIcon(this.cpeDisconnectedIconBig);
          }
        }
        if (this.pointIsDraging !== true) {
          // this.isClosePopupMutex = true;
          event.target.openPopup();
          const cpePopUpShowMoreLink = document.getElementById(`showMoreCpeInfo${newCpeMarker.options.cpe_id}`);
          cpePopUpShowMoreLink.removeEventListener('click', clickshowMoreCpeInfoFunction);
          cpePopUpShowMoreLink.addEventListener('click', clickshowMoreCpeInfoFunction);

          if (this.showCPEsStatisticForUser && newCpeMarker.options.connected && this.mapMode.show) {
            const cpePopUpShowCpeUsersInfo = document.getElementById(`showCpeUsersInfo${newCpeMarker.options.cpe_id}`);
            // console.log(cpePopUpShowMoreLink)
            cpePopUpShowCpeUsersInfo.removeEventListener('click', clickshowCpeUsersInfo);
            cpePopUpShowCpeUsersInfo.addEventListener('click', clickshowCpeUsersInfo);
          }

          // закрываем попап, когда увели с него курсор
          const popup = document.getElementsByClassName('leaflet-popup-content')[0].parentElement;
          popup.addEventListener('mouseover', (event) => {
            event.stopPropagation();
            this.isClosePopupMutex = true;
            // this.isClosePopupMutex = false
          });
          popup.addEventListener('mouseleave', () => {
            event.target.closePopup();
            this.isClosePopupMutex = false;
            // this.isClosePopupMutex = false
          });
          // setTimeout(() => {
          //   if(this.isClosePopupMutex === false){
          //     event.target.closePopup()
          //   }
          //
          // }, 1000)
        }
      });

      newCpeMarker.on('mouseout', (event) => {
        // // if (!this.mapMode.show && !this.mapMode.planning) {
        // //   return;
        // // }
        // // event.target.closePopup();
        // event.target.openTooltip();
        // // let icon = newCpeMarker.options.icon;
        // // icon.options.iconSize = [15, 15];
        // // newCpeMarker.setIcon(icon);
        if (isPlanning) {
          if (newCpeMarker.options.connected) {
            event.target.setIcon(this.cpeConnectedIcon);
          } else {
            event.target.setIcon(this.cpeDisconnectedIcon);
          }
        }
        if (this.pointIsDraging !== true) {
          event.target.openTooltip();
        }
        setTimeout(() => {
          if (this.isClosePopupMutex === false) {
            event.target.closePopup();
          }
        }, 100);
        // setTimeout(() => {
        //
        //   event.target.closePopup();
        //
        // }, 500);
      });

      // для автоматического сохранения положения точки при перетаскивании
      // newCpeMarker.on('moveend', () => {
      //   this.updateCpesOnMap();
      // });
      newCpeMarker.on('moveend', (event) => {
        this.isCPEsChanged = true;
        // newCpeMarker.setIcon(this.cpeNewIcon);
        if (newCpeMarker.options.connected) {
          event.target.setIcon(this.cpeConnectedIcon);
        } else {
          event.target.setIcon(this.cpeDisconnectedIcon);
        }
      });

      newCpeMarker.on('contextmenu', (event) => {
        this.showContext(event, newCpeMarker.options.cpe_id);
      });
      // флаг перетаскивания нужен чтобы не опказывать попап окошко при перетаскивании точки
      newCpeMarker.on('dragstart', (event) => {
        this.pointIsDraging = true;
        event.target.closePopup();
      });
      // флаг перетаскивания нужен чтобы не опказывать попап окошко при перетаскивании точки
      newCpeMarker.on('dragend', (event) => {
        this.pointIsDraging = false;
        // event.target.closePopup();
        setTimeout(() => {
          // сразу после этого события поп-ап открывается, так как курсор на точкой, закрываем его через милисекунду
          event.target.closePopup();
        }, 1);
      });

      this.cpesIdsOnMap.push(cpe.cpe_id);

      // фиксим баг с добавлением точек на новую карту
      if (!this.mapLayers.cpesLayer) {
        this.initCpesLayer();
      }
      this.mapLayers.cpesLayer.addLayer(newCpeMarker);
      // console.log(newCpeMarker)
      // this.mapControls.cpesControl.addOverlay(newCpeMarker, newCpeMarker.options.name);
      this.cpesMarkers.push(newCpeMarker);
      // автосохранение новой точки
      // if (isNewCpe) {
      //    this.updateCpesOnMap();
      // }
      if (isNewCpe) {
        // this.updateCpesOnMap();
        // console.log('добавили новую точку');
        this.isCPEsChanged = true;
      }
    },
    deleteCpeFromMap(id) {
      this.cpesIdsOnMap.forEach((cpe_id, index) => {
        if (cpe_id === id) {
          this.cpesIdsOnMap.splice(index, 1);
        }
      });
      this.cpesMarkers.forEach((cpeMarker, index) => {
        if (cpeMarker.options.cpe_id === id) {
          this.mapLayers.cpesLayer.removeLayer(cpeMarker);
          this.cpesMarkers.splice(index, 1);
          // this.updateCpesOnMap();
          this.isCPEsChanged = true;
          this.resetContext();
        }
      });
    },
    showContext(event, id) {
      if (!this.mapMode.planning) {
        return;
      }
      this.contextMenu.item = id;
      this.contextMenu.top = event.containerPoint.y;
      this.contextMenu.left = event.containerPoint.x;
      this.showContextMenu = true;
    },
    resetContext() {
      this.showContextMenu = false;
      this.contextMenu.item = '';
      this.contextMenu.top = 0;
      this.contextMenu.left = 0;
    },
    searchInputHandler(value) {
      this.macFilter = value;
      clearTimeout(this.delayTimer);
      if (!value) {
        this.clientsMarker = [];
        this.searchSpinner = false;
        this.toggleSearchIcon();
        if (this.mapControls.clientsControl) {
          this.mapControls.clientsControl.remove();
        }
        return;
      }
      this.searchSpinner = true;
      this.toggleSearchIcon();
      this.delayTimer = setTimeout(() => {
        mapsService.getFiltredClients(this, this.editVisible, value);
      }, 2000);
    },
    selectClient(marker) {
      // console.log(marker)
      if (!marker) {
        return;
      }
      // console.log(marker);
      if (
        Object.prototype.hasOwnProperty.call(marker.options, 'mac_addr') &&
        marker.options.mac !== marker.options.mac_addr
      ) {
        // console.log('mac_addr')
        this.getClientInfoFromApi(marker.options.mac_addr);
      } else {
        // console.log('mac')
        this.getClientInfoFromApi(marker.options.mac);
      }
      this.resetFoundClient();
      this.foundClient = marker;
      this.findClient();
      this.setClientControl(marker);
    },
    selectClientWhenManyClientsData(client, marker) {
      if (!marker || !client) {
        return;
      }
      if (Object.prototype.hasOwnProperty.call(client, 'mac_addr') && marker.options.mac !== client.mac_addr) {
        // console.log('mac_addr')
        this.getClientInfoFromApi(client.mac_addr);
      } else {
        // console.log('mac')
        this.getClientInfoFromApi(client.mac);
      }
      this.resetFoundClient();
      this.foundClient = marker;
      this.findClient();
      this.setClientControlWhenManyClients(client, marker);
    },
    findClient() {
      // this.hideClientsLayer();
      // this.resetPath();
      // this.foundClient.addTo(this.map);
      // this.map.setView(this.foundClient._latlng);
      // this.foundClient.openTooltip();

      // this.hideClientsLayer();
      this.resetPath();
      if (this.$store.state.clickManyClientsPointToSeeUserInfo) {
        return;
      }
      if (!this.$store.state.clickUserPointAfterClickSpiderfyDataForSeeUserInfo) {
        this.foundClient.setIcon(this.clientDefaultIconBig);
      }

      this.foundClient.addTo(this.map);
      // this.map.setView(this.foundClient._latlng);
      this.foundClient.openTooltip();
    },
    resetPath() {
      if (this.map && this.pathLayer && this.map.hasLayer(this.pathLayer)) {
        this.map.removeLayer(this.pathLayer);
        this.pathLayer = false;
      }
      if (this.map && this.pathLayerArrows && this.map.hasLayer(this.pathLayerArrows)) {
        this.map.removeLayer(this.pathLayerArrows);
        this.pathLayerArrows = false;
      }
    },
    resetFoundClient() {
      // console.log('reset foun client')
      if (!this.map) {
        return;
      }
      const selectedClient = document.querySelector('.leaflet-selected-client');
      if (!selectedClient) {
        return;
      }
      selectedClient.innerHTML = '';

      if (this.foundClient) {
        // console.log('if found client')
        // this.resetPath();
        // this.map.removeLayer(this.foundClient);
        // this.foundClient = false;
        // this.showClientsLayer();
        // this.pathForSelectedClientShowing = false;

        this.resetPath();

        // // возвращаем слой с выбрным юзером обртано
        this.map.addLayer(this.foundClient);

        // тут обработка случая когда клик блы на точке с множеством пользователей
        if (this.$store.state.clickManyClientsPointToSeeUserInfo === true) {
          this.foundClient = false;
          this.pathForSelectedClientShowing = false;
          this.selectedClientDataFromClientsApi = {};
          this.$nextTick(() => {
            if (this.mapLayers.clientsLayer) {
              this.mapLayers.clientsLayer.refreshClusters();
            }
          });
          return;
        }

        if (this.$store.state.clickInClusterDataForSeeUserInfo === true) {
          // если мы  выбирали точку- юзера не на карте а из списка при клике на кластер, тогда если мы вернем ей сандартную икноку
          // на карте появится лишняя точка (т.к точка клиента вобще не отображалась, только точка кластера)
          // в таком случае просто удаляем этот слой и выключаем режим, включаемый при выборе клиента в списке из  кластера
          this.foundClient.remove(this.map);
          this.$store.commit('clickInClusterDataForSeeUserInfoDisable');
        } else if (this.$store.state.clickUserPointAfterClickSpiderfyDataForSeeUserInfo === true) {
          // а тут если мы выбрали точку из одуванчика, удаляем ее чтобы потом не прорисовывалась поверх кластера
          // this.foundClient.setIcon(this.clientDefaultIcon)
          this.foundClient.remove(this.map);
          this.$store.commit('clickUserPointAfterClickSpiderfyDataForSeeUserInfoDisable');
        } else if (
          this.foundClient.options.hasOwnProperty('loadaedFromSearch') &&
            this.foundClient.options.loadaedFromSearch === true
        ) {
          // проверяем был ли клиент отрисован после поиска с использованием API, если да, удаляем с карты
          this.foundClient.remove(this.map);
        } else {
          // а тут если был клик просто по точке на карте, не в составе кластеров
          // ставим ее на место, где она и была нарисована
          this.foundClient.setIcon(this.clientDefaultIcon);
        }
        // this.foundClient.setIcon(this.clientDefaultIcon)
        this.foundClient = false;
        // this.showClientsLayer();
        this.pathForSelectedClientShowing = false;
      }
      this.selectedClientDataFromClientsApi = {};
    },
    getPath(marker, client = undefined) {
      this.resetPath();
      let mac_addr;
      if (client === undefined) {
        mac_addr = marker.options.mac_addr;
      } else {
        mac_addr = client.mac_addr;
      }
      this.$store.commit('setIsGetClientPathRequestExecutingBegin');
      mapsService.getClientPath(this, mac_addr).then(
        (response) => {
          if (response.data.status === 'success') {
            const coords = response.data.data.itemslist;
            const pathmarkers = [];
            this.pathForSelectedClientShowing = true;
            this.pathForSelectedClientByTime = {};
            this.pathForSelectedClient = [];
            this.pathForSelectedClientTimestampsArray = [];
            this.pathForSelectedClientLastPointTimestamp = null;
            this.pathForSelectedClientSelectedTimestamp = null;
            const polyline = L.polyline(coords, { color: '#00ff35', weight: 1 });
            coords.forEach((point) => {
              const pathPoint = new L.marker([point.lat, point.lng], {
                icon: this.clientPathDot,
                timestamp: moment(point.timestamp * 1000).format(' D MMM YYYY, H:mm '),
                unixTimestamp: point.timestamp,
                pane: 'tooltipPane',
                riseOnHover: true
              });
              pathPoint
                .bindTooltip(
                  `<ul class="list-unstyled m-0 p-0">
                                    <li><strong>Time:</strong>  ${pathPoint.options.timestamp}</li>
                                </ul>`,
                  {
                    permanent: false,
                    direction: 'top',
                    offset: [0, -10]
                  }
                )
                .openTooltip();
              pathmarkers.push(pathPoint);
              this.pathForSelectedClientByTime[point.timestamp] = pathPoint;
              this.pathForSelectedClient.push(pathPoint);
            });

            if (this.pathForSelectedClient.length !== 0) {
              this.pathForSelectedClientLastPointTimestamp = Math.max(...Object.keys(this.pathForSelectedClientByTime));
              this.pathForSelectedClientSelectedTimestamp = this.pathForSelectedClientLastPointTimestamp;
              this.pathForSelectedClientTimestampsArray = Object.keys(this.pathForSelectedClientByTime).map((item) => parseInt(item));
              // console.log(this.pathForSelectedClient)
              this.pathForSelectedClient[0].setIcon(this.clientPathDotFirst);
              if (this.pathForSelectedClient.length > 1) {
                this.pathForSelectedClient[this.pathForSelectedClient.length - 1].setIcon(this.clientPathDotLast);
              }
            }
            const coordForArrows = [];
            coords.forEach((coord) => {
              coordForArrows.push([coord.lat, coord.lng]);
            });
            // console.log(coordForArrows)
            // слой со стрелками
            this.pathLayerArrows = L.featureGroup(this.getArrows(coordForArrows, 'red', 2, this.map)).addTo(this.map);
            this.map.addLayer(this.pathLayerArrows);

            this.pathLayer = L.featureGroup(pathmarkers).addLayer(polyline).addTo(this.map);
            // console.log(coords)

            // // убираем точку, обозначающую клиента, чтобы не мешалась при просмотре пути, который он прошел
            // if (this.foundClient) {
            //   this.map.removeLayer(this.foundClient);
            // }

            // console.log(this.pathLayer);
            // если в пути выбранного клиента одна точка не изменяем масштаб карты
            // а если больше то изменяем, чтобы все уместилось

            // теперь рещили вообще отключить  зум при показывании пути
            // if (this.pathForSelectedClient.length !== 1 && this.pathForSelectedClient.length !== 0) {
            //   this.map.fitBounds(this.pathLayer.getBounds());
            // }
          } else if (response.data.status === 'error') {
          }
          this.$store.commit('setIsGetClientPathRequestExecutingEnd');
        },
        (err) => {
          this.$store.commit('setIsGetClientPathRequestExecutingEnd');
          // VueNotifications.error({ message: err });
        }
      );
    },
    resetCpesLayer() {
      if (this.mapLayers.cpesLayer) {
        this.map.removeLayer(this.mapLayers.cpesLayer);
        this.mapControls.layersControl.removeLayer(this.mapLayers.cpesLayer);
        this.mapLayers.cpesLayer = false;
      }
    },
    resetClientsLayer() {
      if (this.mapLayers.clientsLayer) {
        this.map.removeLayer(this.mapLayers.clientsLayer);
        this.mapControls.layersControl.removeLayer(this.mapLayers.clientsLayer);
        this.mapLayers.clientsLayer = false;
      }
    },
    hideClientsLayer() {
      if (this.map && this.mapLayers.clientsLayer) {
        this.map.removeLayer(this.mapLayers.clientsLayer);
      }
    },
    showCPESLayers() {
      try {
        if (this.map) {
          this.map.addLayer(this.mapLayers.cpesLayer);
        }
      } catch (e) {
        // console.log(e)
      }
    },
    showClientsLayer() {
      if (this.map && this.mapLayers.clientsLayer) {
        this.map.addLayer(this.mapLayers.clientsLayer);
      }
    },
    showZonesLayer() {
      if (this.map && this.zonesLayers) {
        this.map.addLayer(this.zonesLayers);
      }
    },
    saveCpeDisposition(mode) {
      if (!this.isMapUpdating) {
        this.isMapUpdating = true;
        setTimeout(() => {
          mapsService.updateCpesOnMap(this);
          this.isMapUpdating = false;
          //  this.toggleMode(mode);
        }, 1000);
      } else {
      }
    },
    toggleMode(mode) {
      this.setCursorForModes(mode);
      if (this.isCPEsChanged === true) {
        // console.log('есть измененя');
        // this.showingCPEChangedModal = true;
        this.CPEChangedModalShow('toggleMode', mode);
        return;
      }
      this.showZoneEditing = false;
      if (this.zonesDrawButtons !== false) {
        this.map.removeControl(this.zonesDrawButtons);
        this.zonesDrawButtons = false;
      }

      if (this.zonesLayers !== false) {
        this.map.removeLayer(this.zonesLayers);
        // this.zonesLayers = false;
      }

      if (!this.currentMapData.upp || this.loadingCurrentMapData) {
        return;
      }

      // console.log('change')
      this.resetAllMode();
      this.resetAllLayers();
      this.resetControls();

      // this.clearShowMode();

      this.mapMode[mode] = true;
      this.changeModeSelect();

      this.refreshMapCpes();

      if (this.mapMode.show) {
        if (this.isRestoreOfMapZoomAndCenterEnabled) {
          // console.log('restore')
          this.restoreMapZoomAndCenter(true); // восстанавливаем зум и (если можем) центр карты
        }
        this.showMode.clientsLastPathPoints = true;
        this.showClientsLayer();
        this.initShowModeControl();
        this.addZonesOnMap();
      } else if (this.mapMode.planning) {
        this.hideClientsLayer();
        this.showZonesLayer();
      } else if (this.mapMode.zones) {
        this.hideClientsLayer();
        this.showZoneEditing = true;
        this.initZonesEditMode();
      }
    },
    changeModeSelect() {
      const modeControlSelect = document.querySelector('.leaflet-mode-control-select');
      if (!modeControlSelect) {
        return;
      }

      if (this.mapMode.show) {
        modeControlSelect.options[0].selected = true;
      } else if (this.mapMode.planning) {
        modeControlSelect.options[1].selected = true;
      } else if (this.mapMode.scale) {
        modeControlSelect.options[2].selected = true;
      } else if (this.mapMode.zones) {
        modeControlSelect.options[3].selected = true;
      }
    },
    refreshMapCpes() {
      // в зависимости от того полказыать ли юзеру статистику по точке или нет
      // просто обновляем слой с точками, или еще и подгружаем статистику
      if (this.showCPEsStatisticForUser) {
        this.getCpesRatingAndClientsAndRerefreshMapCpes();
      } else {
        this.refreshMapCpesNOgetCpesRatingAndClients();
      }
      // this.resetCpesLayer();
      // this.addCpesOnMap();
    },
    refreshMapCpesNOgetCpesRatingAndClients() {
      // console.log('refreshed');
      this.resetCpesLayer();
      this.addCpesOnMap();
    },
    getCpesRatingAndClientsAndRerefreshMapCpes() {
      const cpeList = [];
      for (const cpe of this.currentMapData.cpe_list) {
        cpeList.push(cpe.cpe_id);
      }
      // console.log(cpeList)
      mapsService.getCpesRatingAndClientsAndRefreshMapCpes(this, cpeList);
    },
    resetAllMode() {
      this.mapMode.scale = false;
      this.mapMode.zones = false;
      this.mapMode.show = false;
      this.mapMode.planning = false;
      this.showMode.heatmap = false;
      this.showMode.timeline = false;
      this.showMode.bubble_clients = false;
      this.showMode.bubble_lbs = false;
      this.showMode.bubble_traffic = false;
      this.showMode.clientsLastPathPoints = false;
    },
    toggleHeatmap(heatMapButton) {
      // console.log('toggle heatmap')
      if (this.loadingCurrentMapData) {
        return;
      }

      if (!this.mapLayers.heatLayer) {
        //  // with Leaflet.heat
        // const heatmapData = this.makeDataForHeatmapLeafletHeat(this.clientsOnMap)
        // this.mapLayers.heatLayer = L.heatLayer(heatmapData.dataForHeatmap, {
        //   blur: 30,
        //   radius: 30
        //   // minOpacity: 0.1,
        //   // max: 0.1,
        //   // radius: 30,
        //   // blur: 30,
        //   // maxZoom: 13,
        //   // gradient: {
        //   //   0: 'Navy',
        //   //   0.25: 'Blue',
        //   //   0.5: 'Green',
        //   //   0.65: 'Yellow',
        //   //   0.9: 'Orange',
        //   //   1: 'Red'
        //   // }
        // });
        // // with Heatmap js
        // const heatmapData = this.makeDataForHeatmapLeafletHeatMAPJS(this.clientsOnMap)
        // this.mapLayers.heatLayer = new HeatmapOverlay (this.configForHeatmapJS)
        // this.mapLayers.heatLayer.setData(heatmapData)

        // with selfmade heatmap
        const selfmadeHeatmapData = this.makeDataForHeatmapSelfmade(this.clientsOnMap);
        const selfmadeHeatmapRectangles = this.createRectanglesForSelfmadeHeatmap(selfmadeHeatmapData);
        // console.log(selfmadeHeatmapRectangles)
        this.mapLayers.heatLayer = L.layerGroup(selfmadeHeatmapRectangles);
      }
      if (this.showMode.heatmap) {
        if (this.beforeTimelineShowShowingMode.length > 0 && this.beforeTimelineShowShowingMode.includes('heatmap')) {
          const index = this.beforeTimelineShowShowingMode.findIndex((item) => item === 'heatmap');
          this.beforeTimelineShowShowingMode.splice(1, index);
        }
        this.showMode.heatmap = false;
        heatMapButton.classList.remove('active');
        this.mapLayers.heatLayer.remove();
        this.showHeatmapLegend = false;
      } else {
        if (this.beforeTimelineShowShowingMode.length > 0 && !this.beforeTimelineShowShowingMode.includes('heatmap')) {
          this.beforeTimelineShowShowingMode.push('heatmap');
        }
        this.showMode.heatmap = true;
        heatMapButton.classList.add('active');
        this.updateHeatmap();
      }
    },
    // toggleTimeline() {
    //   const elements = Array.from(document.querySelectorAll('#hide-on-timeline'));
    //   if (this.showMode.timeline) {
    //     this.showMode.timeline = false;
    //     elements.forEach((el) => (el.hidden = false));
    //   } else {
    //     this.resetBubble();
    //     this.showMode.timeline = true;
    //     elements.forEach((el) => (el.hidden = true));
    //   }
    //   this.getClientsOnMap();
    // },
    toggleTimelineV2() {
      // console.log(this.showMode)

      const elements = Array.from(document.querySelectorAll('#hide-on-timeline'));
      if (this.showMode.timeline) {
        // если выключаем режим таймлайна
        this.mapVisitorsSelectedSegmentNumber = undefined;
        this.resetClientsLayer();
        this.showMode.timeline = false;
        // возвращаем спрятаные кнопочки
        elements.forEach((el) => (el.hidden = false));
        // устанавливаем режим, который был до включения таймлайна
        this.setShowedMode(this.beforeTimelineShowShowingMode);
        // // рефрешим
        // this.refreshV2();
      } else {
        // если включаем режим таймлайна
        // запоминаем какой режим был до включения
        this.beforeTimelineShowShowingMode = this.getShowedMode();
        // убираем пузыри
        this.resetBubble();
        this.showMode.timeline = true;
        // // прячем кнопочки
        // elements.forEach((el) => (el.hidden = true));
        // // подгружаем данные
        // this.getClientsOnMap();
        // подгружаем данные в зависимости от выбранного режима
        if (this.beforeTimelineShowShowingMode.includes('clientsLastPathPoints')) {
          this.getClientsOnMap();
        } else if (this.beforeTimelineShowShowingMode.includes('bubble_clients')) {
          mapsService.getBubblesTimeline(
            this,
            this.currentMapData.id,
            'bubble_clients',
            this.switchSegmentForBubbles.bind(this)
          );
        } else if (this.beforeTimelineShowShowingMode.includes('bubble_lbs')) {
          mapsService.getBubblesTimeline(
            this,
            this.currentMapData.id,
            'bubble_lbs',
            this.switchSegmentForBubbles.bind(this)
          );
        } else if (this.beforeTimelineShowShowingMode.includes('bubble_traffic')) {
          mapsService.getBubblesTimeline(
            this,
            this.currentMapData.id,
            'bubble_traffic',
            this.switchSegmentForBubbles.bind(this)
          );
        }
      }
      // this.getClientsOnMap();
    },
    toggleBubble(type) {
      if (this.loadingCurrentMapData) {
        return;
      }
      this.resetClientsLayer();
      if (this.showMode[type]) {
        this.resetBubble();
        this.showMode.clientsLastPathPoints = false;
      } else {
        this.resetBubble();
        this.showMode.clientsLastPathPoints = false;
        this.showMode[type] = true;
        if (this.showMode.bubble_lbs) {
          mapsService.getBubbleLbs(this, this.$store.state.editMapId);
        } else if (this.showMode.bubble_clients) {
          mapsService.getBubbleClients(this, this.$store.state.editMapId);
        } else if (this.showMode.bubble_traffic) {
          mapsService.getBubbleTraffic(this, this.$store.state.editMapId);
        }
      }
    },
    drawBubbleForTimeline() {
      this.map.removeLayer(this.mapLayers.bublesLayer);
      this.mapLayers.bublesLayer = L.featureGroup().addTo(this.map);
      this.bubblesOnMaps.forEach((bubble) => {
        let bubbleTooltipTemplate;
        if (this.beforeTimelineShowShowingMode.includes('bubble_lbs')) {
          bubbleTooltipTemplate = ` <ul class="text-center list-unstyled m-q p-0">
                            <li class="bubble-result"> ${bubble.result}</li>
                            <li class="bubble-descr">${this.$t('maps.bublePasserbyClients')}</li>
                         </ul>`;
        } else if (this.beforeTimelineShowShowingMode.includes('bubble_clients')) {
          bubbleTooltipTemplate = `<ul class="text-center list-unstyled m-q p-0">
                            <li class="bubble-result"> ${bubble.result}</li>
                            <li class="bubble-descr">${this.$t('maps.bubleConnectedClients')}</li>
                         </ul>`;
        } else if (this.beforeTimelineShowShowingMode.includes('bubble_traffic')) {
          bubbleTooltipTemplate = `<ul class="text-center list-unstyled m-q p-0">
                                                    <li class="bubble-result bubble-result--traffic">
                                                     ${commonService.formatBytes(bubble.result)}
                                                    </li>
                                                    <li class="bubble-result bubble-result--traffic-rxtx">${this.$t(
    'maps.bubbleRX'
  )}:
                                                     ${commonService.formatBytes(bubble.result_rx)}
                                                    </li>
                                                    <li class="bubble-result bubble-result--traffic-rxtx">${this.$t(
    'maps.bubbleTX'
  )}:
                                                     ${commonService.formatBytes(bubble.result_tx)}
                                                    </li>
                                                </ul>`;
        }

        bubble.fillColor = this.beforeTimelineShowShowingMode.includes('bubble_lbs') ?
          '#f8cb00' :
          this.beforeTimelineShowShowingMode.includes('bubble_clients') ?
            '#88ec89' :
            this.beforeTimelineShowShowingMode.includes('bubble_traffic') ?
              '#7cb5ec' :
              '#cecece';
        bubble.color = this.beforeTimelineShowShowingMode.includes('bubble_lbs') ?
          '#f8cb00' :
          this.beforeTimelineShowShowingMode.includes('bubble_clients') ?
            '#88ec89' :
            this.beforeTimelineShowShowingMode.includes('bubble_traffic') ?
              '#7cb5ec' :
              '#cecece';
        const bubleMarker = L.circle(bubble.center, bubble)
          .bindTooltip(bubbleTooltipTemplate, this.tooltipBubbleOptions)
          .openTooltip();
        this.mapLayers.bublesLayer.addLayer(bubleMarker);
      });
      this.highlightCpeTooltipsIfHighlightEnabled();
    },
    fillBubbleForTimeLine(cpesData, max, min, segmentNumber) {
      // тут происходит первоначальная отрисовка пузырей, еще же есть перерисовка при изменении
      // масштаба карты, это в функции mapZoomLevelChange
      const b = this.currentBounds[1];
      const a = this.currentBounds[0];
      const distance = Math.sqrt(Math.pow(b[1] - a[1], 2) + Math.pow(b[0] - a[0], 2));

      const maxRadius = distance * 0.2;
      const minRadius = distance * 0.1;
      const maxOpacity = 0.8;
      const minOpacity = 0.4;
      const radiusDiff = maxRadius - minRadius;
      const opacityDiff = maxOpacity - minOpacity;

      const valuesDiff = max - min;
      const radiusStep = radiusDiff / valuesDiff;
      const opacityStep = opacityDiff / valuesDiff;

      this.segmentedForBubbles[segmentNumber] = [];

      // this.bubblesOnMaps = [];
      cpesData.forEach((cpe) => {
        for (const mapCpe of this.cpesMarkers) {
          if (mapCpe.options.cpe_id === cpe.cpe_id) {
            const calculatedBubbleRadius = (() => {
              if (cpe.total === max) {
                return maxRadius;
              }
              if (cpe.total === min) {
                return minRadius;
              }
              return (cpe.total - min) * radiusStep + minRadius;
            })();

            const calculatedBubleOpacity = (() => {
              if (cpe.total === max) {
                return maxOpacity;
              }
              if (cpe.total === min) {
                return minOpacity;
              }
              return (cpe.total - min) * opacityStep + minOpacity;
            })();

            const mapZoom = this.map._zoom;

            this.segmentedForBubbles[segmentNumber].push({
              center: L.latLng(mapCpe._latlng.lat, mapCpe._latlng.lng),
              // radius: calculatedBubbleRadius * this.bubbleRadiusCoefficientCalculatedUsingMapZoomValue(this.map._zoom),
              radius: 0,
              fillOpacity: calculatedBubleOpacity,
              cpe_id: mapCpe.options.cpe_id,
              result: cpe.total,
              result_rx: cpe.total_rx,
              result_tx: cpe.total_tx,
              stroke: false,
              original_radius: calculatedBubbleRadius
            });
          }
        }
      });
    },
    drawBubble(cpesData, max, min) {
      // тут происходит первоначальная отрисовка пузырей, еще же есть перерисовка при изменении
      // масштаба карты, это в функции mapZoomLevelChange
      const b = this.currentBounds[1];
      const a = this.currentBounds[0];
      const distance = Math.sqrt(Math.pow(b[1] - a[1], 2) + Math.pow(b[0] - a[0], 2));

      const maxRadius = distance * 0.2;
      const minRadius = distance * 0.1;
      const maxOpacity = 0.8;
      const minOpacity = 0.4;
      const radiusDiff = maxRadius - minRadius;
      const opacityDiff = maxOpacity - minOpacity;

      const valuesDiff = max - min;
      const radiusStep = radiusDiff / valuesDiff;
      const opacityStep = opacityDiff / valuesDiff;

      this.bubblesOnMaps = [];
      cpesData.forEach((cpe) => {
        for (const mapCpe of this.cpesMarkers) {
          if (mapCpe.options.cpe_id === cpe.cpe_id) {
            const calculatedBubbleRadius = (() => {
              if (cpe.total === max) {
                return maxRadius;
              }
              if (cpe.total === min) {
                return minRadius;
              }
              return (cpe.total - min) * radiusStep + minRadius;
            })();

            const calculatedBubleOpacity = (() => {
              if (cpe.total === max) {
                return maxOpacity;
              }
              if (cpe.total === min) {
                return minOpacity;
              }
              return (cpe.total - min) * opacityStep + minOpacity;
            })();

            const mapZoom = this.map._zoom;

            this.bubblesOnMaps.push({
              center: L.latLng(mapCpe._latlng.lat, mapCpe._latlng.lng),
              // radius: calculatedBubbleRadius * this.bubbleRadiusCoefficientCalculatedUsingMapZoomValue(this.map._zoom),
              radius: 0,
              fillOpacity: calculatedBubleOpacity,
              cpe_id: mapCpe.options.cpe_id,
              result: cpe.total,
              result_rx: cpe.total_rx,
              result_tx: cpe.total_tx,
              stroke: false,
              original_radius: calculatedBubbleRadius
            });
          }
        }
      });

      this.mapLayers.bublesLayer = L.featureGroup().addTo(this.map);

      this.bubblesOnMaps.forEach((bubble) => {
        let bubbleTooltipTemplate;
        if (this.showMode.bubble_lbs) {
          bubbleTooltipTemplate = ` <ul class="text-center list-unstyled m-q p-0">
                            <li class="bubble-result"> ${bubble.result}</li>
                            <li class="bubble-descr">${this.$t('maps.bublePasserbyClients')}</li>
                         </ul>`;
        } else if (this.showMode.bubble_clients) {
          bubbleTooltipTemplate = `<ul class="text-center list-unstyled m-q p-0">
                            <li class="bubble-result"> ${bubble.result}</li>
                            <li class="bubble-descr">${this.$t('maps.bubleConnectedClients')}</li>
                         </ul>`;
        } else if (this.showMode.bubble_traffic) {
          bubbleTooltipTemplate = `<ul class="text-center list-unstyled m-q p-0">
                                                    <li class="bubble-result bubble-result--traffic">
                                                     ${commonService.formatBytes(bubble.result)}
                                                    </li>
                                                    <li class="bubble-result bubble-result--traffic-rxtx">${this.$t(
    'maps.bubbleRX'
  )}:
                                                     ${commonService.formatBytes(bubble.result_rx)}
                                                    </li>
                                                    <li class="bubble-result bubble-result--traffic-rxtx">${this.$t(
    'maps.bubbleTX'
  )}:
                                                     ${commonService.formatBytes(bubble.result_tx)}
                                                    </li>
                                                </ul>`;
        }

        bubble.fillColor = this.showMode.bubble_lbs ?
          '#f8cb00' :
          this.showMode.bubble_clients ?
            '#88ec89' :
            this.showMode.bubble_traffic ?
              '#7cb5ec' :
              '#cecece';
        bubble.color = this.showMode.bubble_lbs ?
          '#f8cb00' :
          this.showMode.bubble_clients ?
            '#88ec89' :
            this.showMode.bubble_traffic ?
              '#7cb5ec' :
              '#cecece';
        const bubleMarker = L.circle(bubble.center, bubble)
          .bindTooltip(bubbleTooltipTemplate, this.tooltipBubbleOptions)
          .openTooltip();
        this.mapLayers.bublesLayer.addLayer(bubleMarker);
      });
      this.highlightCpeTooltipsIfHighlightEnabled();
    },
    handleDateChange(newDate) {
      // this.searchInputHandler('');
      // // this.dateRange.startDate = newDate.start.valueOf();
      // // this.dateRange.endDate = newDate.end.valueOf();
      // this.resetAllLayers();
      // this.resetButtons();
      // this.getClientsOnMap();

      this.refreshV2();
    },
    tryToGetClients() {
      this.warningMapModal = false;
      this.getClientsOnMap();
    },
    closeWarningModal() {
      this.warningMapModal = false;
    },
    // updateCpesOnMap() {
    //   if (!this.isMapUpdating) {
    //     this.isMapUpdating = true;
    //     setTimeout(() => {
    //       mapsService.updateCpesOnMap(this);
    //       this.isMapUpdating = false;
    //     }, 1000);
    //   } else {
    //   }
    // }
    updateCpesOnMap() {
      if (!this.isMapUpdating) {
        this.isMapUpdating = true;
        setTimeout(() => {
          mapsService.updateCpesOnMap(this);
          this.isMapUpdating = false;
        }, 1000);
      } else {
      }
    },
    saveMapZoomAndCenterValuesToLocalStorage(mapZoom, mapId, mapCenterLat, mapCenterLng) {
      try {
        // используется для сохраенния масштаба конкретной карты и координат ее центра в localStorage

        // console.log(mapZoom);
        // console.log(mapId);
        // console.log(mapCenterLat)
        // console.log(mapCenterLng)

        if (mapZoom !== undefined && mapId !== undefined && mapCenterLat !== undefined && mapCenterLng !== undefined) {
          // смотрим есть ли вобще такой объект в localstorage, если нет то создаем пустой
          if (localStorage.getItem('mapsZoomAndCenter') === null) {
            localStorage.setItem('mapsZoomAndCenter', '{}');
          }
          const mapsZoomAndCenterObjFromLocalStorage = JSON.parse(localStorage.getItem('mapsZoomAndCenter'));

          // смотрим есть ли у этого объекта из localstorage ключ с ID этой карты,
          // где хрянятся зум и координаты центра для нее,
          // если нет,то создаем.
          if (!Object.prototype.hasOwnProperty.call(mapsZoomAndCenterObjFromLocalStorage, mapId)) {
            mapsZoomAndCenterObjFromLocalStorage[mapId] = {};
          }
          const currentMapZoomAndCenterObjForMapIdFromLocalStorage = mapsZoomAndCenterObjFromLocalStorage[mapId];

          // сохраняем координаты центра и значение зума в объект, доступный по ID этой карты
          // в качестве ключа
          currentMapZoomAndCenterObjForMapIdFromLocalStorage.zoom = mapZoom;
          currentMapZoomAndCenterObjForMapIdFromLocalStorage.centerLat = mapCenterLat;
          currentMapZoomAndCenterObjForMapIdFromLocalStorage.centerLng = mapCenterLng;

          // и сохраняем это все обратно в localstorage
          localStorage.setItem('mapsZoomAndCenter', JSON.stringify(mapsZoomAndCenterObjFromLocalStorage));
          // console.log(mapsZoomAndCenterObjFromLocalStorage);
          // this.map.setView(new L.LatLng(mapCenterLat + 1, mapCenterLng - 1), mapZoom);
          // console.log('saved');
        }
      } catch (e) {
        console.log(e);
      }
    },
    getSavedMapZoomAndCenterValuesFromLocalStorage(mapId) {
      // результат по умолчанию
      const result = {
        zoom: undefined,
        centerLat: undefined,
        centerLng: undefined
      };

      // если такого объекта вообще нет в localstorage возвращаем результат по умолчанию
      if (localStorage.getItem('mapsZoomAndCenter') === null) {
        return result;
      }

      // смотртим есть ли в mapsZoomAndCenter ключ-со значением интересующей карты, если нет возвращаем результат
      // по умолчанию
      const mapsZoomAndCenterObjFromLocalStorage = JSON.parse(localStorage.getItem('mapsZoomAndCenter'));
      if (!Object.prototype.hasOwnProperty.call(mapsZoomAndCenterObjFromLocalStorage, mapId)) {
        return result;
      }

      // если все таки нашелся объект по ключу-id карты то наполняем объект с результатом значениями из
      // local storage, если они там есть
      const currentMapZoomAndCenterObjForMapIdFromLocalStorage = mapsZoomAndCenterObjFromLocalStorage[mapId];

      if (Object.prototype.hasOwnProperty.call(currentMapZoomAndCenterObjForMapIdFromLocalStorage, 'zoom')) {
        result.zoom = currentMapZoomAndCenterObjForMapIdFromLocalStorage.zoom;
      }
      if (Object.prototype.hasOwnProperty.call(currentMapZoomAndCenterObjForMapIdFromLocalStorage, 'centerLat')) {
        result.centerLat = currentMapZoomAndCenterObjForMapIdFromLocalStorage.centerLat;
      }
      if (Object.prototype.hasOwnProperty.call(currentMapZoomAndCenterObjForMapIdFromLocalStorage, 'centerLng')) {
        result.centerLng = currentMapZoomAndCenterObjForMapIdFromLocalStorage.centerLng;
      }
      return result;
    },
    restoreMapZoomAndCenter(animationEnable = false) {
      // восстанавливаем зум карты и центровку карты
      try {
        const mapAndZoomForCurrentMap = this.getSavedMapZoomAndCenterValuesFromLocalStorage(this.currentMapData.id);
        const { zoom } = mapAndZoomForCurrentMap;
        const { centerLng } = mapAndZoomForCurrentMap;
        const { centerLat } = mapAndZoomForCurrentMap;

        if (zoom === undefined && centerLng === undefined && centerLat === undefined) {
          // инчего не делаем если почему то все поля undefined
          // console.log('all undefined');
          return;
        }
        if (zoom !== undefined && centerLng !== undefined && centerLat !== undefined) {
          // console.log('all');
          // если все поля есть утсанавливаем или центр и зум или просто зум
          const centerPoint = new L.LatLng(centerLat, centerLng);
          if (centerPoint) {
            this.map.setView(centerPoint, zoom, { animate: animationEnable });
          } else {
            this.map.setZoom(zoom, { animate: animationEnable });
          }
          return;
        }
        if (zoom !== undefined && (centerLng === undefined || centerLat === undefined)) {
          // если есть только зум, то устанавливаем только зум
          // console.log('only zoom');
          this.map.setZoom(zoom, { animate: animationEnable });
          return;
        }
      } catch (e) {
        console.error(e);
      }
    },
    deleteSavedMapZoomAndCenterValuesForMapFromLocalStorage(mapId) {
      // используется для удаления сохраненных в local storage данных о зуме и центре конкретной карты
      // например после сохранения карты в режиме изменения масштаба
      // (где выбор 2 точек и расстояния между ними)
      try {
        if (mapId !== undefined) {
          // смотрим есть ли вобще такой объект в local storage
          if (localStorage.getItem('mapsZoomAndCenter') !== null) {
            // смотртим есть ли в mapsZoomAndCenter ключ-со значениями интересующей карты
            const mapsZoomAndCenterObjFromLocalStorage = JSON.parse(localStorage.getItem('mapsZoomAndCenter'));
            if (Object.prototype.hasOwnProperty.call(mapsZoomAndCenterObjFromLocalStorage, mapId)) {
              // если есть - удаляем этот ключ вместе со всеми данными и обновляем в local storage
              // объект с данными по центру и зуму карт уже без данных по карте с id == mapId
              // console.log(`Deleted for map ${mapId}`)
              delete mapsZoomAndCenterObjFromLocalStorage[mapId];
              localStorage.setItem('mapsZoomAndCenter', JSON.stringify(mapsZoomAndCenterObjFromLocalStorage));
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  },
  filters: {
    moment(value) {
      if (value) {
        return moment(value * 1000).format(' D MMM YYYY, H:mm ');
      }
    }
  },
  // created() {
  //   cpeService.getAllCompactCpes(this);
  //   // mapsService.getMaps(this);
  //   mapsService.getMapsV2(this);
  //   this.$store.commit('setMapsEditId', null);
  //   // следующий блок нужен для работы модального окна с раширенной информайцие по точке
  //   templatesService.getCpeModels(this);
  //   templatesService.getCpeTemplates(this);
  //   cpeService.getCpesTags(this);
  //   wlanService.getAllWlans(this);
  //   wlanService.getHosts(this);
  //   redirectsService.getRedirectsRule(this);
  //   if (!String.prototype.format) {
  //     String.prototype.format = function () {
  //       const args = arguments;
  //       return this.replace(/{(\d+)}/g, (match, number) => (typeof args[number] !== 'undefined' ? args[number] : match));
  //     };
  //   }
  //   // конец блока
  // }
    created() {
     // this.readCpeTooltipsHighlightEnablingStateFromLocalStorageAndInit();
    // cpeService.getAllCompactCpes(this);
      cpeService.getAllCompactCpesWithDataRemaining(this);
    // mapsService.getMaps(this);
      let inHeaderLocationSelectedApplying = true;
    if (this.$store.state.inHeaderLocationSelectModeEnabled &&
      inHeaderLocationSelectedApplying) {
      let withChildsForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
      let locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
      if (!locationForFiltering || locationForFiltering === '') {
        // locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation
        locationForFiltering = 'All locations';
      }
      if (locationForFiltering === 'All locations') {
        this.locationInFiltersData = [];
      } else {
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
      }

      this.filters.location = locationForFiltering;
      this.filters.with_childs = withChildsForFiltering;
    }

    mapsService.getMapsV3(this, true);
    this.$store.commit('setMapsEditId', null);
    // следующий блок нужен для работы модального окна с раширенной информайцие по точке
    templatesService.getCpeModels(this);
    templatesService.getCpeTemplates(this);
    cpeService.getCpesTags(this);
    // wlanService.getAllWlans(this);
    wlanService.getAllCompactWlansWithDataRemainingWithoutClients(this);
    wlanService.getHosts(this);
    redirectsService.getRedirectsRule(this);
    if (!String.prototype.format) {
      String.prototype.format = function () {
        const args = arguments;
        return this.replace(/{(\d+)}/g, (match, number) => (typeof args[number] !== 'undefined' ? args[number] : match));
      };
    }
    // конец блока
  }
};
</script>

<style lang="scss">
@import '~leaflet/dist/leaflet.css';
@import '~leaflet.markercluster/dist/MarkerCluster.css';
@import '~leaflet.markercluster/dist/MarkerCluster.Default.css';

@import '~leaflet-fullscreen/dist/leaflet.fullscreen.css';
/*@import "~leaflet-search/dist/leaflet-search.min.css";*/

.tooltip-highlighted {
  /*  -webkit-box-shadow:inset 0px 0px 0px 1px blue !important;*/
  /*-moz-box-shadow:inset 0px 0px 0px 1px blue !important;*/
  /*box-shadow:inset 0px 0px 0px 1px blue !important;*/
  border: 2px gray solid !important;
  background-color: #f4f4f4;
  box-shadow: 0px 3px 5px 2px rgba(0, 0, 0, 0.5);
}

/* Map */
.maps {
  display: flex;
}
.map-list {
  display: inline-block;
  width: 100%;
  min-width: 170px;
}
.map-edit {
  display: inline-block;
  width: 0%;
  word-wrap: break-word;
  min-width: 600px;
}

.map-list,
.map-edit {
  transition: width 0.2s;
}

.map-filter-block {
  position: static;
}

.map-container {
  position: relative;
  min-width: 100px;
  min-height: 570px;
  width: 100%;
  display: flex;
}

.edit-visible .map-list {
  transition: width, margin-right 0.2s;
  margin-right: 10px;
  width: 20%;
  min-width: 200px;
}

.edit-visible .map-edit {
  min-width: 700px;
  transition: width 0.2s;
  width: 80%;
}

.map-row {
  transition: background 0.15s;
  cursor: pointer;
}
.map-row.map-row-disabled {
  cursor: not-allowed;
}

.map-row.selected {
  background: #d9e3ec;
}

.map-row:hover {
  background: rgba(236, 236, 236, 0.59);
}

/* leaflet map */
.map {
  display: flex;
  width: 100%;
}

/* End map */

/* Assign block */
.assign-cpes-items {
  height: 288px;
  overflow-y: scroll;
  margin-bottom: 10px;
}

.assign-cpes-item {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  & .add-text {
    display: none;
    transition: all 0.2s;
  }
  &:hover .add-text {
    display: inline;
  }
}

.assign-cpes-item:hover {
  background: rgba(236, 236, 236, 0.59);
}

.assign-cpes-item .badge-status:after {
  content: '';
  position: absolute;
  left: -13px;
  top: 7px;
  display: inline-block !important;
  width: 10px;
  height: 10px;
  margin-right: 0.25rem;
  border: 1px solid #fff;
  border-radius: 50em;
}

.assign-cpes-block {
  animation: shake 1s;
  animation-duration: 0.9s;
}

/*End Assign block */

.set-scale-block {
  display: inline-block;
  max-width: 200px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 60px;
  left: 80px;
  background: white;
  opacity: 0.95;
  padding: 10px;
  border-radius: 3px;
  z-index: 1000;
}

/* Control */

.leaflet-touch .leaflet-control-fit-bounds {
  font-size: 22px;
  font: bold 18px 'Lucida Console', Monaco, monospace;
  text-indent: 1px;
}

.leaflet-search-control {
  width: 160px;
  border-radius: 5px;

  & .form-control {
    padding: 5px;
  }

  & .clear-input {
    cursor: pointer;

    right: 12px;
    top: 6px;
  }
}

.leaflet-clients-control {
  width: 160px;
  background: #ffffff;

  & div {
    max-height: 100px;
    overflow-y: scroll;
  }

  & p {
    cursor: pointer;
    padding: 0.5rem;
    &:hover {
      background: rgba(236, 236, 236, 0.59);
    }
  }
}

.leaflet-client-info {
  width: 160px;
  background: #ffffff;
}

.leaflet-selected-client {
  margin-top: 5px;
  width: 160px;

  background: #ffffff;

  & .leaflet-selected-client-info {
    padding: 0.5rem;
  }
}

.leaflet-selected-client-actions {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
}

.leaflet-mode-control {
  display: flex;
}

.leaflet-mode-control-select {
  width: 160px;
  margin-right: 30px;

  padding-right: 25px;
}

.leaflet-panel-custom-width {
  min-width: 120px !important;
}

.leaflet-show-mode-control {
  width: 106px;
  padding: 6px 10px 6px 6px;

  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;

  background-color: #ffffff;

  & li {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.marker-cluster span {
  white-space: nowrap;
}
.map-context-menu-block {
  display: inline-block;
  max-width: 200px;
  max-height: 100px;
  position: absolute;
  bottom: 10px;
  left: 10px;
  background: #ececec;
  opacity: 0.95;
  padding: 10px;
  border-radius: 3px;
  z-index: 1000;
  min-width: 100px;
  min-height: 30px;
}

.context-menu-item-logo {
  vertical-align: middle;
}

.context-menu-item {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
}

.context-menu-item:hover {
  background: #f8cb00;
}

label.btn > input[type='checkbox'] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.cpe-tooltip .badge-status:after {
  top: 3px;
}
/*.cpe-tooltip-wrapper{*/
/*  background-color: #f4f4f4;*/
/*  border: 1px #a3a3a3 solid;*/
/*  box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 0.5);*/
/*  !*backdrop-filter: blur(1.0px);*!*/
/*}*/
.cpe-tooltip-wrapper{
  /*background-color: #f4f4f4;*/
  /*border: 1px #a3a3a3 solid;*/
  /*box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 0.5);*/
  /*backdrop-filter: blur(1.0px);*/
}

/* End control */

/* Bubble */
.bubble-tooltip {
  border-radius: 20% !important;
}

/*.bubble-tooltip {*/
/*  border-radius: 20% !important;*/
/*  background-color: #f4f4f4;*/
/*  border: 1px #a3a3a3 solid;*/
/*  box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 0.5);*/
/*  !*backdrop-filter: blur(1.0px);*!*/
/*}*/

.bubble-result {
  font-size: 30px;

  &--traffic {
    font-size: 17px;
  }
  &--traffic-rxtx {
    font-size: 10px;
  }
}

.bubble-descr {
  color: #454545;
  font-size: 12px;
}

.selected {
  background: #d9e3ec;
}

.clients-list {
  max-height: 303px;
  overflow-y: auto;
}

.layers-div {
  min-width: 106px;
}
.cpesControl-div {
  min-width: 106px;
  position: absolute;
  right: 130px;
  top: 0px;
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-15px);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translateX(15px);
  }
}
</style>

<style lang="scss" scoped>
.cpe-clients-modal-table-client-row {
  text-align: center;
}

.cpe-clients-modal-table-th {
  text-align: center;
}

.badge-client {
  position: relative;
  white-space: nowrap;
}

.client-name {
  white-space: nowrap;
  position: relative;

  & i {
    position: absolute;
    display: block;
    width: 15px;
    top: 0px;
    left: -38px;
  }

  & i.fa-mobile {
    /*left: 3px;*/
  }

  & .badge-client {
    margin-left: 10px;
  }
  & :after {
    position: absolute;
    display: block;
    top: 1px;
  }
}
.health-status-badge-inactive {
  background-color: #4f5a66 !important;
  color: white !important;
}
.health-status-badge-poor {
  background-color: #f86c6b !important;
  color: white !important;
}
.health-status-badge-fair {
  background-color: #f8cb00 !important;
  color: white !important;
}
.health-status-badge-good {
  background-color: #4dbd74 !important;
  color: white !important;
}
.available-cpe-hint-fullscreen {
  max-width: 200px;
  color: #94a0b2;
  font-size: 0.9em;
}
.datepicker-on-fullscreen-map {
  position: absolute;
  z-index: 99999;
  top: 10px;
  right: 250px;
}
.context-on-fullscreen-map {
  position: absolute;
  z-index: 99999;
}
.position-clients-graph {
  position: relative;
  z-index: 99999;

  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
  align-self: flex-end;
  width: calc(100% - 200px);
  max-width: 600px;
}

.position-heatmap-legend {
  position: absolute;
  z-index: 999;

  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  top: 114px;
  right: 140px;
  align-self: flex-start;
  width: 105px;
  max-width: 600px;
  /*height: 350px;*/
}

.clients-path-timeline {
  background-color: white;

  position: absolute;
  z-index: 99999;
  margin-left: auto;
  margin-right: auto;
  left: 10px;
  top: 220px;
  align-self: flex-end;
  width: 160px;
  height: 70px;
  cursor: default;
}
.clients-path-timeline-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 10px;
}
.slider {
  width: 140px;
}
.slider-buttons {
  height: 100%;
  width: 100%;
  font-size: 1.5em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: 100;
  color: rgba(0, 0, 0, 0.8);
}
.slider-buttons-disable {
  color: rgba(128, 128, 128, 0.51);
  cursor: default;
}
.clients-info-from-api {
  background-color: white;

  position: absolute;
  z-index: 99998;
  margin-left: auto;
  margin-right: auto;
  left: 10px;
  top: 220px;
  align-self: flex-end;
  width: 160px;
  height: 200px;
  cursor: default;
  overflow: auto;
}

.clients-info-from-cluster-click {
  background-color: white;
  position: absolute;
  z-index: 99998;
  margin-left: auto;
  margin-right: auto;
  left: calc(50% - 150px);
  top: calc(40% - 100px);
  align-self: flex-end;
  width: 300px;
  height: 190px;
  cursor: default;
  overflow: hidden;
  padding: 10px;
}

.clients-info-from-cluster-click-list {
  height: 100px !important;
  overflow-y: scroll;
  position: relative;
  top: 40px;
}
.clients-info-from-cluster-click-items-counter {
  position: relative;
  top: 50px;
}
.clients-info-from-cluster-header {
  position: absolute;
  height: 30px;
  width: 280px;
}

.zone-editing {
  background-color: white;
  position: absolute;
  z-index: 99998;
  margin-left: auto;
  margin-right: auto;
  left: 10px;
  top: 152px;
  align-self: flex-end;
  width: 200px;
  height: 300px;
  cursor: default;
  overflow: auto;
}
.new-zone-name {
  background-color: white;
  position: absolute;
  z-index: 99998;
  margin-left: auto;
  margin-right: auto;
  left: 10px;
  top: 152px;
  align-self: flex-end;
  width: 180px;
  height: 70px;
  cursor: default;
  overflow: auto;
}
.new-zone-name-inner {
  padding: 1em;
}
.edit-zone-block {
  background-color: white;
  position: absolute;
  z-index: 99998;
  margin-left: auto;
  margin-right: auto;
  left: 10px;
  top: 152px;
  align-self: flex-end;
  width: 220px;
  height: 170px;
  cursor: default;
  overflow: auto;
}
.creating-zone-block-zone-name-error {
  background-color: white;
  position: absolute;
  z-index: 99998;
  top: 50%; /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */

  transform: translate(-50%, -50%);
  align-self: flex-end;
  width: 250px;
  height: 150px;
  cursor: default;
  overflow: auto;
  border: 2px solid rgba(0, 0, 0, 0.2);
}
.creating-zone-block-intersection-error {
  background-color: white;
  position: absolute;
  z-index: 99998;
  top: 50%; /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */

  transform: translate(-50%, -50%);
  align-self: flex-end;
  width: 250px;
  height: 120px;
  cursor: default;
  overflow: auto;
  border: 2px solid rgba(0, 0, 0, 0.2);
}
.creating-zone-block-intersection-error-close-button {
  position: absolute;
  top: 3px;
  right: 3px;
  font-size: 0.7em;
}
.new-zone-block {
  background-color: white;
  position: absolute;
  z-index: 99998;
  margin-left: auto;
  margin-right: auto;
  left: 10px;
  top: 152px;
  align-self: flex-end;
  width: 200px;
  height: 130px;
  cursor: default;
  overflow: auto;
}
.clients-info-from-api-header {
  font-weight: bold;
}
</style>

<style lang="scss">
  .pulse-heatmap-legend-item {
    animation: pulse-hetamap-legend 1s infinite;
    animation-timing-function: ease-in-out;
    stroke: gray !important;
    stroke-width: 2px;
    stroke-opacity: 1;
  }

  @keyframes pulse-hetamap-legend {
    0% {
      transform:  translate(0.5px, 0);
    }
    50% {
      transform: translate(-0.5px, 0)
    }
    100% {
      transform:  translate(0.5px, 0)
    }

  }

  /*
@keyframes pulse-hetamap-legend {
  0% { transform: translate(0.5px, 0); }
  50% { transform: translate(-0.5px, 0);}
  100% { transform: translate(0.5px, 0); }

}
*/
              .input-group-for-location-select-in-AnalyticsMaps--filters {


    position: relative;
    display: flex;

    align-items: stretch;
    width: 100%;
    max-width: 100%;
    /*z-index: 9997 !important;*/


  }

  .select-location-in-AnalyticsMaps-filters .select__list-container {
    width: 400px;
  }

  .interface-channel-trapezoid__ht160 {
    font-size: 1em;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    width: 25%;
    box-sizing: border-box;
    min-width: 210px;
    max-width: 300px;
    margin: 6px -1px;
    height: 0;
    border: 10px solid rgba(0, 0, 0, 0);
    border-top: 0 solid;
    border-bottom: 30px solid #bcc5d0;
    text-align: center;
    line-height: 30px;
    color: #fff;
    cursor: pointer;
    transition: border-bottom-color 0.5s;
  }

  .interface-channel-trapezoid__ht160:hover {
    border-bottom-color: #8cd4a5;
  }

  .active.interface-channel-trapezoid__ht160 {
    border-bottom-color: #4ebd75;
  }

  .interface-channel-trapezoid__ht160.disabled {
    cursor: not-allowed;
  }
.custom-cluster-for-heatmaps {
  width: 40px;
  height: 40px;
  background-color: #ffac3a;
  text-align: center;
  font-size: 11px;
  color: black;
  border-radius: 50%;
  display: flex; /* or inline-flex */
  align-items: center;
  justify-content: center;
}
.custom-many-clients-for-heatmaps {
  width: 40px;
  height: 40px;
  background-color: #ffac3a;
  background-image: url('/static/img/users-heatmap.svg');
  text-align: center;
  font-size: 10px;
  color: black;
  border-radius: 50%;
  display: flex; /* or inline-flex */
  align-items: center;
  justify-content: center;
}
@keyframes client-path-shake-icon-fade {
  from {
    opacity: 0.5;
    width: 55px;
    height: 55px;
  }
}

.client-path-shake-icon {
  animation: client-path-shake-icon-fade 0.5s alternate;
}
.arrow-icon-for-clients-path {
  width: 14px !important;
  height: 14px !important;
}

.arrow-icon-for-clients-path > div {
  margin-left: -1px !important;
  margin-top: -3px !important;
  transform-origin: center center !important;
  font: 12px/1.5 'Helvetica Neue', Arial, Helvetica, sans-serif !important;
}
.cpe-statistic-in-tooltip {
  margin-top: -5px !important;
}
.edit-zone-block-buttons-wrapper {
  position: absolute;
  bottom: 15px;
  width: 80%;
}
.cpes-list-in-heatmap-wrapper {
  position: absolute;
  z-index: 99999;
  top: 100px;
  cursor: default !important;
  position: relative !important;
}
.maps-list-in-heatmap-wrapper {
  position: absolute;
  z-index: 99999;
  bottom: 0px;
  cursor: default !important;
  //position: relative !important
}
.maps-list-in-heatmap-inner {
  height: 288px;
  overflow-y: scroll;
  margin-bottom: 10px;
}

.cpes-list-in-heatmap {
  cursor: default !important;
}
.zones-on-heatmap-tooltip {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.modal-more-cpe-info .modal-content {
  position: relative !important;
  top: 50px;
}
.modal-with-cpe-users-info .modal-content {
  position: relative !important;
  top: 50px;
}
.loader-maps:after {
  position: absolute;
  /*top: calc(50vh);*/
  top: 30px;
}
.custom-border-for-client-info {
  border: rgba(0, 0, 0, 0.15) solid 1px !important;
}
.modal-with-more-cpe-info-in-heatmap {
  cursor: default !important;
}
.modal-with-more-cpe-info-in-heatmap input {
  cursor: default !important;
}
.modal-with-more-cpe-info-in-heatmap .multiselect-disabled-field {
  cursor: default !important;
}
.modal-with-more-cpe-info-in-heatmap .select-disabled {
  cursor: default !important;
}
.leaflet-draw-actions a {
  background-color: whitesmoke !important;
  color: black !important;
}
.leaflet-bottom .leaflet-control-zoom,
.leaflet-control-fit-bounds,
n {
  display: inline-block !important;
  float: none !important;
}
.leaflet-control-fullscreen {
  position: relative !important;
  bottom: -26px !important;
  right: 7px !important;
}
.leaflet-control-fullscreen a {
  background-size: 26px 52px !important;
  /*background-position: 2px, 2px !important;*/
}
.leaflet-bottom.leaflet-right .leaflet-bar a {
  width: 26px !important;
  height: 26px !important;
  /*padding-left: 7px;*/
}
.leaflet-bottom.leaflet-right .leaflet-bar {
  border: 1px #cccccc sold;
}
.nightClientsSwitcherText {
  font-size: 0.9em !important;
  cursor: help;
  position: relative;
  top: -2px;
}
#night-clients-switcher-checkbox {
  transform: scale(0.8);
}
.wrapper-for-night-clients-switcher {
  text-align: center;
  /*height: 15px;*/
  /*line-height: 15px;*/
}
/*  !* Leaflet crispness override *!*/
/*#map.leaflet-container .leaflet-overlay-pane svg,*/
/*#map.leaflet-container .leaflet-marker-pane img,*/
/*#map.leaflet-container .leaflet-shadow-pane img,*/
/*#map.leaflet-container .leaflet-tile-pane img,*/
/*#map.leaflet-container img.leaflet-image-layer {*/
/*  max-width: none !important;*/
/*  !* Preserve crisp pixels with scaled up images *!*/
/*  image-rendering: optimizeSpeed;             !* Legal fallback *!*/
/*  image-rendering: -moz-crisp-edges;          !* Firefox        *!*/
/*  image-rendering: -o-crisp-edges;            !* Opera          *!*/
/*  image-rendering: -webkit-optimize-contrast; !* Safari         *!*/
/*  image-rendering: optimize-contrast;         !* CSS3 Proposed  *!*/
/*  image-rendering: crisp-edges;               !* CSS4 Proposed  *!*/
/*  image-rendering: pixelated;                 !* CSS4 Proposed  *!*/
/*  -ms-interpolation-mode: nearest-neighbor;   !* IE8+           *!*/
/*}*/

#map.map-class-for-image-rendering-hack .leaflet-container .leaflet-overlay-pane svg,
#map.map-class-for-image-rendering-hack .leaflet-container .leaflet-marker-pane img,
#map.map-class-for-image-rendering-hack .leaflet-container .leaflet-shadow-pane img,
#map.map-class-for-image-rendering-hack .leaflet-container .leaflet-tile-pane img,
#map.map-class-for-image-rendering-hack img.leaflet-image-layer {
  image-rendering: -webkit-optimize-contrast; /* webkit   hack   */
}
</style>
